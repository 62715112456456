import {combineReducers} from '@reduxjs/toolkit'

import invoiceReducer from './invoiceSlice'
import quoteReducer from './quoteSlice'
import dealsReducer from './dealsSlice'

const salesReducer = combineReducers({
  invoice: invoiceReducer,
  quote: quoteReducer,
  deals: dealsReducer,
})

export default salesReducer
