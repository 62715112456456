import React, { useEffect, useState } from 'react';
import { Developer } from '../core/_models';
import { useParams } from 'react-router-dom';
import { getCompanyById } from '../core/_requests';
import { ProperList } from './ProperList';
import { Launch } from '../../event/components/Launch';
import { Notes } from './Notes';


const DeveloperDetails: React.FC = () => {
    const [data, setData] = useState<Developer>({})
	const [property, setProperty] = useState("general")
	const companyId = useParams().id
	const PF:any = "https://unifeed.s3.ap-south-1.amazonaws.com/";
	
	useEffect(() => {
		const Companydetail = async() => {
			const res = await getCompanyById(companyId)
			setData(res)
			}
			Companydetail()
	}, [companyId])
  return (
    <div>
		    <div>

								{/* <!--begin::Content container--> */}
								
									{/* <!--begin::Form--> */}
									<form id="kt_ecommerce_add_product_form" className="form d-flex flex-column flex-lg-row" /* data-kt-redirect="../../demo1/dist/apps/ecommerce/catalog/products.html" */>
										{/* <!--begin::Aside column--> */}
										<div className="d-flex flex-column gap-7 m-4 my-12 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
											{/* <!--begin::Thumbnail settings--> */}
											<div className="card card-flush py-4">
												{/* <!--begin::Card header--> */}
												<div className="card-header">
													{/* <!--begin::Card title--> */}
													{/* <div className="card-title">
														<h2>Thumbnail</h2>
													</div> */}
													{/* <!--end::Card title--> */}
												</div>
												{/* <!--end::Card header-->
												<!--begin::Card body--> */}
												<div className="card-body text-center pt-0">
													<img src={PF+data.productPic} alt="pic" className='' />

												</div>
												{/* <!--end::Card body--> */}
											</div>
											{/* <!--end::Thumbnail settings-->
											<!--begin::Status--> */}
											<div className="card card-flush py-4">
												{/* <!--begin::Card header--> */}
												<div className="card-header">
													{/* <!--begin::Card title--> */}
													<div className="card-title">
														<h4 className=' text-muted'>Company</h4>
													</div>
													{/* <!--end::Card title-->
													<!--begin::Card toolbar--> */}
													{/* <div className="card-toolbar">
														<div className="rounded-circle bg-success w-15px h-15px" id="kt_ecommerce_add_product_status"></div>
													</div> */}
													{/* <!--begin::Card toolbar--> */}
												</div>
												{/* <!--end::Card header-->
												<!--begin::Card body--> */}
												<div className="card-body pt-7 ">
												<h2 className=' text-center'>{data.name}</h2>
												<p className=' text-center'>LIVE THE LUXURY</p>
													
													
													{/* <!--end::Select2-->
													<!--begin::Description--> */}
													 <div className="text-muted fs-7 items-center align-middle flex justify-center py-4">{data.address}</div> 
													{/* <!--end::Description-->
													<!--begin::Datepicker--> */}
													
													{/* <!--end::Datepicker--> */}
												</div>
											{/* <!--end::Card body--> */}
											</div>
											{/* <!--end::Status-->
											<!--begin::Category & tags--> */}
											
											{/* <!--end::Category & tags-->
											<!--begin::Card widget 6--> */}
											<div className="card  card-flush">
												{/* <!--begin::Header--> */}
												<div className=" bg-blue-400 rounded-md pt-5">
													{/* <!--begin::Title--> */}
													<div className="card-title py-5 px-3  d-flex flex-column">
														{/* <!--begin::Info--> */}
														<div className="d-flex gap-8 align-items-center">
															
															<span className="fs-4hx fw-bold text-white me-2 lh-1 ls-n2">44</span>
															{/* <!--end::Amount-->
															<!--begin::Badge--> */}
															<span className="text-3xl text-white fs-semibold">
															Number of Units</span>
															{/* <!--end::Badge--> */}
														</div>
														
													</div>
													{/* <!--end::Title--> */}
												</div>
												
											</div>
											
											<div className="card card-flush">
												{/* <!--begin::Header--> */}
												<div className="card-header pt-5">
													{/* <!--begin::Title--> */}
													<div className="card-title d-flex py-5 flex-column">
														{/* <!--begin::Info--> */}
														<div className="d-flex gap-8 align-items-center">
															<div className='flex flex-col'>
															<span className="fs-4hx fw-bold text-blue-400 me-2 lh-1 ls-n2">1.5</span>
															<span className=' text-blue-400 pl-4'>Million</span>
															</div>
															{/* <!--end::Amount-->
															<!--begin::Badge--> */}
															<span className=" text-3xl text-blue-400 fs-semibold">
															Average Price</span>
															{/* <!--end::Badge--> */}
														</div>
														
													</div>
													{/* <!--end::Title--> */}
												</div>
												
											</div>
											{/* <!--end::Card widget 6-->
											<!--begin::Template settings--> */}
											
											{/* <!--end::Template settings--> */}
										</div>
										{/*  <!--end::Aside column--> */}

						{/* right side */}				
										<div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
												<div className='d-flex h-55px'>
													<ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap cursor-pointer'>
													
														<li className='nav-item'>
														<div
															className={
															`nav-link text-active-primary me-6  
															${property === "general" ? " active" :""}`} onClick={()=>setProperty("general")
															}>
															
														
															General
															</div>
														</li>
														<li className='nav-item'>
														<div
															className={
															`nav-link text-active-primary me-6  
															${property === "properties" ? " active" :""}`} onClick={()=>setProperty("properties")
															}>
															
														
															Properties
															</div>
														</li>
														<li className='nav-item'>
														<div
															className={
															`nav-link text-active-primary me-6  
															${property === "events" ? " active" :""}`} onClick={()=>setProperty("events")
															}>
															
														
															Events
															</div>
														</li>
														
														<li className='nav-item'>
														<div
															className={
															`nav-link text-active-primary me-6  
															${property === "deals" ? " active" :""}`} onClick={()=>setProperty("deals")
															}>
															Deals
															</div>
														</li>
													</ul>
												</div>
												{
													property==="general" && (
														<div className='m-2 '>
															<div>
																<h4 className=' text-muted'>Company</h4>
																<p className=' font-semibold '>{data.name}</p>
																<p className='leading-none text-muted '>{data.address}</p>
														    </div>
															<div className='pt-6'>
																<h4 className=' text-muted'>Description</h4>
																<p className=' font-medium'>{data.description}</p>
															</div> 
															
                                                            
															{/* <!--begin::Table widget 14--> */}
											               <div className="card card-flush mb-7">
												
																<div className="card-header pt-7">
																	
																	<h3 className="card-title align-items-start flex-column">
																		<span className="card-label fw-bold text-gray-800">Contact</span>
																	
																	</h3>
																	
																	
																	
																</div>
																
																<div className="card-body pt-6">
																	
																	<div className="table-responsive">
																		
																		<table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
																			
																			<thead>
																				<tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
																					<th className="  text-start">Name</th>
																					<th className=" ">Official Address</th>
																					<th className=" ">Developer Id</th>
																					<th className="  ">Contact</th>
																					<th className="   ">Email</th>
																					
																				</tr>
																			</thead>
																			
																			<tbody>
																				<tr>
																					<td>
																						<div className="d-flex align-items-center">
																							
																							<div className="d-flex justify-content-start flex-column">
																								<span className="text-indigo-950 fw-bold text-hover-primary ">{data.name}</span>
																								
																							</div>
																						</div>
																					</td>
																					<td className=" ">
																						<span className="text-indigo-950 fw-bold fs-6">{data.address}</span>
																					</td>
																					<td className=" ">
																						<span className="text-indigo-950 fw-bold fs-6">{data._id}</span>
																					</td>
																					<td className="">
																						
																					<span className="text-indigo-950 fw-bold fs-6">{data.phoneNo}</span>
																						
																					</td>
																					<td className=" ">
																					<span className="text-indigo-950 fw-bold fs-6">{data.email}</span>
																						</td>
																					
																					
																				</tr>
																			</tbody>
																			
																		</table>
																	</div>
																	
																</div>
																
															</div>
															
															<div className="card card-flush">
																<Notes className='card card-flush' noteslist={data.notes}/>
															</div>
												    </div>
                                                )}
												{
													property==="properties" && (
														<div className='m-3 '>
															<ProperList/>

												</div>
                                                )}
												{
													property==="events" && (
														<div className='m-3 '>
															<Launch/>

												</div>
                                                )}
												{
													property==="deals" && (
														<div className='m-3 '>
															<p>Deals</p>

												</div>
                                                )}
										</div>
									</form> 
			                  </div>
			
						
    </div>
  );
};

export default DeveloperDetails;