import {useEffect, useRef, useState} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Step4} from './steps/Step4'

import {StepperComponent} from '../../../../../_metronic/assets/ts/components'
import {Form, Formik, FormikValues} from 'formik'
import {createAccountSchemas, ICreateAccount, inits} from './CreateAccountWizardHelper'
import { addProperty, postImages, postMultipleImages } from '../../core/_requests'
import { Step5 } from './steps/Step5'
import {  useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../../../modules/auth'

const Vertical = () => {
  const {currentUser} = useAuth()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  // const [images, setimages] = useState([])
  // const [imagename, setimagename] = useState('')
  const id = useParams().id
  const Navigate = useNavigate()

  // const submitdata = async(values) =>{
  //   const data = {

  //   }
  //   const res = postproperty()
  // }
  const submitImages = async (values) => {
    // Create a FormData object
    const formData = new FormData();

    for (let i = 0; i < values.images.length; i++) {
      // imagesFormData.append('name', fieldValue.imagesname[i]);
      formData.append('image', values.images[i]);
    }
    formData.append('name', values.imagesname);

    await postMultipleImages(formData)
  
    // Append each image to FormData with its unique name
    // values.images.forEach((image, index) => {
     
    //   const uniqueName = values.imagesname[index]; 
    //   formData.append('name', uniqueName);
    //   formData.append('image', image); // Use a more meaningful name if needed
    //   postImages(formData);
    // });
  
   
  };

  // useEffect(() => {
  //   // This useEffect will be triggered whenever imagename or images are updated
  //   // You can perform additional actions here if needed
  //   console.log("Images and imagename updated:", images, imagename);
  // }, [images, imagename]);

  


  const submitImage = async (values) => {
    // Create a FormData object
    const formData = new FormData();
  
    // Append each image to FormData with its unique name
    const image = values.profileImage

      const uniqueName =  values.profileImagename; 
    console.log()
      formData.append('name', uniqueName);
      formData.append('image', image); // Use a more meaningful name if needed
      postImages(formData);

  
   
  };



  const submitdata = async (values) => {

    const data = {
      name: values.propertyName,
      productPic: values.profileImagename,
      mediaPic:values.imagesname,
      units: values.propertyUnits,
      startingprice: values.propertyPrice,
      descriptor: values.propertyDescriptor,
      description: values.propertyDescription,
      email: values.propertyEmail,
      phone: values.propertyPhone,
      address: values.propertyAddress,
      lat: values.propertyLatitude,
      lng: values.propertyLongitude,
      projectType: values.propertyType,
      area: values.propertyArea,
      status: values.Propertystatus,
      handOver: values.PropertyHandover,
      aminities: values.propertyAnimities,
      developer:id

    }

    const res = await addProperty(data,currentUser?.company)
    console.log(res)
  
  }
  
  
  const savedata =async (values) => {


    await submitImages(values).then(async (res)=>{
      await submitImage(values).then(async (res) => (
        await submitdata(values)
      ))
    })


  }
  
  // Call the submitImages function when needed
  
  


  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
     return
    }

    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      stepper.current.goNext()
    } else {
      savedata(values)
       actions.resetForm()
       Navigate("/property/developer")
    }

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
      id='kt_create_account_stepper'
    >
      {/* begin::Aside*/}
      <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
        {/* begin::Wrapper*/}
        <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
          {/* begin::Nav*/}
          <div className='stepper-nav'>
            {/* begin::Step 1*/}
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>1</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Poperty Details</h3>

                  <div className='stepper-desc fw-semibold'>Setup Your Property Type</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 1*/}

            {/* begin::Step 2*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>2</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Property Details</h3>
                  <div className='stepper-desc fw-semibold'>Setup Your Poperty Info</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 2*/}

            {/* begin::Step 3*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>3</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Property Features</h3>
                  <div className='stepper-desc fw-semibold'>Setup Property Features</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 3*/}

            {/* begin::Step 4*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>4</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                <h3 className='stepper-title'>Property Information</h3>
                  <div className='stepper-desc fw-semibold'>Other important Property informations</div>
                </div>
                
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 4*/}

            {/* begin::Step 4*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>5</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                <h3 className='stepper-title'>Images</h3>
                  <div className='stepper-desc fw-semibold'>Profile and Media Picture</div>
                </div>
                
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 4*/}

    
          </div>
          {/* end::Nav*/}
        </div>
        {/* end::Wrapper*/}
      </div>
      {/* begin::Aside*/}

      <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
        <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
          {() => (
            <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
              <div className='current' data-kt-stepper-element='content'>
                <Step1 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step2 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step3 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step4 />
              </div>
              <div data-kt-stepper-element='content'>
                <Step5 />
              </div>

            

              <div className='d-flex flex-stack pt-10'>
                <div className='mr-2'>
                  <button
                    onClick={prevStep}
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                  >
                    <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                    Back
                  </button>
                </div>

                <div>
                  <button type='submit' className='btn btn-lg btn-primary me-3'>
                    <span className='indicator-label'>
                      {stepper.current?.currentStepIndex !== 5 && 'Continue'}
                      {stepper.current?.currentStepIndex === 5 && 'Submit'}
                      <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                    </span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export {Vertical}
