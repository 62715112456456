import {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from '../../../../../_metronic/partials'

interface Customer {
  fullName: string
  performancePercentage: number
}

interface Props {
  customers: Customer[]
}

const AgentStatBar: React.FC<Props> = ({customers}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  useEffect(() => {
    const refreshChart = () => {
      if (!chartRef.current || customers.length === 0) {
        return
      }

      const chartData = customers.map((customer) => ({
        x: customer.fullName,
        y: customer.performancePercentage,
      }))

      const chart = new ApexCharts(chartRef.current, getChartOptions(chartData))
      chart.render()

      return chart
    }
    
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [customers, mode])

  return (
    <div>
      <div
        ref={chartRef}
        id='kt_charts_widget_8_chart'
        style={{height: '350px'}}
        className='card-rounded-bottom'
      ></div>
    </div>
  )
}

export default AgentStatBar

function getChartOptions(chartData: {x: string; y: number}[]): ApexOptions {
  return {
    series: [
      {
        name: 'Performance Percentage',
        data: chartData,
      },
    ],
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      min: 0, // Minimum value of x-axis
      max: 100, // Maximum value of x-axis
      tickAmount: 5, // Show 11 ticks (0, 10, 20, ..., 100)
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: 700,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: 'Inter',
          fontWeight: 700,
        },
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (value: number) => `${value.toFixed(2)}%`, // Format y-axis value to show up to two decimal places
      },
    },
  }
}
