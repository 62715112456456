import React, { useEffect, useRef, useState } from 'react'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'

import { linkPropertyToAgent, searchProperty } from '../core/_requests'
import {useAuth} from '../../../modules/auth/core/Auth'
import { useParams } from 'react-router-dom'
import { debounce } from 'lodash';

function Propertysearch({handleTrigger }) {
    const agentId = useParams().id
  const [users, setUsers] = useState<any[]>([]);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const PF:any = "https://unifeed.s3.ap-south-1.amazonaws.com/";
  const {currentUser} = useAuth()
  
  const handleSearch = async () => {
    try {
      if (searchTerm !== '') {
        const response = await searchProperty(searchTerm, currentUser.company);
        setUsers(response);
      } else {
        setUsers([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const debouncedSearch = debounce(handleSearch, 500);
  useEffect(() => {

    debouncedSearch();

    return () => debouncedSearch.cancel();
  }, [searchTerm]);

  const handleClickList = (e: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
      setUsers([]);
      setSearchTerm('');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickList);
    return () => {
      document.removeEventListener('mousedown', handleClickList);
    };
  }, []);
  
  const onAddAgent = async (inventoryId,index) => {
    const ids = {
      agentId,
      inventoryId,
    };
    
    const res = await linkPropertyToAgent(ids);
    const tuser = [...users]
    tuser[index] = res
    setUsers(tuser)
    handleTrigger()
    };


  return (
    <div className="modal fade" tabIndex={-1} id="kt_modal_12">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Assign Property</h5>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon svg-icon-2x"
            />
          </div>
        </div>
  
        <div className="modal-body">
        <div className="mb-10">
    <label className="form-label">Search Property</label>
    <input
      type="text"
      className="form-control"
      placeholder="Name"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
  
  
  
  <div ref={modalRef} className='serach'>
  
  <div className="search-list" >
  <div className='line'></div>
  
  
      <div className='card card-xl-stretch mb-xl-8' >
        {/* begin::Body */}
        {users.map((user,index) => (
        <div className='card-body pt-3' key={index}>
          {/* begin::Item */}
          <div className='d-flex align-items-sm-center mb-7'>
            {/* begin::Symbol */}
            <div className='symbol symbol-60px symbol-2by3 me-4'>
              <div
                className='symbol-label'
                style={{backgroundImage: `url(${PF+user.productPic})`}}
              ></div>
            </div>
            <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
              <div className='flex-grow-1 me-2'>
                <div className='text-gray-800 fw-bold text-hover-primary fs-6'>
                {user.name}
                </div>
                <span className='text-muted fw-semibold d-block pt-1'>{user.projectType}</span>
              </div>
  
              <div className='card-toolbar' onClick={()=>onAddAgent(user._id,index)}>
            <button  className={`${!user.agent.includes(agentId) ? "btn-light-primary" :"btn-light-danger"} btn btn-sm `}>
              <KTIcon iconName='plus' className='fs-2' />
              {
              !user.agent.includes(agentId) ? "Assign": "Remove"
              }
            </button>
          </div>
  
            </div>
          </div>
        </div>
        ))}
      </div>
   
    
  
  </div>
  
  </div>
  
  
   {/* <img
        className='postProfilePic'
        src={PF+user.profilePic}
        alt={PF + 'person/noAvatar.png'}
      />
    <Link  className="search-link" to={`/activity/${user._id}`} onClick={() => setSearchTerm("")}>{user.userName}</Link>
    <button onClick={()=>onAddAgent(user._id,index)} className= {!user.client.includes(agentId) ? "btn btn-primary": "btn btn-danger"}> 
    {
        !user.client.includes(agentId) ? "Add": "Remove"
      }
     </button>
    <div className='line'></div> */}
  
  
  
    </div>
    
      
        </div>
  
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            data-bs-dismiss="modal"
            id="closeModalButtondel" 
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-light"
            data-bs-dismiss="modal"
            id="closeModalButtondel" 
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </div>
    
  )
}

export default Propertysearch