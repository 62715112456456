import * as Yup from 'yup'

export interface ICreateAccount {
  propertyType: string
  propertyPrice: string,
  propertyUnits: string,
  propertyAnimities: Array<string>,
  propertyArea: string
  propertyLatitude: string
  propertyLongitude: string
  Propertystatus: string
  PropertyHandover: string
  propertyName: string
  propertyDescriptor: string
  propertyAddress: string
  propertyDescription: string
  propertyEmail: string
  propertyPhone: string

  images: File[]; 
  profileImage: File | null;
}

const createAccountSchemas = [
  Yup.object({
    propertyType: Yup.string().required().label('Property Type'),
  }),
  Yup.object({
    propertyName: Yup.string().required().label('Property Name'),
    propertyDescriptor: Yup.string().required().label('Shortened Descriptor'),
    propertyAddress: Yup.string().required().label('Property Address'),
    propertyLatitude: Yup.string().required().label('Latitude'),
    propertyLongitude: Yup.string().required().label('Longitude'),
    propertyDescription: Yup.string().required().label('Property Description'),
    propertyEmail: Yup.string().required().label('Contact Email'),
    propertyPhone: Yup.string().required().label('Contact Phone'),
  }),
  Yup.object({
    propertyPrice: Yup.string().required().label('Property Price'),
    propertyUnits: Yup.string().required().label('Property Units'),
    propertyAnimities: Yup.array().required().label('Property Animities'),
  }),
  Yup.object({
    propertyArea: Yup.string().required().label('Area'),

    Propertystatus: Yup.string().required().label('Property Status'),
    PropertyHandover: Yup.string().required().label('Property Handover Year'),
  }),

  Yup.object({
    images: Yup.array().required().label('Images'),
    profileImage: Yup.mixed().required().label('Profile Image'),
    imagesname: Yup.array().required().label(' Image name'),
    profileImagename: Yup.string().required().label('Profile Image name'),
  }),

]

const inits: ICreateAccount = {
  propertyType: 'primary',
 
  propertyPrice:'',
  propertyUnits:'',
  propertyAnimities:[],
  propertyArea:'',
  propertyLatitude:'',
  propertyLongitude:'',
  Propertystatus:'',
  PropertyHandover:'',
  propertyName: '',
  propertyDescriptor: '',
  propertyAddress: '',
  propertyDescription: '',
  propertyEmail: '',
  propertyPhone: '',

  images: [],


  profileImage: null,
}

export {createAccountSchemas, inits}
