import {Suspense, createContext, useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {ThemeModeProvider} from '../_metronic/partials'
import {useJsApiLoader} from '@react-google-maps/api'
import toast, {Toaster} from 'react-hot-toast'
import {onMessage} from 'firebase/messaging'
import {messaging} from './firebase'
import {Provider} from 'react-redux'
import store from './redux/store'

export const FilterContext = createContext<any>(null)

const App = () => {
  // Google map api initialization
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  })

  const [filter, setFilter] = useState<any>({
    status: '',
    category: '',
    source: '',
    agent: '',
    country: '',
    assignedDate: '',
  })

  useEffect(() => {
    onMessage(messaging, (payload) => {
      console.log('Received message on focused tab', payload)
      toast.success(payload.notification.title)
    })
  }, [])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Provider store={store}>
        <I18nProvider>
          <LayoutProvider>
            <ThemeModeProvider>
              <AuthInit>
                <FilterContext.Provider value={{filter, setFilter}}>
                  <Outlet />
                  <MasterInit />
                  <Toaster position='top-right' reverseOrder={false} />
                </FilterContext.Provider>
              </AuthInit>
            </ThemeModeProvider>
          </LayoutProvider>
        </I18nProvider>
      </Provider>
    </Suspense>
  )
}

export {App}
