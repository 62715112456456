/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {getPermissionsForHierarchy1, getRoles, getUserByToken, login} from '../core/_requests'
import {KTIcon} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import axios, {AxiosResponse} from 'axios'

import {generateToken} from '../../../firebase'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  subscribeToNotifications: Yup.boolean(),
})

const initialValues = {
  email: '',
  password: '',
  subscribeToNotifications: false,
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [showPassword, setShowPassword] = useState(false)
  const fcmTokenRef = useRef(null)
  const accessKey = 'AmHisDy8RCzQimJO9Zd8'
  const accessSecret = 'DspyhiNsk3Xr5fdV8wcx'

  async function getAuthToken(userEmail: string, accessKey: string, accessSecret: string) {
    const url = 'https://api.maqsam.com/v2/token'
    try {
      const response: AxiosResponse = await axios.post(url, `UserEmail=${userEmail}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        auth: {
          username: accessKey,
          password: accessSecret,
        },
      })

      if (response.status === 200) {
        return response.data?.result?.token
      } else {
        throw new Error(`Failed to get authentication token. Status: ${response.status}`)
      }
    } catch (error) {
      console.error('Error:', error.message)
      // window.location.reload()
      throw new Error(`Error getting authentication token: ${error.message}`)
    }
  }
  // Function to perform auto-login
  async function autoLogin(authToken: any) {
    const continuePath = 'https://portal.maqsam.com/phone/dialer'
    const url = `https://portal.maqsam.com/autologin?auth_token=${authToken}&continue_path=${continuePath}`

    try {
      // Open the URL in a new tab
      const newTab = window.open(url, '_blank')

      // Delay the closing of the new tab (adjust the time as needed)
      setTimeout(() => {
        // Close the new tab
        if (newTab) {
          newTab.close()
          // window.location.reload()
        }
      }, 1000) // 1000 milliseconds = 1 second
    } catch (error) {
      throw new Error(`Error during auto-login: ${error.message}`)
    }
  }
  useEffect(() => {
    const generateFcmToken = async () => {
      try {
        const token = await generateToken()
        fcmTokenRef.current = token
      } catch (error) {
        console.error('Error generating FCM token:', error.message)
      }
    }

    generateFcmToken()
  }, [])
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        let token = null
        token = await generateToken()
        const {data: auth} = await login(values.email, values.password, token)
        saveAuth(auth)

        const {data: user} = await getUserByToken(auth.api_token)
        if (user.profileType !== 'superadmin' && user?.hierarchy !== 1) {
          const data = await getRoles(user?.department, user?.profileType?.toLowerCase())
          user.permissions = data
        } else if (user.hierarchy === 1) {
          const data = await getPermissionsForHierarchy1(
            user.company,
            user.profileType.toLowerCase()
          )
          user.permissions = data
        }
        setCurrentUser(user)

        try {
          if (user.app.telecom === true) {
            const authToken = await getAuthToken(values.email, accessKey, accessSecret)
            await autoLogin(authToken)
          }
        } catch (authError) {
          console.error('Error during authentication:', authError.message)
        }
      } catch (error) {
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        <div className='text-gray-500 fw-semibold fs-6'>To simplify realestate</div>
      </div>
      {/* begin::Heading */}

      {/* begin::Login options */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>Login using email and password provided by your company</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        {/* <div className='position-relative d-flex align-items-center justify-content-end'>
          <input
            type={showPassword ? 'text' : 'password'}
            autoComplete='off'
            placeholder='Password'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          <span className='input-group-text btn btn-sm btn-outline h-100 py-3' onClick={togglePasswordVisibility}>
            {showPassword ? (
              // <i className='bi bi-eye-slash-fill'></i>
              <KTIcon iconName='eye-slash' iconType='solid' className='fs-3 text-dark h-100'/>
            ) : (
              // <i className='bi bi-eye-fill'></i>
              <KTIcon iconName='eye' iconType='solid' className='fs-3 text-dark h-100'/>
            )}
          </span>
        </div> */}
        <div className='position-relative d-flex align-items-center justify-content-end'>
          <input
            type={showPassword ? 'text' : 'password'}
            autoComplete='off'
            placeholder='Password'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
            style={{height: '100%'}}
          />
          <span
            className='input-group-text btn btn-sm btn-outline h-100 py-3'
            onClick={togglePasswordVisibility}
            style={{height: '100%'}}
          >
            {showPassword ? (
              <KTIcon iconName='eye-slash' iconType='solid' className='fs-3 text-muted' />
            ) : (
              <KTIcon iconName='eye' iconType='solid' className='fs-3 text-muted' />
            )}
          </span>
        </div>

        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      {/* <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />


        <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link>

      </div> */}
      {/* end::Wrapper */}
      {/* begin::Form group for Subscription Checkbox */}
      {/* <div className='fv-row mb-3'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            type='checkbox'
            className='form-check-input'
            id='subscribeToNotifications'
            {...formik.getFieldProps('subscribeToNotifications')}
          />
          <label className='form-check-label' htmlFor='subscribeToNotifications'>
            Subscribe for Notifications
          </label>
        </div>
      </div> */}
      {/* end::Form group for Subscription Checkbox */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
      {/* 
      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div> */}
    </form>
  )
}
