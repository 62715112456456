import React, {FC, useEffect, useState} from 'react'
import {StatisticsWidget5} from '../../../../_metronic/partials/widgets'
import { getclientcount, getclientcountagent, getclientpipecount, getclientpipecountagent } from '../core/_requests';
import { useAuth } from '../../../modules/auth';
import {useSelector, useDispatch} from 'react-redux'
import { RootState } from '../core/_models';
import { setPipelineCounts, setTotalClients } from '../../../redux/client/clientStatsSlice';

const Clientstat: FC = () => {
  const pipelineCount = useSelector((state: RootState)=> state.client.clientStat.pipelineCount)
  const totalClients = useSelector((state: RootState)=> state.client.clientStat.totalClients)
  // const [totalClient, settotalClient] = useState<any>("Loading")
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  const dispatch = useDispatch()

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])
  
  const getclientpipecounts = async () => {
    try {
      if(currentUser?.profileType === "superadmin" || permissions?.clients?.admin){
        const rescount = await getclientpipecount(currentUser?.company)
        const totalclient = await getclientcount(currentUser?.company)
        // setcount(rescount);
        dispatch(setPipelineCounts(rescount))
        // settotalClient(totalclient.totalClients)
        dispatch(setTotalClients(totalclient?.totalClients))
        // console.log(rescount)
      }else  if(permissions?.clients?.admin === false){
        const rescount = await getclientpipecountagent(currentUser?.company,currentUser?._id)
        const totalclient = await getclientcountagent(currentUser?._id)
        // setcount(rescount);
        dispatch(setPipelineCounts(rescount))
        // settotalClient(totalclient.totalClients)
        dispatch(setTotalClients(totalclient?.totalClients))
        // console.log(rescount)
        // console.log(totalclient)
      }
      
     
      
    
    } catch (error) {
      console.error('Error fetching client list:', error);
    }
  };

  useEffect(() => {
    if(totalClients === "Loading" && pipelineCount?.noanswer === "Loading" && pipelineCount?.callback === "Loading" && pipelineCount?.reject === "Loading"){
      getclientpipecounts()
    }
  }, [permissions?.clients])
  
  return (
    <>
    

      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='address-book'
            color='primary'
            iconColor='light'
            title={`${totalClients} Total`}
            titleColor='light'
            description='Clients'
            descriptionColor='light'
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='shield-slash'
            color='dark'
            iconColor='light'
            title={`${pipelineCount?.noanswer} No Answers`}
            titleColor='light'
            description='Clients'
            descriptionColor='light'
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='abstract-18'
            color='dark'
            iconColor='light'
            title={`${pipelineCount?.callback} Call Back`}
            titleColor='light'
            description='Clients'
            descriptionColor='light'
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='abstract-11'
            color='danger'
            iconColor='light'
            title={`${pipelineCount?.reject} Rejected`}
            titleColor='light'
            description='Clients'
            descriptionColor='light'
          />
        </div>
      </div>
      {/* end::Row */}

     
    </>
  )
}

export {Clientstat}
