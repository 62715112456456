import React, {FC, useEffect, useState} from 'react'
import {ErrorMessage, Field, useFormikContext} from 'formik'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import loadScript from 'load-script';

const Step2: FC = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
    const [locationValue, setLocationValue] = useState('');
    const [locationName, setLocationName] = useState('');
    const [bool, setbool] = useState(false);
    const { setFieldValue } = useFormikContext();
    
  useEffect(() => {
    const loadGoogleScript = async () => {
      try {
        await new Promise<void>((resolve, reject) => {
          loadScript(
            `https://maps.googleapis.com/maps/api/js?key=AIzaSyA7grj1MqTFqXCMFr_iRHTrAtZSCU3opDQ&libraries=places`,
            (error) => {
              if (error) {
                reject(error);
              } else {
                resolve();
              }
            }
          );
        });
        setScriptLoaded(true);
      } catch (error) {
        console.error('Error loading Google Maps script', error);
      }
    };

    loadGoogleScript();
  }, []);

  const handleSelect = async (address: string) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setLocationName(results[0].formatted_address)
      console.log(latLng.lat)
      setbool(true)
      setFieldValue('propertyAddress',results[0].formatted_address);
      setFieldValue('propertyLatitude', latLng.lat);
      setFieldValue('propertyLongitude',latLng.lng );

    } catch (error) {
      console.error('Error selecting location', error);
    }
  };

  if (!scriptLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className='w-100'>
    <div className='pb-10 pb-lg-12'>
      <h2 className='fw-bolder text-dark'>Property Details</h2>
{/* 
      <div className='text-gray-400 fw-bold fs-6'>
        If you need more info, please check out
        <a href='/dashboard' className='link-primary fw-bolder'>
          {' '}
          Help Page
        </a>
        .
      </div> */}
    </div>

    <div className='fv-row mb-10'>
      <label className='form-label required'>Property Name</label>

      <Field name='propertyName' className='form-control form-control-lg form-control-solid' />
      <div className='text-danger mt-2'>
        <ErrorMessage name='propertyName' />
      </div>
    </div>

    <div className='fv-row mb-10'>
      <label className='d-flex align-items-center form-label'>
        <span className='required'>Shortened Descriptor</span>
      </label>

      <Field
        name='propertyDescriptor'
        className='form-control form-control-lg form-control-solid'
      />
      <div className='text-danger mt-2'>
        <ErrorMessage name='propertyDescriptor' />
      </div>

      <div className='form-text'>
        Agents will see this shortened version of your statement descriptor
      </div>
    </div>

    <div className='fv-row mb-10'>
      <label className='d-flex align-items-center form-label'>
        <span className='required'>
          Property Address
        </span>
      </label>

      {
        bool && (
          <input
       
          className='form-control form-control-lg form-control-solid'
          value={locationName}
          onClick={()=>setbool(false)}
          readOnly
          
        />
        )
      }

      {
        !bool && (

          <PlacesAutocomplete
          value={locationValue}
          onChange={(value) => setLocationValue(value)}
          onSelect={handleSelect}
        >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
  
         
            <input
          {...getInputProps({
            placeholder: 'Search Places...',
            className: 'location-search-input',
          })}
          className='form-control form-control-lg form-control-solid'
          value={locationValue}
         
          
        />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, { className })}
                    key={suggestion.placeId}
                    className=' cursor-pointer form-control form-control-lg form-control-solid'
              
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
  
        )
      }



      
    </div>

    


    <div className='fv-row mb-10'>
      <label className='form-label'>
      <span className='required'>Property Description</span></label>

      <Field
        as='textarea'
        name='propertyDescription'
        className='form-control form-control-lg form-control-solid'
        rows={3}
      ></Field>
    </div>

    <div className='fv-row mb-10'>
      <label className='fs-6 fw-bold form-label required'>Contact Email</label>

      <Field name='propertyEmail' className='form-control form-control-lg form-control-solid' />
      <div className='text-danger mt-2'>
        <ErrorMessage name='propertyEmail' />
      </div>
    </div>
    
    <div className='fv-row mb-0'>
      <label className='fs-6 fw-bold form-label required'>Contact Phone</label>

      <Field name='propertyPhone' className='form-control form-control-lg form-control-solid' />
      <div className='text-danger mt-2'>
        <ErrorMessage name='propertyPhone' />
      </div>
    </div>
  </div>
  )
}

export {Step2}
