import {combineReducers} from '@reduxjs/toolkit'
import primaryReducer from './primarySlice'
import secondaryReducer from './secondarySlice'
import developerReducer from './developerSlice'

const rootReducer = combineReducers({
  primary: primaryReducer,
  secondary: secondaryReducer,
  developer: developerReducer,
})

export default rootReducer
