import FullCalendar from '@fullcalendar/react'
import React, {useEffect, useRef, useState} from 'react'
import {Card} from 'react-bootstrap'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import multiMonthPlugin from '@fullcalendar/multimonth'
import {useParams} from 'react-router-dom'
import {getevents} from '../../calender/core/_requests'
import Swal from 'sweetalert2'

const AgentCalender: React.FC = () => {
  const params = useParams().id
  const [calendarEvents, setCalendarEvents] = useState([])
  const calendarComponentRef = useRef(null)

  const handleShowModal = (eventClick) => {
    Swal.fire({
      title: eventClick.event.title,
      html: `<div class="table-responsive">
           <table class="table">
              <tbody>
                 <tr>
                    <td>Title</td>
                    <td class="capitalize"><strong>${eventClick.event?.title}</strong></td>
                 </tr>
                 <tr>
                    <td>Start Time</td>
                    <td><strong>${eventClick.event?.start}</strong></td>
                 </tr>
                 <tr>
                    <td>Description</td>
                    <td><strong>${eventClick.event?._def?.extendedProps?.description}</strong></td>
                 </tr>
                 <tr>
                    <td>Agents</td>
                    <td><strong>${eventClick.event?._def?.extendedProps?.agent
                      ?.map((agent) => agent.fullName)
                      .join(', ')}</strong></td>
                 </tr>
                 <tr>
                    <td>Client</td>
                    <td><strong>${eventClick.event?._def?.extendedProps?.client?.name || ""}</strong></td>
                 </tr>
              </tbody>
           </table>
        </div>
        `,
      //   showCancelButton: true,
      //   confirmButtonColor: '#d33',
      //   cancelButtonColor: '#3085d6',
      //   confirmButtonText: 'OK',
      //   cancelButtonText: 'Close',
    })
  }

  
  useEffect(() => {
    const getevent = async () => {
      try {
        const res = await getevents(params)
        setCalendarEvents(res) // Assuming that res is an array
      } catch (error) {
        console.error('Error fetching todo list:', error)
      }
    }

    getevent()
  }, [params])

  const handleDateSelect = (selectInfo) => {
    // const {start, end} = selectInfo
    // Do something with the selected date range
  }

  return (
    <Card>
      <Card.Body>
        <div className='demo-app-calendar' id='mycalendartest'>
          <FullCalendar
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
              multiMonthPlugin,
            ]}
            initialView={'dayGridMonth'}
            headerToolbar={{
              start: 'today prev,next',
              center: 'title',
              end: 'timeGridDay,timeGridWeek,dayGridMonth,multiMonthYear,listYear',
            }}
            selectable={true}
            select={handleDateSelect}
            rerenderDelay={10}
            eventDurationEditable={false}
            // editable={true}
            // droppable={true}
            weekends={true}
            ref={calendarComponentRef}
            events={calendarEvents}
            // eventDrop={eventClick}
            // eventReceive={handleEventReceive}
            eventClick={(e) => {
              handleShowModal(e)
            }}
            // eventRemove={handleEventRemove}
          />
        </div>
      </Card.Body>
    </Card>
  )
}

export default AgentCalender
