import {combineReducers} from '@reduxjs/toolkit'

import pipelineStatsReducer from './pipelineStatsSlice'
import pipelinesReducer from './pipelineSlice'

const pipelineReducer = combineReducers({
  pipeline: pipelinesReducer,
  pipelineStats: pipelineStatsReducer,
})

export default pipelineReducer
