import axios, {AxiosResponse} from 'axios'
import {Agents, Client, Property} from './_models'
const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_CLIENT_URL = `${API_URL}/client/get/`
const GET_CLIENT_SEARCH_URL = `${API_URL}/client`
const GET_CLIENTBYCOMPANY_URL = `${API_URL}/client/company/`
const GET_CLIENTBYAGENT_URL = `${API_URL}/client/agent/`
const GET_AGENT_URL = `${API_URL}/agent/actives`
const GET_PROPERTY_URL = `${API_URL}/inventory/`
const GET_LENGTH_URL = `${API_URL}/sales/invoicelength`
const GET_LENGTH_URL_Q = `${API_URL}/sales/quotelength`
const POST_INVOICE_URL = `${API_URL}/sales/invoice`
const EDIT_INVOICE_URL = `${API_URL}/sales/invoice`
const GET_INVOICE_URL = `${API_URL}/sales/invoice`
const GET_PENDINGINVOICE_URL = `${API_URL}/sales/pendinginvoices`
const GET_NONPENDINGINVOICE_URL = `${API_URL}/sales/nonpendinginvoices`
const GET_PENDINGQUOTE_URL = `${API_URL}/sales/pendingquotes`
const GET_NONPENDINGQUOTE_URL = `${API_URL}/sales/nonpendingquotes`
const GET_INVOICE_URL_AGENT = `${API_URL}/sales/invoiceofagent`
const POST_QUOTE_URL = `${API_URL}/sales/quote`
const EDIT_QUOTE_URL = `${API_URL}/sales/quote`
const GET_QUOTES_URL = `${API_URL}/sales/quote`
const GET_QUOTES_URL_AGENT = `${API_URL}/sales/quoteofagent`
const GET_DEALS_URL = `${API_URL}/sales/deals`
const GET_DEALS_URL_AGENT = `${API_URL}/sales/dealsbyagentid`
const GET_DEALS_URL_CLIENT = `${API_URL}/sales/dealsbyclientid`
const POST_DEALS_URL = `${API_URL}/sales/deals`
const POST_DEALSID_URL = `${API_URL}/sales/dealdetails/`
const DEAL_STATUS_URL = `${API_URL}/sales/changedealstatus/`
const GET_ALL_DATA = `${API_URL}/sales/getmergedData`
const GET_INVOICE_DETAILS = `${API_URL}/sales/invoicebyid/`
const GET_QUOTE_DETAILS = `${API_URL}/sales/quotebyid/`
const PUT_INVOICEGEN_URL = `${API_URL}/sales/generatereceipt/`
const APPROVE_ACC_URL = `${API_URL}/sales/statusapprove/`
const REJECT_ACC_URL = `${API_URL}/sales/statusreject/`
const APPROVE_QUOTE_URL = `${API_URL}/sales/statusquoteapprove/`
const REJECT_QUOTE_URL = `${API_URL}/sales/statusquotereject/`
const ACCOUNTS_URL = `${API_URL}/maccount`
const CLIENT_SEARCH_URL = `${API_URL}/client/sales/get`


export async function setStatusApprove(id:string) {
  try {
    const response = await axios.put(`${APPROVE_ACC_URL}${id}`);
    return response.data;
  } catch (error) {
    // Handle errors here
    console.error("Add Account error:", error);
    throw error;
  }
}

export async function setStatusReject(id:string) {
  try {
    const response = await axios.put(`${REJECT_ACC_URL}${id}`);
    return response.data;
  } catch (error) {
    // Handle errors here
    console.error("Add Account error:", error);
    throw error;
  }
}

export async function setQuoteStatusApprove(id:string) {
  try {
    const response = await axios.put(`${APPROVE_QUOTE_URL}${id}`);
    return response.data;
  } catch (error) {
    // Handle errors here
    console.error("Add Account error:", error);
    throw error;
  }
}

export async function setQuoteStatusReject(id:string) {
  try {
    const response = await axios.put(`${REJECT_QUOTE_URL}${id}`);
    return response.data;
  } catch (error) {
    // Handle errors here
    console.error("Add Account error:", error);
    throw error;
  }
}


export const getClient = async(companyid:string):Promise<Array<Client>> => {
  try {
  const response : AxiosResponse<Client[]> = await axios.get<Array<Client>>(`${GET_CLIENT_URL}${companyid}`)
  return response.data
} catch (error) {
  // Handle errors here
  console.error("Add client error:", error);
  throw error;
}
  
}

export const getClientbyagent = async(agentId:string):Promise<Array<Client>> => {
  try {
  const response : AxiosResponse<Client[]> = await axios.get<Array<Client>>(`${GET_CLIENTBYAGENT_URL}${agentId}`)
 
  return response.data
} catch (error) {
  // Handle errors here
  console.error("Add client error:", error);
  throw error;
}
  
}
export const getClientbycompany = async(companyId:string):Promise<Array<Client>> => {
  try {
  const response : AxiosResponse<Client[]> = await axios.get<Array<Client>>(`${GET_CLIENTBYCOMPANY_URL}${companyId}`)
 
  return response.data
} catch (error) {
  // Handle errors here
  console.error("Add client error:", error);
  throw error;
}
  
}

  
  export const getAgent = async(companyid:string):Promise<Array<Agents>> => {
    try {
    const response : AxiosResponse<Agents[]> = await axios.get<Array<Agents>>(`${GET_AGENT_URL}/${companyid}`)
    return response.data
} catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  }

  export async function getProperty(companyid:string): Promise<Array<Property>>  {
    try {
      const response: AxiosResponse<Property[]> = await axios.get(`${GET_PROPERTY_URL}${companyid}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }

  export async function getInvoiceid(): Promise<any>  {
    try {
      const response: AxiosResponse<any> = await axios.get(`${GET_LENGTH_URL}`);
      return response.data+1;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }

  export async function generateReceipt(data:any,id:string): Promise<any>  {
    try {
      const response: AxiosResponse<any> = await axios.put(`${PUT_INVOICEGEN_URL}${id}`,data);
      return response.data
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }


  export async function getQuoteid(): Promise<any>  {
    try {
      const response: AxiosResponse<any> = await axios.get(`${GET_LENGTH_URL_Q}`);
      return response.data+1;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }


  export const postinvoice = async(invoice:any, companyid:string):Promise<any> => {
    try {
    const response : AxiosResponse<any> = await axios.post(`${POST_INVOICE_URL}/${companyid}`,invoice)
    return response.data
} catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  }

  export const editInvoice = async(invoice:any, invoiceid:string):Promise<any> => {
    try {
    const response : AxiosResponse<any> = await axios.put(`${EDIT_INVOICE_URL}/${invoiceid}`,invoice)
    return response.data
} catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  }

  export const postinvoicetodeal = async(invoice:any, companyid:string, dealid:string):Promise<any> => {
    try {
    const response : AxiosResponse<any> = await axios.post(`${POST_INVOICE_URL}/todeal/${companyid}/${dealid}`,invoice)
    return response.data
} catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  }

  export const postquote = async(quote:any,companyid:string):Promise<any> => {
    try {
    const response : AxiosResponse<any> = await axios.post(`${POST_QUOTE_URL}/${companyid}`,quote)
    return response.data
} catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  }

  export const editQuote = async(quote:any,quoteid:string):Promise<any> => {
    try {
    const response : AxiosResponse<any> = await axios.put(`${EDIT_QUOTE_URL}/${quoteid}`,quote)
    return response.data
} catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  }

  export const postquotetodeal = async(quote:any,companyid:string,dealid:string):Promise<any> => {
    try {
    const response : AxiosResponse<any> = await axios.post(`${POST_QUOTE_URL}/todeal/${companyid}/${dealid}`,quote)
    return response.data
} catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  }

  export const postlead = async(data:any, companyid:string):Promise<any> => {
    try {
    const response : AxiosResponse<any> = await axios.post(`${POST_DEALS_URL}/${companyid}`,data)
    return response.data
} catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  }


  
  export async function getDeals(companyid:string): Promise<Array<any>>  {
    try {
      const response: AxiosResponse<any[]> = await axios.get(`${GET_DEALS_URL}/${companyid}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }
  export async function getDealsofagent(agentid:string): Promise<Array<any>>  {
    try {
      const response: AxiosResponse<any[]> = await axios.get(`${GET_DEALS_URL_AGENT}/${agentid}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }
  export async function getDealsofclient(client:string): Promise<Array<any>>  {
    try {
      const response: AxiosResponse<any[]> = await axios.get(`${GET_DEALS_URL_CLIENT}/${client}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }

  export async function getQuotes(companyid:string): Promise<Array<any>>  {
    try {
      const response: AxiosResponse<any[]> = await axios.get(`${GET_QUOTES_URL}/${companyid}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }
  export async function getQuotesbyAgentId(id:string): Promise<Array<any>>  {
    try {
      const response: AxiosResponse<any[]> = await axios.get(`${GET_QUOTES_URL_AGENT}/${id}`);
   
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }
  export async function getInvoice(companyid:string): Promise<Array<any>>  {
    try {
      const response: AxiosResponse<any[]> = await axios.get(`${GET_INVOICE_URL}/${companyid}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }
  export async function getPendingInvoice(companyid:string): Promise<Array<any>>  {
    try {
      const response: AxiosResponse<any[]> = await axios.get(`${GET_PENDINGINVOICE_URL}/${companyid}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }
  export async function getNonPendingInvoice(companyid:string): Promise<Array<any>>  {
    try {
      const response: AxiosResponse<any[]> = await axios.get(`${GET_NONPENDINGINVOICE_URL}/${companyid}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }
 
  export async function getPendingQuotes(companyid:string): Promise<Array<any>>  {
    try {
      const response: AxiosResponse<any[]> = await axios.get(`${GET_PENDINGQUOTE_URL}/${companyid}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }
  export async function getNonPendingQuotes(companyid:string): Promise<Array<any>>  {
    try {
      const response: AxiosResponse<any[]> = await axios.get(`${GET_NONPENDINGQUOTE_URL}/${companyid}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }

  export async function getInvoicebyAgnetid(id:string): Promise<Array<any>>  {
    try {
      const response: AxiosResponse<any[]> = await axios.get(`${GET_INVOICE_URL_AGENT}/${id}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }

  export async function getall(companyid:string, dealid:string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axios.get(`${GET_ALL_DATA}/${companyid}/${dealid}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }


  export async function getDealsbyids(id:string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axios.get(`${POST_DEALSID_URL}${id}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }

  export async function changeOrderStatus(past, present, dealId) {
    const body = {past, present, dealId}
    try {
      const response = await axios.put(`${DEAL_STATUS_URL}`, body)
      return response.data
    } catch (error) {
      // Handle errors here
      console.error('Add Company error:', error)
      throw error
    }
  }

  export async function getInvoicebyids(id:string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axios.get(`${GET_INVOICE_DETAILS}${id}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }
  export async function getQuotebyids(id:string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axios.get(`${GET_QUOTE_DETAILS}${id}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }

  export async function getAccountsNameForPaymentReceivedInvoice(companyid: string) {
    try {
      const response = await axios.get(
        `${ACCOUNTS_URL}/getaccounts/accountsforinvoicepaymentreceived/${companyid}`
      )
      return response.data
    } catch (error) {
      // Handle errors here
      console.error('Add Account error:', error)
      throw error
    }
  }

  export async function searchClientByQuery(companyid:string, query: string) {
    try {
      const response = await axios.get(`${GET_CLIENT_SEARCH_URL}/search/client/${companyid}?search=${query}`)
      return response.data
    } catch (error) {
      // Handle errors here
      console.error('Add Account error:', error)
      throw error
    }
  }

export const getClients = async(id:string, search:string, type: string):Promise<any> => {
    try {
      const response : AxiosResponse<any> = await axios.get<any>(`${CLIENT_SEARCH_URL}/${id}?search=${search}&type=${type}`)
      return response.data
    } catch (error) {
    // Handle errors here
      console.error("Add client error:", error);
      throw error;
    }
  }