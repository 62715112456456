import React, {useEffect, useState} from 'react'
import {Card} from 'react-bootstrap'
import {DragDropContext, Droppable, Draggable, DropResult} from 'react-beautiful-dnd'
// import { changePipeline, getPipeline } from '../../../services/apiservice';

import './pipeline.scss'
import {useAuth} from '../../modules/auth/core/Auth'
import {changePipeline, getPipeline, getPipelineagent} from './core/_requests'
import {Clientstat} from './components/ClientStat'
import {KTIcon, KTSVG} from '../../../_metronic/helpers'
import Select from 'react-select'
import {getAgent} from '../agent/core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../redux/store'
import {setPipelineData} from '../../redux/pipeline/pipelineSlice'
import {setAgentsData} from '../../redux/agent/agentSlice'
import * as XLSX from 'xlsx'
import {Spinner} from 'react-bootstrap'
import {getClientsForExportPipeline} from '../client/core/_requests'
import Swal from 'sweetalert2'

interface PipelineItem {
  id: string
  title: string
  items: any[] // Adjust the type according to your data structure
}

const PipelineWrapper: React.FC = () => {
  const {currentUser} = useAuth()
  const pipelineData = useSelector((state: RootState) => state.pipeline.pipeline.pipelineData)
  const loading = useSelector((state: RootState) => state.pipeline.pipeline.loading)
  const agentsData = useSelector((state: RootState) => state.agent.agent.agents)
  const [data, setData] = useState<PipelineItem[]>(pipelineData)
  const [pipeline, setPipeline] = useState<any>(null)
  const [agentsList, setAgentsList] = useState<any>([])
  const [selectedAgent, setselectedAgent] = useState<any>(null)
  const [permissions, setPermissions] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)
  const [exportLoading, setExportLoading] = useState(false)
  const [status, setstatus] = useState('')
  const [agent, setagent] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const fetchPipeline = async () => {
    if (currentUser?.profileType === 'superadmin' || permissions?.pipeline?.admin) {
      const res = await getPipeline(currentUser.company)
      setPipeline(res)
    } else if (permissions?.pipeline?.admin === false) {
      const res = await getPipelineagent(currentUser.company, currentUser._id)
      setPipeline(res)
    }
  }

  const getAgenttList = async () => {
    try {
      const res = await getAgent(currentUser?.company)
      setAgentsList(
        res.map((r) => ({
          value: r._id,
          label: r.fullName, // Corrected the typo here from "labe" to "label"
        }))
      )
      dispatch(setAgentsData(res))
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    if (agentsData.length === 0) {
      if (currentUser?.profileType === 'superadmin' || permissions?.pipeline?.admin) {
        getAgenttList()
      }
    } else {
      setAgentsList(
        agentsData.map((r) => ({
          value: r._id,
          label: r.fullName, // Corrected the typo here from "labe" to "label"
        }))
      )
    }

    const fetchData = async () => {
      if (currentUser?.profileType === 'superadmin' || permissions?.pipeline?.admin) {
        const res = await getPipeline(currentUser.company)
        // setPipeline(res);
        return res
      } else if (permissions?.pipeline?.admin === false) {
        const res = await getPipelineagent(currentUser.company, currentUser._id)
        // setPipeline(res);
        return res
      }
    }

    if (pipelineData.length === 0) {
      fetchData().then((res) => {
        const initialData: PipelineItem[] = [
          {
            id: 'new',
            title: 'New',
            items: res?.new || [],
          },
          {
            id: 'prospect',
            title: 'Prospect',
            items: res?.prospect || [],
          },
          {
            id: 'viewing',
            title: 'Viewing',
            items: res?.viewing || [],
          },
          {
            id: 'close',
            title: 'Close',
            items: res?.close || [],
          },
        ]
        dispatch(setPipelineData(initialData))
        setData(initialData)
      })
    }
  }, [currentUser, permissions?.pipeline])

  useEffect(() => {
    if (pipeline) {
      const initialData: PipelineItem[] = [
        {
          id: 'new',
          title: 'New',
          items: pipeline.new || [],
        },
        {
          id: 'prospect',
          title: 'Prospect',
          items: pipeline.prospect || [],
        },
        {
          id: 'viewing',
          title: 'Viewing',
          items: pipeline.viewing || [],
        },
        {
          id: 'close',
          title: 'Close',
          items: pipeline.close || [],
        },
        // {
        //   id: 'reject',
        //   title: 'Reject',
        //   items: pipeline.reject || [],
        // },
        // Add more cards and items as needed
      ]
      setData(initialData)
      setIsLoading(false)
    }
  }, [pipeline])

  const handleAgentChange = async (selectedOption) => {
    setIsLoading(true)
    setselectedAgent(selectedOption)
    if (selectedOption === null) {
      fetchPipeline()
      return
    }
    const res = await getPipelineagent(currentUser.company, selectedOption.value)
    setPipeline(res)
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return

    const {source, destination, draggableId} = result

    if (source.droppableId === destination.droppableId) {
      // Reorder items within the same card
      const cardIndex = data.findIndex((card) => card.id === source.droppableId)
      const items = [...data[cardIndex].items]
      const [removedItem] = items.splice(source.index, 1)
      items.splice(destination.index, 0, removedItem)

      // const updatedData = [...data];
      // updatedData[cardIndex].items = items;
      const updatedData = data.map((card) => {
        if (card.id === source.droppableId) {
          return {
            ...card,
            items,
          }
        } else {
          return card
        }
      })
      setData(updatedData)
    } else {
      // Move item to a different card
      const sourceCardIndex = data.findIndex((card) => card.id === source.droppableId)
      const destCardIndex = data.findIndex((card) => card.id === destination.droppableId)
      const sourceItems = [...data[sourceCardIndex].items]
      const destItems = [...data[destCardIndex].items]
      const [movedItem] = sourceItems.splice(source.index, 1)
      destItems.splice(destination.index, 0, movedItem)

      // const updatedData = [...data];
      // updatedData[sourceCardIndex].items = sourceItems;
      // updatedData[destCardIndex].items = destItems;
      const updatedData = data.map((card) => {
        if (card.id === source.droppableId) {
          return {
            ...card,
            items: sourceItems,
          }
        } else if (card.id === destination.droppableId) {
          return {
            ...card,
            items: destItems,
          }
        } else {
          return card
        }
      })
      dispatch(setPipelineData(updatedData))
      setData(updatedData)
      changePipeline(
        data[sourceCardIndex].id,
        data[destCardIndex].id,
        currentUser?.pipeline,
        movedItem._id
      )
    }
  }

  const handleExportClients = async () => {
    try {
      setExportLoading(true)
      const res = await getClientsForExportPipeline(currentUser?.company, status, agent)

      if (!res) {
        throw new Error('No data received')
      }

      const data = res.map((item, index) => ({
        ...item,
        _id: index + 1,
        agent: item?.agent?.map((currElem) => currElem?.fullName).join(', '),
        notes: item?.notes?.map((note) => note?.text)?.join(', '),
      }))

      const ws = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Clients')
      const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})

      const blob = new Blob([excelBuffer], {type: 'application/octet-stream'})
      const fileName = 'client-list.xlsx'

      // Create and trigger download link
      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.setAttribute('download', fileName)
      downloadLink.click()

      Swal.fire({
        icon: 'success',
        title: 'Clients Exported Successfully!',
        confirmButtonText: 'OK',
      }).then((result) => {
        if (result.isConfirmed) {
          document.getElementById('closeModalButton').click()
        }
      })
    } catch (error) {
      console.error('Error exporting clients:', error)
      Swal.fire({
        icon: 'error',
        title: 'Failed to Export Clients',
        text: error.message,
        confirmButtonText: 'OK',
      })
    } finally {
      setExportLoading(false)
      setstatus('')
      setagent('')
    }
  }

  if (loading) {
    return (
      <div className='d-flex justify-content-center'>
        <div className='loader'></div>
      </div>
    )
  }

  return (
    <div className=' m-4'>
      <div className='d-flex justify-end'>
        <div className='d-flex gap-5 align-items-center'>
          {(currentUser?.hierarchy === 1 || currentUser?.profileType === 'superadmin') && (
            <button
              type='button'
              className='btn btn-danger btn-lg card-toolbar'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_1'
            >
              Export Clients
            </button>
          )}
          {(currentUser?.profileType === 'superadmin' || permissions?.pipeline?.admin) && (
            <div className='d-flex justify-end m-4'>
              <div
                className='btn btn-primary me-3'
                data-kt-menu-trigger='hover'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTIcon iconName='filter' className='fs-2' />
                Filter
                <div
                  className='menu menu-sub menu-sub-dropdown w-400px w-md-400px p-5'
                  data-kt-menu='true'
                  style={{zIndex: 1000000}}
                >
                  {/* begin::Header */}
                  <div className='px-7 py-5'>
                    <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                  </div>
                  {/* end::Header */}

                  {/* begin::Separator */}
                  <div className='separator border-gray-200'></div>
                  {/* end::Separator */}

                  {/* begin::Content */}
                  <div className='px-7 py-5' data-kt-user-table-filter='form'>
                    {/* begin::Input group */}
                    <div className='mb-10 d-flex flex-row align-content-center justify-content-center'>
                      <label className='form-label fs-6 fw-bold text-gray-700 mb-3 me-5 mt-5'>
                        Agent:
                      </label>

                      <Select
                        name='agent'
                        aria-label='Select a Agent'
                        placeholder='Select agent'
                        options={agentsList}
                        defaultValue={{value: currentUser?._id, label: currentUser?.fullName}}
                        isSearchable={true}
                        className='form-control form-control-solid'
                        onChange={handleAgentChange}
                        value={selectedAgent}
                      />
                    </div>

                    {/* end::Input group */}

                    {/* begin::Actions */}
                    <div className='d-flex justify-content-end'>
                      <button
                        type='button'
                        // disabled={isLoading}
                        // onClick={filterData}
                        className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                        data-kt-menu-dismiss='true'
                        data-kt-user-table-filter='reset'
                        onClick={() => {
                          setselectedAgent(null)
                          // handleAgentChange(null)
                          setData(pipelineData)
                        }}
                      >
                        RESET
                      </button>
                    </div>
                    {/* end::Actions */}
                  </div>
                  {/* end::Content */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Clientstat />
      {isLoading ? (
        <>
          <div className='d-flex justify-content-center'>
            {/* <div className='spinner-grow text-primary' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div> */}
            <div className='loader'></div>
          </div>
        </>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10   '>
            {data.map((card) => (
              <div className='col-xl-3 mb-4  ' key={card.id}>
                <Card
                  className={`bg-light  w-100 ${
                    card.title === 'New'
                      ? 'bg-light-primary  border border-primary'
                      : card.title === 'Prospect'
                      ? 'bg-light-warning border border-warning'
                      : card.title === 'Viewing'
                      ? 'bg-light-info border border-info'
                      : card.title === 'Close'
                      ? 'bg-light-success border border-success'
                      : 'bg-light-danger border border-danger'
                  }`}
                  style={{width: '19rem'}}
                >
                  {/* <Card.Header> */}
                  <div
                    className={`p-2  m-2  ${
                      card.title === 'New'
                        ? 'bg-light-primary '
                        : card.title === 'Prospect'
                        ? 'bg-light-warning'
                        : card.title === 'Viewing'
                        ? 'bg-light-info'
                        : card.title === 'Close'
                        ? 'bg-light-success'
                        : 'bg-light-danger'
                    }`}
                  >
                    <div
                      className={`font-semibold flex justify-center items-center  text-xl ${
                        card.title === 'New'
                          ? 'text-primary'
                          : card.title === 'Prospect'
                          ? 'text-warning'
                          : card.title === 'Viewing'
                          ? 'text-info'
                          : card.title === 'Close'
                          ? 'text-success'
                          : 'text-danger'
                      } `}
                    >
                      {card.title}
                    </div>
                  </div>
                  {/* </Card.Header> */}
                  <Droppable droppableId={card.id} type='ITEM'>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {card.items.map((item, index) => (
                          <Draggable key={item._id} draggableId={item._id} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className=' m-3 my-5 '>
                                  <div className=' text-md font-light bg-white rounded-lg p-3 leading-none px-3'>
                                    <h4 className='text-black'>{item.name}</h4>
                                    <p className=' text-black'>{item.phone}</p>
                                    <p className=' text-black'>{item.email}</p>
                                    {(currentUser?.profileType === 'superadmin' ||
                                      permissions?.pipeline?.admin) &&
                                      item?.agent?.length > 0 && (
                                        <p className='text-black truncate'>
                                          <span className='text-black'>
                                            Agent:{' '}
                                            {item?.agent?.map((agent, index) => (
                                              <span
                                                key={agent._id}
                                                className='text-black fw-semibold'
                                              >
                                                {agent.fullName}
                                                {index < item.agent.length - 1 && ', '}
                                              </span>
                                            ))}
                                          </span>
                                        </p>
                                      )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Card>
              </div>
            ))}
          </div>
        </DragDropContext>
      )}
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Export Clients</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <div className=''>
                <div className='px-7 py-5' data-kt-user-table-filter='form'>
                  {/* begin::Input group */}
                  <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
                    <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Status:</label>
                    <select
                      className='form-select form-select-solid fw-bolder  w-75'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      data-kt-user-table-filter='status'
                      data-hide-search='true'
                      onChange={(e) => setstatus(e.target.value)}
                      value={status}
                    >
                      <option value=''>Choose</option>
                      <option value='new'>New</option>
                      <option value='prospect'>Prospect</option>
                      <option value='viewing'>Viewing</option>
                      <option value='close'>Close</option>
                      <option value='reject'>Reject</option>
                      <option value='noanswer'>No Answer</option>
                      <option value='callback'>Call Back</option>
                      <option value='pending'>Pending</option>
                      <option value='junk'>Junk</option>
                    </select>
                  </div>
                  <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
                    <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Agent:</label>

                    <select
                      className='form-select form-select-solid fw-bolder  w-75'
                      data-kt-select2='true'
                      data-placeholder='Select agent'
                      data-allow-clear='true'
                      data-kt-user-table-filter='agent'
                      data-hide-search='false'
                      onChange={(e) => setagent(e.target.value)}
                      value={agent}
                    >
                      <option value=''>Choose</option>
                      <option value='unassigned'>Unassigned</option>
                      {agentsList.map((agent) => (
                        <option key={agent.value} value={agent.value}>
                          {agent.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton'
              >
                Close
              </button>
              <button type='button' className='btn btn-danger' onClick={handleExportClients}>
                {exportLoading ? (
                  <>
                    <Spinner animation='border' role='status' />
                  </>
                ) : (
                  'Export Clients'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PipelineWrapper
