/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'

import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {  getAgentbyClientId, getclientdata } from '../core/_requests'
import { Link, useParams } from 'react-router-dom';
import { Agent, Clientinfo } from '../../agent/core/_models';
import AgentSearchInClient from './AgentSearchInClient';
import { useAuth } from '../../../modules/auth';
import profile from './profile.png'




const AgentlistbyId: React.FC = () => {
  const PF:any = "https://unifeed.s3.ap-south-1.amazonaws.com/";
  const id  = useParams().id
  const [agents, setagents] = useState<Agent[]>([]); 
  const [clients, setclients] = useState<Clientinfo[]>([]); 
  const [agentid, setagentid] = useState<string>(''); 
  const [isLoading, setisLoading] = useState<boolean>(true);
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const getClientList = async () => {
    try {
      const res = await getAgentbyClientId(id);
      setagents(res);
      setisLoading(false)
      console.log(res)
   
    } catch (error) {
      console.error('Error fetching client list:', error);
    }
  };

  const getClientInfo = async () => {
    try {
      const res = await getclientdata(agentid);
      console.log(res)
      setclients(res);

    } catch (error) {
      console.error('Error fetching client list:', error);
    }
  };
  
  useEffect(() => {
    getClientList();
  }, []);

  useEffect(() => {
    getClientInfo()
  }, [agentid]);

  
  const handleTrigger = () => {
    getClientList()
  }


  return (
    <>
    {
      isLoading ? (
        <></>
      ):(
    <div className={'card h-md-50 mb-5 mb-xl-10 min-h-500px overflow-scroll'}>
      {/* begin::Header */}
           

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'></span>
         
        </h3>

        {
          (currentUser?.profileType === "superadmin" || permissions?.agent?.assign) &&(
            <div className='d-flex flex-row'>
            <div className='card-toolbar me-5'    data-bs-toggle="modal" data-bs-target="#kt_modal_3" >
              <a  className='btn btn-sm btn-light-primary'>
                <KTIcon iconName='plus' className='fs-2' />
                Assign Agent
              </a>
            </div>
    
    
          </div>)
        }
      
        
 
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
        
                <th className='min-w-150px'>Name</th>
                <th className='min-w-100px'>Employee Id</th>
                <th className='min-w-150px'>Info</th>
                <th className='min-w-200px'>Clients Status</th>
            
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {
            agents.map((product:Agent | undefined) => (
              <tr key={product?._id?.toString()}>
              
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      {
                        product?.profilePic === '' ? (
                          <img src={profile} alt='' className="object-fit-cover" />
                        ) : (<img src={PF + product?.profilePic} alt='' className="object-fit-cover" />)
                      }
                    </div>
                    <Link to={`/agents/agentDetails/${product._id}`} className=' client_field cursor-pointer '>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        {product?.fullName}
                      </a>
                     
                    </div>
                    </Link>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    {product?.employeeId}
                  </a>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    {product?.email}
                  </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    {product?.phone}
                  </span>
                </td>

                <td className='text-end'  data-bs-toggle="modal" data-bs-target="#kt_modal_4" onClick={()=>setagentid(product._id)}>
                <div className='d-flex'>
                <div className="symbol symbol-30px border me-2 border-primary">
                  <div className="symbol-label fs-5 fw-semibold bg-light-primary text-primary ">{product?.statusCounts?.new}</div>
              </div>
                <div className="symbol symbol-30px border me-2 border-warning">
                  <div className="symbol-label fs-5 fw-semibold bg-light-warning text-warning ">{product?.statusCounts?.prospect}</div>
              </div>
                <div className="symbol symbol-30px border me-2 border-info">
                  <div className="symbol-label fs-5 fw-semibold bg-light-info text-info ">{product?.statusCounts?.viewing}</div>
              </div>
                <div className="symbol symbol-30px border me-2 border-success">
                  <div className="symbol-label fs-5 fw-semibold bg-light-success text-success ">{product?.statusCounts?.close}</div>
              </div>
                <div className="symbol symbol-30px border me-2 border-danger">
                  <div className="symbol-label fs-5 fw-semibold bg-light-danger text-danger ">{product?.statusCounts?.reject}</div>
              </div>

                  </div>
                </td>

                {/* <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>50%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-primary'
                        role='progressbar'
                        style={{width: '50%'}}
                      ></div>
                    </div>
                  </div>
                </td>
                 */}
              </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}


<div className="modal fade" tabIndex={-1} id="kt_modal_4">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Client List</h5>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon svg-icon-2x"
          />
        </div>
      </div>

      <div className="modal-body">

    <div className='card card-xl-stretch mb-xl-8' >
      {/* begin::Body */}
      <div className='card-header align-items-center border-0 mt-4'>
      {clients.map((user,index) => (
      <div className='card-body pt-3' key={index}>
        {/* begin::Item */}
        <div className='d-flex align-items-sm-center mb-2'>
          {/* begin::Symbol */}
          <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
            <div className='flex-grow-1 me-2'>
              <a href='#' className='text-gray-800 fw-bold text-hover-primary fs-5'>
              {user.name}
              </a>
              <span className='text-muted fw-semibold d-block pt-1'>{user.phone}</span>
            </div>

            <span className={`badge fs-7 p-3  ${user.pipelinestatus === "new" ? "badge-light-primary" : user.pipelinestatus=== "prospect" ? "badge-light-warning" : user.pipelinestatus === "viewing" ? "badge-light-info":user.pipelinestatus === "close" ? "badge-light-success": "badge-light-danger"}`}>{user.pipelinestatus}</span>

          </div>
        </div>
      </div>
      ))}
        </div>
      </div>
      
    </div>
</div>
</div>
  </div>
  
    
  <AgentSearchInClient  handleTrigger = {handleTrigger}/>

</>
    
    )
  }
export {AgentlistbyId}
