import React, {Fragment} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {reportsMenus} from '../core/_model'
import {Link} from 'react-router-dom'

const ReportsPage = () => {
  return (
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10 '>
      <div className='col-xxl-12'>
        <div className='row h-md-100 g-5 g-xl-10 mb-5 mb-xl-5'>
          {reportsMenus?.map((elem, index) => (
            <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-4 '>
              <div className='card card-flush' key={index}>
                <div className='card-header pt-5'>
                  <h3 className='card-title text-gray-800 fw-bold'>{elem?.title}</h3>
                  <div className='card-toolbar'></div>
                </div>
                <div className='card-body pt-5'>
                  {elem?.items?.map((row, idx) => (
                    <Fragment key={`rows-${idx}`}>
                      <div className='d-flex flex-stack'>
                        <Link to={`${row.link}`} className='text-primary fw-semibold fs-6 me-2'>
                          {row.name}
                        </Link>
                        <Link
                          to={`${row.link}`}
                          className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
                        >
                          <KTIcon iconName='exit-right-corner' className='fs-2' />
                        </Link>
                      </div>
                      {elem?.items?.length - 1 > idx && (
                        <div className='separator separator-dashed my-3' />
                      )}
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ReportsPage
