import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../modules/auth';
import { getQuotes, getQuotesbyAgentId } from '../core/_requests';
import { KTIcon } from '../../../../_metronic/helpers';
import {useSelector, useDispatch} from 'react-redux'
import { RootState } from '../../../redux/store';
import { setQuotes } from '../../../redux/sales/quoteSlice';

function QuotesList() {
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const quoteList = useSelector((state:RootState) => state.sales.quote.quotes)
  const loading = useSelector((state:RootState) => state.sales.quote.loading)
  const [permissions, setPermissions] = useState<any>({})

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const getdealslist = async() => {
    if(permissions?.sales?.admin === false){
      const res = await getQuotesbyAgentId(currentUser?._id)
      dispatch(setQuotes(res))
      console.log(res)
    }else if(currentUser?.profileType === "superadmin" || permissions?.sales?.admin){
      const res = await getQuotes(currentUser?.company)
      dispatch(setQuotes(res))
      console.log(res)
    }
    
}
useEffect(() => {
  if (loading) {
    getdealslist()
  }
}, [permissions])

  if (loading) {
    return (
      <div className='d-flex justify-content-center'>
        <div className='loader'></div>
      </div>
    )
  }


  return (
    <div className='card card-flush'>
       
    <div className='card-header border-0 pt-5'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bold fs-3 mb-1'>Quotes</span>
       
      </h3>

      {/* {!(currentUser?.profileType === "superadmin" || permissions?.sales?.admin) &&  */}
      <div className='card-toolbar'>
        <Link to={'/sales/quote/add'} className='btn btn-sm btn-light-primary'>
          <KTIcon iconName='plus' className='fs-2' />
          Add Quote
        </Link>
      </div>
      {/* } */}

    </div>
    {/* end::Header */}
    {/* begin::Body */}
    <div className='card-body py-3'>
      {/* begin::Table container */}
      <div className='table-responsive'>
        {/* begin::Table */}
        <table className='table align-middle gs-0 gy-4 table-row-bordered' >
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold text-muted bg-light'>
              <th className='ps-4 min-w-50px rounded-start'>ID</th>
              <th className='min-w-150px'>Agent</th>
              <th className='min-w-150px'>Client</th>
              <th className='min-w-150px'>Property</th>
              <th className='min-w-100px'>Amount</th>
              <th className='min-w-100px'>Status</th>
              {
                (currentUser?.profileType === "superadmin" || permissions?.sales?.update) && <th className='min-w-100px'>Action</th>
              }
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
          {quoteList.map((lead:any) => (
            <tr key={lead?._id?.toString()}>
                
                <td>
                <Link to={`/sales/quote/details/${lead.id}`}  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
               
                {lead?.id}
               
                </Link>
              </td>
             <td>
             <Link to={`/sales/quote/details/${lead.id}`}  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
               
                {lead?.agent?.fullName}
               
                </Link>
              </td>
              <td>
              <Link to={`/sales/quote/details/${lead.id}`}  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
               
                {lead?.client?.name}
               
                </Link>
              </td>
              <td>
              <Link to={`/sales/quote/details/${lead.id}`}  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
               
                {lead?.inventory?.name}
               
                </Link>
              </td>
             
              <td>
              <Link to={`/sales/quote/details/${lead.id}`}  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
              
                <span className={`badge  fs-6 fw-semibold `}> {lead?.total}</span>
               
                </Link>
              </td>

              <td>
              <Link to={`/sales/quote/details/${lead.id}`}  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
              
                <span className={`badge  fs-7 fw-semibold ${lead?.status==='pending' ? "badge-light-primary" : lead?.status === 'accepted' ? "badge-light-success" : "badge-light-danger"}`}> {lead?.status}</span>
               
                </Link>
              </td>
             
              {
                (currentUser?.profileType === "superadmin" || permissions?.sales?.update) && <td>
                  <Link to={`/sales/quote/edit/${lead.id}`} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                    <KTIcon iconName='pencil' className='fs-3' />
                  </Link>
                </td>
              }
              
            </tr>
          ))}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
      {/* end::Table container */}
    </div>
    {/* begin::Body */}

    {/* Modal for add client */}
 
    </div>
  )
}

export default QuotesList