import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react'
import DeveloperWidget from './DeveloperWidget'
import { addDeveloper, deleteDeveloper, editDeveloper, getCompany, postImages } from '../core/_requests'
import { AddDeveloper, Developer } from '../core/_models'
import grouppic from './camera.png';
import './some.css'
import { KTIcon } from '../../../../_metronic/helpers';
import { useAuth } from '../../../modules/auth';
import {useSelector, useDispatch} from 'react-redux'
import { RootState } from '../../../redux/store';
import { setDevelopers } from '../../../redux/property/developerSlice';

const DeveloperList: React.FC = () => {
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const developersList = useSelector((state: RootState) => state.property.developer.developers)
  const [productData, setproductData] = useState<Developer[]>(developersList)
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [developerid, setdeveloperId] = useState<any>(null);
	const [selectedImage, setSelectedImage] = useState<any>(null);
  const [imagePreviewEdit, setImagePreviewEdit] = useState<any>(null);
	const [selectedImageEdit, setSelectedImageEdit] = useState<any>(null);
  const [companyId, setcompanyId] = useState<string>("")
  const PF:any = "https://unifeed.s3.ap-south-1.amazonaws.com/";
  const [permissions, setPermissions] = useState<any>({})

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])
  
  const [formData, setFormData] = useState<AddDeveloper>({
    name: '',
    productPic: '',
    address: '',
    phoneNo: '',
    email: '',
    description: ''
  });

  const [formDataEdit, setFormDataEdit] = useState<AddDeveloper>({
    name: '',
    productPic: '',
    address: '',
    phoneNo: '',
    email: '',
  });


  const handlecompanyid = (id:string) => {
    setcompanyId(id)
  }

  useEffect(() => {
    setFormDataEdit(productData.find(item => item._id === companyId))
  }, [companyId, productData])
  



  

  

  const handleImageChangeEdit = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setSelectedImage(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // This will set the image preview URL
      };

      setFormData({
        ...formData,
        productPic: Date.now() + file.name,
      });

      reader.readAsDataURL(file);
    }
  };

  const handleImageChangeUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
console.log("sd")
    if (file) {
      setSelectedImageEdit(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewEdit(reader.result); // This will set the image preview URL
      };

      setFormDataEdit({
        ...formDataEdit,
        productPic: Date.now() + file.name,
      });

      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputChangeEdit = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormDataEdit({
      ...formDataEdit,
      [name]: value,
    });
  };



  const handleSubmit = async(e: FormEvent) => {
    e.preventDefault();
    const imageData = new FormData();
    const productPic = formData.productPic;
    imageData.append('name', productPic);
    imageData.append('image', selectedImage);

    await postImages(imageData).then(
    async (res) => {
      await addDeveloper(formData, currentUser?.company).then((response) => {
        console.log(response)
        const updatedproduct = [...productData, response];
      setproductData(updatedproduct)
      dispatch(setDevelopers(updatedproduct))
      setFormData({
        name: '',
        productPic: '',
        address: '',
        phoneNo: '',
        email: '',
        description: ''
      })
      const closeModalButton = document.getElementById('closeModalButton');
      if (closeModalButton) {
        closeModalButton.click();
      }
      })
    
       
    }
    )

    // Add your logic for handling form data here
  };

  const handleSubmitEdit = async(e: FormEvent) => {
    e.preventDefault();
    const imageData = new FormData();
    const productPic = formDataEdit.productPic;
    imageData.append('name', productPic);
    imageData.append('image', selectedImageEdit);

    await postImages(imageData).then(
    async (res) => {
      await editDeveloper(formDataEdit, companyId).then((response) => {
        console.log(response)
        const updatedproduct = productData.map(item => item._id === response._id ? response : item);
      setproductData(updatedproduct)
      dispatch(setDevelopers(updatedproduct))
      setFormDataEdit({
        name: '',
        productPic: '',
        address: '',
        phoneNo: '',
        email: '',
      })
      setcompanyId('')
      const closeModalButton = document.getElementById('closeModalButtonEdit');
      if (closeModalButton) {
        closeModalButton.click();
      }
      })
    
       
    }
    )

    // Add your logic for handling form data here
  };

  const propertylist = async() => {
		const res = await getCompany(currentUser?.company)
		setproductData(res)
    dispatch(setDevelopers(res))
	  }
  
useEffect(() => {
  if (developersList.length === 0) propertylist()
}, [])


const handleid = (id:string) => {
  setdeveloperId(id)
}

const handledelete = async() => {
  const res = await deleteDeveloper(developerid)
  console.log(res)
  if (res === "deleted"){
    const updatedArray = productData.filter((item) => item._id !== developerid);
    setproductData(updatedArray)
    dispatch(setDevelopers(updatedArray))
    const closeModalButton = document.getElementById('closeModalButtondeldelete');
    if (closeModalButton) {
      closeModalButton.click();
    }
  }
}


  return (
    <>
{(currentUser?.profileType === 'superadmin' || permissions?.developer?.create) && <div className='d-flex justify-content-end'>
  <div className='card-toolbar ms-auto me-5 mb-10' data-bs-toggle="modal" data-bs-target="#kt_modal_8">
    <a className='btn btn-sm btn-light-primary'>
      <KTIcon iconName='plus' className='fs-2' />
      Add Developer
    </a>
  </div>
</div>}

    
      <div className='row g-5 g-xl-8'>
        
        {
          productData.map((item,index) => (
            
            
        <div className='col-xl-4' key={item?._id}>
          
          <DeveloperWidget
            className='card-xl-stretch mb-xl-8'
            chartColor='light'
            image={item?.productPic}
            id = {item._id}
            handlecompanyid = {handlecompanyid}
            handleid = {  handleid}
          />
        </div>
       
          ))}
    
      </div>
      {/* end::Row */}

      <div className="modal fade" tabIndex={-1} id="kt_modal_8">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Add Developer</h5>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
        
        </div>
      </div>

      <div className="row modal-body">
      <form >
              <div className="row">
                <div className="messagepicinput d-flex flex-center ">
                  <label htmlFor="messagegrouppic" className="messagesharefilelabel">
                  <div className="symbol symbol-100px border border-gray-300 ">
                  {imagePreview && (
                        <img
      
                          src={imagePreview}
                          alt='add'
                        />
                      )}
                      {!imagePreview && <img className="" src={grouppic} alt='add'/>}
                  </div>
  
                    <input type="file" id="messagegrouppic" onChange={handleImageChangeEdit} style={{ display: "none" }} />
                  </label>
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Full Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Address</label>
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Phone No</label>
                  <input
                    type="text"
                    name="phoneNo"
                    value={formData.phoneNo}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                  </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Email</label>
                  <input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Description</label>
                  <input
                    type="text"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
              
               
              </div>
           
            </form>
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          data-bs-dismiss="modal"
          id="closeModalButton" 
        >
          Close
        </button>
        <button type="button" className="btn btn-primary" onClick={handleSubmit}   >
          Add
        </button>
      </div>
    </div>
  </div>
</div>




<div className="modal fade" tabIndex={-1} id="kt_modal_3">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Edit Developer</h5>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
        
        </div>
      </div>

      <div className="row modal-body">
      <form >
              <div className="row">
                <div className="messagepicinput d-flex flex-center ">
                  <label htmlFor="messagegrouppicedit" className="messagesharefilelabel">
               
                  <div className="symbol symbol-100px border border-gray-300 ">
                  {imagePreviewEdit && (
                        <img
      
                          src={imagePreviewEdit}
                          alt='add'
                        />
                      )}
                      {!imagePreviewEdit &&  <img className="" src={formDataEdit?.productPic !== "" ? PF+formDataEdit?.productPic : grouppic} alt='add'/>}
                  </div>
                    <input type="file" id="messagegrouppicedit" onChange={handleImageChangeUpdate} style={{ display: "none" }} />
                  </label>
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Full Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formDataEdit?.name}
                    onChange={handleInputChangeEdit}
                    className="form-control"
                  />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Address</label>
                  <input
                    type="text"
                    name="address"
                    value={formDataEdit?.address}
                    onChange={handleInputChangeEdit}
                    className="form-control"
                  />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Phone No</label>
                  <input
                    type="text"
                    name="phoneNo"
                    value={formDataEdit?.phoneNo}
                    onChange={handleInputChangeEdit}
                    className="form-control"
                  />
                  </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Email</label>
                  <input
                    type="text"
                    name="email"
                    value={formDataEdit?.email}
                    onChange={handleInputChangeEdit}
                    className="form-control"
                  />
                </div>
              
               
              </div>
           
            </form>
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          data-bs-dismiss="modal"
          id="closeModalButtonEdit" 
        >
          Close
        </button>
        <button type="button" className="btn btn-primary" onClick={handleSubmitEdit}   >
          Edit
        </button>
      </div>
    </div>
  </div>
</div>



<div className="modal fade" tabIndex={-1} id="kt_modal_4">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Delete Developer</h5>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
        
        </div>
      </div>

      <div className="row modal-body">
          Delete This Developer
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          data-bs-dismiss="modal"
          id="closeModalButtondeldelete" 
        >
          Close
        </button>
        <button type="button" className="btn btn-danger" onClick={handledelete}  >
          Delete
        </button>
      </div>
    </div>
  </div>
  </div>
    </>
  )
}

export {DeveloperList}
