import React from 'react'
import { SecondaryList } from './SecondaryList'

function Secondary() {
  return (
    <div>
      <SecondaryList/>
    </div>
  )
}

export default Secondary