import axios from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL

const PROJECTS_URL = `${API_URL}/project/`

export const addProject = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(`${PROJECTS_URL}`, data)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const editProjectById = async (data: any, projectId: string): Promise<any> => {
  try {
    const response = await axios.put(`${PROJECTS_URL}${projectId}`, data)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const getProjects = async (companyid: string): Promise<any> => {
  try {
    const response = await axios.get(`${PROJECTS_URL}${companyid}`)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const getProjectById = async (id: string): Promise<any> => {
  try {
    const response = await axios.get(`${PROJECTS_URL}projectbyid/${id}`)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const getAgentsByProjectId = async (id: string): Promise<any> => {
  try {
    const response = await axios.get(`${PROJECTS_URL}getagents/${id}`)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const deleteProject = async (id: string): Promise<any> => {
  try {
    const response = await axios.delete(`${PROJECTS_URL}${id}`)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const addTask = async (data: any, projectId: string): Promise<any> => {
  try {
    const response = await axios.post(`${PROJECTS_URL}${projectId}/task`, data)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const editTask = async (data: any, taskId: string): Promise<any> => {
  try {
    const response = await axios.put(`${PROJECTS_URL}updatetask/${taskId}`, data)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const changeTaskStatus = async (status: string, taskId: string): Promise<any> => {
  try {
    const response = await axios.put(`${PROJECTS_URL}change/${taskId}`, {status})
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const getTaskById = async (taskId: string): Promise<any> => {
  try {
    const response = await axios.get(`${PROJECTS_URL}gettasks/${taskId}`)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export const deleteTaskById = async (taskId: string): Promise<any> => {
  try {
    const response = await axios.delete(`${PROJECTS_URL}deletetask/${taskId}`)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}
