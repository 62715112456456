import {useEffect, useState} from 'react'
import {getBalanceSheet} from '../../core/_requests'
import { useAuth } from '../../../../modules/auth'

const BalanceSheet = () => {
  const {currentUser} = useAuth()
  const [data, setData] = useState([])
  const [balanceSheet, setBalanceSheet] = useState({
    assets: 0,
    liabilities: 0,
  })

  const fetchData = async () => {
    const res = await getBalanceSheet(currentUser?.company)
    console.log(res)
    setData(res)
    res?.map((item) => {
      if (
        item['type'] === 'cash' ||
        item['type'] === 'bank' ||
        item['type'] === 'otherasset' ||
        item['type'] === 'othercurrentasset' ||
        item['type'] === 'fixedasset' ||
        item['type'] === 'currentasset' ||
        item['type'] === 'stock'
      ) {
        setBalanceSheet((prev) => ({...prev, assets: prev.assets + item['total']}))
      }
      if (
        item['type'] === 'paymentclearing' ||
        item['type'] === 'othercurrentliability' ||
        item['type'] === 'creditcard' ||
        item['type'] === 'longtermliability' ||
        item['type'] === 'otherliability'
      ) {
        setBalanceSheet((prev) => ({...prev, liabilities: prev.liabilities + item['total']}))
      }
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header d-flex align-items-center'>
          <h2 className='fs-1'>Balance Sheet</h2>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-150px rounded-start'>Account</th>
                  <th className='min-w-150px'>Account Code</th>
                  <th className='min-w-150px'>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='ps-4 text-dark fw-bolder mb-1 fs-6'>Assets</td>
                </tr>
                <tr>
                  <td className='ps-8 text-dark fw-bolder mb-1 fs-6'>Current Assets</td>
                </tr>
                <tr>
                  <td className='ps-12 text-dark fw-bolder mb-1 fs-6'>Cash</td>
                </tr>
                {data?.map((item) => {
                  if (item['type'] === 'cash') {
                    return item['accountName']?.map((account: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td className='ps-8 text-primary fw-bold mb-1 fs-6'>{account.name}</td>
                          <td className='ps-4 text-primary fw-bold mb-1 fs-6'>
                            {account.accountCode}
                          </td>
                          <td className='ps-4 text-primary fw-bold mb-1 fs-6'>{account.total}</td>
                        </tr>
                      )
                    })
                  }
                  return null
                })}
                <tr>
                  <td className='ps-12 text-dark fw-bold mb-1 fs-6'>Total for Cash</td>
                  <td></td>
                  <td className='ps-4 text-dark fw-bold mb-1 fs-6'>
                    {data?.map((item) => {
                      if (item['type'] === 'cash' || item['type'] === 'bank') {
                        return item['accountName']?.map((account: any) => {
                          return account.total
                        })
                      }
                      return null
                    })}
                  </td>
                </tr>
                <tr>
                  <td className='ps-12 text-dark fw-bolder mb-1 fs-6'>Other current assets</td>
                </tr>
                {data?.map((item) => {
                  if (
                    item['type'] === 'otherasset' ||
                    item['type'] === 'othercurrentasset' ||
                    item['type'] === 'fixedasset' ||
                    item['type'] === 'currentasset' ||
                    item['type'] === 'stock'
                  ) {
                    return item['accountName']?.map((account: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td className='ps-8 text-primary fw-bold mb-1 fs-6'>{account.name}</td>
                          <td className='ps-4 text-primary fw-bold mb-1 fs-6'>
                            {account.accountCode}
                          </td>
                          <td className='ps-4 text-primary fw-bold mb-1 fs-6'>{account.total}</td>
                        </tr>
                      )
                    })
                  }
                  return null
                })}
                <tr>
                  <td className='ps-12 text-dark fw-bold mb-1 fs-6'>
                    Total for Other current assets
                  </td>
                  <td></td>
                  <td className='ps-4 text-dark fw-bold mb-1 fs-6'>
                    {data?.map((item) => {
                      if (
                        item['type'] === 'otherasset' ||
                        item['type'] === 'othercurrentasset' ||
                        item['type'] === 'fixedasset' ||
                        item['type'] === 'currentasset' ||
                        item['type'] === 'stock'
                      ) {
                        return item['accountName']?.map((account: any) => {
                          return account.total
                        })
                      }
                      return null
                    })}
                  </td>
                </tr>
                <tr>
                  <td className='ps-8 text-dark fw-bold mb-1 fs-6'>Total for Current Assets</td>
                </tr>
                <tr>
                  <td className='ps-4 text-dark fw-bold mb-1 fs-6'>Total for Assets</td>
                  <td></td>
                  <td className='ps-4 text-dark fw-bold mb-1 fs-6'>{balanceSheet.assets}</td>
                </tr>
                <tr>
                  <td className='text-dark fw-bolder mb-1 fs-6'>Liabilities & Equities</td>
                </tr>
                <tr>
                  <td className='ps-8 text-dark fw-bolder mb-1 fs-6'>Liabilities</td>
                </tr>
                {data?.map((item) => {
                  if (
                    item['type'] === 'paymentclearing' ||
                    item['type'] === 'othercurrentliability' ||
                    item['type'] === 'creditcard' ||
                    item['type'] === 'longtermliability' ||
                    item['type'] === 'otherliability'
                  ) {
                    return item['accountName']?.map((account: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td className='ps-12 text-primary fw-bold mb-1 fs-6'>{account.name}</td>
                          <td className='ps-4 text-primary fw-bold mb-1 fs-6'>
                            {account.accountCode}
                          </td>
                          <td className='ps-4 text-primary fw-bold mb-1 fs-6'>{account.total}</td>
                        </tr>
                      )
                    })
                  }
                  return null
                })}

                <tr>
                  <td className='ps-8 text-dark fw-bolder mb-1 fs-6'>Equities</td>
                </tr>
                {data?.map((item) => {
                  if (item['type'] === 'equity') {
                    return item['accountName']?.map((account: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td className='ps-12 text-primary fw-bold mb-1 fs-6'>{account.name}</td>
                          <td className='ps-4 text-primary fw-bold mb-1 fs-6'>
                            {account.accountCode}
                          </td>
                          <td className='ps-4 text-primary fw-bold mb-1 fs-6'>{account.total}</td>
                        </tr>
                      )
                    })
                  }
                  return null
                })}
                <tr>
                  <td className='ps-8 text-dark fw-bold mb-1 fs-6'>Total for Equities</td>
                  <td></td>
                  <td className='ps-4 text-dark fw-bold mb-1 fs-6'>
                    {data?.map((item) => {
                      if (item['type'] === 'equity') {
                        return item['accountName']?.map((account: any) => {
                          return account.total
                        })
                      }
                      return null
                    })}
                  </td>
                </tr>
                <tr>
                  <td className='text-dark fw-bold mb-1 fs-6'>Total for Liabilities & Equities</td>
                  <td></td>
                  <td className='ps-4 text-dark fw-bold mb-1 fs-6'>{balanceSheet.liabilities}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BalanceSheet
