import axios from 'axios'
const API_URL = process.env.REACT_APP_THEME_API_URL
const EMPLOYEE_URL = `${API_URL}/employee`
const HRM_URL = `${API_URL}/hrm`
const EMP_DEPARTMENT_URL = `${API_URL}/employeeDepartment`
const LEAVE_URL = `${API_URL}/hrm/leaveManagement`
const ROLES_URL = `${API_URL}/user-management/roles`
const POST_DOCS_URL = `${API_URL}/uploaddocs/employee/`
const ATTENDANCE_URL = `${API_URL}/attendance`
const PAYROLL_URL = `${API_URL}/payroll`
const REPORTS_URL = `${API_URL}/reports`

export async function addEmployee(formData) {
  try {
    const response = await axios.post(`${EMPLOYEE_URL}`, formData)
    return response.data
  } catch (error) {
    console.error('Add Employee error:', error)
    throw error
  }
}

export const getEmployeeByStatus = async (query, orgid) => {
  try {
    console.log(`${EMPLOYEE_URL}/employeesByFiltering/${orgid}?${query}`)
    const response = await axios.get(`${EMPLOYEE_URL}/employeesByFiltering/${orgid}?${query}`)
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getEmployee = async (orgid) => {
  try {
    const response = await axios.get(`${EMPLOYEE_URL}/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getEmployeeById = async (employeeId) => {
  try {
    const response = await axios.get(`${EMPLOYEE_URL}/employeeById/${employeeId}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const deleteEmployee = async (employeeId) => {
  try {
    const response = await axios.delete(`${EMPLOYEE_URL} / ${employeeId}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const updateEmployee = async (employeeID, productdata) => {
  try {
    console.log(productdata)
    const response = await axios.put(`${EMPLOYEE_URL}/${employeeID}`, productdata)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const deactivateEmployee = async (employeeID) => {
  try {
    const response = await axios.put(`${EMPLOYEE_URL}/deactivate/${employeeID}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
// export const getAllDepartment = async () => {
//   try {
//     const response = await axios.get(`${ DEPARTMENT_URL }`)
//     return response.data
//   } catch (error) {
//     console.error('Axios error:', error)
//     throw error
//   }
// }
export const getAllEmployeeByID = async (id) => {
  try {
    const response = await axios.get(`${EMP_DEPARTMENT_URL}/employees/${id}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
export async function postLeaveApply(values) {
  try {
    const response = await axios.post(`${HRM_URL}/leaveManagement`, values)
    console.log('Leave request submitted successfully:', response.data)
    return response.data
  } catch (error) {
    console.error('Error submitting leave request:', error)
  }
}
//all department
export const getAllDepartment = async (orgid) => {
  try {
    const response = await axios.get(`${EMP_DEPARTMENT_URL}/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}
export const createEmpDepartment = async (data) => {
  try {
    const response = await axios.post(`${EMP_DEPARTMENT_URL}`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

//all roles
export const getAllRoles = async () => {
  try {
    const response = await axios.get(`${ROLES_URL}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getAllLeaveDetails = async (orgid) => {
  try {
    const response = await axios.get(`${LEAVE_URL}/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}
export const getLeaveDetailByID = async (id) => {
  try {
    const response = await axios.get(`${LEAVE_URL} / getleavebyid / ${id}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

//POST LEAVE TYPE
export async function postLeaveType(values) {
  try {
    const response = await axios.post(`${HRM_URL}/leaveType`, values)
    console.log('Leave request submitted successfully:', response.data)
    return response.data
  } catch (error) {
    console.error('Error submitting leave request:', error)
  }
}

export const getLeaveTypeByOrgId = async (orgid) => {
  try {
    const response = await axios.get(`${HRM_URL}/leaveType/getLeaveTypes/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const addLeaveTypeToOrg = async (data, orgid) => {
  try {
    const response = await axios.put(`${HRM_URL}/leaveType/addleaveType/${orgid}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

//GET LEAVE TYPE
export const getLeaveTypes = async (orgid) => {
  try {
    const response = await axios.get(`${HRM_URL}/leaveType/leave-types/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

//GET LEAVE TYPE BY ID
export const getLeaveTypeById = async (leaveId) => {
  try {
    const response = await axios.get(`${HRM_URL}/leaveType/leavetypesbyid/${leaveId}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

//GET LEAVE TYPE BY DEPT ID
export const getLeaveTypeByDeptId = async (orgid, deptId) => {
  try {
    const response = await axios.get(`${HRM_URL}/leaveType/leavetypesbydeptid/${orgid}/${deptId}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
  }
}

//EDIT EMP DEPARTMENT
export const editEmployeeDepartmentById = async (departmentId, departmentData) => {
  try {
    console.log(departmentData)
    const response = await axios.put(`${EMP_DEPARTMENT_URL}/${departmentId}`, departmentData)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

//DEACTIVATE EMP DEPT
export const deleteEmployeeDepartmentById = async (deptId) => {
  try {
    const response = await axios.put(`${EMP_DEPARTMENT_URL}/deactivate/${deptId}`)
    console.log('Deleted employee department', response)

    return response
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

//GET EMP DEPARTMENT BY ID
export const getEmployeeDepartmentById = async (id) => {
  try {
    const response = await axios.get(`${EMP_DEPARTMENT_URL}/employees/${id}`)
    console.log('EMP DEPT BY ID', response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function getDocs(docid) {
  try {
    const response = await axios.get(`${EMPLOYEE_URL}/files/${docid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export const deletedocs = async (id, docid) => {
  try {
    const response = await axios.delete(`${EMPLOYEE_URL}/files/${id}/${docid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export const editdocs = async (data, id, docid) => {
  try {
    const response = await axios.put(`${EMPLOYEE_URL}/files/${id}/${docid}`, data)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function uploaddocs(formData, id) {
  try {
    const response = await axios.post(`${POST_DOCS_URL}${id}`, formData)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

// Get function for payroll by month grouping
export const getLeavesWithMonth = async (orgid) => {
  try {
    const response = await axios.get(`${LEAVE_URL}/getleavesbymonth/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

// Get function for payroll by months and year
export const getLeavesByMonth = async (month, orgid) => {
  try {
    const response = await axios.get(`${LEAVE_URL}/getleavesbymonth/${orgid}/${month}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

//Post Employee Report (submit Inspection form data)
export async function postEmployeeReport(data) {
  try {
    const response = await axios.post(`${HRM_URL}/employeeReport`, data)
    console.log('data submitted successfully:', response.data)
    return response.data
  } catch (error) {
    console.error('Error submitting data:', error)
  }
}

//get Employee Report (fetch Inspection form data)
export async function getEmployeeReports(orgid) {
  try {
    const response = await axios.get(`${HRM_URL}/employeeReport/allemployeeReport/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Add Client error:', error)
    throw error
  }
}

// (fetch all submission form data)
export async function getEmployeeReportSubmission(orgid) {
  try {
    const response = await axios.get(`${HRM_URL}/employeeReportSubmission/${orgid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

// (fetch inspection form data based on deptid and role)
export async function getEmployeeReportbyDeptandRole(deptId, role) {
  try {
    const response = await axios.get(`${HRM_URL}/employeeReport/department/${deptId}/role/${role}/`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}
// (fetch inspection form data based on id)
export async function getEmployeeReportbyId(Id) {
  try {
    const response = await axios.get(`${HRM_URL}/employeeReport/employeeReportById/${Id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

// get employee based on email
export const getEmployeeByEmail = async (email) => {
  try {
    const response = await axios.get(`${EMPLOYEE_URL}/employeeByEmail/${email}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

//submit AddReportSubmission data by selected employeeReport id
export async function postEmployeeReportAnswersByFormId(formId, values) {
  try {
    const response = await axios.post(
      `${HRM_URL}/employeeReportSubmission/submit/${formId}`,
      values
    )
    console.log('Answers submitted successfully:', response.data)
    return response.data
  } catch (error) {
    console.error('Error submitting Answers', error)
  }
}

//getEmployeeReportSubmissionwithCount
export async function getEmployeeReportSubmissionCount() {
  try {
    const response = await axios.get(`${HRM_URL}/employeeReport/submission-count`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getEmployeeByDepart(deptId) {
  try {
    console.log('emp url', `${EMP_DEPARTMENT_URL}/employees/${deptId}`)
    const response = await axios.get(`${EMP_DEPARTMENT_URL}/employees/${deptId}`)
    console.log('data', response.data)
    return response.data
  } catch (error) {
    console.error('Add client error:', error)
    throw error
  }
}

export async function getEmployeesAttendance(orgid) {
  try {
    const response = await axios.get(`${ATTENDANCE_URL}/all/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function getEmployeeTotalWorkingHours(employeeid, data) {
  try {
    const response = await axios.post(`${ATTENDANCE_URL}/workinghours/${employeeid}`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function getEmployeeAttendanceById(employeeid) {
  try {
    const response = await axios.get(`${ATTENDANCE_URL}/attendance/${employeeid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function getEmployeeAttendanceByDate(data) {
  try {
    const response = await axios.post(`${ATTENDANCE_URL}/attendance/bydate`, data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function getEmployeesIdOfAttendanceByOrgId(orgid) {
  try {
    const response = await axios.get(`${ATTENDANCE_URL}/attendance/employee/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
//get all submissions by employeeReport id
export async function getEmployeeReportSubmissionByReportId(reportId) {
  try {
    const response = await axios.get(
      `${HRM_URL}/employeeReportSubmission/employee-report/${reportId}/submissions`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

//get submission details by its id
export async function getEmployeeReportSubmissionDetailsById(Id) {
  try {
    const response = await axios.get(`${HRM_URL}/employeeReportSubmission/submission/${Id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

//post function for payroll
export const postPayroll = async (data) => {
  try {
    const response = await axios.post(`${PAYROLL_URL}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

//get function for payroll
export const getPayroll = async () => {
  try {
    const response = await axios.get(`${PAYROLL_URL}/getpayroll`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

// Get function for payroll by month grouping
export const getPayrollWithMonth = async (orgid) => {
  try {
    const response = await axios.get(`${PAYROLL_URL}/getpayrollbymonth/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

// Get function for payroll by months and year
export const getPayrollsByMonth = async (month, orgid) => {
  try {
    const response = await axios.get(`${PAYROLL_URL}/getpayrollbymonth/${orgid}/${month}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const postAttendance = async (data) => {
  try {
    const response = await axios.post(`${ATTENDANCE_URL}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getAgentsPerformance = async (companyid, startDate, endDate) => {
  try {
    const response = await axios.get(
      `${REPORTS_URL}/agent/${companyid}?startDate=${startDate}&endDate=${endDate}`
    )
    console.log('Response of sales performance', response.data)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getClientsPerformance = async (companyid) => {
  try {
    const response = await axios.get(`${REPORTS_URL}/client/${companyid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
