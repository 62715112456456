import React from 'react'
import { ProperList } from './ProperList'

function Property() {
  return (
    <div>
      <ProperList/>
    </div>
  )
}

export default Property