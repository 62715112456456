import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  clientCount: 'Loading',
}

const dashboardStatsSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setClientCount(state, action) {
      state.clientCount = action.payload
    },
  },
})

export const {setClientCount} = dashboardStatsSlice.actions
export default dashboardStatsSlice.reducer
