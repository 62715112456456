import React, { useEffect } from 'react';
import { getAgent } from '../agent/core/_requests';
import { useAuth } from '../../modules/auth';
import profile from './profile.png';
import {useSelector, useDispatch} from 'react-redux';
import { RootState } from '../../redux/store';
import { setAgentsData } from '../../redux/agent/agentSlice';

type Props = {
    agentids: any,
    setagentids:any
  }

const AgentList: React.FC<Props> = ({agentids,setagentids}) => {
  const { currentUser } = useAuth();
  // const [agent, setAgent] = useState<any>([]);
  const dispatch = useDispatch();
  const agent = useSelector((state: RootState) => state.agent.agent.agents);

  const PF: string = 'https://unifeed.s3.ap-south-1.amazonaws.com/';

  const handleSelectAll = (isChecked: boolean) => {
    const allCheckboxValues = agent.map((user: any) => user?._id);
    setagentids(isChecked ? allCheckboxValues : []);
  };

  const handleCheckboxChange = (value: string) => {
    const updatedSelectedCheckboxes = agentids.includes(value)
      ? agentids.filter((checkbox) => checkbox !== value)
      : [...agentids, value];

    setagentids(updatedSelectedCheckboxes);
  };

  useEffect(() => {
    const getAgentLists = async () => {
      const res = await getAgent(currentUser?.company);
      dispatch(setAgentsData(res))
    };

    if (agent.length === 0)
      getAgentLists();
  }, [agent, currentUser, dispatch]);

  return (
    <div className={`card`}>
      {/* begin::Header */}

      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Select All</th>
                <th className='w-75px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      onChange={(e) => handleSelectAll(e.target.checked)}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {agent.map((user, index) => (
                <tr key={index}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-45px me-5'>
                        <img src={user?.profilePic ? PF + user?.profilePic : profile} alt='profile' />
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <div className='text-dark fw-bold text-hover-primary fs-6'>
                          {user?.fullName}
                        </div>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>{user?.profileType}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input widget-9-check'
                        type='checkbox'
                        value={user?._id}
                        checked={agentids?.includes(user?._id)}
                        onChange={() => handleCheckboxChange(user?._id)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export default AgentList;
