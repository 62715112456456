import React, {FC} from 'react'
import {ErrorMessage, Field} from 'formik'

const Step4: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Property Info</h2>
      </div>

      <div className='d-flex flex-column mb-7 fv-row'>
        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
          <span className='required'>
            Latitude 
            </span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title="Specify property area in sqft"
          ></i>
        </label>

        <Field
          type='text'
          className='form-control form-control-solid'
          placeholder=''
          name='propertyLatitude'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='propertyLatitude' />
        </div>
      </div>
      <div className='d-flex flex-column mb-7 fv-row'>
        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
          <span className='required'>
            Longitude 
          </span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title="Specify property area in sqft"
          ></i>
        </label>

        <Field
          type='text'
          className='form-control form-control-solid'
          placeholder=''
          name='propertyLongitude'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='propertyLongitude' />
        </div>
      </div>

      <div className='d-flex flex-column mb-7 fv-row'>
        <label className='required fs-6 fw-bold form-label mb-2'>Area</label>

          <Field
            type='text'
            className='form-control form-control-solid'
       
            name='propertyArea'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='propertyArea' />
          </div>

       
    
      </div>

      <div className='row mb-10'>

            <div className='col-6'>
            <label className='required fs-6 fw-bold form-label mb-2'>Property Status</label>
              <Field as='select' name='Propertystatus' className='form-select form-select-solid'>
                <option></option>
                <option value='new'>new</option>
                <option value='completed'>completed</option>
                <option value='onprocess'>onprocess</option>
                <option value='old'>old</option>

              </Field>
              <div className='text-danger mt-2'>
                <ErrorMessage name='Propertystatus' />
              </div>
            </div>

            <div className='col-6'>
            <label className='required fs-6 fw-bold form-label mb-2'>Property Handover Year</label>
                  <Field
                  type='text'
                  className='form-control form-control-solid'

                  name='PropertyHandover'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='PropertyHandover' />
                </div>
           

        </div>

      
      </div>


    </div>
  )
}

export {Step4}
