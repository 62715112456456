/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import './mixed.css'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../modules/auth'

type Props = {
  className: string
  chartColor: string
  image: string
  id:string
  handlecompanyid:any
  handleid:any
}

const DeveloperWidget: React.FC<Props> = ({className, chartColor, image, id, handlecompanyid,  handleid}) => {
  const PF:any = "https://unifeed.s3.ap-south-1.amazonaws.com/";
  const {currentUser} = useAuth()

  const [permissions, setPermissions] = useState<any>({})

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])
 
  // const [formDataProp, setFormDataProp] = useState<AddProperty>({
  //   name: '',
  //   productPic: '',
  //   startingprice: '',
  //   lastprice: '',
  //   address: '',
  //   lat:'',
  //   lng:'',
  //   projectType:'',
  //   area:'',
  //   status:'',
  //   handOver:'',
  // });

  
  // const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];

  //   if (file) {
  //     setSelectedImage(file);

  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setImagePreview(reader.result); // This will set the image preview URL
  //     };

  //     setFormDataProp({
  //       ...formDataProp,
  //       productPic: Date.now() + file.name,
  //     });

  //     reader.readAsDataURL(file);
  //   }
  // };


  
  

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
     
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Chart */}
        <div

          className={` card-rounded-bottom h-200px mixed_body bg-${chartColor}`}
        >
          <img src={`${PF+image}`} alt="Your d"  style={{ width: '100%', height: '100%' }}/>

          <div className={`card-header border-0 py-5 bg-${chartColor} mixed_child `}>
          <div className='d-flex flex-stack justify-content-end w-100'>
           
           <div className='ms-1'>
        
             {/* <div className='btn btn-sm btn-icon btn-color-danger btn-active-light-danger' onClick={()=>handleid(id)}  data-bs-toggle="modal" data-bs-target="#kt_modal_4">
             <KTIcon iconName='trash' className='fs-2'/>
             </div> */}

             {(currentUser?.profileType === 'superadmin' || permissions?.developer?.update) && <button
              type='button'
              className='btn btn-sm btn-icon btn-color-dark btn-active-light-dark'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              >
              <KTIcon iconName='category' className='fs-2' />
              </button>}
              {/* begin::Menu 2 */}
              <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-200px'
              data-kt-menu='true' style={{ zIndex: 1000000}}
              >
              {/* begin::Menu item */}
              <div className='menu-item px-3'>
                <div className='menu-content fs-6 text-dark fw-bold px-3 py-4'>Quick Actions</div>
              </div>
{/*        
              <div className='menu-item px-3' onClick={()=>handleid(item?._id)}  data-bs-toggle="modal" data-bs-target="#kt_modal_3">
                <a href='#' className='menu-link px-3'>
                  Edit
                </a>
              </div> */}
              <div className='separator mt-3 opacity-75'></div>
              <div className='menu-item px-3' onClick={()=>handleid(id)}  data-bs-toggle="modal" data-bs-target="#kt_modal_4">
                <a href='#' className='menu-link px-3'>
                  Delete
                </a>
              </div>
              <div className='separator mt-3 opacity-75'></div>
              </div>
     
           
        
         </div>
         </div>
      
  
      </div>

        </div>
        {/* end::Chart */}
        {/* begin::Stats */}
        <div className='card-p mt-n20 position-relative mixed_btm'>
          {/* begin::Row */}
          <div className='row g-0'>
            {/* begin::Col */}
           
            <div className='col bg-light-warning px-6 py-4 rounded-2 me-7  mixed_btmitem mixed_btmitem'>
            <Link to= {`/property/developerDetails/${id}`} className='text-warning fw-semibold d-flex justify-between align-items-center flex-column'>
              <KTIcon iconName='chart-simple' className='fs-3x text-warning d-block my-2' />
              
                Details
                </Link>
            </div>
           
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col bg-light-primary px-6 py-4 rounded-2 mb-7 mixed_btmitem' >
              <Link to={`/property/addproperty/${id}`} className='text-primary fw-semibold d-flex justify-between align-items-center flex-column'>
              <KTIcon iconName='plus' className='fs-3x text-primary d-block my-2' />
                Add Properties
              </Link>
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
          {/* begin::Row */}
          <div className='row g-0'>
            {/* begin::Col */}
            <div className='col bg-light-danger px-6 py-4 rounded-2 me-7 mixed_btmitem'>
            <Link to= {`/property/propertlylistofdeveloper/${id}`} className='text-warning fw-semibold fs-6 d-flex justify-between align-items-center flex-column'>
              <KTIcon iconName='abstract-26' className='fs-3x text-danger d-block my-2' />
              <a href='#' className='text-danger fw-semibold mt-2'>
                Property List
              </a>
              </Link>
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col bg-light-success px-6 py-4 rounded-2 mixed_btmitem cursor-pointer d-flex justify-between align-items-center flex-column'  onClick={()=>handlecompanyid(id)}   data-bs-toggle="modal" data-bs-target="#kt_modal_3">

              <KTIcon iconName='sms' className='fs-3x text-success d-block my-2' />
              <a href='#' className='text-success fw-semibold mt-2'>
                Edit
              </a>

            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
        </div>
        {/* end::Stats */}
      </div>
      {/* end::Body */}

      
      
     
    </div>
  )
}



export default DeveloperWidget
