/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import  { useEffect, useState } from 'react'
import { Property } from '../../project/core/_models'
import { getProperty } from '../../project/core/_requests'
import { useAuth } from '../../../modules/auth'


type Props = {
  
}

const PropertyTable: React.FC<Props> = () => {
    const [propertyList, setpropertyList] = useState<Property[]>([])
    const PF:any = "https://unifeed.s3.ap-south-1.amazonaws.com/";
const {currentUser} = useAuth()


    useEffect(() => {
		const getpropertylist= async() => {
      try{
      const res = await  getProperty(currentUser.company);
      setpropertyList(res)
			}catch (error) {
       
        console.error('Error fetching client details:', error);
      }
    };
    getpropertylist()
	}, [])
    
  return (
    <div className='card mb-5 mb-xl-8 h-100 overflow-scroll'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>New Properties</span>
          <span className='text-muted mt-1 fw-semibold fs-7'></span>
        </h3>
        {/* <div className='card-toolbar'>
          <a href='#' className='btn btn-sm btn-light-primary'>
            <KTIcon iconName='plus' className='fs-2' />
            New Properties
          </a>
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-200px rounded-start'>Name</th>
                <th className='min-w-150px'>Price</th>
                <th className='min-w-150px'>Handover</th>
                {/* <th className='min-w-200px'>Agent</th> */}
                <th className='min-w-150px'>Status</th>
             
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
              <tbody>
            {
        propertyList.map((item, idx) =>(
              <tr key={idx}>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-5'>
                      <img
                        src={PF + item.productPic}
                        className=' object-fit-cover'
                        alt={PF + 'person/noAvatar.png'}
                      />
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {item?.name}
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        {item?.name}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    {item?.startingprice}
                  </div>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>Paid</span>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    {item?.handOver}
                  </div>
                 {/*  <span className='text-muted fw-semibold text-muted d-block fs-7'>Rejected</span> */}
                </td>
                {/* <td>
                  <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    Bradly Beal
                  </div>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>Insurance</span>
                </td> */}
                <td>
                  <span className='badge badge-light-primary fs-7 fw-semibold'>{item?.status}</span>
                </td>
          
              </tr>
              
              
            ))
          }
          </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {PropertyTable}
