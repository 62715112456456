import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  clientPerformance: [],
  loading: true,
}

const clientPerformanceSlice = createSlice({
  name: 'clientPerformance',
  initialState,
  reducers: {
    setPerformance(state, action) {
      state.clientPerformance = action.payload
      state.loading = false
    },
    setLoading(state) {
      state.loading = true
    },
  },
})

export const {setPerformance, setLoading} = clientPerformanceSlice.actions
export default clientPerformanceSlice.reducer
