import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useLocation} from 'react-router'
import { useEffect, useState } from 'react'
import { useAuth } from '../../../../../app/modules/auth'


export function MenuInner() {
const {currentUser} =useAuth()
  const { pathname } = useLocation();
  const [currentUrl, setCurrentUrl] = useState<string>('');
  const [permissions, setPermissions] = useState<any>({})

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  useEffect(() => {
    // Check if the URL contains the substring "marketing"
    if (pathname.includes("marketing")) {
      setCurrentUrl("marketing");
    } else if (pathname.includes("telecom")) {
      setCurrentUrl("telecom");
    }else if (pathname.includes("property")) {
      setCurrentUrl("property");
    }else if (pathname.includes("events")) {
      setCurrentUrl("events");
    }else if (pathname.includes("sales")) {
      setCurrentUrl("sales");

    }else if (!pathname.includes("account/expenses") && pathname.includes("expenses")) {
      setCurrentUrl("expenses");
   
    }else if (pathname.includes("finance")) {
      setCurrentUrl("finance");
    }else if (pathname.includes("-report")){
      setCurrentUrl("performance")
    }else {
      // If none of the conditions match, set a default value
      setCurrentUrl("none");
    }
  }, [pathname]);

  return (
    <>
    {
      currentUrl === "marketing" ? (
        <>
        <MenuItem title="marketing1" to='/marketing/marketing1' />
      <MenuItem title='marketing2' to='/marketing/marketing2' />
      <MenuItem title='marketing3' to='/marketing/marketing3' />

      </>
      

      ): currentUrl === "telecom"? (
        <>
        {/* {
                (currentUser?.profileType === "admin") && (
        <MenuItem title="Dashboard" to='/telecom/dashboard' />)} */}
      <MenuItem title='Calls' to='/telecom/calls'/>
      <MenuItem title='Failed Calls' to='/telecom/failedcalls' />
      {
        (currentUser?.profileType === "superadmin" || permissions?.telecom?.admin || currentUser?.profileType === 'admin') && (
      <MenuItem title='Live' to='/telecom/live'/>)}
      <MenuItem title='Phonebook' to='/telecom/phonebook' />
      <MenuItem title='Dialer' to={`/telecom/dialer` }/>

      </>
      ):currentUrl === "property"? (
        <>
              <MenuItem title='Primary' to='/property/Primary' />
            <MenuItem title='Secondary' to='/property/secondary' />
      <MenuItem title='Developer' to='/property/developer' />
      <MenuItem title='Maps' to='/property/maps' />
      </>
      ):currentUrl === "expenses"? (
        <>
       
              <MenuItem title='Expense' to='/expenses/expense' />
              {
                (currentUser?.profileType === "superadmin" || permissions?.expenses?.admin) && (
                  <MenuItem title='Status' to='/expenses/status' />
               
                )
              }

              {
                (currentUser?.profileType === "superadmin" || permissions?.expenses?.admin) && (
                  <MenuItem title='Expenses' to='/expenses/trialbalance' />
               
                )
              }
              {
                (currentUser?.profileType === "superadmin" || permissions?.expenses?.admin) && (
                  <MenuItem title='Revenue' to='/expenses/revenue' />
                 
               
                )
              }
              {
                (currentUser?.profileType === "superadmin" || permissions?.expenses?.admin) && (
                  <MenuItem title='P & L' to='/expenses/pnl' />
               
                )
              }
           
      </>
      )
      :currentUrl === "events"? (
        <>
              <MenuItem title='Road Show' to='/events/roadshow' />
            <MenuItem title='Launch' to='/events/launch' />
      
      </>
      )
      :currentUrl === "performance"? (
        <>
              <MenuItem title='Agent' to='/agent-report' />
            <MenuItem title='Client' to='/client-report' />
      </>
    
      ):currentUrl === "sales"? (
        <>
            <MenuItem title='Deals' to='/sales/deals' />
            {
                (currentUser?.profileType === "superadmin" || permissions?.sales?.admin) && (
                  <MenuItem title='Status' to='/sales/status' />
               
                )
              }
            <MenuItem title='Quotes' to='/sales/quotes' />
            <MenuItem title='Invoices' to='/sales/invoices' />
      </>
    
      ):currentUrl === "finance"? (
        <>
        {
          (currentUser?.profileType === "agent") && (
          <MenuItem title='finance1' to='/finance/finance1' />
          )
        }
            
        
            <MenuItem title='finance2' to='/finance/finance2' />
            
      </>
      ):(
        <>

      
      </>
      )
    }
      
    </>
  )
}
