import React from 'react'
import Dealsdetailsheader from '../components/Dealsdetailsheader'
import Dealhistory from '../components/Dealhistory'


function Dealsdetails() {
  return (
    <div>
      <Dealsdetailsheader/>
      <div className='mt-10'>
    
    <Dealhistory/>
    </div>
    </div>
  )
}

export default Dealsdetails