import React, {useEffect, useRef, useState} from 'react'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {
  getClientbyagent,
  getClientbycompany,
  getDeals,
  getDealsofagent,
  getProperty,
  postlead,
} from '../core/_requests'
import {Link} from 'react-router-dom'
import Select from 'react-select'
import {useAuth} from '../../../modules/auth/core/Auth'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setDeals} from '../../../redux/sales/dealsSlice'
import ClientSearch from './ClientSearch'

function Deallist() {
  const dispatch = useDispatch()
  const Leadlist = useSelector((state: RootState) => state.sales.deals.deals)
  const loading = useSelector((state: RootState) => state.sales.deals.loading)
  const [selectedBillFrom, setselectedBillFrom] = useState(null)
  const [dealName, setDealName] = useState<string>('')
  const [propertyList, setPropertyList] = useState<any>([])
  const [selectedProperty, setselectedProperty] = useState<any>(null)
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  const [selectClient, setSelectClient] = useState<boolean>(false)
  const [clientName, setClientName] = useState<any>({})

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  // const getClientList = async () => {
  //   try {
  //     const res = await getClientbycompany(currentUser?.company)
  //     setClientsList(
  //       res.map((r) => ({
  //         value: r._id,
  //         label: r.name, // Corrected the typo here from "labe" to "label"
  //       }))
  //     )

  //     console.log(res)
  //   } catch (error) {
  //     console.error('Error fetching client list:', error)
  //   }
  // }

  // const getClientListbyagent = async () => {
  //   try {
  //     const res = await getClientbyagent(currentUser?._id)
  //     setClientsList(
  //       res.map((r) => ({
  //         value: r._id,
  //         label: r.name, // Corrected the typo here from "labe" to "label"
  //       }))
  //     )

  //     console.log(res)
  //   } catch (error) {
  //     console.error('Error fetching client list:', error)
  //   }
  // }

  const fetchProperties = async () => {
    const res = await getProperty(currentUser?.company)
    setPropertyList(
      res?.map((r) => ({
        value: r._id,
        label: r.name,
      }))
    )
  }

  useEffect(() => {
    // if (permissions?.sales?.admin === false) {
    //   getClientListbyagent()
    // } else if (currentUser?.profileType === 'superadmin' || permissions?.sales?.admin) {
    //   getClientList()
    // }
    fetchProperties()
  }, [permissions])

  const getdealslist = async () => {
    if (permissions?.sales?.admin === false) {
      const res = await getDealsofagent(currentUser?._id)
      dispatch(setDeals(res))
      console.log(res)
    } else if (currentUser?.profileType === 'superadmin' || permissions?.sales?.admin) {
      const res = await getDeals(currentUser?.company)
      dispatch(setDeals(res))
      console.log(res)
    }
  }

  useEffect(() => {
    if (loading) getdealslist()
  }, [permissions])

  useEffect(() => {
    setSelectClient(false)
  }, [selectedBillFrom])

  const handleBillFromChange = (selectedOption) => {
    setselectedBillFrom(selectedOption)
  }

  const handlePropertyChange = (selected) => {
    setselectedProperty(selected)
  }

  const handleSubmit = async () => {
    const data: any = {
      agent: currentUser?._id,
      client: selectedBillFrom,
      project: selectedProperty?.value || null,
      dealName,
    }
    try {
      await postlead(data, currentUser?.company).then((res) => {
        getdealslist()
        console.log(res)
        setDealName('')
        setselectedProperty(null)
        setselectedBillFrom(null)
        const closeModalButton = document.getElementById('closeModalButton')
        if (closeModalButton) {
          closeModalButton.click()
        }
      })
    } catch (error) {}
  }

  if (loading) {
    return (
      <div className='d-flex justify-content-center'>
        <div className='loader'></div>
      </div>
    )
  }

  return (
    <div className={`card h-md-50 mb-5 mb-xl-10`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Deals</span>
        </h3>

        {/* {!(currentUser?.profileType === 'superadmin' || permissions?.sales?.admin) && ( */}
        <div className='card-toolbar' data-bs-toggle='modal' data-bs-target='#kt_modal_1'>
          <a href='#' className='btn btn-sm btn-light-primary'>
            <KTIcon iconName='plus' className='fs-2' />
            Add Deal
          </a>
        </div>
        {/* )} */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4 table-row-bordered'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-200px rounded-start'>ID</th>
                <th className='min-w-200px'>Deal Name</th>
                <th className='min-w-200px'>Agent</th>
                <th className='min-w-200px'>Client</th>
                <th className='min-w-200px'>Project</th>
                <th className='min-w-100px'>Status</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {Leadlist.map((lead: any) => (
                <tr key={lead?._id?.toString()}>
                  <td>
                    <Link to={`/sales/dealdetails/${lead._id}`}>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 ps-4'
                      >
                        {lead?.id}
                      </a>
                    </Link>
                  </td>
                  <td>
                    <Link to={`/sales/dealdetails/${lead._id}`}>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {lead?.dealName}
                      </a>
                    </Link>
                  </td>
                  <td>
                    <Link to={`/sales/dealdetails/${lead._id}`}>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {lead?.agent?.fullName}
                      </a>
                    </Link>
                  </td>
                  <td>
                    <Link to={`/sales/dealdetails/${lead._id}`}>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {lead?.client?.name}
                      </a>
                    </Link>
                  </td>
                  <td>
                    <Link to={`/sales/dealdetails/${lead._id}`}>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {lead?.project?.name}
                      </a>
                    </Link>
                  </td>
                  <td>
                    <Link to={`/sales/dealdetails/${lead._id}`}>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <span
                          className={`badge  fs-7 fw-semibold ${
                            lead?.status === 'open' ? 'badge-light-primary' : 'badge-light-success'
                          }`}
                        >
                          {' '}
                          {lead?.status}
                        </span>
                      </a>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {/* Modal for add client */}

      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add Deals</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <form>
                {selectClient ? (
                  <div>
                    <button
                      type='button'
                      className='btn btn-sm btn-light-primary mb-5'
                      onClick={() => setSelectClient(false)}
                    >
                      <KTIcon iconName='arrow-left' className='fs-2' />
                    </button>
                    <ClientSearch
                      setClient={setselectedBillFrom}
                      clientId={selectedBillFrom}
                      setClientName={setClientName}
                      closeRef={null}
                    />
                  </div>
                ) : (
                  <div className='row rowwidthclient'>
                    <div className='form-group mb-3 col-md-12'>
                      <label className='required'>Deal Name</label>

                      <input
                        type='text'
                        placeholder='Enter Deal Name'
                        value={dealName}
                        onChange={(e) => setDealName(e.target.value)}
                        className='form-control form-control-solid'
                      />

                      <label className='mt-3'>Select Project</label>
                      <Select
                        name='project'
                        aria-label='Select Project'
                        placeholder='Select project'
                        options={propertyList}
                        isSearchable={true}
                        onChange={handlePropertyChange}
                        value={selectedProperty}
                      />
                      <label className='required mt-3 block'>Select Client</label>
                      {clientName && <h6 className='capitalize my-2'>{clientName?.name}</h6>}
                      {/* <Select
                            name="client"
                            aria-label="Select Client"
                            placeholder="Select client"
                            options={clientsList}
                            isSearchable={true}

                            onChange={handleBillFromChange}
                            value={selectedBillFrom}

                        /> */}
                      <button
                        type='button'
                        className='btn btn-sm btn-success form-control'
                        onClick={() => setSelectClient(true)}
                      >
                        Select Client
                      </button>
                    </div>
                  </div>
                )}
              </form>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton'
              >
                Close
              </button>
              <button type='button' className='btn btn-primary' onClick={handleSubmit}>
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Deallist
