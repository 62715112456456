import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  deals: [],
  loading: true,
}

export const dealsSlice = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    setDeals: (state, action) => {
      state.deals = action.payload
      state.loading = false
    },
  },
})

export const {setDeals} = dealsSlice.actions
export default dealsSlice.reducer
