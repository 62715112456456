import React from 'react'
import {useEffect, useState} from 'react'
import {getSalesByCustomer} from '../../core/_requests'
import {useAuth} from '../../../../modules/auth'

const SalesByCustomer = () => {
  const {currentUser} = useAuth()
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)

  const fetchData = async () => {
    const res = await getSalesByCustomer(currentUser?.company)
    console.log(res)
    setData(res)
    res?.map((item) => {
      setTotal((prev) => prev + item['totalSales'])
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header d-flex align-items-center'>
          <h2 className='fs-1'>Sales By Customer</h2>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-150px rounded-start'>Customer</th>
                  <th className='min-w-150px'>Sales Code</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <tr key={index}>
                    <td className='ps-4 text-dark fw-bold mb-1 fs-6'>{item?.customerName}</td>
                    <td className='text-primary fw-bold mb-1 fs-6'>{item?.totalSales}</td>
                  </tr>
                ))}
                <tr>
                  <td className='ps-4 text-dark fw-bolder mb-1 fs-6'>Total</td>
                  <td className='text-primary fw-bolder mb-1 fs-6'>{total}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SalesByCustomer
