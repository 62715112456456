/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import { getAgentById } from '../core/_requests'
import { Agent } from '../core/_models'
import { ClientlistbyId } from './ClientListbyId'
import profile from './profile.png'
import { PropertyListbyId } from './PropertyListbyId'
import { Documents } from './Documnets'
import { Notes } from './Notes'
import TodoListprofile from '../../calender/TodoListprofile'
import Deallist from './Deallist'
import MeetList from './MeetList'
import AgentCalender from './AgentCalender'

const AgentDetail: React.FC = () => {
  const [data, setData] = useState<Agent>()
  const [property, setProperty] = useState("overview")
  const agentId = useParams().id
	const PF:any = "https://unifeed.s3.ap-south-1.amazonaws.com/"; 

  useEffect(() => {
		const Agentdetail = async() => {
      try{
      const res = await getAgentById(agentId);
      setData(res)
			}catch (error) { 
        console.error('Error fetching client details:', error);
      }
    };
    Agentdetail()
	}, [agentId])
  

  return (
   <div> 
        <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
                <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative border border-gray'>
                  {
                   ( data?.profilePic === "") ?(
                      <img src={`${profile}`} alt='Metornic' />
                   ):(
                    <img src={`${PF+data?.profilePic}`} alt='Metornic' className='object-fit-cover' />
                   )
                  }
               
                <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle  border-2 border-white h-20px w-20px'></div>
                </div>
            </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <div className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {data?.fullName}
                  </div>
                  <div>
                    <KTIcon iconName='verify' className='fs-1 text-primary' />
                  </div>
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <div
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                    {data?.phone}
                  </div>
                  {/* <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='geolocation' className='fs-4 me-1' />
                    {data?.country}
                  </a> */}
                  <div
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    <KTIcon iconName='sms' className='fs-4 me-1' />
                    {data?.email}
                  </div>
                </div>
              </div>

              <div className='d-flex my-4'>
                <a href={`https://wa.me/${data?.phone}`} className='btn btn-sm btn-light me-2' id='kt_user_follow_button' target='_blank' rel='noreferrer'>
                  <KTIcon iconName='check' className='fs-3 d-none' />

                  <span className='indicator-label'>Whatsapp</span>
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </a>
                <div
                  className='btn btn-sm btn-primary me-3'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_offer_a_deal'
                >
                  Call
                </div>
                <div className='me-0'>
                  <button
                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <i className='bi bi-three-dots fs-3'></i>
                  </button>
                  {/* <Dropdown1 /> */}
                </div>
              </div>
            </div>

            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
              
              </div>

              {/* <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                  <span className='fw-bolder fs-6'>50%</span>
                </div>
                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                  <div
                    className='bg-success rounded h-5px'
                    role='progressbar'
                    style={{width: '50%'}}
                  ></div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className='d-flex overflow-hidden h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap cursor-pointer'>
          
            <li className='nav-item'>
              <div
                className={
                  `nav-link text-active-primary me-6  
                  ${property === "overview" ? " active" :""}`} onClick={()=>setProperty("overview")
                }>
                
              
                Overview
                </div>
            </li>
            <li className='nav-item'>
              <div
                className={
                  `nav-link text-active-primary me-6  
                  ${property === "properties" ? " active" :""}`} onClick={()=>setProperty("properties")
                }>
                
              
                Properties
                </div>
            </li>
            <li className='nav-item'>
              <div
                className={
                  `nav-link text-active-primary me-6  
                  ${property === "documents" ? " active" :""}`} onClick={()=>setProperty("documents")
                }>
                
              
                Documents
                </div>
            </li>
            
            <li className='nav-item'>
              <div
                className={
                  `nav-link text-active-primary me-6  
                  ${property === "client" ? " active" :""}`} onClick={()=>setProperty("client")
                }>
                Clients
                </div>
            </li>
            <li className='nav-item'>
              <div
                className={
                  `nav-link text-active-primary me-6  
                  ${property === "deals" ? " active" :""}`} onClick={()=>setProperty("deals")
                }>
                Deals
                </div>
            </li>
            <li className='nav-item'>
              <div
                className={
                  `nav-link text-active-primary me-6  
                  ${property === "meetings" ? " active" :""}`} onClick={()=>setProperty("meetings")
                }>
                Meetings
                </div>
            </li>
           
           
           
          </ul>
        </div>
      </div>
    </div>
    {
        property==="overview" && (
          <div className=' '>
          <div className='card mb-5  '>
            <div className='card-body pt-9 pb-0 mb-xl-10'>
              <div>
                  <h1 className=' text-2xl font-semibolds p-7 '>Contact Info</h1>
              </div>
              
              <div className=' d-flex flex-wrap flex-stack  '>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
            <div className='d-flex flex-wrap justify-content-between ps-10 pe-10'>
                    <div className='mb-5 w-150px'>
                      <h2 className=' text-slate-500 text-base '>Full Name</h2>
                      <p className=''>{data?.fullName}</p>
                    </div>
                    <div className='mb-5 w-150px'>
                      <h2 className=' text-slate-500 text-base '>Email Id</h2>
                      <p>{data?.email}</p>
                    </div>
                    <div className='mb-5 w-150px'>
                      <h2 className=' text-slate-500 text-base '>Location</h2>
                      <p>{data?.country}</p>
                    </div>
                    
                    <div className='mb-5 w-150px'>
                      <h2 className=' text-slate-500 text-base '>Phone number</h2>
                      <p>{data?.phone}</p>
                    </div>
                
                </div>
                </div>
                </div>
              </div>
              </div>
              <div className='row g-5 g-xl-8 mt-5'>
                <div className='col-xl-8'>
                  <Notes className='card-xl-stretch mb-xl-8' noteslist = {data?.notes} />
              
                </div>
                <div className='col-xl-4 h-550px'>
                <TodoListprofile/>
                </div>
               
      </div>

      <div>
        <AgentCalender />
      </div>
           
      
         </div>
        )}
    {
        property==="properties" && (
         <PropertyListbyId/>
        )}
    {
        property==="documents" && (
           <Documents/>
        )}
    {
        property==="client" && (
            <div className=' m-4'>
                
                <div>
                  <ClientlistbyId/>
                </div>
             </div>
        )}

{
        property==="deals" && (
            <div className=' m-4'>
                
                <div>
                  <Deallist/>
                </div>
             </div>
        )}
{
        property==="meetings" && (
            <div className=' m-4'>
                
                <div>
                  <MeetList/>
                </div>
             </div>
        )}




<div className="modal fade" tabIndex={-1} id="kt_modal_5">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Client List</h5>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon svg-icon-2x"
          />
        </div>
      </div>

      <div className="modal-body">

    Comingsoon
    </div>
</div>
</div>
  </div>
  

</div>
  )
}

export {AgentDetail}
