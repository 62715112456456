import axios, {AxiosResponse} from 'axios'
import {AddDeveloper, AddProperty, Developer, Property} from './_models'
import { Client } from '../../client/core/_models'
import { Agent } from '../../agent/core/_models';

const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_DEVELOPER_URL = `${API_URL}/company/`
const GET_PROPERTY_URL = `${API_URL}/inventory/primary`
const GET_PROPERTYSEC_URL = `${API_URL}/inventory/secondary`
const GET_PROPERTYDETAILS_URL = `${API_URL}/inventory/get/`
const GET_PROPERTYBYCOMPANYID_URL = `${API_URL}/inventory/getByCompanyId/`
const DETAILS_DEVELOPER_URL = `${API_URL}/company/get/`
const ADD_DEVELOPER_URL = `${API_URL}/company/`
const EDIT_DEVELOPER_URL = `${API_URL}/company/`
const ADD_PROPERTY_URL = `${API_URL}/inventory/`
const ADD_IMAGES_URL = `${API_URL}/images/`
const ADD_MULTIPLE_IMAGES_URL = `${API_URL}/multi-images/`
const LINK_USERS_URL = `${API_URL}/inventory/clientadd`
const LINK_AGENT_URL = `${API_URL}/inventory/agentadd`
const SEARCH_USERS_URL = `${API_URL}/client/search`
const GET_CLIENT_URL = `${API_URL}/client/inventory/`
const GET_AGENT_URL = `${API_URL}/agent/inventory/`
const GET_CLIENTINFO_URL = `${API_URL}/agent/getclientinfo`
const SEARCH_AGENT_URL = `${API_URL}/agent/search`
const GET_DOCS_URL = `${API_URL}/inventory/documents/`
const POST_DOCS_URL = `${API_URL}/uploaddocsinventory/`
const DEL_DOCS_URL = `${API_URL}/inventory/documents/`
const ADD_NOTES_URL = `${API_URL}/company/notes`

export const deletedocs = async(id:string, docid:string) => {
  try {
  const response  = await axios.delete(`${DEL_DOCS_URL}${id}/${docid}`)
  return response.data
} catch (error) {
  // Handle errors here
  console.error("Add client error:", error);
  throw error;
}
  
}

// export const getClient = (query: string) => {
//     console.log(query)
//      return axios
//       .get(`${GET_USERS_URL}?${query}`)
//       .then((res: AxiosResponse<ClientQueryResponse>) => res.data)
//   }

export async function addnotes(data, developerId) {
  // console.log(past,present, pipelineId, developerId)

    try {
      const response = await axios.put(`${ADD_NOTES_URL}/${developerId}`,data);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add Company error:", error);
      throw error;
    }
  }

export async function uploaddocs(formData: any, id:string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${POST_DOCS_URL}${id}`, formData);
    return response.data;
  } catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
}

export async function getDocs(clientId: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${GET_DOCS_URL}${clientId}`);
    return response.data;
  } catch (error) {
    // Handle errors here
    console.error("Add Client error:", error);
    throw error;
  }
}

export async function searchAgent(searchTerm: string): Promise<any>  {
  try {
    const response: AxiosResponse<any> = await axios.get(`${SEARCH_AGENT_URL}/?search=${searchTerm}`);
    return response.data;
  } catch (error) {
    // Handle errors here
    console.error("search agent error:", error);
    throw error;
  }
}

export const getclientdata = async(agentId :string):Promise<Array<Agent>> => {
  try {
  const response : AxiosResponse<Agent[]> = await axios.get<Array<Agent>>(`${GET_CLIENTINFO_URL}/${agentId}`)
  return response.data
} catch (error) {
  // Handle errors here
  console.error("Add client error:", error);
  throw error;
}
  
}


export async function getCompany(companyid:string): Promise<Array<Developer>>  {
    try {
      const response: AxiosResponse<Developer[]> = await axios.get(`${GET_DEVELOPER_URL}/${companyid}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add Company error:", error);
      throw error;
    }
  }

  export async function getProperty(companyid:string): Promise<Array<Property>>  {
    try {
      const response: AxiosResponse<Property[]> = await axios.get(`${GET_PROPERTY_URL}/${companyid}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }
  export async function getPropertysec(companyid:string): Promise<Array<Property>>  {
    try {
      const response: AxiosResponse<Property[]> = await axios.get(`${GET_PROPERTYSEC_URL}/${companyid}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }

  export async function getPropertyByCompanyId(id:string): Promise<Array<Property>>  {
    try {
      const response: AxiosResponse<Property[]> = await axios.get(`${GET_PROPERTYBYCOMPANYID_URL}${id}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }

  export async function getCompanyById(companyId: string): Promise<Developer> {
    try {
      const response: AxiosResponse<Developer> = await axios.get(`${DETAILS_DEVELOPER_URL}${companyId}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add Company error:", error);
      throw error;
    }
  }


  export async function getPropertyDetails(id: string): Promise<Property> {
    try {
      const response: AxiosResponse<Property> = await axios.get(`${GET_PROPERTYDETAILS_URL}${id}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add Company error:", error);
      throw error;
    }
  }

  export const getClientpropertyId = async(id:string):Promise<Array<Client>> => {
    try {
    const response : AxiosResponse<Client[]> = await axios.get<Array<Client>>(`${GET_CLIENT_URL}${id}`)
    return response.data
} catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  }
  export const getAgentbyPropertyid = async(id:string):Promise<Array<Agent>> => {
    try {
    const response : AxiosResponse<Agent[]> = await axios.get<Array<Agent>>(`${GET_AGENT_URL}${id}`)
    return response.data
} catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  }

 
  export async function addDeveloper(formData: AddDeveloper, companyid):Promise<Developer> {
    try {
      const response: AxiosResponse<Developer> = await axios.post(`${ADD_DEVELOPER_URL}${companyid}`, formData);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add Company error:", error);
      throw error;
    }
  }


  export async function postImages(imageData:any) {
    try {
        const response = await axios.post(ADD_IMAGES_URL, imageData);
        console.log('image uploaded to server');
        return response.data;
        
      } catch (error) {
        // Handle errors here
        console.error("Add client error:", error);
        throw error;
      }
    }

  export async function postMultipleImages(imageData:FormData) {
    try {
        const response = await axios.post(ADD_MULTIPLE_IMAGES_URL, imageData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log('image uploaded to server');
        return response.data;
        
      } catch (error) {
        // Handle errors here
        console.error("Add client error:", error);
        throw error;
      }
    }


    export async function addProperty(formDataProp: any,companyid:string) {
      try {
        const response: AxiosResponse<any> = await axios.post(`${ADD_PROPERTY_URL}${companyid}`, formDataProp);
        return response.data;
      } catch (error) {
        // Handle errors here
        console.error("Add property error:", error);
        throw error;
      }
    }

    export async function editProperty(formData: AddProperty, id:string) {
      try {
        const response: AxiosResponse<Property> = await axios.put(`${ADD_PROPERTY_URL}/get/${id}`, formData);
        return response.data;
      } catch (error) {
        // Handle errors here
        console.error("Add property error:", error);
        throw error;
      }
    }

    export async function deleteProperty( id:string) {
      try {
        const response: AxiosResponse<any> = await axios.delete(`${ADD_PROPERTY_URL}${id}`);
        return response.data;
      } catch (error) {
        // Handle errors here
        console.error("Add property error:", error);
        throw error;
      }
    }
    
    export async function deleteDeveloper( id:string) {
      try {
        const response: AxiosResponse<any> = await axios.delete(`${ADD_DEVELOPER_URL}${id}`);
        return response.data;
      } catch (error) {
        // Handle errors here
        console.error("Add property error:", error);
        throw error;
      }
    }

    export async function editDeveloper(formDataEdit: AddDeveloper, id:string) {
      try {
        const response: AxiosResponse<Developer> = await axios.put(`${EDIT_DEVELOPER_URL}/${id}`, formDataEdit);
        return response.data;
      } catch (error) {
        // Handle errors here
        console.error("Add property error:", error);
        throw error;
      }
    }


    export async function linkPropertyToClient(ids: any)  : Promise<any> {
      try {
        const response: AxiosResponse<any> = await axios.put(`${LINK_USERS_URL}`,ids);
        return response.data;
      } catch (error) {
        // Handle errors here
        console.error("Add property error:", error);
        throw error;
      }
    }

    export async function linkPropertyToAgent(ids: any)  : Promise<any> {
      try {
        const response: AxiosResponse<any> = await axios.put(`${LINK_AGENT_URL}`,ids);
        return response.data;
      } catch (error) {
        // Handle errors here
        console.error("Add property error:", error);
        throw error;
      }
    }


    export async function searchClient(searchTerm: string): Promise<any>  {
      try {
        const response: AxiosResponse<any> = await axios.get(`${SEARCH_USERS_URL}/?search=${searchTerm}`);
        return response.data;
      } catch (error) {
        // Handle errors here
        console.error("search agent error:", error);
        throw error;
      }
    }
  