import React, {FC, useEffect, useState} from 'react'
import { getPropertyByCompanyId } from '../core/_requests'
import { PropertyWidget } from './PropertyWidget'
import { Property } from '../core/_models'
import { useParams } from 'react-router-dom';


const PropertyListOfDeveloper: FC = () => {
  const [propertyList, setpropertyList] = useState<Property[]>([])
  const id = useParams().id
  // const [propertyId, setpropertyId] = useState<string>('')

  const handleid = (id:string) => {
    // setpropertyId(id)
  }

  useEffect(() => {
		
    const getpropertylist = async() => {
      const res = await getPropertyByCompanyId(id)
      console.log(res)
      setpropertyList(res)
      }
      
getpropertylist()
}, [])
  
  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        {
          propertyList.map((item,index) => (
            <div className='col-xl-4'>
            <PropertyWidget className='card-xl-stretch mb-xl-8' item={item}  handleid={handleid}/>
          </div>
          ))
        }
       
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      
    </>
  )
}

export {PropertyListOfDeveloper}
