import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {deleteProject, getProjects, editProjectById} from '../core/_requests'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {useAuth} from '../../../modules/auth'
import AgentList from './AgentList'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setSprints} from '../../../redux/calendar/sprintSlice'

const ProjectList: React.FC = () => {
  const [projectlist, setProjectlist] = useState<any>([])
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const sprintData = useSelector((state: RootState) => state.calendar.sprint.sprints)
  const loading = useSelector((state: RootState) => state.calendar.sprint.loading)
  const [editProject, setEditProject] = useState<any>('')
  const [assiopen, setassiopen] = useState(false)
  const [projectName, setProjectName] = useState('')
  const [agentids, setagentids] = useState<any>([])
  const [deleteid, setdeleteid] = useState<any>('')
  const PF: any = 'https://unifeed.s3.ap-south-1.amazonaws.com/'

  const fetchProjects = async () => {
    try {
      const response = await getProjects(currentUser?.company)
      console.log(response)
      setProjectlist(response)
      dispatch(setSprints(response))
    } catch (err) {
      console.error(err.message)
    }
  }

  useEffect(() => {
    if (loading) fetchProjects()
    else setProjectlist(sprintData)
  }, [currentUser])

  const handledelete = async () => {
    const res = await deleteProject(deleteid)
    if (res === 'deleted') {
      const updatedProjects = projectlist.filter((project: any) => project._id !== deleteid)
      setdeleteid('')
      setProjectlist(updatedProjects)
      const closeModalButton = document.getElementById('closeModalButtondelete1')
      if (closeModalButton) {
        closeModalButton.click()
      }
    }
  }

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'

    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })

    return formattedDate
  }

  const handleEdit = async () => {
    const data = {
      name: projectName,
      agentids: agentids,
    }
    console.log(data)
    const res = await editProjectById(data, editProject)
    if (res) {
      setProjectName('')
      setagentids([])
      setEditProject('')
      setassiopen(false)
      fetchProjects()
      const closeModalButton1 = document.getElementById('closeModalButton1')
      closeModalButton1?.click()
    }
  }

  if (loading) {
    return (
      <div className='d-flex justify-content-center'>
        <div className='loader'></div>
      </div>
    )
  }

  return (
    <div className='card-body py-3'>
      {/* begin::Table container */}
      <div className='table-responsive'>
        {/* begin::Table */}
        <table className='table align-middle gs-0 gy-4 table-row-bordered'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold text-muted bg-light'>
              <th className='ps-4 min-w-25px rounded-start'>No.</th>
              <th className='min-w-200px'>Name</th>
              <th className='min-w-200px'>Agents</th>
              <th className='min-w-100px'>Created On</th>
              <th className='min-w-100px'>Actions</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
            {projectlist?.map((project: any, index) => (
              <tr key={project?._id?.toString()}>
                <td>
                  <Link to={`/sprint/${project._id}`}>
                    <a
                      href='#'
                      className='ps-4 text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                    >
                      {index + 1}
                    </a>
                  </Link>
                </td>
                <td>
                  <Link to={`/sprint/${project._id}`}>
                    <a
                      href='#'
                      className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 capitalize'
                    >
                      {project?.name}
                    </a>
                  </Link>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      {project?.agents && project.agents[0]?.fullName ? (
                        project?.agents.length > 1 ? (
                          <div
                            className='symbol-group symbol-hover'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                          >
                            {project.agents.map((item, index) => (
                              <div className='symbol symbol-circle symbol-30px ' key={index}>
                                {item.profilePic !== '' ? (
                                  <img
                                    src={PF + item.profilePic}
                                    alt=''
                                    className='object-fit-cover'
                                  />
                                ) : (
                                  <div className='symbol-label fs-6 fw-semibold bg-danger text-inverse-danger'>
                                    {item.fullName.charAt(0).toUpperCase()}
                                  </div>
                                )}
                              </div>
                            ))}

                            <div
                              className='menu menu-sub menu-sub-dropdown w-200px w-md-200px p-5'
                              data-kt-menu='true'
                              style={{zIndex: 1000000}}
                            >
                              {project.agents.map((item, index) => (
                                <div className='p-3' key={index}>
                                  <div className='symbol symbol-30px me-2 symbol-square'>
                                    {item?.profilePic ? (
                                      <img
                                        src={PF + item.profilePic}
                                        className='object-fit-cover'
                                        alt=''
                                      />
                                    ) : (
                                      <div className='symbol-label fs-6 fw-semibold bg-danger text-inverse-danger'>
                                        {item.fullName.charAt(0).toUpperCase()}
                                      </div>
                                    )}
                                  </div>
                                  <a
                                    href='#'
                                    className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                                  >
                                    {item.fullName}
                                  </a>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className='symbol symbol-30px me-2 symbol-square'>
                              {project?.agents && project.agents[0]?.profilePic ? (
                                <img
                                  src={PF + project.agents[0].profilePic}
                                  className='object-fit-cover'
                                  alt=''
                                />
                              ) : (
                                <div className='symbol-label fs-6 fw-semibold bg-danger text-inverse-danger'>
                                  {project.agents[0].fullName.charAt(0).toUpperCase()}
                                </div>
                              )}
                            </div>
                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              {project?.agents[0]?.fullName}
                            </a>
                          </div>
                        )
                      ) : (
                        <a href='#' className='text-danger fw-bold text-hover-primary mb-1 fs-6'>
                          Not Assigned
                        </a>
                      )}
                    </div>
                  </div>
                </td>
                <td>
                  <Link to={`/sprint/${project._id}`}>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {formatCreatedAt(project.createdAt)}
                    </a>
                  </Link>
                </td>
                <td className=''>
                  <a
                    href='#'
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_3'
                    onClick={() => {
                      setEditProject(project?._id)
                      setProjectName(project?.name)
                      setagentids(project?.agents.map((item: any) => item._id))
                    }}
                  >
                    <KTIcon iconName='pencil' className='fs-3' />
                  </a>
                  <a
                    href='#'
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_2'
                    onClick={() => setdeleteid(project._id)}
                  >
                    <KTIcon iconName='trash' className='fs-3' />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
      {/* end::Table container */}
      <div className='modal fade' tabIndex={-1} id='kt_modal_3'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Edit Project</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <form>
                {!assiopen ? (
                  <div>
                    <div className='mb-10'>
                      <label className='form-label'>Name</label>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Enter title'
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                      />
                    </div>
                    <button
                      type='button'
                      className='btn btn-sm btn-light-primary mt-5'
                      onClick={() => setassiopen(true)}
                    >
                      <KTIcon iconName='plus' className='fs-2' />
                      Assign Agent
                    </button>
                  </div>
                ) : (
                  <div className='modal-body'>
                    <button
                      type='button'
                      className='btn btn-sm btn-light-primary mb-5'
                      onClick={() => setassiopen(false)}
                    >
                      <KTIcon iconName='arrow-left' className='fs-2' />
                    </button>
                    <AgentList agentids={agentids} setagentids={setagentids} />
                  </div>
                )}
              </form>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton1'
              >
                Close
              </button>
              <button type='button' className='btn btn-primary' onClick={handleEdit}>
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Delete</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <h3> Are you sure you want to delete</h3>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButtondelete1'
              >
                No
              </button>
              <button type='button' className='btn btn-primary' onClick={handledelete}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectList
