import axios, {AxiosResponse} from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const API_URL_s = process.env.REACT_APP_THEME_API_URL
const ROLES_URL = `${API_URL_s}/roles`

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL_s}/auth/verify_token`
export const LOGIN_URL = `${API_URL_s}/auth/login`
export const FLUSH_TOKEN_URL = `${API_URL_s}/auth/logout`

export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string, fcmtoken: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
    fcmtoken,
    id: 'evotech',
  })
}

export async function getRoles(departmentId: string, roleName: string) {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ROLES_URL}/${departmentId}/${roleName}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function getPermissionsForHierarchy1(companyid: string, roleName: string) {
  try {
    const response: AxiosResponse<any> = await axios.get(
      `${ROLES_URL}/getpermissionsforhierarchy1/${companyid}/${roleName}`
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export function flushToken(agentId: string, fcmtoken: string) {
  return axios.post(FLUSH_TOKEN_URL, {
    agentId,
    fcmtoken,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
