import React, { useEffect } from 'react'
import { useAuth } from '../../../modules/auth'
import { changePipeline } from '../../pipeline/core/_requests'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'


function Pipelinestatus({pipelinestatus,clientId,handleTrigger}) {
    const {currentUser} = useAuth()

    const handlepipelinestatus = (status) => {
        if(status === pipelinestatus) return
      changePipeline(pipelinestatus, status ,currentUser?.pipeline,clientId).then(
        res => handleTrigger()
      )
    }

    useEffect(() => {
        MenuComponent.reinitialization()
      }, [])

  return (
    <>
         <div
        className={`cursor-pointer badge fs-6 capitalize fw-semibold  ${
       pipelinestatus === 'new'
            ? 'badge-light-primary'
            :pipelinestatus === 'prospect'
            ? 'badge-light-warning'
            :pipelinestatus === 'viewing'
            ? 'badge-light-info'
            :pipelinestatus === 'close'
            ? 'badge-light-success'
            :pipelinestatus === 'reject'
            ? 'badge-light-danger'
            : 'badge-light-dark'
        }`}
   
      data-kt-menu-trigger='click'
      data-kt-menu-placement='bottom-end'
      data-kt-menu-flip='top-end'
    >
    {pipelinestatus}                
    </div>
    <div
        className='menu menu-sub menu-sub-dropdown w-150px w-md-150px p-5'
        data-kt-menu='true'
        style={{zIndex: 1000000}}
      >
        <div className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${pipelinestatus ==="new" ? "badge-primary": "badge-light-dark"} `} onClick={() => handlepipelinestatus("new")}>new</div>
        <div  className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${pipelinestatus ==="prospect" ? "badge-warning": "badge-light-dark"} `} onClick={() => handlepipelinestatus("prospect")}>prospect</div>
        <div  className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${pipelinestatus ==="viewing" ? "badge-info": "badge-light-dark"} `} onClick={() => handlepipelinestatus("viewing")}>viewing</div>
        <div  className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${pipelinestatus ==="close" ? "badge-success": "badge-light-dark"} `} onClick={() => handlepipelinestatus("close")}>close</div>
        <div  className={`badge fs-7 p-3 mb-3 fw-semibold cursor-pointer ${pipelinestatus ==="reject" ? " badge-danger": " badge-light-dark"} `} onClick={() => handlepipelinestatus("reject")}>reject</div>
        <div  className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${pipelinestatus ==="noanswer" ? "badge-dark": "badge-light-dark"} `} onClick={() => handlepipelinestatus("noanswer")}>noanswer</div>
        <div  className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${pipelinestatus ==="callback" ? "badge-dark": "badge-light-dark"} `} onClick={() => handlepipelinestatus("callback")}>callback</div>
        <div  className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${pipelinestatus ==="pending" ? "badge-dark": "badge-light-dark"} `} onClick={() => handlepipelinestatus("pending")}>pending</div>
        <div  className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${pipelinestatus ==="junk" ? "badge-dark": "badge-light-dark"} `} onClick={() => handlepipelinestatus("junk")}>junk</div>
      </div>
    </>
  )
}

export default Pipelinestatus