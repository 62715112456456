import React, {FC, useEffect, useState} from 'react'
import {StatisticsWidget5} from '../../../../_metronic/partials/widgets'
import { getclientpipecount, getclientpipecountagent } from '../core/_requests';
import { useAuth } from '../../../modules/auth';
import {useSelector, useDispatch} from 'react-redux'
import { RootState } from '../../../redux/store';
import { setClose, setNew, setProspect, setViewing } from '../../../redux/pipeline/pipelineStatsSlice';

const Clientstat: FC = () => {
  // const [count, setcount] = useState<any>({
  //   new: "Loading",
  //   prospect: "Loading",
  //   viewing: "Loading",
  //   close: "Loading",
  // })
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const newCount = useSelector((state: RootState) => state.pipeline.pipelineStats.new)
  const prospect = useSelector((state: RootState) => state.pipeline.pipelineStats.prospect)
  const viewing = useSelector((state: RootState) => state.pipeline.pipelineStats.viewing)
  const close = useSelector((state: RootState) => state.pipeline.pipelineStats.close)
  const [permissions, setPermissions] = useState<any>({})

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])
  
  const getclientpipecounts = async () => {
    try {
      if(currentUser?.profileType === "superadmin" || permissions?.pipeline?.admin){
        const rescount = await getclientpipecount(currentUser?.company)
        // setcount(rescount);
        dispatch(setNew(rescount.new))
        dispatch(setProspect(rescount.prospect))
        dispatch(setViewing(rescount.viewing))
        dispatch(setClose(rescount.close))
      }else  if(permissions?.pipeline?.admin === false){
        const rescount = await getclientpipecountagent(currentUser?.company,currentUser?._id)
        // setcount(rescount);
        // console.log(rescount)
        dispatch(setNew(rescount.new))
        dispatch(setProspect(rescount.prospect))
        dispatch(setViewing(rescount.viewing))
        dispatch(setClose(rescount.close))
      }
    } catch (error) {
      console.error('Error fetching client list:', error);
    }
  };

  useEffect(() => {
    if (newCount === 'Loading' && prospect === 'Loading' && viewing === 'Loading' && close === 'Loading')
      getclientpipecounts()
  }, [permissions?.pipeline])
  
  return (
    <>
    

      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='filter-tablet'
            color='primary'
            iconColor='light'
            title={`${newCount} New`}
            titleColor='light'
            description='Clients'
            descriptionColor='light'
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='design-2'
            color='warning'
            iconColor='light'
            title={`${prospect} Prospect`}
            titleColor='light'
            description='Clients'
            descriptionColor='light'
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='rocket'
            color='info'
            iconColor='light'
            title={`${viewing} Viewing`}
            titleColor='light'
            description='Clients'
            descriptionColor='light'
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='dollar'
            color='success'
            iconColor='light'
            title={`${close} Close`}
            titleColor='light'
            description='Clients'
            descriptionColor='light'
          />
        </div>
      </div>
      {/* end::Row */}

     
    </>
  )
}

export {Clientstat}
