import axios, {AxiosResponse} from 'axios'
import {AddDeveloper, AddEvent, Developer, Event} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_DEVELOPER_URL = `${API_URL}/company/`
const GET_EVENT_URL = `${API_URL}/event/`
const GET_EVENTBYCOMPANYID_URL = `${API_URL}/event/getByCompanyId/`
const DETAILS_DEVELOPER_URL = `${API_URL}/company/get/`
const ADD_DEVELOPER_URL = `${API_URL}/company/`
const EDIT_DEVELOPER_URL = `${API_URL}/company/`
const ADD_EVENT_URL = `${API_URL}/event/`
const ADD_IMAGES_URL = `${API_URL}/images/`

// export const getClient = (query: string) => {
//     console.log(query)
//      return axios
//       .get(`${GET_USERS_URL}?${query}`)
//       .then((res: AxiosResponse<ClientQueryResponse>) => res.data)
//   }

export async function getCompany(): Promise<Array<Developer>>  {
    try {
      const response: AxiosResponse<Developer[]> = await axios.get(`${GET_DEVELOPER_URL}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add Company error:", error);
      throw error;
    }
  }

  export async function getEvent(): Promise<Array<Event>>  {
    try {
      const response: AxiosResponse<Event[]> = await axios.get(`${GET_EVENT_URL}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add Event error:", error);
      throw error;
    }
  }

  export async function getEventByCompanyId(id:string): Promise<Array<Event>>  {
    try {
      const response: AxiosResponse<Event[]> = await axios.get(`${GET_EVENTBYCOMPANYID_URL}${id}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add Event error:", error);
      throw error;
    }
  }

  export async function getCompanyById(companyId: string): Promise<Developer> {
    try {
      const response: AxiosResponse<Developer> = await axios.get(`${DETAILS_DEVELOPER_URL}${companyId}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add Company error:", error);
      throw error;
    }
  }

 
  export async function addDeveloper(formData: AddDeveloper):Promise<Developer> {
    try {
      const response: AxiosResponse<Developer> = await axios.post(ADD_DEVELOPER_URL, formData);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add Company error:", error);
      throw error;
    }
  }


  export async function postImages(imageData:any) {
    try {
        const response = await axios.post(ADD_IMAGES_URL, imageData);
        console.log('image uploaded to server');
        return response.data;
        
      } catch (error) {
        // Handle errors here
        console.error("Add client error:", error);
        throw error;
      }
    }


    export async function addEvent(formData: AddEvent) {
      try {
        const response: AxiosResponse<Event> = await axios.post(ADD_EVENT_URL, formData);
        return response.data;
      } catch (error) {
        // Handle errors here
        console.error("Add Event error:", error);
        throw error;
      }
    }

    export async function editEvent(formData: AddEvent, id:string) {
      try {
        const response: AxiosResponse<Event> = await axios.put(`${ADD_EVENT_URL}/${id}`, formData);
        return response.data;
      } catch (error) {
        // Handle errors here
        console.error("Add Event error:", error);
        throw error;
      }
    }

    export async function deleteEvent( id:string) {
      try {
        const response: AxiosResponse<any> = await axios.delete(`${ADD_EVENT_URL}${id}`);
        return response.data;
      } catch (error) {
        // Handle errors here
        console.error("Add Event error:", error);
        throw error;
      }
    }

    export async function editDeveloper(formDataEdit: AddDeveloper, id:string) {
      try {
        const response: AxiosResponse<Developer> = await axios.put(`${EDIT_DEVELOPER_URL}/${id}`, formDataEdit);
        return response.data;
      } catch (error) {
        // Handle errors here
        console.error("Add Event error:", error);
        throw error;
      }
    }
