import React from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import MeetingsList from './components/MeetingsList'

const MeetingsPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/'
          element={
            <>
              <MeetingsList />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default MeetingsPage
