import React, { ChangeEvent, useEffect, useState } from 'react';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import './print.css'; 
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css'; // Choose a theme from the flatpickr themes folder
import 'flatpickr/dist/flatpickr.min.css'; // Import the Flatpickr CSS
import { getAccountsNameForPaymentReceivedInvoice, getAgent, getClientbyagent, getClientbycompany, getInvoiceid, getProperty, postinvoicetodeal } from '../core/_requests';
import { useNavigate, useParams } from 'react-router-dom';
import {useAuth} from '../../../modules/auth/core/Auth'
import { KTIcon } from '../../../../_metronic/helpers';
function Createinvoicetodeal() {
  const navigate = useNavigate();
  const {currentUser} = useAuth()
  const dealid = useParams().id
  const [loading, setLoading] = useState(false)
  const [clientsList, setClientsList] = useState<any>([]); 
  const [agentsList, setAgentsList] = useState<any>([]); 
  const [propertyList, setPropertyList] = useState<any>([]); 
  const [selectedBillFrom, setselectedBillFrom] = useState(null);
  const [selectedBillTo, setselectedBillTo] = useState(null);
  const [selectedItem, setselectedItem] = useState(null);
  const [permissions, setPermissions] = useState<any>({})
  const [isDiscount, setIsDiscount] = useState(false);
  const [accountsNameList, setAccountsNameList] = useState([])


  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])


  const [invoice, setInvoice] = useState({
    date: new Date(),
    agent: '',
    client: '',
    id: '',
    inventory: '',
    depositTo: '',
    subtotal: 0,
    tax: 0,
    total: 0,
    notes: '',
    discount: 0,
    lpo:'',
    trn:'',
    paymentMode: 'cash',
    paymentReceived: false
  });


  const [items, setItems] = useState([
    {
   name:"",
    description: '',
    amount:''
  }
]);

const fetchAccountsName = async () => {
  const res = await getAccountsNameForPaymentReceivedInvoice(currentUser?.company)
  const data = res?.map((items) => {
    return {
      label: items?.accountName,
      value: items?._id,
    }
  })
  setAccountsNameList(data)
  setInvoice((prevInvoice) => ({
    ...prevInvoice,
    depositTo: data[0]?.value,
  }))
}
  
  
    const currencyOptions = [
  { value: 'AED', label: 'AED - United Arab Emirates dirham', flag: 'flags/united-arab-emirates.svg' },
  { value: 'USD', label: 'USD - USA dollar', flag: 'flags/united-states.svg' },
  { value: 'GBP', label: 'GBP - British pound', flag: 'flags/united-kingdom.svg' },
  { value: 'AUD', label: 'AUD - Australian dollar', flag: 'flags/australia.svg' },
  { value: 'JPY', label: 'JPY - Japanese yen', flag: 'flags/japan.svg' },
  { value: 'SEK', label: 'SEK - Swedish krona', flag: 'flags/sweden.svg' },
  { value: 'CAD', label: 'CAD - Canadian dollar', flag: 'flags/canada.svg' },
  { value: 'CHF', label: 'CHF - Swiss franc', flag: 'flags/switzerland.svg' },
  // Add other options similarly
];
const defaultCurrency = currencyOptions.find((option) => option.value === 'AED');



const getClientList = async () => {
  try {
    const res = await getClientbycompany(currentUser?.company);
    setClientsList(
      res.map((r) => ({
        value: r._id,
        label: r.name, // Corrected the typo here from "labe" to "label"
      }))
    );

    console.log(res);
  } catch (error) {
    console.error('Error fetching client list:', error);
  }
};

const getClientListbyagent = async () => {
  try {
    const res = await getClientbyagent(currentUser?._id);
    setClientsList(
      res.map((r) => ({
        value: r._id,
        label: r.name, // Corrected the typo here from "labe" to "label"
      }))
    );

    console.log(res);
  } catch (error) {
    console.error('Error fetching client list:', error);
  }
};

const getAgenttList = async () => {
  try {
    const res = await getAgent(currentUser?.company);
    setAgentsList(
      res.map((r) => ({
        value: r._id,
        label: r.fullName, // Corrected the typo here from "labe" to "label"
      }))
    );

    console.log(res)
 
  } catch (error) {
    console.error('Error fetching client list:', error);
  }
};

const getPropertyList = async () => {
  try {
    const res = await getProperty(currentUser?.company);
    setPropertyList(
      res.map((r) => ({
        value: r._id,
        label: r.name, // Corrected the typo here from "labe" to "label"
      }))
    );

    console.log(res)
 
  } catch (error) {
    console.error('Error fetching client list:', error);
  }
};

const getInvoiceLength = async () => {
  try {
    const res = await getInvoiceid();
    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      id: res,
      // You can add other properties if needed
    }));

    console.log(res)
 
  } catch (error) {
    console.error('Error fetching client list:', error);
  }
};




useEffect(() => {
  if(permissions?.sales?.admin === false){
    getClientListbyagent()
  }else if(currentUser?.profileType === "superadmin" || permissions?.sales?.admin){
    getClientList()
  }
  if(permissions?.accountant?.read || currentUser?.profileType === "superadmin"){
    fetchAccountsName()
  }
}, [permissions])

useEffect(()=>{
  getAgenttList()
  getPropertyList()
  getInvoiceLength()
},[])


const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
  e.preventDefault();
  const { name, value } = e.target;
  setInvoice((prevInvoice) => ({
    ...prevInvoice,
    [name]: value,
    // You can add other properties if needed
  }));
};

useEffect(() => {
  setInvoice((prevInvoice) => ({
    ...prevInvoice,
    subtotal: items.reduce((accumulator, currentItem) => {
      // Assuming that the 'amount' property is a number
      return accumulator + parseFloat(currentItem.amount );
    }, 0),
    // You can add other properties if needed
  }));
}, [items]);


useEffect(() => {
  setInvoice((prevInvoice) => {
    const subtotal = prevInvoice.subtotal || 0;
    const tax = prevInvoice.tax || 0;

    const total = (subtotal * (1 + tax / 100)).toFixed(2);

    return {
      ...prevInvoice,
      total: parseFloat(total) - Number(prevInvoice.discount),
      // You can add other properties if needed
    };
  });
}, [items, invoice.tax, invoice.discount]);

const handleBillToChange = (selectedOption) => {
  setselectedBillTo(selectedOption);

  setInvoice((prevInvoice) => ({
    ...prevInvoice,
    client: selectedOption.value,

  }));
};

const handleBillFromChange = (selectedOption) => {
  setselectedBillFrom(selectedOption);
  setInvoice((prevInvoice) => ({
    ...prevInvoice,
    agent: selectedOption.value,

  }));
};

const handleItemChange = (selectedOption) => {
  setselectedItem(selectedOption);
  setInvoice((prevInvoice) => ({
    ...prevInvoice,
    inventory: selectedOption.value,

  }));
};

const handleDateChange = (selectedDate) => {
  setInvoice((prevInvoice) => ({
    ...prevInvoice,
    date: selectedDate[0], // Flatpickr returns an array of selected dates
  }));
};


useEffect(() => {
 console.log(invoice)
}, [invoice])


const handleSubmit = async() => {
  const data={
    ...invoice,items
  }
  setLoading(true)
     try{
          await postinvoicetodeal(data,currentUser?.company,dealid).then((res)=> {
            setLoading(false)
            console.log(res)
            navigate(`/sales/invoice/details/${res.id}`);
          })
     }catch(error){

     }
}

const handlePrint = () => {
  window.print(); // This will trigger the browser's print functionality
};


const additem = () =>{
  setItems([...items,{
    name:"",
     description: '',
     amount:''
   }])
  }
  
  
  const removeitem = (index) => {
    const temp = [...items]
    temp.splice(index,1)
    setItems(temp)
  }


    
const handleChangerows = (index, e) => {
  const { name, value } = e.target;
  setItems((prevItems) => {
    const updatedItems = [...prevItems];
    updatedItems[index] = {
      ...updatedItems[index],
      [name]: value,
    };
    return updatedItems;
  });
};

  return (
    <div id="kt_app_content" className="app-content flex-column-fluid print-content">
      {/* begin::Content container */}
      <div id="kt_app_content_container" className="app-container container-xxl">
        {/* begin::Layout */}
        <div className="d-flex flex-column flex-lg-row">
          {/* begin::Content */}
          <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
            {/* begin::Card */}
            <div className="card">
              {/* begin::Card body */}
              <div className="card-body p-12">
                {/* begin::Form */}
                <form action="" id="kt_invoice_form">
                  {/* begin::Wrapper */}
                  <div className="d-flex flex-column align-items-start flex-xxl-row">
                    {/* begin::Input group */}
                    <div className="d-flex align-items-center flex-equal fw-row me-4 order-2" data-bs-toggle="tooltip" data-bs-trigger="hover" title="Specify invoice date">
                      {/* begin::Date */}
                      <div className="fs-6 fw-bold text-gray-700 text-nowrap">Date:</div>
                      {/* Date */}
                      {/* begin::Input */}
                      <div className="position-relative d-flex align-items-center w-150px">
                        {/* begin::Datepicker */}
                        <Flatpickr
                            value={invoice.date}
                            onChange={handleDateChange}
                            className='form-control form-control-solid'
                            placeholder='Pick date'
                          />
                        {/* Datepicker */}
                        {/* begin::Icon */}
                        <i className="ki-duotone ki-down fs-4 position-absolute ms-4 end-0"></i>
                        {/* Icon */}
                      </div>
                      {/* Input */}
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div className="d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4" data-bs-toggle="tooltip" data-bs-trigger="hover" title="Enter invoice number">
                      <span className="fs-2x fw-bold text-gray-800">Invoice #</span>
                      <input type="text" className="form-control form-control-flush fw-bold text-muted fs-3 w-125px" value={invoice.id} placeholder="..." disabled/>
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div className="d-flex align-items-center justify-content-end flex-equal order-3 fw-row" data-bs-toggle="tooltip" data-bs-trigger="hover" title="Specify invoice due date">
                    
                    </div>
                    {/* Input group */}
                  </div>
                  {/* Top */}
                  {/* begin::Separator */}
                  <div className="separator separator-dashed my-10"></div>
                  {/* Separator */}
                  {/* begin::Wrapper */}
                  <div className="mb-0">
                    {/* begin::Row */}
                    <div className="row gx-10 mb-5">
                      {/* begin::Col */}
                      <div className="col-lg-6">
                        <label className="form-label fs-6 fw-bold text-gray-700 mb-3">Bill From</label>
                        {/* begin::Input group */}
                        <Select
                           name="agent"
                           aria-label="Select a Agent"
                           placeholder="Select agent"
                           options={agentsList}
                           defaultValue={{value:currentUser?._id, label:currentUser?.fullName}}
                           isSearchable={true}
                           className="form-control form-control-solid"
                           onChange={handleBillFromChange}
                           value={selectedBillFrom}
                  
                        />
                        
                        {/* Input group */}
                      </div>
                      {/* Col */}
                      {/* begin::Col */}
                      <div className="col-lg-6">
                        <label className="form-label fs-6 fw-bold text-gray-700 mb-3">Bill To</label>
                        {/* begin::Input group */}
                        <Select
                           name="client"
                           aria-label="Select a Client"
                           placeholder="Select a Client"
                           options={clientsList}
                           isSearchable={true}
                           className="form-control form-control-solid"
                           onChange={handleBillToChange}
                           value={selectedBillTo}
                        />
                      </div>
                      {/* Col */}
                      <div className="col-lg-12">
                        <label className="form-label fs-6 fw-bold text-gray-700 mb-3">Property</label>
                        {/* begin::Input group */}
                        <Select
                          name="property"
                          aria-label="Select a Property"
                          placeholder="Select a Property"
                          options={propertyList}
                          isSearchable={true}
                          className="form-control form-control-solid"
                          onChange={handleItemChange}
                          value={selectedItem}
                            />
                      </div>
                      {/* Col */}
                    </div>
                    {/* Row */}
                    <div className="row gx-10 mb-5">
                      {/* begin::Col */}
                      <div className="col-lg-6">
                        <label className="form-label fs-6 fw-bold text-gray-700 mb-3">LPO No.</label>
                        <input type="text" className="form-control form-control-solid " name="lpo" placeholder="LPO" value={invoice.lpo} onChange={handleChange} />
                        
                        {/* Input group */}
                      </div>
                      {/* Col */}
                      {/* begin::Col */}
                      <div className="col-lg-6">
                        <label className="form-label fs-6 fw-bold text-gray-700 mb-3">TRN No</label>
                        {/* begin::Input group */}
                        <input type="text" className="form-control form-control-solid " name="trn" placeholder="TRN" value={invoice.trn} onChange={handleChange} />
                      </div>
                      {/* Col */}
                    </div>
                    {/* begin::Table wrapper */}
                    <div className="table-responsive mb-10">
                      {/* begin::Table */}
                      <table className="table g-5 gs-0 mb-0 fw-bold text-gray-700" data-kt-element="items">
                        {/* begin::Table head */}
                        <thead>
                          <tr className="border-bottom fs-7 fw-bold text-gray-700 text-uppercase">
                          <th className="min-w-100px w-250px">Item</th>
                            <th className="min-w-150px w-250px">Description</th>
                            <th className="min-w-50px w-150px">Amount</th>
                            <th className="min-w-70px w-150px">Action</th>
                
                        
                          </tr>
                        </thead>
                        {/* Table head */}
                        {/* begin::Table body */}
                        <tbody>
                        {
                          items.map((item,index) => (
                            <tr className="border-bottom border-bottom-dashed" data-kt-element="item" key={index}>
                            <td className="pe-7">
                                 {/* begin::Input group */}
                             
                                <input type="text" className="form-control form-control-solid" name="name" placeholder="Description" value={item.name} onChange={(e) => handleChangerows(index,e)} />
                              </td>
                              <td className="pe-7">
                                 {/* begin::Input group */}
                             
                                <input type="text" className="form-control form-control-solid" name="description" placeholder="Description" value={item.description} onChange={(e) => handleChangerows(index,e)} />
                              </td>
                              
                              <td>
                                <input type="number" className="form-control form-control-solid text-end" name="amount" placeholder="0.00"  value={item.amount} onChange={(e) => handleChangerows(index,e)}/>
                              </td>
                              <td>
                              <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3' onClick={additem}>
                              <KTIcon iconName='plus-square' className='fs-3'  />
                            </div>
                                <div className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm ' onClick={() => removeitem(index)}>
                                <KTIcon iconName='trash' className='fs-3'  />
                              </div>
                              
                             
                              </td>
                              
                       
                            </tr>
                          ))
                         }
                           
                        </tbody>
                        {/* Table body */}
                        {/* begin::Table foot */}
                        <tfoot>
                          <tr className="border-top border-top-dashed align-top fs-6 fw-bold text-gray-700">
                            <th className="text-primary">

                            </th>
                            <th colSpan={2} className="border-bottom border-bottom-dashed ps-0">
                              <div className="d-flex flex-column align-items-start">
                                <div className="fs-5">Subtotal</div>
                                <button className="btn btn-link " data-bs-toggle="tooltip" data-bs-trigger="hover" title="Tax in percentage">Add tax %</button>
                                {isDiscount && 
                                  <div className='fs-5'>Discount</div> }                 
                              </div>
                            </th>
                            <th colSpan={2} className="border-bottom border-bottom-dashed text-end">
                              <span data-kt-element="sub-total" className="  text-end">{invoice.subtotal}</span>
                              <input type="number" className="form-control form-control-solid text-end " name="tax" placeholder="0.00"   value={invoice.tax} onChange={handleChange} />
                              {isDiscount && <input type="number" className="form-control form-control-solid text-end " name="discount" placeholder="0.00" min={0}   value={invoice.discount} onChange={handleChange} />}
                              </th>
                          </tr>
                          <tr className="align-top fw-bold text-gray-700">
                            <th></th>
                            <th colSpan={2} className="fs-4 ps-0">Total</th>
                            <th colSpan={2} className="text-end fs-4 text-nowrap">AED 
                              <span data-kt-element="grand-total"> { invoice.total}</span></th>
                          </tr>
                        </tfoot>
                        {/* Table foot */}
                      </table>
                    </div>
                    {/* Table */}
                    {/* begin::Item template */}
                   
                    <table className="table d-none" data-kt-element="empty-template">
                      <tr data-kt-element="empty">
                        <th colSpan={5} className="text-muted text-center py-10">No items</th>
                      </tr>
                    </table>
                    {/* Item template */}
                    {/* begin::Notes */}
                    {invoice.paymentReceived && (
                      <div className=''>
                        <div className='row gx-10 mb-5'>
                          {/* begin::Col */}
                          <div className='col-lg-6'>
                            <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                              Payment Mode
                            </label>
                            {/* begin::Input group */}
                            <select
                              className='form-select form-select-solid'
                              value={invoice.paymentMode}
                              name='paymentMode'
                              onChange={handleChange}
                            >
                              <option value='cash'>Cash</option>
                              <option value='cheque'>Cheque</option>
                            </select>
                            {/* Input group */}
                          </div>
                          {/* Col */}
                          {/* begin::Col */}
                          <div className='col-lg-6'>
                            <label className='form-label required fs-6 fw-bold text-gray-700 mb-3'>
                              Deposit To
                            </label>
                            {/* begin::Input group */}
                            <select
                              className='form-select form-select-solid'
                              value={invoice.depositTo}
                              name='depositTo'
                              onChange={handleChange}
                            >
                              {accountsNameList?.map((item) => {
                                return (
                                  <option value={item?.value} key={item?.value}>
                                    {item?.label}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                          {/* Col */}
                        </div>
                      </div>
                    )}
                    <div className="mb-0">
                      <label className="form-label fs-6 fw-bold text-gray-700">Notes</label>
                      <input type='textarea' name="notes" className="form-control form-control-solid"  placeholder="Thanks for your business" value={invoice.notes} onChange={handleChange}/>
                    </div>
                    {/* Notes */}
                  </div>
                  {/* Wrapper */}
                </form>
                {/* Form */}
              </div>
              {/* Card body */}
            </div>
            {/* Card */}
          </div>
          {/* Content */}
          {/* begin::Sidebar */}
          <div className="flex-lg-auto min-w-lg-300px">
            {/* begin::Card */}
            <div className="card" data-kt-sticky="true" data-kt-sticky-name="invoice" data-kt-sticky-offset="{default: false, lg: '200px'}" data-kt-sticky-width="{lg: '250px', lg: '300px'}" data-kt-sticky-left="auto" data-kt-sticky-top="150px" data-kt-sticky-animation="false" data-kt-sticky-zindex="95">
              {/* begin::Card body */}
              <div className="card-body p-10">
                {/* begin::Input group */}
                <div className="mb-10">
                        <label className="form-label fw-bold fs-6 text-gray-700">Currency</label>
                        <Select
                           name="currency"
                           aria-label="Select a Currency"
                           placeholder="Select currency"
                           options={currencyOptions}
                           defaultValue={defaultCurrency}
                           isSearchable={true}
                           className=""
                        />
                        </div>
                        {/* <!--::Input group-->
                        <!--begin::Separator--> */}
               
                        {/* <!--::Separator-->
                        <!--begin::Input group--> */}
                        <div className="mb-8 print-content">
                            {/* <!--begin::Option--> */}
                            <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-5">
                            <span className="form-check-label ms-0 fw-bold fs-6 text-gray-700">Payment method</span>
                            <input className="form-check-input" type="checkbox" 
                            checked={invoice.paymentReceived} onChange={
                              (e) => {
                                setInvoice((prevInvoice) => ({
                                  ...prevInvoice,
                                  paymentReceived: e.target.checked,
                                }));
                              }
                            } />
                            </label>

                            {/* <!--::Option-->
                            <!--begin::Option--> */}
                            <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-5">
                            <span className="form-check-label ms-0 fw-bold fs-6 text-gray-700">Discount</span>
                                <input className="form-check-input" type="checkbox" 
                                checked={isDiscount} onChange={(e) => {
                                    setIsDiscount(e.target.checked)
                                    setInvoice((prevInvoice) => ({
                                      ...prevInvoice,
                                      discount: 0,
                                    }));
                                  }} />
                            </label>
                            {/* <!--::Option-->
                            <!--begin::Option--> */}
                            <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack">
                                <span className="form-check-label ms-0 fw-bold fs-6 text-gray-700">Notes</span>
                                <input className="form-check-input" type="checkbox" value="" />
                            </label>
                            {/* <!--::Option--> */}
                        </div>
                        {/* <!--::Input group-->
                        <!--begin::Separator--> */}
                        <div className="separator separator-dashed mb-8"></div>
                        {/* <!--::Separator-->
                        <!--begin::Actions--> */}
                        <div className="mb-0">
                            {/* <!--begin::Row--> */}
                       
                            {/* <!--::Row--> */}
                        
                                <button   className="btn btn-primary w-100"  disabled= {loading} id="kt_invoice_submit_button" onClick={handleSubmit}>
                                <i className="ki-duotone ki-triangle fs-3">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                </i>Create Invoice</button>
                             

                           
                            
                        </div>
                        {/* <!--::Actions--> */}
                    </div>
                    {/* <!--::Card body--> */}
                </div>
                {/* <!--::Card--> */}
            </div>
            {/* <!--::Sidebar--> */}
        </div>
        {/* <!--::Layout--> */}
    </div>
    {/* <!--::Content container--> */}

</div>
  )
}

export default Createinvoicetodeal