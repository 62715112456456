/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { useParams } from 'react-router-dom'
import { addnotes } from '../core/_requests'



type Props = {
  className: string
  noteslist:string[]
}

const Notes: React.FC<Props> = ({className,noteslist}) => {
    const id = useParams().id
    const [notes, setnotes] = useState("")
    const [notesarray, setnotesarray] = useState([])

    useEffect(() => {
      setnotesarray(noteslist)
    }, [noteslist])

    const handleSubmit= async() => {
        const data = {
            notes
        }
        const res = await addnotes(data,id)
        console.log(res.notes)
        setnotesarray(res.notes);


    }
    const formatCreatedAt = (createdAt: string | undefined) => {
      if (!createdAt) return 'N/A';
    
      const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    
      return formattedDate;
    };

  return (
    <div className={`card w-100 position-relative ${className}`}>

<div className='card-header align-items-center border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold text-dark'>Notes</span>
        </h3>
        <div className='card-toolbar'>
   
        </div>
      </div>      {/* begin::Body */}
      <div className='card-body pb-0 h-400px overflow-scroll '>
        
        {/* begin::Header */}
     
{
            notesarray?.map((note,index) => (
                <div className='d-flex align-items-center bg-light-dark rounded p-5 mb-7 ' key={index}>
          {/* begin::Icon */}
       
          {/* end::Icon */}
          {/* begin::Title */}
          <div className='flex-grow-1 me-2'>
            <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
              {note?.text}
            </a>
            <span className='text-muted fw-semibold d-block'>{formatCreatedAt(note?.date)}</span>
          </div>
          {/* end::Title */}
          {/* begin::Lable */}
      
          {/* end::Lable */}
        </div>
            ))
        }



        {/* begin::Reply input */}
        
        {/* edit::Reply input */}
      </div>
      <div className='d-flex align-items-center justify-content-center  mb-5 mt-20'>

  
          {/* begin::User */}
          <form className='position-absolute bottom-0 mb-6 card w-75'>
        <div className='separator mb-4'></div>
          <textarea
            className='form-control border-0 p-0 ps-10 pe-10 resize-none min-h-25px'
            rows={1}
            placeholder='Add Notes..'
            value={notes}
            onChange={(e)=>setnotes(e.target.value)}
          ></textarea>
             <div className='separator mt-4'></div>

          <div className='position-absolute top-0 end-0 me-n5'>
        
            <span className='btn btn-icon btn-sm btn-active-color-primary ps-0 pe-15 mt-2' onClick={handleSubmit}>
            <KTSVG path="/media/icons/duotune/general/gen016.svg" className="svg-icon-muted svg-icon-2hx" />
            </span>
          </div>
        </form>

        </div>
      {/* end::Body */}
    </div>
  )
}

export {Notes}
