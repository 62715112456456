/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { Clientlist } from './components/Clientlist'
import { Clientstat } from './components/Clientstat'


const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Client List/',
    path: '/Clients/Dashboard',
    isSeparator: false,
    isActive: false,
  }
]

const Clientmain: React.FC = () => {



 

 return( <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      <Clientstat  />
        <Clientlist  />
    
       </div>
  </>
)}

const ClientWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>Client List</PageTitle>
      <Clientmain />
    </>
  )
}

export {ClientWrapper}
