import axios, {AxiosResponse} from 'axios'
import {Property} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_USERS_URL = `${API_URL}/inventory/`

// export const getClient = (query: string) => {
//     console.log(query)
//      return axios
//       .get(`${GET_USERS_URL}?${query}`)
//       .then((res: AxiosResponse<ClientQueryResponse>) => res.data)
//   }

export const getProperty = (companyid:string) => {

     return axios
      .get(`${GET_USERS_URL}${companyid}`)
      .then((res: AxiosResponse<Property[]>) => res.data)
  }

