/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {getAlerts, getnotification} from '../../../../app/pages/agent/core/_requests'
import {useAuth} from '../../../../app/modules/auth'
import isEqual from 'lodash/isEqual'
import {onMessage} from 'firebase/messaging'
import {messaging} from '../../../../app/firebase'
import toast from 'react-hot-toast'
import {formatDistance} from 'date-fns'

interface Props {
  setShowDot: React.Dispatch<React.SetStateAction<boolean>>
}

const HeaderNotificationsMenu: FC<Props> = ({setShowDot}) => {
  const {currentUser} = useAuth()
  const [notifi, setnotifi] = useState([])
  const [alerts, setalerts] = useState([])
  const [payloadData, setPayLoadData] = useState<any>()

  const PF = "https://unifeed.s3.ap-south-1.amazonaws.com/"

  const getnoti = async () => {
    const res = await getnotification(currentUser?._id)
    setnotifi(res)
  }
  useEffect(() => {
    getnoti()
    setShowDot(true)

    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Received message on focused tab', payload)
      setPayLoadData(payload)
        
      // toast.success(payload.notification.title)
      toast.custom((t) => (
        <div
          className={`${
            t.visible ? 'show' : 'hide'
          } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex border border-secondary`}
          style={{ display: t.visible ? 'flex' : 'none' }}
        >
          <div className="flex-1 p-3 d-flex align-items-start">
            <div className="flex-shrink-0 mr-3">
              <img
                // className="rounded-circle"
                src={PF + '1722438893811notification-icon-alert.png'}
                alt="Notification Icon"
                style={{ height: '40px', width: '40px' }}
              />
            </div>
            <div className="ml-3 flex-1">
              <p className="fw-bold text-muted fs-1 mb-1">
                {payload.notification.title || "Notification"}
              </p>
              {/* <p className="text-muted mb-0">
                {payload.notification.body || "You have a new notification."}
              </p> */}
            </div>
          </div>
          <div className="d-flex border-left pe-3">
            <button
              onClick={() => toast.dismiss(t.id)}
              className="btn btn-link text-primary hover:opacity-80 cursor-pointer"
            >
              Close
            </button>
          </div>
        </div>
      ),
    {
      duration: 5000
    });


      fetchAlerts()
    })
    return () => {
      unsubscribe()
    }
  }, [payloadData])

  const fetchAlerts = async () => {
    const res = await getAlerts(currentUser?._id)
    if (!isEqual(res, alerts)) {
      setalerts(res)
      setShowDot(true)
    }
  }

  useEffect(() => {
    // const refreshNotifications = setInterval(()=>{
    //   getnoti()
    //   fetchAlerts()
    // }, 60000)
    getnoti()
    fetchAlerts()
    setShowDot(true)
    // return () => {
    //   clearInterval(refreshNotifications)
    // }
  }, [])

  const calcDiff = (date: string) => {
    const date1 = new Date(date)
    const date2 = new Date()
    const diffTime = date1.getTime() - date2.getTime()
    // console.log(diffTime/(1000 * 60 * 60))
    const result = diffTime / (1000 * 60 * 60)
    return result < 0 && result > -48
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')`}}
      >
        <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
          Notifications <span className='fs-8 opacity-75 ps-3'></span>
        </h3>

        <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9'>
          <li className='nav-item'>
            <a
              className='nav-link text-white opacity-75 opacity-state-100 pb-4'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_1'
            >
              Alerts
            </a>
          </li>

          <li className='nav-item'>
            <a
              className='nav-link text-white opacity-75 opacity-state-100 pb-4 active'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_2'
            >
              Updates
            </a>
          </li>

          {/* <li className='nav-item'>
          <a
            className='nav-link text-white opacity-75 opacity-state-100 pb-4'
            data-bs-toggle='tab'
            href='#kt_topbar_notifications_3'
          >
            Logs
          </a>
        </li> */}
        </ul>
      </div>

      <div className='tab-content'>
        <div className='tab-pane fade' id='kt_topbar_notifications_1' role='tabpanel'>
          <div className='scroll-y mh-325px my-5 px-8'>
            {/* {defaultAlerts.map((alert, index) => (
            <div key={`alert${index}`} className='d-flex flex-stack py-4'>
              <div className='d-flex align-items-center'>
                <div className='symbol symbol-35px me-4'>
                  <span className={clsx('symbol-label', `bg-light-${alert.state}`)}>
                    {' '}
                    <KTIcon iconName="color-swatch" className={`fs-2 text-${alert.state}`} />
                  </span>
                </div>

                <div className='mb-0 me-2'>
                  <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bolder'>
                    {alert.title}
                  </a>
                  <div className='text-gray-400 fs-7'>{alert.description}</div>
                </div>
              </div>

              <span className='badge badge-light fs-8'>{alert.time}</span>
            </div>
          ))} */}
            {alerts.map(
              (alert, index) =>
                calcDiff(alert?.event?.reminder) && (
                  <div key={`alert${index}`} className='d-flex flex-stack py-4'>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-35px me-4'>
                        <span className={clsx('symbol-label', `bg-light-warning`)}>
                          {' '}
                          <KTIcon iconName='color-swatch' className={`fs-2 text-warning`} />
                        </span>
                      </div>
                      {alert.type === 'eventadd' && (
                        <div className='mb-0 me-2'>
                          <Link
                            to={`/calendar`}
                            className='fs-6 text-gray-800 text-hover-primary fw-bolder capitalize'
                          >
                            {alert?.event?.title}
                          </Link>
                          <div className='text-gray-400 fs-7'>To-Do</div>
                        </div>
                      )}
                    </div>

                    <span className='badge badge-light fs-8'>
                      {formatDistance(new Date(alert?.event?.reminder), new Date(), {
                        addSuffix: true,
                      })}
                    </span>
                  </div>
                )
            )}
          </div>
        </div>

        <div className='tab-pane fade show active' id='kt_topbar_notifications_2' role='tabpanel'>
          <div className='scroll-y mh-325px my-5 px-8'>
            {notifi.map((alert, index) => (
              <div key={`alert${index}`} className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-35px me-4'>
                    <span
                      className={clsx(
                        'symbol-label',
                        `${
                          alert.type === 'clientadd' ||
                          alert.type === 'propertyadd' ||
                          alert.type === 'eventadd' ||
                          alert.type === 'sprintchange'
                            ? 'bg-light-success'
                            : 'bg-light-danger'
                        }`
                      )}
                    >
                      {' '}
                      <KTIcon
                        iconName='message-notif'
                        className={`fs-2 ${
                          alert.type === 'clientadd' ||
                          alert.type === 'propertyadd' ||
                          alert.type === 'eventadd' ||
                          alert.type === 'sprintchange'
                            ? 'text-success'
                            : 'text-danger'
                        }`}
                      />
                    </span>
                  </div>
                  {alert.type === 'clientadd' ? (
                    <div className='mb-0 me-2'>
                      <Link
                        to={`/clients/clientDetails/${alert?.client?._id}`}
                        className='fs-6 text-gray-800 text-hover-primary fw-bolder'
                      >
                        New Client Assigned
                      </Link>
                      <div className='text-gray-400 fs-7'>{alert?.client?.name}</div>
                    </div>
                  ) : alert.type === 'clientremove' ? (
                    <div className='mb-0 me-2'>
                      <Link
                        // href={`/clients/clientDetails/${alert?.client?._id}`}
                        to='#'
                        className='fs-6 text-gray-800 text-hover-primary fw-bolder'
                      >
                        Client Removed
                      </Link>
                      <div className='text-gray-400 fs-7'>{alert?.client?.name}</div>
                    </div>
                  ) : alert.type === 'propertyadd' ? (
                    <div className='mb-0 me-2'>
                      <Link
                        to={`/property/propertydetail/${alert?.property?._id}`}
                        className='fs-6 text-gray-800 text-hover-primary fw-bolder'
                      >
                        New Property Assigned
                      </Link>
                      <div className='text-gray-400 fs-7'>{alert?.property?.name}</div>
                    </div>
                  ) : alert.type === 'propertyremove' ? (
                    <div className='mb-0 me-2'>
                      <Link
                        // href={`/property/propertydetail/${alert?.property?._id}`}
                        to='#'
                        className='fs-6 text-gray-800 text-hover-primary fw-bolder'
                      >
                        Property Removed
                      </Link>
                      <div className='text-gray-400 fs-7'>{alert?.property?.name}</div>
                    </div>
                  ) : alert.type === 'eventadd' ? (
                    <div className='mb-0 me-2'>
                      <Link
                        to={`/calendar`}
                        className='fs-6 text-gray-800 text-hover-primary fw-bolder capitalize'
                      >
                        New Task Assigned
                      </Link>
                      <div className='text-gray-400 fs-7'>{alert?.event?.title}</div>
                    </div>
                  ) : alert.type === 'sprintchange' ? (
                    <div className='mb-0 me-2'>
                      <Link
                        to={`/sprints/${alert?.sprint?._id}`}
                        className='fs-6 text-gray-800 text-hover-primary fw-bolder capitalize'
                      >
                        Sprint Changed
                      </Link>
                      <div className='text-gray-400 fs-7'>{alert?.task?.title}</div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>

                <span className='badge badge-light fs-8'>
                  {formatDistance(new Date(alert?.date), new Date(), {addSuffix: true})}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className='tab-pane fade' id='kt_topbar_notifications_3' role='tabpanel'>
          <div className='scroll-y mh-325px my-5 px-8'>
            {/* {defaultLogs.map((log, index) => (
            <div key={`log${index}`} className='d-flex flex-stack py-4'>
              <div className='d-flex align-items-center me-2'>
                <span className={clsx('w-70px badge', `badge-light-${log.state}`, 'me-4')}>
                  {log.code}
                </span>

                <a href='#' className='text-gray-800 text-hover-primary fw-bold'>
                  {log.message}
                </a>

                <span className='badge badge-light fs-8'>{log.time}</span>
              </div>
            </div>
          ))} */}
          </div>
        </div>
      </div>
    </div>
  )
}

export {HeaderNotificationsMenu}
