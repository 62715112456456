/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {Client} from '../../client/core/_models'
import {useAuth} from '../../../modules/auth/core/Auth'
import {Dropdown2} from '../../../../_metronic/partials/content/dropdown/Dropdown2'
import {Link, useParams} from 'react-router-dom'
import {debounce} from 'lodash'
import Clientsearch from './Clientsearch'
import {getClientagentIdQuery} from '../core/_requests'
import Flatpickr from 'react-flatpickr'

const ClientlistbyId: React.FC = () => {
  const agentid = useParams().id
  const {currentUser} = useAuth()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [page, setPage] = useState<number>(1)
  const [dateState, setDateState] = useState<any>({
    startDate: null,
    endDate: null,
  })

  const PF: any = 'https://unifeed.s3.ap-south-1.amazonaws.com/'

  const [isLoading, setisLoading] = useState<boolean>(true)
  const [loadingClients, setLoadingClients] = useState<boolean>(false)
  const [clients, setClients] = useState<Client[]>([])
  const [permissions, setPermissions] = useState<any>({})

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const getClientList = useCallback(async () => {
    try {
      setLoadingClients(true)
      const res = await getClientagentIdQuery(agentid, searchTerm, page.toString(), dateState)
      setClients(res)
      setisLoading(false)
      setLoadingClients(false)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }, [page, searchTerm, dateState, agentid])

  useEffect(() => {
    // getClientList();
    // debounce the search
    const debouncedSearch = debounce(getClientList, 1000)
    debouncedSearch()

    return () => {
      debouncedSearch.cancel()
    }
  }, [page, searchTerm, dateState.endDate, getClientList])


  const handleTrigger = () => {
    getClientList()
  }

  const handleAutoDial = (phoneNumber: string) => {
    const url = `https://portal.maqsam.com/phone/dialer#autodial=${phoneNumber}`
    window.open(url, null, 'toolbar=no,menubar=no')
  }

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <div className={`card h-md-50 mb-5 mb-xl-10 min-h-500px overflow-scroll `}>
          <div className='card-header border-0 pt-5'>
            {/* <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb1'></span>
         
        </h3> */}
            <div className='d-flex align-items-center position-relative me-5'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search client'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <div className='d-flex align-items-left position-relative me-5'>
              <Flatpickr
                value={dateState.date}
                onChange={([startDate, endDate]) => {
                  setDateState({startDate, endDate})
                }}
                options={{
                  mode: 'range',
                }}
                className='form-control form-control-solid w-250px mx-2 my-4'
                placeholder='Pick date'
              />
            </div>

            {(currentUser?.profileType === 'superadmin' || permissions?.clients?.assign) && (
              <div className='d-flex flex-row'>
                <div
                  className='card-toolbar me-5'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_4'
                >
                  <a className='btn btn-sm btn-light-primary'>
                    <KTIcon iconName='plus' className='fs-2' />
                    Assign Client
                  </a>
                </div>
              </div>
            )}
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table align-middle gs-0 gy-4 table-row-bordered'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='ps-4 min-w-50px rounded-start'>Name</th>
                    <th className='min-w-170px'>Email</th>
                    <th className='min-w-1700px'>Phone</th>
                    <th className='min-w-170px'>Agent</th>
                    <th className='min-w-100px'>Status</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {loadingClients ? (
                    <div className='d-flex justify-content-center align-items-center'>
                      <div className='spinner spinner-primary spinner-lg mr-15'></div>
                    </div>
                  ) : (
                    <>
                      {clients.map((product: Client | undefined) => (
                        <tr key={product?._id?.toString()}>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='symbol symbol-50px me-5'>
                                {/* <img
                        src={toAbsoluteUrl('/media/stock/600x400/img-26.jpg')}
                        className=''
                        alt=''
                      /> */}
                              </div>
                              <Link
                                to={`/clients/clientDetails/${product._id}`}
                                className=' client_field cursor-pointer '
                              >
                                <div className='d-flex justify-content-start flex-column'>
                                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                    {product?.name}
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </td>
                          <td>
                            <div
                              className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                            >
                              {product?.email}
                            </div>
                          </td>
                          <td>
                            <div
                              className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                              onClick={() => handleAutoDial(product?.phone)}
                            >
                              {product?.phone}
                            </div>
                          </td>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                {product?.agent && product.agent[0]?.fullName ? (
                                  product?.agent.length > 1 ? (
                                    <div
                                      className='symbol-group symbol-hover'
                                      data-kt-menu-trigger='click'
                                      data-kt-menu-placement='bottom-end'
                                      data-kt-menu-flip='top-end'
                                    >
                                      {product.agent.map((item, index) => (
                                        <div className='symbol symbol-circle symbol-30px '>
                                          {item.profilePic !== '' ? (
                                            <img src={PF + item.profilePic} alt='' />
                                          ) : (
                                            <div className='symbol-label fs-5 fw-semibold bg-danger text-inverse-danger'>
                                              {item.fullName.charAt(0).toUpperCase()}
                                            </div>
                                          )}
                                        </div>
                                      ))}

                                      <div
                                        className='menu menu-sub menu-sub-dropdown w-200px w-md-200px p-5'
                                        data-kt-menu='true'
                                        style={{zIndex: 1000000}}
                                      >
                                        {product.agent.map((item, index) => (
                                          <div className='p-3'>
                                            <div className='symbol symbol-30px me-2 symbol-square'>
                                              {item?.profilePic ? (
                                                <img
                                                  src={PF + item.profilePic}
                                                  className=''
                                                  alt=''
                                                />
                                              ) : (
                                                <div className='symbol-label fs-5 fw-semibold bg-danger text-inverse-danger'>
                                                  {item.fullName.charAt(0).toUpperCase()}
                                                </div>
                                              )}
                                            </div>
                                            <div
                                              className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                                            >
                                              {item.fullName}
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <div className='symbol symbol-30px me-2 symbol-square'>
                                        {product?.agent && product.agent[0]?.profilePic ? (
                                          <img
                                            src={PF + product.agent[0].profilePic}
                                            className=''
                                            alt=''
                                          />
                                        ) : (
                                          <div className='symbol-label fs-5 fw-semibold bg-danger text-inverse-danger'>
                                            {product.agent[0].fullName.charAt(0).toUpperCase()}
                                          </div>
                                        )}
                                      </div>
                                      <div
                                        className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                                      >
                                        {product?.agent[0]?.fullName}
                                      </div>
                                    </div>
                                  )
                                ) : (
                                  <div
                                    className='text-danger fw-bold text-hover-primary mb-1 fs-6'
                                  >
                                    Not Assigned
                                  </div>
                                )}
                              </div>
                            </div>
                            <Dropdown2 />
                          </td>
                          <td>
                            <span
                              className={`badge  fs-7 fw-semibold ${
                                product?.pipelinestatus === 'new'
                                  ? 'badge-light-primary'
                                  : product?.pipelinestatus === 'prospect'
                                  ? 'badge-light-warning'
                                  : product?.pipelinestatus === 'viewing'
                                  ? 'badge-light-info'
                                  : product?.pipelinestatus === 'contract'
                                  ? 'badge-light-sucsess'
                                  : 'badge-light-danger'
                              }`}
                            >
                              {' '}
                              {product?.pipelinestatus}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
                {/* end::Table body */}
              </table>
              <div className='w-100 d-flex justify-end gap-4 p-2'>
                <button
                  className={`btn btn-sm btn-primary ${page === 1 ? 'disabled' : ''}`}
                  onClick={() => setPage(page - 1)}
                >
                  Previous
                </button>
                <div className='d-flex gap-3 justify-center align-middle'>
                  {page > 1 && <h3 className={`btn btn-sm btn-secondary disabled`}>{page - 1}</h3>}
                  {page > 0 && <h3 className={`btn btn-sm btn-danger disabled`}>{page}</h3>}
                  {page > 1 && <h3 className={`btn btn-sm btn-secondary disabled`}>{page + 1}</h3>}
                </div>
                <button className={`btn btn-sm btn-primary`} onClick={() => setPage(page + 1)}>
                  Next
                </button>
              </div>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}

          {/* Modal for add client */}
        </div>
      )}

      <Clientsearch handleTrigger={handleTrigger} />
    </>
  )
}

export {ClientlistbyId}
