import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  totalClients: 'Loading',
  pipelineCount: {
    noanswer: 'Loading',
    callback: 'Loading',
    reject: 'Loading',
  },
  error: null,
}

const clientSlice = createSlice({
  name: 'clientStats',
  initialState,
  reducers: {
    setTotalClients(state, action) {
      state.totalClients = action.payload
      state.error = false
    },
    setPipelineCounts(state, action) {
      state.pipelineCount = action.payload
      state.error = false
    },
    setLoading(state) {
      state.error = null
    },
    setError(state, action) {
      state.error = action.payload
    },
  },
})

export const {setPipelineCounts, setTotalClients, setLoading, setError} = clientSlice.actions
export default clientSlice.reducer
