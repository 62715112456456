import React from 'react'
import Leadlist from '../components/Deallist'


function Dashboard() {
  return (
    <div>
      <Leadlist/>
    </div>
  )
}

export default Dashboard