import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  calenderData: [],
}

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setCalendarData: (state, action) => {
      state.calenderData = action.payload
    },
  },
})

export const {setCalendarData} = calendarSlice.actions
export default calendarSlice.reducer
