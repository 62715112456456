import React, {createContext, useState} from 'react'
import {Route, Routes} from 'react-router-dom'
import Accountant from './pages/Accountant'
import AddAccount from './components/AddAccount'
import ExpensePage from './pages/ExpensePage'
import AddExpense from './components/AddExpense'
import JournalPage from './pages/JournalPage'
import AddJournal from './components/AddJournal'
import EditAccount from './components/EditAccount'
import AccountDetails from './components/AccountDetails'
import ReportWrapper from './ReportWrapper'
import CategoryPage from './pages/CategoryPage'
import CategoryDetailPage from './pages/CategoryDetailPage'
import AccountsDocuments from './pages/AccountsDocuments'
import {FilesPage} from './components/FilesPage'
import AddCategoryOfAccounts from './components/AddCategoryOfAccounts'

export const FilterContext = createContext<any>(null)

function AccountWrapper() {
  const [filter, setFilter] = useState({
    previousSelectedAccountType: null,
  })
  return (
    <FilterContext.Provider value={{filter, setFilter}}>
      <Routes>
        <Route path='/chartofaccounts' element={<Accountant />} />
        <Route path='/chartofaccounts/add' element={<AddAccount />} />
        <Route path='/chartofaccounts/edit/:id' element={<EditAccount />} />
        <Route path='/chartofaccounts/:id' element={<AccountDetails />} />
        <Route path='/expenses' element={<ExpensePage />} />
        <Route path='/expenses/add' element={<AddExpense />} />
        <Route path='/journals' element={<JournalPage />} />
        <Route path='/journals/add' element={<AddJournal />} />
        <Route path='/reports/*' element={<ReportWrapper />} />
        <Route path='/categoryofaccounts' element={<CategoryPage />} />
        <Route path='/categoryofaccounts/add' element={<AddCategoryOfAccounts />} />
        <Route path='/categoryofaccounts/:id' element={<CategoryDetailPage />} />
        <Route path='/documents' element={<AccountsDocuments />} />
        <Route path='/documents/:id' element={<FilesPage />} />
      </Routes>
    </FilterContext.Provider>
  )
}

export default AccountWrapper
