import React, {ChangeEvent, FC, FormEvent, useEffect, useState} from 'react'
import { addEvent, deleteEvent, editEvent, getEvent, postImages } from '../core/_requests'
import {KTSVG} from '../../../../_metronic/helpers'

import { Event } from '../core/_models'
import grouppic from './camera.png';
import { LaunchWidget } from './LaunchWidget';


const Launch: FC = () => {
  
  const [eventList, seteventList] = useState<Event[]>([])
  const [eventId, seteventId] = useState<string>('')
  const [imagePreview, setImagePreview] = useState<any>(null);
	const [selectedImage, setSelectedImage] = useState<any>(null);
  const PF:any = "https://unifeed.s3.ap-south-1.amazonaws.com/";


 
  const [formDataProp, setFormDataProp] = useState<Event>({
    name: '',
    productPic: '',
    place: '',
    status:'',
    date:'',
  });

  const [formData, setFormData] = useState<Event>({
    name: '',
    productPic: '',
    place: '',
    status:'',
    date:'',
   

  });

  
  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setSelectedImage(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // This will set the image preview URL
      };

      setFormDataProp({
        ...formDataProp,
        productPic: Date.now() + file.name,
      });

      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormDataProp({
      ...formDataProp,
      [name]: value,
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmitAdd =  async(e: FormEvent) => {
    e.preventDefault();
  
    try {
      const response = await addEvent(formData);
      console.log(response);
  
      const updatedEvent = [...eventList, response];
      seteventList(updatedEvent)
  
      setFormData({
        name: '',
        productPic: '',
        place: '',
        status:'',
        date:'',  // Assuming user?.pipeline returns a string or undefined
      });

      const closeModalButton = document.getElementById('closeModalButton');
      if (closeModalButton) {
        closeModalButton.click();
      }
  
 
    } catch (error) {
      // Handle error as needed
      console.error('Error adding client:', error);
    }
  };

  const handleSubmit = async(e: FormEvent) => {
    e.preventDefault();
    const imageData = new FormData();
    const productPic = formDataProp.productPic;
    imageData.append('name', productPic);
    imageData.append('image', selectedImage);
    await postImages(imageData).then(
    async (res) => {
      await editEvent(formDataProp, eventId).then((response) => {
        const updateEvent = eventList.map(item => item._id === response._id ? response : item)
        seteventList(updateEvent)
      setFormDataProp({
        name: '',
        productPic: '',
        place: '',
        status:'',
        date:'',
      })
      seteventId("")
      const closeModalButton = document.getElementById('closeModalButtonEdit');
      if (closeModalButton) {
        closeModalButton.click();
      }
      })
    
       
    }
    )
    

    // Add your logic for handling form data here
  };

  const handledelete = async() => {
    const res = await deleteEvent(eventId)
    console.log(res)
    if (res === "deleted"){
      const updatedArray = eventList.filter((item) => item._id !== eventId);
      seteventList(updatedArray)
      const closeModalButton = document.getElementById('closeModalButtondeldelete');
      if (closeModalButton) {
        closeModalButton.click();
      }
    }
  }

  const handleid = (id:string) => {
    seteventId(id)
  }

  
  useEffect(() => {
    setFormDataProp(eventList.find(item => item._id === eventId))
  }, [eventId])


  const handleDelete = () => {
    console.log("eref")
  }



  useEffect(() => {
		
    const getEventlist = async() => {
      const res = await getEvent()
      console.log(res)
      seteventList(res)
      }
      
getEventlist()
}, [])
  
  return (
    <>
      <div>
      {/* <button type="button" className='client_headerbtn bg-indigo-950 text-white m-2 p-3 px-6 rounded-lg my-4' onClick={()=>setModal(true)}  >+ Add</button> */}
      <button className=' bg-indigo-950 text-white m-2 p-3 px-6 rounded-lg my-4'  type='button'  data-bs-toggle="modal"
       data-bs-target="#kt_modal_1">
          
          + Add Event
          
        </button>
      </div>
      
      {/* begin::Row */}
      
      <div className='row g-5 g-xl-8 '>
        {
          eventList.map((item,index) => (
            <div className='col-xl-4'>
              
            <LaunchWidget className='card-xl-stretch mb-xl-8' item={item} handleid={handleid}/>
           
          </div>
          ))
        }
       
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      


      <div className="modal fade" tabIndex={-1} id="kt_modal_1">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Add Events</h5>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon svg-icon-2x"
          />
        </div>
      </div>

      <div className="modal-body">
      <form >
              <div className="row">
                <div className="messagepicinput d-flex flex-center ">
                  <label htmlFor="messagegrouppic" className="messagesharefilelabel">
                  <div className="symbol symbol-100px border border-gray-300 ">
                  {imagePreview && (
                        <img
      
                          src={imagePreview ? imagePreview : grouppic}
                          alt='add'
                        />
                      )}
                      {!imagePreview && <img className="" src={formData?.productPic !== "" ? PF+formData?.productPic : grouppic} alt='add'/>}
                  </div>
  
                    <input type="file" id="messagegrouppic" onChange={handleImageChange} style={{ display: "none" }} />
                  </label>
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Full Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData?.name}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-3 col-md-12">
                  <label>Place</label>
                  <input
                    type="text"
                    name="place"
                    value={formData?.place}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-3 col-md-6">
                  <label>Date</label>
                  <input
                    type="text"
                    name="date"
                    value={formData?.date}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Status</label>
                  <input
                    type="text"
                    name="status"
                    value={formData?.status}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>

                

                
              
               
              </div>
           
            </form>
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          data-bs-dismiss="modal"
          id="closeModalButton" 
       
        >
          Close
        </button>
        <button type="button" className="btn btn-primary"    onClick={handleSubmitAdd}>
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>

{/* Edit */}


      <div className="modal fade" tabIndex={-1} id="kt_modal_3">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Edit Event</h5>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
        
        </div>
      </div>

      <div className="row modal-body">
      <form >
              <div className="row">
                <div className="messagepicinput d-flex flex-center ">
                  <label htmlFor="messagegrouppic" className="messagesharefilelabel">
                  <div className="symbol symbol-100px border border-gray-300 ">
                  {imagePreview && (
                        <img
      
                          src={imagePreview ? imagePreview : grouppic}
                          alt='add'
                        />
                      )}
                      {!imagePreview && <img className="" src={formDataProp?.productPic !== "" ? PF+formDataProp?.productPic : grouppic} alt='add'/>}
                  </div>
  
                    <input type="file" id="messagegrouppic" onChange={handleImageChange} style={{ display: "none" }} />
                  </label>
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label>Full Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formDataProp?.name}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-3 col-md-12">
                  <label>Place</label>
                  <input
                    type="text"
                    name="place"
                    value={formDataProp?.place}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-3 col-md-6">
                  <label>Date</label>
                  <input
                    type="text"
                    name="date"
                    value={formDataProp?.date}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>

                <div className="form-group mb-3 col-md-6">
                  <label>Status</label>
                  <input
                    type="text"
                    name="status"
                    value={formDataProp?.status}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>

               

                
              
               
              </div>
           
            </form>
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          data-bs-dismiss="modal"
          id="closeModalButtonEdit" 
        >
          Close
        </button>
        <button type="button" className="btn btn-primary" onClick={handleSubmit}  >
          Edit
        </button>
      </div>
    </div>
  </div>
  </div>




  <div className="modal fade" tabIndex={-1} id="kt_modal_4">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Delete event</h5>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
        
        </div>
      </div>

      <div className="row modal-body">
          Delete This event
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          data-bs-dismiss="modal"
          id="closeModalButtondeldelete" 
        >
          Close
        </button>
        <button type="button" className="btn btn-danger" onClick={handledelete}  >
          Delete
        </button>
      </div>
    </div>
  </div>
  </div>
      
    </>
  )
}

export {Launch}



