import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../modules/auth';
import { generateReceipt, getInvoice, getInvoicebyAgnetid } from '../core/_requests';
import { KTIcon, KTSVG } from '../../../../_metronic/helpers';
import Select from 'react-select';
import {useSelector, useDispatch} from 'react-redux'
import { RootState } from '../../../redux/store';
import { setInvoices } from '../../../redux/sales/invoiceSlice';


function InvoiceList() {
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const Invoicelist = useSelector((state:RootState) => state.sales.invoice.invoices)
  const loading = useSelector((state:RootState) => state.sales.invoice.loading)
  const [selectedmethod, setselectedmethod] = useState<any>()
  const [id, setid] = useState<any>()
const Navigate = useNavigate()
  const [permissions, setPermissions] = useState<any>({})

    useEffect(() => {
      if (currentUser?.permissions) {
        setPermissions(currentUser?.permissions)
      }
    }, [currentUser])

  const methods = [
    { value: "cash", label: "Cash" },
    { value: "cheque", label: "Cheque" },
    { value: "card", label: "Card" },
  ];

  const handlemethodChange = (selectedOption) => {
setselectedmethod(selectedOption)
  }

 

  const getdealslist = async() => {
    if(permissions?.sales?.admin === false){
      const res = await getInvoicebyAgnetid(currentUser?._id)
      dispatch(setInvoices(res))
      console.log(res)

    }else if(currentUser?.profileType === "superadmin" || permissions?.sales?.admin){
      const res = await getInvoice(currentUser?.company)
      dispatch(setInvoices(res))
      console.log(res)

    }

}
useEffect(() => {
  if (loading) {
    getdealslist()
  }
}, [permissions])

const handleSubmit =  async() => {

    const data = {
        paid:true,
        paymentMethod: selectedmethod.value
    }

    await generateReceipt(data,id).then(res => Navigate(`/sales/receipt/details/${id}`))
    const closeModalButton = document.getElementById('closeModalButton');
    if (closeModalButton) {
      closeModalButton.click();
    }

}

  if (loading){
    return (
      <div className='d-flex justify-content-center'>
        <div className='loader'></div>
      </div>
    )
  }


  return (
    <div className='card card-flush'>
       
    <div className='card-header border-0 pt-5'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bold fs-3 mb-1'>Invoices</span>
       
      </h3>

      {/* {!(currentUser?.profileType === "superadmin" || permissions?.sales?.admin) &&  */}
      <div className='card-toolbar'>
        <Link to={'/sales/invoice/add'} className='btn btn-sm btn-light-primary'>
          <KTIcon iconName='plus' className='fs-2' />
          Add Invoice
        </Link>
      </div>
      {/* } */}

    </div>
    {/* end::Header */}
    {/* begin::Body */}
    <div className='card-body py-3'>
      {/* begin::Table container */}
      <div className='table-responsive'>
        {/* begin::Table */}
        <table className='table align-middle gs-0 gy-4 table-row-bordered' >
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold text-muted bg-light'>
              <th className='ps-4 min-w-50px rounded-start'>ID</th>
              <th className='min-w-150px'>Agent</th>
              <th className='min-w-150px'>Client</th>
              <th className='min-w-150px'>Property</th>
              <th className='min-w-100px'>Amount</th>
              <th className='min-w-100px'>Status</th>
              <th className='min-w-150px'>Receipt</th>
              {
                (permissions?.sales?.update) && (
                  <th className='min-w-100px'>Action</th>
                )
              }
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
          {Invoicelist.map((item:any) => (
            <tr key={item?._id?.toString()}>
                
                <td>
               
                     <Link to={`/sales/invoice/details/${item.id}`}  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                {item?.id}
                </Link>
                
              </td>
             <td>
            
                     <Link to={`/sales/invoice/details/${item.id}`}  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                {item?.agent?.fullName}
                </Link>
                
              </td>
              <td>
             
                     <Link to={`/sales/invoice/details/${item.id}`}  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                {item?.client?.name}
                </Link>
                
              </td>
              <td>
             
                     <Link to={`/sales/invoice/details/${item.id}`}  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                {item?.inventory?.name}
                </Link>
                
              </td>
              <td>
             
              <Link to={`/sales/invoice/details/${item.id}`}  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                <span className={`badge  fs-6 fw-semibold `}> {item?.total}</span>
                </Link>
               
              </td>
              <td>
             
                <a  href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6' >
                <span className={`badge  fs-7 fw-semibold ${item?.status==='pending' ? "badge-light-primary" : item?.status === 'accepted' ? "badge-light-success" : "badge-light-danger"}`}> {item?.status}</span>
                </a>
         
              </td>

              {
                item?.paid ? (
                    <td>
             <div className='card-toolbar'>
                        <Link to={`/sales/receipt/details/${item?.id}`} className='btn btn-sm btn-light-success' >
                        <KTIcon iconName='plus' className='fs-2' />
                        view Reciept
                        </Link>
                    </div>
              </td>
                ):(
                   
                    item?.status === "accepted" ? (
                      <td>
                      <div className='card-toolbar' data-bs-toggle="modal" data-bs-target="#kt_modal_1" onClick={()=>setid(item?.id)}>
                          <a href='#' className='btn btn-sm btn-light-info'>
                          <KTIcon iconName='plus' className='fs-2' />
                          Generate Reciept
                          </a>
                      </div>
                </td>
                    ):(
                      <td>
                      <div className='card-toolbar' data-bs-toggle="modal" data-bs-target="#kt_modal_2" >
                          <a href='#' className='btn btn-sm btn-light-secondary'>
                          <KTIcon iconName='plus' className='fs-2' />
                          Generate Reciept
                          </a>
                      </div>
                </td>
                    )

                   
                )
              }
              {
                (permissions?.sales?.update) && <td>
                  <Link to={`/sales/invoice/edit/${item.id}`} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                    <KTIcon iconName='pencil' className='fs-3' />
                  </Link>
                </td>
              }
             
              
            </tr>
          ))}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
      {/* end::Table container */}
    </div>
    {/* begin::Body */}

    {/* Modal for add client */}
    <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Alert</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <h4> Invoice is not accepted</h4>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButtondelete'
              >
                Close
              </button>
           
            </div>
          </div>
        </div>
      </div>

         
    <div className="modal fade" tabIndex={-1} id="kt_modal_1">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Generate Receipt</h5>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon svg-icon-2x"
          />
        </div>
      </div>

      <div className="modal-body">
      <form >
      <div className="row rowwidthclient">
        <div className="form-group mb-3 col-md-12">
          <label>Select Payment Method</label>
          <Select
            name="method"
            aria-label="Select Payment Method"
            placeholder="Select method"
            options={methods}
            isSearchable={true}
            className="form-control form-control-solid"
            onChange={handlemethodChange}
            value={selectedmethod}

        />
          
      </div>
      </div>
      
    </form>
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          data-bs-dismiss="modal"
          id="closeModalButton" 
       
        >
          Close
        </button>
        <button type="button" className="btn btn-primary"    onClick={handleSubmit}>
          Generate
        </button>
      </div>
    </div>
  </div>
</div>
 
    </div>
  )
}

export default InvoiceList