import {Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Project from './components/Project'
import ProjectDetails from './components/ProjectDetails'

const chatBreadCrumbs: Array<PageLink> = [
  {
    title: 'Projects',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
]

const ProjectWrapper = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Primary</PageTitle>
              <Project />
            </>
          }
        />
        <Route
          path='/:projectid'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Primary</PageTitle>
              <ProjectDetails />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default ProjectWrapper
