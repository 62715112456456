import axios, {AxiosResponse} from 'axios'
import {Agent} from './_models'
import { Property } from '../../project/core/_models'
import { Client } from '../../client/core/_models'


// const headers = {
//   'Content-Type': 'application/json',
//   'X-Tenant-Id': 'reez',  // Replace with your logic to get the actual tenant ID
// };

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/agent`
const GET_USERS_URL = `${API_URL}/agent/get/`
const DETAILS_AGENT_URL = `${API_URL}/agent/getdetails/`
const GET_CLIENTINFO_URL = `${API_URL}/agent/getclientinfo`
const AGENT_PROPERT_URL = `${API_URL}/inventory/agent/`
const AGENT_PROPERTSEC_URL = `${API_URL}/inventory/agent/secondary/`
const LINK_USERS_URL = `${API_URL}/agent/clientadd`
const SEARCH_USERS_URL = `${API_URL}/client/search`
const GET_CLIENT_URL = `${API_URL}/client/agent/`
const SEARCH_PROPERTY_URL = `${API_URL}/inventory/search`
const LINK_PROPERTY_URL = `${API_URL}/agent/inventoryadd`
const POST_DOCS_URL = `${API_URL}/uploaddocsagent/`
const GET_DOCS_URL = `${API_URL}/agent/documents/`
const GET_CLIENT_COUNT = `${API_URL}/agent/clientlength/`
const GET_NOTI = `${API_URL}/notification/`
const ADD_NOTES_URL = `${API_URL}/agent/notes`
const DEL_DOCS_URL = `${API_URL}/agent/documents/`
const GET_AGENT_COUNT = `${API_URL}/agent/total/`
const GET_EVENT_URL = `${API_URL}/event/eventbyagentid/`


export const getagentcount = async(companyid:string) => {
  try {
  const response : AxiosResponse<any> = await axios.get(`${GET_AGENT_COUNT}${companyid}`)
  return response.data
} catch (error) {
  // Handle errors here
  console.error("Add client error:", error);
  throw error;
}}

export async function getmeeting(agentid: string): Promise<any>  {
  try {
    const response: AxiosResponse<any> = await axios.get(`${GET_EVENT_URL}${agentid}`);
    return response.data;
  } catch (error) {
    // Handle errors here
    console.error("search agent error:", error);
    throw error;
  }
}

export const deletedocs = async(id:string, docid:string) => {
  try {
  const response  = await axios.delete(`${DEL_DOCS_URL}${id}/${docid}`)
  return response.data
} catch (error) {
  // Handle errors here
  console.error("Add client error:", error);
  throw error;
}
  
}

export async function addnotes(data, agentId) {
  // console.log(past,present, pipelineId, agentId)

    try {
      const response = await axios.put(`${ADD_NOTES_URL}/${agentId}`,data);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add Company error:", error);
      throw error;
    }
  }


export const getnotification = async(id:string) => {
  try {
  const response : AxiosResponse<any> = await axios.get(`${GET_NOTI}${id}`)
  return response.data
} catch (error) {
  // Handle errors here
  console.error("Add client error:", error);
  throw error;
}
  
}


export const getAlerts = async(id:string) => {
  try {
  const response : AxiosResponse<any> = await axios.get(`${GET_NOTI}alert/${id}`)
  return response.data
} catch (error) {
  // Handle errors here
  console.error("Add client error:", error);
  throw error;
}
  
}


export const getclientcount = async(id:string) => {
  try {
  const response : AxiosResponse<any> = await axios.get(`${GET_CLIENT_COUNT}${id}`)
  return response.data
} catch (error) {
  // Handle errors here
  console.error("Add client error:", error);
  throw error;
}
  
}

export async function getPropertybyAgentId(id:string): Promise<Array<Property>>  {
  try {
    const response: AxiosResponse<Property[]> = await axios.get(`${AGENT_PROPERT_URL}${id}`);
    return response.data;
  } catch (error) {
    // Handle errors here
    console.error("Add property error:", error);
    throw error;
  }
}

export async function searchProperty(searchTerm: string,companyid:string): Promise<any>  {
  try {
    const response: AxiosResponse<any> = await axios.get(`${SEARCH_PROPERTY_URL}/${companyid}?search=${searchTerm}`);
    return response.data;
  } catch (error) {
    // Handle errors here
    console.error("search agent error:", error);
    throw error;
  }
}

export async function searchClient(searchTerm: string,companyid:string): Promise<any>  {
  try {
    const response: AxiosResponse<any> = await axios.get(`${SEARCH_USERS_URL}/${companyid}?search=${searchTerm}`);
    return response.data;
  } catch (error) {
    // Handle errors here
    console.error("search agent error:", error);
    throw error;
  }
}

export async function searchClientForAgent(searchTerm: string,companyid:string, agentid:string): Promise<any>  {
  try {
    const response: AxiosResponse<any> = await axios.get(`${SEARCH_USERS_URL}/${companyid}/${agentid}?search=${searchTerm}`);
    return response.data;
  } catch (error) {
    // Handle errors here
    console.error("search agent error:", error);
    throw error;
  }
}

export async function uploaddocs(formData: any, id:string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${POST_DOCS_URL}${id}`, formData);
    return response.data;
  } catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
}

export async function getDocs(clientId: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${GET_DOCS_URL}${clientId}`);
    return response.data;
  } catch (error) {
    // Handle errors here
    console.error("Add Client error:", error);
    throw error;
  }
}


export async function linkPropertyToAgent(ids: any)  : Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(`${LINK_PROPERTY_URL}`,ids);
    return response.data;
  } catch (error) {
    // Handle errors here
    console.error("Add property error:", error);
    throw error;
  }
}


export const getClientagentId = async(id:string):Promise<Array<Client>> => {
  try {
  const response : AxiosResponse<Client[]> = await axios.get<Array<Client>>(`${GET_CLIENT_URL}${id}`);
  return response.data
} catch (error) {
  // Handle errors here
  console.error("Add client error:", error);
  throw error;
}
  
}

export const getClientagentIdQuery = async(id:string, search:string, page:string, date: any):Promise<Array<Client>> => {
  try {
  const response : AxiosResponse<Client[]> = await axios.get<Array<Client>>(`${SEARCH_USERS_URL}/agent/query/${id}?search=${search}&startDate=${date?.startDate || ""}&endDate=${date?.endDate || ""}&page=${page}`);
  return response.data
} catch (error) {
  // Handle errors here
  console.error("Add client error:", error);
  throw error;
}
  
}

export async function linkAgentToClient(ids: any)  : Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(`${LINK_USERS_URL}`,ids);
    return response.data;
  } catch (error) {
    // Handle errors here
    console.error("Add property error:", error);
    throw error;
  }
}





export async function getPropertysecbyAgentId(id:string): Promise<Array<Property>>  {
  try {
    const response: AxiosResponse<Property[]> = await axios.get(`${AGENT_PROPERTSEC_URL}${id}`);
    return response.data;
  } catch (error) {
    // Handle errors here
    console.error("Add property error:", error);
    throw error;
  }
}


  export const getAgent = async(companyid:string):Promise<Array<Agent>> => {
    try {
    const response : AxiosResponse<Agent[]> = await axios.get<Array<Agent>>(`${GET_USERS_URL}${companyid}`,)
    return response.data
} catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  }

  export async function getAgentById(agentId: string): Promise<Agent> {
    try {
      const response: AxiosResponse<Agent> = await axios.get(`${DETAILS_AGENT_URL}${agentId}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add Agent error:", error);
      throw error;
    }
  }

   export const getclientdata = async(agentId :string):Promise<Array<Agent>> => {
    try {
    const response : AxiosResponse<Agent[]> = await axios.get<Array<Agent>>(`${GET_CLIENTINFO_URL}/${agentId}`)
    return response.data
} catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  } 

   export const getclientstatusdata = async(agentId :string) => {
    try {
    const response  = await axios.get(`${USER_URL}/getclientstatus/${agentId}`)
    return response.data
} catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  } 