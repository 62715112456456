import React, {useContext, useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import Select from 'react-select'
import {getAccounts, updateActivationStatus} from '../core/_requests'
import Swal from 'sweetalert2'
import {FilterContext} from '../AccountWrapper'
import {useAuth} from '../../../modules/auth'

const Accountant = () => {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  const [tableData, setTableData] = useState([])
  const {filter, setFilter} = useContext(FilterContext)
  const [selectedAccountType, setSelectedAccountType] = useState(filter.previousSelectedAccountType)

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const [accountTypes] = useState([
    {
      value: 'active',
      label: 'Active Accounts',
    },
    {
      value: 'inactive',
      label: 'Inactive Accounts',
    },
    {
      value: 'asset',
      label: 'Asset Accounts',
    },
    {
      value: 'liability',
      label: 'Liability Accounts',
    },
    {
      value: 'equity',
      label: 'Equity Accounts',
    },
    {
      value: 'income',
      label: 'Income Accounts',
    },
    {
      value: 'expense',
      label: 'Expense Accounts',
    },
  ])

  const fetchData = async () => {
    if (filter.previousSelectedAccountType) {
      const res = await getAccounts(filter.previousSelectedAccountType?.value, currentUser?.company)
      console.log(res)
      setTableData(res)
    } else fetchAllData()
  }

  const fetchAllData = async () => {
    const res = await getAccounts('0', currentUser?.company)
    console.log(res)
    setTableData(res)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleAccountTypeChange = async (e) => {
    console.log(e)
    setSelectedAccountType(e)
    setFilter({previousSelectedAccountType: e})
    if (e === null) {
      fetchAllData()
      return
    }
    const res = await getAccounts(e?.value, currentUser?.company)
    console.log(res)
    setTableData(res)
  }

  const handleShowModal = (index) => {
    Swal.fire({
      title: tableData[index]?.accountName,
      html: `<div class="row table-responsive">
      <div class="col-12">
        <p><b>Account Name:</b> ${tableData[index]?.accountName}</p>
        <p><b>Account Code:</b> ${tableData[index]?.accountCode}</p>
        <p><b>Account Type:</b> ${tableData[index]?.accountType}</p>
        <p><b>Account Description:</b> ${tableData[index]?.description}</p>
        <p><b>Amount:</b> ${tableData[index]?.amount}</p>
      </div>
    </div>`,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Close',
    })
  }

  const handleActivation = async (id, status) => {
    const res = await updateActivationStatus(id, {status: !status})
    if (res) {
      fetchData()
    }
  }

  // const handleDelete = async () => {
  //   await deleteAccount(deleteId)
  //     .then((res) => {
  //       if (res) {
  //         const closeModal = document.getElementById('closeModalButtondelete')
  //         closeModal?.click()
  //         const index = tableData.findIndex((item) => item?._id === deleteId)
  //         const temp = [...tableData]
  //         temp.splice(index, 1)
  //         setTableData(temp)
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })
  // }

  return (
    <div className='card card-flush'>
      <div className='card-header border-0 pt-5 d-flex justify-between'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Chart of Accounts</span>
        </h3>

        <div className='d-flex gap-4 align-items-center'>
          <div
            className='btn btn-primary me-3'
            data-kt-menu-trigger='hover'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='filter' className='fs-2' />
            Filter
            <div
              className='menu menu-sub menu-sub-dropdown w-400px w-md-400px p-5'
              data-kt-menu='true'
              style={{zIndex: 1000000}}
            >
              {/* begin::Header */}
              <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
              </div>
              {/* end::Header */}

              {/* begin::Separator */}
              <div className='separator border-gray-200'></div>
              {/* end::Separator */}

              {/* begin::Content */}
              <div className='px-7 py-5' data-kt-user-table-filter='form'>
                {/* begin::Input group */}
                <div className='mb-10 d-flex flex-row align-content-center justify-content-center'>
                  <label className='form-label fs-6 fw-bold text-gray-700 mb-3 me-5 mt-5'>
                    Account type:
                  </label>

                  <Select
                    name='accountType'
                    aria-label='Select an Account Type'
                    placeholder='Select Account Type'
                    options={accountTypes}
                    defaultValue={{value: '', label: 'All Accounts'}}
                    isSearchable={true}
                    className='form-control form-control-solid'
                    onChange={handleAccountTypeChange}
                    value={selectedAccountType}
                  />
                </div>

                {/* end::Input group */}

                {/* begin::Actions */}
                <div className='d-flex justify-content-end'>
                  <button
                    type='button'
                    // disabled={isLoading}
                    // onClick={filterData}
                    className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                    data-kt-menu-dismiss='true'
                    data-kt-user-table-filter='reset'
                    onClick={() => {
                      setSelectedAccountType(null)
                      setFilter({previousSelectedAccountType: null})
                      handleAccountTypeChange(null)
                    }}
                  >
                    RESET
                  </button>
                </div>
              </div>
              {/* end::Actions */}
            </div>
            {/* end::Content */}
          </div>

          {(currentUser?.profileType === 'superadmin' || permissions?.accountant?.create) && (
            <div className='card-toolbar'>
              <Link to={'/account/chartofaccounts/add'} className='btn btn-sm btn-light-success'>
                <KTIcon iconName='plus' className='fs-2' />
                New Account
              </Link>
            </div>
          )}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4 table-row-bordered'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-25px rounded-start'></th>
                <th className='min-w-150px'>Account Name</th>
                <th className='min-w-150px'>Account Code</th>
                <th className='min-w-150px'>Account Type</th>
                {(currentUser?.profileType === 'superadmin' || permissions?.accountant?.update) && (
                  <th className='min-w-150px'>Action</th>
                )}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {tableData?.map((item, index) => (
                <tr key={item?._id}>
                  {/* show lock icon if fixed value is true */}
                  <td className='ps-4 text-hover-primary'>
                    {item?.fixed ? <KTIcon iconName='lock-2' className='fs-2' /> : ''}
                  </td>
                  <td
                    className='capitalize cursor-pointer fw-bold text-hover-primary mb-1'
                    //   className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                    // onClick={() => handleShowModal(index)}
                  >
                    <Link to={`/account/chartofaccounts/${item?._id}`}>{item?.accountName}</Link>
                  </td>
                  <td
                  //   className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                  >
                    {item?.accountCode}
                  </td>
                  <td
                  //   className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                  >
                    {item?.accountType}
                  </td>
                  {(currentUser?.profileType === 'superadmin' ||
                    permissions?.accountant?.create) && (
                    <td>
                      {!item?.fixed && (
                        <button
                          type='button'
                          className='btn btn-sm btn-color-dark btn-active-light-dark'
                          data-kt-menu-trigger='hover'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                        >
                          Actions
                        </button>
                      )}
                      <div
                        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-125px'
                        data-kt-menu='true'
                        style={{zIndex: 1000000}}
                      >
                        {/* begin::Menu item */}

                        <div className='menu-item px-3 mt-1'>
                          <Link
                            to={`/account/chartofaccounts/edit/${item?._id}`}
                            className='menu-link px-3'
                          >
                            Edit
                          </Link>
                        </div>
                        <div className='separator mt-3 opacity-75'></div>
                        {item?.status ? (
                          <div
                            className='menu-item px-3'
                            onClick={() => handleActivation(item?._id, item?.status)}
                          >
                            <a href='#' className='menu-link px-3'>
                              Deactivate
                            </a>
                          </div>
                        ) : (
                          <div
                            className='menu-item px-3'
                            onClick={() => handleActivation(item?._id, item?.status)}
                          >
                            <a href='#' className='menu-link px-3'>
                              Activate
                            </a>
                          </div>
                        )}
                        <div className='separator mt-3 opacity-75'></div>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>

            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {/* Modal for add client */}
    </div>
  )
}

export default Accountant
