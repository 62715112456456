// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyCNYHOSpJaMBFSfWH5Ny3bUfGu0fnnR4A8",
  authDomain: "fir-416411.firebaseapp.com",
  projectId: "firebase-416411",
  storageBucket: "firebase-416411.appspot.com",
  messagingSenderId: "892983571469",
  appId: "1:892983571469:web:8a2cf1d6b113f02d2a9144"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);


export const generateToken = async () => {
     try {
       const permission = await Notification.requestPermission();
   
       if (permission === 'granted') {
         const token = await getToken(messaging, {
           vapidKey: "BFKxYFPxEEC7SX10qRSnCl8ukV-JK9k7Q0cxpwEMWjyeyxJXbxnvnffF2TIuIsWEL9f0VvZMSeDIS8BFnZ-qDyY"
         });
   
         return token;
       } else {
         return null;
       }
     } catch (error) {
       return null;
     }
   };
   