import React from 'react'
import {Route, Routes} from 'react-router-dom'
import ReportsPage from './pages/ReportsPage'
import ProfitAndLoss from './components/reports/ProfitAndLoss'
import BalanceSheet from './components/reports/BalanceSheet'
import SalesByCustomer from './components/reports/SalesByCustomer'

const ReportWrapper = () => {
  return (
    <Routes>
      <Route path='/' element={<ReportsPage />} />
      {/* Business Overview */}
      <Route path='/profitandloss' element={<ProfitAndLoss />} />
      <Route path='/balancesheet' element={<BalanceSheet />} />

      {/* Sales */}
      <Route path='/salesbycustomer' element={<SalesByCustomer />} />
    </Routes>
  )
}

export default ReportWrapper
