import {createSlice} from '@reduxjs/toolkit'
import {Property} from '../../pages/project/core/_models'

const initialState = {
  propertyList: [] as Property[],
}

const primarySlice = createSlice({
  name: 'primary',
  initialState,
  reducers: {
    setPropertyList: (state, action) => {
      state.propertyList = action.payload
    },
  },
})

export const {setPropertyList} = primarySlice.actions
export default primarySlice.reducer
