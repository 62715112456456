import React, {useEffect, useState} from 'react'

import {Link, useParams} from 'react-router-dom'
import {getmeeting} from '../core/_requests'
function MeetList() {
  const [Leadlist, setLeadlist] = useState<any>([])
  const id = useParams().id

  useEffect(() => {
    const getdealslist = async () => {
      const res = await getmeeting(id)
      const data = res.filter((r: any) => {
        return r?.type === 'meeting' && r.client?.length > 0
      })
      setLeadlist(data)
    }

    getdealslist()
  }, [id])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  const formatCreatedAttime = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'

    const formattedTime = new Date(createdAt).toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })

    return formattedTime
  }

  return (
    <div className={`card h-md-50 mb-5 mb-xl-10`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Meetings</span>
        </h3>

        {/* <div className='card-toolbar' data-bs-toggle="modal" data-bs-target="#kt_modal_1">
        <div className='btn btn-sm btn-light-primary'>
          <KTIcon iconName='plus' className='fs-2' />
          Add Deals
        </div>
      </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4 table-row-bordered'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-50px rounded-start'>ID</th>
                <td className='min-w-200px'>Title</td>
                <th className='min-w-200px'>Client</th>
                <th className='min-w-200px'>Date</th>
                <th className='min-w-200px'>Time</th>
                <th className='min-w-200px'>Priority</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {Leadlist.map((lead: any, index) => (
                <>
                  {lead?.type === 'meeting' && (
                    <tr key={lead?._id?.toString()}>
                      <td>
                        <div className='text-dark fw-bold text-hover-primary d-block ps-4 mb-1 fs-6'>
                          {index + 1}
                        </div>
                      </td>
                      <td>
                        <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {lead?.title}
                        </div>
                      </td>
                      <td>
                        <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          <td>
                            <div className='d-flex align-items-center'>
                              <Link
                                to={`/clients/clientDetails/${lead?.client[0]?._id}`}
                                className=' client_field cursor-pointer '
                              >
                                <div className='d-flex justify-content-start flex-column'>
                                  <div className='text-dark fw-bold text-hover-primary fs-6'>
                                    {lead?.client[0]?.name}
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </td>
                        </div>
                      </td>
                      <td>
                        <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {formatCreatedAt(lead?.start)}
                        </div>
                      </td>
                      <td>
                        <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {formatCreatedAttime(lead?.start)}
                        </div>
                      </td>
                      <td>
                        <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          <span
                            className={`badge fs-7 fw-semibold ${
                              lead?.priority === 'Important'
                                ? 'badge-light-danger'
                                : lead?.priority === 'Medium'
                                ? 'badge-light-warning'
                                : 'badge-light-success'
                            }`}
                          >
                            {lead?.priority}
                          </span>
                        </div>
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {/* Modal for add client */}
    </div>
  )
}

export default MeetList
