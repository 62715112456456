import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  new: 'Loading',
  prospect: 'Loading',
  viewing: 'Loading',
  close: 'Loading',
}

const pipelineStatsSlice = createSlice({
  name: 'pipelineStats',
  initialState,
  reducers: {
    setNew(state, action) {
      state.new = action.payload
    },
    setProspect(state, action) {
      state.prospect = action.payload
    },
    setViewing(state, action) {
      state.viewing = action.payload
    },
    setClose(state, action) {
      state.close = action.payload
    },
  },
})

export const {setNew, setProspect, setViewing, setClose} = pipelineStatsSlice.actions
export default pipelineStatsSlice.reducer
