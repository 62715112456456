import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  agentPerformance: [],
  dateState: {
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString(),
    endDate: new Date().toISOString(),
  },
  loading: true,
}

const agentPerformanceSlice = createSlice({
  name: 'agentPerformance',
  initialState,
  reducers: {
    setPerformance(state, action) {
      state.agentPerformance = action.payload
      state.loading = false
    },
    setDateRange(state, action) {
      state.dateState = {
        startDate: new Date(action.payload.startDate).toISOString(),
        endDate: new Date(action.payload.endDate).toISOString(),
      }
    },
    setLoading(state) {
      state.loading = true
    },
  },
})

export const {setPerformance, setLoading, setDateRange} = agentPerformanceSlice.actions
export default agentPerformanceSlice.reducer
