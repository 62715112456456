import React, {useEffect, useRef} from 'react'
import {useAuth} from '../../../../modules/auth/core/Auth'
import ClientSummaryCard from './ClientSummaryCard'
import {getClientsPerformance} from '../../core/_requests'
import * as XLSX from 'xlsx'
import html2canvas from 'html2canvas'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../redux/store'
import {setPerformance} from '../../../../redux/performance/clientPerformanceSlice'

const ClientPerformance: React.FC = () => {
  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const toCapture = useRef(null)
  const companyid = currentUser.company
  // const [salesPerformanceData, setSalesPerformanceData] = useState([])
  const salesPerformanceData = useSelector(
    (state: RootState) => state.performance.client.clientPerformance
  )
  const loading = useSelector((state: RootState) => state.performance.client.loading)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getClientsPerformance(companyid)

        // Sort data by performance percentage in descending order
        // data.sort((a, b) => b.performancePercentage - a.performancePercentage)

        // setSalesPerformanceData(data)
        dispatch(setPerformance(data))
      } catch (error) {
        console.error('Error fetching sales performance data:', error)
      }
    }
    if (loading) fetchData()
  }, [companyid, dispatch, loading])

  // Slice the first 5 customers from the salesPerformanceData array
  const top5Customers = salesPerformanceData.slice(0, 5)

  const handleDownloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(salesPerformanceData)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Client Performance')
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})

    const blob = new Blob([excelBuffer], {type: 'application/octet-stream'})
    const fileName = 'client-performance.xlsx'

    // Create download link
    const downloadLink = document.createElement('a')
    downloadLink.href = URL.createObjectURL(blob)
    downloadLink.setAttribute('download', fileName)

    // Trigger download
    downloadLink.click()
  }

  const captureScreenshot = () => {
    let canvasPromise = html2canvas(toCapture.current, {
      useCORS: true, // in case you have images stored in your application
    })
    canvasPromise.then((canvas) => {
      var dataURL = canvas.toDataURL('client-performance/png')
      // Create an image element from the data URL
      var img = new Image()
      img.src = dataURL

      // Create a link element
      var a = document.createElement('a')
      a.innerHTML = 'download image'
      a.href = dataURL
      a.download = 'client-performance.png'
      document.body.appendChild(a)

      a.click()
    })
  }

  return (
    <div>
      <div className='flex-1'>
        <ClientSummaryCard customers={top5Customers} />
      </div>
      <div className='card p-4'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title d-flex align-items-center justify-between w-100'>
            <span className='card-label fw-bold fs-3 mb-1'>Client Performance</span>
            {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 members</span> */}
            <div className='d-flex gap-3 align-items-center flex-wrap justify-center'>
              {/* button for download excel */}
              <button type='button' className='btn btn-primary' onClick={handleDownloadExcel}>
                Download Excel
              </button>
              <button type='button' className='btn btn-info' onClick={captureScreenshot}>
                Download PNG
              </button>
            </div>
          </h3>
        </div>

        <div className='card-body py-3' ref={toCapture}>
          {loading ? (
            <div className='d-flex justify-content-center'>
              <div className='loader'></div>
            </div>
          ) : (
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-150px'>Client</th>
                    <th className='min-w-140px'>No. of Notes</th>
                    <th className='min-w-140px'>No. of Property</th>
                    <th className='min-w-140px'>No. of Quotes</th>
                    <th className='min-w-140px'>No. of Invoices</th>
                    <th className='min-w-140px'>No. of Meetings</th>
                    {/* <th className='min-w-140px'>Total Business</th> */}
                    <th className='min-w-140px'>Performance Percentage</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {salesPerformanceData.map((customer, index) => (
                    <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-45px me-5'></div>
                          <div className='d-flex justify-content-start flex-column'>
                            <div className='text-dark fw-bold text-hover-primary fs-6'>
                              {customer.name}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className='text-start'>{customer.notes}</td>
                      <td className='text-start'>{customer.inventory}</td>
                      <td className='text-start'>{customer.quote}</td>
                      <td className='text-start'>{customer.invoice}</td>
                      <td className='text-start'>{customer.meeting}</td>
                      {/* <td className='text-start'>
                        {' '}
                        {customer?.totalBusiness?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}{' '}
                      </td> */}
                      <td className='text-end'>
                        <div className='d-flex flex-column w-100 me-2'>
                          <div className='d-flex flex-stack mb-2'>
                            <span className='text-muted me-2 fs-7 fw-semibold'>
                              {customer.performancePercentage.toFixed(2)}%
                            </span>
                          </div>
                          <div className='progress h-6px w-100'>
                            <div
                              className='progress-bar bg-info'
                              role='progressbar'
                              style={{width: `${customer.performancePercentage}%`}}
                            ></div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
          )}
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </div>
  )
}

export default ClientPerformance
