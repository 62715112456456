import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPropertyDetails } from '../core/_requests';
import { ClientlistbyId } from './ClientListbyId';
import { AgentlistbyId } from './AgentListbyId';
import { Documents } from './Documnets';
import { useAuth } from '../../../modules/auth';


const PropertyDetails: React.FC = () => {
    const [propertys, setpropertys] = useState<any>()
	const [property, setProperty] = useState("general")
	const id = useParams().id
	const { currentUser } = useAuth()
	const [permissions, setPermissions] = useState<any>({})

	useEffect(() => {
	  if (currentUser?.permissions) {
		setPermissions(currentUser?.permissions)
	  }
	}, [currentUser])
	const PF:any = "https://unifeed.s3.ap-south-1.amazonaws.com/";

    console.log(id)
	
	 useEffect(() => {
		
        const getpropertydetail = async() => {
          const res = await getPropertyDetails(id)
          console.log(res)
          setpropertys(res)
          }
			
		getpropertydetail()
		}, []) 
	return (
    <div>
		    <div>

								{/* <!--begin::Content container--> */}
								
									{/* <!--begin::Form--> */}
									<form id="kt_ecommerce_add_product_form" className="form d-flex flex-column flex-lg-row" /* data-kt-redirect="../../demo1/dist/apps/ecommerce/catalog/products.html" */>
										{/* <!--begin::Aside column--> */}
										<div className="d-flex flex-column gap-7 m-4 my-12 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
											{/* <!--begin::Thumbnail settings--> */}
											<div className="card card-flush ">
												{/* <!--begin::Card header--> */}
												<div className="card-header">
													{/* <!--begin::Card title--> */}
													<div className="card-title">
														<h4 className=' text-muted'>Featured Image</h4>
													</div>
													{/* <div className="card-title">
														<h2>Thumbnail</h2>
													</div> */}
													{/* <!--end::Card title--> */}
												</div>
												{/* <!--end::Card header-->
												<!--begin::Card body--> */}
												<div className="card-body text-center w-100 h-100 pt-0 ">
													<img src={PF+propertys?.productPic} alt="pic" className='object-fit-cover' />

												</div>
												{/* <!--end::Card body--> */}
											</div>
											{/* <!--end::Thumbnail settings-->
											<!--begin::Status--> */}
											<div className="card card-flush py-4">
												{/* <!--begin::Card header--> */}
												<div className="card-header">
													{/* <!--begin::Card title--> */}
													<div className="card-title">
														<h4 className=' text-muted'>Company</h4>
													</div>
													{/* <!--end::Card title-->
													<!--begin::Card toolbar--> */}
													{/* <div className="card-toolbar">
														<div className="rounded-circle bg-success w-15px h-15px" id="kt_ecommerce_add_product_status"></div>
													</div> */}
													{/* <!--begin::Card toolbar--> */}
												</div>
												{/* <!--end::Card header-->
												<!--begin::Card body--> */}
												<div className="card-body pt-7 ">
												<h2 className=' text-center'>{propertys?.name}</h2>
												<p className=' text-center'>{propertys?.descriptor}</p>
													
													
													{/* <!--end::Select2-->
													<!--begin::Description--> */}
													 <div className="text-muted fs-7 items-center align-middle flex justify-center py-4">{propertys?.address}</div> 
													{/* <!--end::Description-->
													<!--begin::Datepicker--> */}
													
													{/* <!--end::Datepicker--> */}
												</div>
											{/* <!--end::Card body--> */}
											</div>
											{/* <!--end::Status-->
											<!--begin::Category & tags--> */}
											
											{/* <!--end::Category & tags-->
											<!--begin::Card widget 6--> */}
											<div className="card  card-flush">
												{/* <!--begin::Header--> */}
												<div className=" bg-blue-400 rounded-md pt-5">
													{/* <!--begin::Title--> */}
													<div className="card-title py-5 px-3  d-flex flex-column">
														{/* <!--begin::Info--> */}
														<div className="d-flex gap-8 align-items-center">
															
															<span className="fs-4hx fw-bold text-white me-2 lh-1 ls-n2">{propertys?.units}</span>
															{/* <!--end::Amount-->
															<!--begin::Badge--> */}
															<span className="text-3xl text-white fs-semibold">
															Number of Units</span>
															{/* <!--end::Badge--> */}
														</div>
														
													</div>
													{/* <!--end::Title--> */}
												</div>
												
											</div>
											
											<div className="card card-flush">
												{/* <!--begin::Header--> */}
												<div className="card-header pt-5">
													{/* <!--begin::Title--> */}
													<div className="card-title d-flex py-5 flex-column">
														{/* <!--begin::Info--> */}
														<div className="d-flex gap-8 align-items-center">
															<div className='flex flex-col'>
															<span className="fs-2hx fw-bold text-blue-400 me-2 lh-1 ls-n2">AED {propertys?.startingprice}</span>
							
															</div>
															{/* <!--end::Amount-->
															<!--begin::Badge--> */}
															<span className=" text-3xl text-blue-400 fs-semibold">
															Average Price</span>
															{/* <!--end::Badge--> */}
														</div>
														
													</div>
													{/* <!--end::Title--> */}
												</div>
												
											</div>
											{/* <!--end::Card widget 6-->
											<!--begin::Template settings--> */}
											
											{/* <!--end::Template settings--> */}
										</div>
										{/*  <!--end::Aside column--> */}

						{/* right side */}				
										<div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
												<div className='d-flex h-55px'>
													<ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap cursor-pointer'>
													
														<li className='nav-item'>
														<div
															className={
															`nav-link text-active-primary me-6  
															${property === "general" ? " active" :""}`} onClick={()=>setProperty("general")
															}>

															General
															</div>
														</li>
														<li className='nav-item'>
														<div
															className={
															`nav-link text-active-primary me-6  
															${property === "clients" ? " active" :""}`} onClick={()=>setProperty("clients")
															}>
															
															Clients
															</div>
														</li>
														{(currentUser?.profileType === 'superadmin' || permissions?.agents?.read) && <li className='nav-item'>
														<div
															className={
															`nav-link text-active-primary me-6  
															${property === "agents" ? " active" :""}`} onClick={()=>setProperty("agents")
															}>
															
														
															Agents
															</div>
														</li>}
														<li className='nav-item'>
														<div
															className={
															`nav-link text-active-primary me-6  
															${property === "documnets" ? " active" :""}`} onClick={()=>setProperty("documents")
															}>
															
														
															Documents
															</div>
														</li>
														
														
													</ul>
												</div>
												{
													property==="general" && (
														<div className='m-2 '>
															<div>
																<h4 className=' text-muted'>Company</h4>
																<p className=' font-semibold '>{propertys?.name}</p>
																<p className='leading-none text-muted '>{propertys?.address}</p>
														    </div>
															<div className='pt-6'>
																<h4 className=' text-muted'>Description</h4>
																<p className=' font-medium'>{propertys?.description}</p>
															</div>
                    {/* contact  */}                                        
																{/* <!--begin::Table widget 14--> */}
																<div className="card card-flush ">
												
												<div className="card-header pt-7">
													
													<h3 className="card-title align-items-start flex-column">
														<span className="card-label fw-bold text-gray-800">Contact</span>
													
													</h3>
													
													
													
												</div>
												
												<div className="card-body pt-6">
													
													<div className="table-responsive">
														
														<table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
															
															<thead>
																<tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
																	<th className="min-w-150px  text-start">Name</th>
																	<th className="min-w-150px ">Official Address</th>
																	<th className="min-w-150px ">Developer Id</th>
																	<th className="min-w-150px  ">Contact</th>
																	<th className="min-w-150px   ">Email</th>
																	
																</tr>
															</thead>
															
															<tbody>
																<tr>
																	<td>
																		<div className="d-flex align-items-center">
																			
																			<div className="d-flex justify-content-start flex-column">
																				<span className="text-indigo-950 fw-bold text-hover-primary ">{propertys?.name}</span>
																				
																			</div>
																		</div>
																	</td>
																	<td className=" ">
																		<span className="text-indigo-950 fw-bold fs-6 truncate">{propertys?.address}</span>
																	</td>
																	<td className=" ">
																		<span className="text-indigo-950 fw-bold fs-6">{propertys?._id}</span>
																	</td>
																	<td className="">
																		
																	<span className="text-indigo-950 fw-bold fs-6">{propertys?.phone}</span>
																		
																	</td>
																	<td className=" ">
																	<span className="text-indigo-950 fw-bold fs-6">{propertys?.email}</span>
																		</td>
																	
																	
																</tr>
															</tbody>
															
														</table>
													</div>
													
												</div>
												
											</div>

               {/*  Image Carousel */}
                                                            <div className=' my-6'>
                                                            
															<div id="kt_carousel_1_carousel" className="carousel carousel-custom slide mt-16" data-bs-ride="carousel" data-bs-interval="4000">
                                                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                                                               <h3 className=' text-muted py-3'>Media</h3>
                                                                <ol className="p-0 m-0 carousel-indicators carousel-indicators-dots">
                                                                    <li data-bs-target="#kt_carousel_1_carousel" data-bs-slide-to="0" className="ms-1 active"></li>
                                                                    <li data-bs-target="#kt_carousel_1_carousel" data-bs-slide-to="1" className="ms-1"></li>
                                                                    <li data-bs-target="#kt_carousel_1_carousel" data-bs-slide-to="2" className="ms-1"></li>
                                                                </ol>
                                                            </div>
                                                            <div className="carousel-inner pt-8">
																{
																	propertys?.mediaPic?.map((item, index)=>(<Fragment key={index}>
																		{index === 0  ? <div className="carousel-item active">
																			<img src={PF+item} alt="" className='object-fit-cover' />
																		</div>
																		: <div className="carousel-item">
																			<img src={PF+item} alt="" className='object-fit-cover' />
																		</div>
																		}
																		</Fragment>
																	))
																}
                                                            </div>
                                                            
                                                        </div>
                                                        </div>
                                                        <br/>

             {/*  <!--begin::Pricing--> */}
														
														{/* <!--end::Pricing--> */}

                                                        <div className='my-4 shadow-md p-6 py-16'>
																<h3 className=' text-muted py-3'>Aminities</h3>
																<ul className="property-features grid grid-cols-3 font-medium gap-6">
																	{
																		propertys?.aminities.map((item,index) => (
																			<li key={index}><i className="p-2 fas fa-check-circle"></i>{item}</li>
																		))
																	}

                                                                </ul>
														    </div>
                                                     </div>
                                                )}
												{
													property==="clients" && (
														<div className='m-3 '>
															<ClientlistbyId/>

												</div>
                                                )}
												{ property==="agents" && (
														<div className='m-3 '>
                                                            <AgentlistbyId/>
															

												</div>
                                                )}
												{
													property==="documents" && (
														<div className='m-3 '>
                                                            <Documents/>
															

												</div>
                                                )}
												
										</div>
									</form> 
			                  </div>
			
						
    </div>
  );
};

export default PropertyDetails;