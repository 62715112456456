import {configureStore} from '@reduxjs/toolkit'
import clientReducer from './client/clientReducer'
import dashboardReducer from './dashboard/dashboardStatsSlice'
import pipelineReducer from './pipeline/pipelineReducer'
import agentReducer from './agent/agentReducer'
import propertyReducer from './property/propertyReducer'
import salesReducer from './sales/salesReducer'
import calendarReducer from './calendar/calendarReducer'
import performanceReducer from './performance/performanceReducer'
const store = configureStore({
  reducer: {
    client: clientReducer,
    dashboard: dashboardReducer,
    pipeline: pipelineReducer,
    agent: agentReducer,
    property: propertyReducer,
    sales: salesReducer,
    calendar: calendarReducer,
    performance: performanceReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
})

export default store
export type RootState = ReturnType<typeof store.getState>
