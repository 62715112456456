/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../../_metronic/helpers'

import {
  setQuoteStatusApprove,
  setQuoteStatusReject,
} from '../core/_requests'

import profile from './profile.png'

type props = {
  fetchAccount: any
  invoice: any
  change: any
}

const PendingQuotes: React.FC<props> = ({fetchAccount, invoice, change}) => {
  const PF = 'https://unifeed.s3.ap-south-1.amazonaws.com/'

  const handleApproved = async (id, index) => {
    await setQuoteStatusApprove(id).then(()=>change())
  }

  const handleReject = async (id, index) => {
    await setQuoteStatusReject(id).then(()=>change())
  }

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  return (
    <div className='card mt-5'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5 flex justify-between'>
        <div>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>New Quotes</span>
          </h3>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3  h-500px overflow-scroll'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}

          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-50px rounded-start'>Id</th>
                <th className='min-w-200px'>Agent</th>
                <th className='min-w-200px'>Property</th>
                <th className='min-w-150px'>Date</th>
                <th className='min-w-100px'>Status</th>
                <th className='min-w-200px rounded-end'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {invoice?.map((user, index) => (
                <tr key={index}>
                  <td>
                    <div className='d-flex align-items-center ps-4'>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {user?.id}
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-50px me-5'>
                        <img
                          src={user?.agent.profilePic ? PF + user?.agent.profilePic : profile}
                          className=''
                          alt=''
                        />
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {user?.agent.fullName}
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {user?.inventory?.name}
                    </a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {formatCreatedAt(user.date)}
                    </a>
                  </td>

                  <td>
                    <span
                      className={`badge fs-7 fw-semibold ${
                        user.status === 'accepted'
                          ? 'badge-light-success'
                          : user.status === 'rejected'
                          ? 'badge-light-danger'
                          : 'badge-light-warning'
                      }`}
                    >
                      {user.status}
                    </span>
                  </td>
                  <td>
                    <div className='d-flex'>
                      {(user.status === 'pending' || user.status === 'rejected') && (
                        <div
                          className='btn btn-sm btn-light-success me-3'
                          onClick={() => handleApproved(user._id, index)}
                        >
                          <KTIcon iconName='check' className='fs-1' />
                        </div>
                      )}
                      {(user.status === 'pending' || user.status === 'accepted') && (
                        <div
                          className='btn btn-sm btn-light-danger'
                          onClick={() => handleReject(user._id, index)}
                        >
                          <KTIcon iconName='cross' className='fs-1' />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>

          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {PendingQuotes}
