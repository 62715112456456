import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import { AgentWrapper } from '../AgentWrapper'
import { AgentDetail } from './AgentDetail'




const chatBreadCrumbs: Array<PageLink> = [
  {
    title: 'Projects',
    path: '/',
    isSeparator: false,
    isActive: false,
  }
]

const AgentPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/dashboard'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Primary</PageTitle>
              <AgentWrapper />
            </>
          }
        />
        <Route
          path='agentDetails/:id'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Details</PageTitle>
              <AgentDetail />
            </>
          }
        />
       
        <Route index element={<Navigate to='/agents/dashboard' />} />
      </Route>
    </Routes>
  )
}

export default AgentPage