/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ChangeEvent, SyntheticEvent, useContext, useEffect, useRef, useState} from 'react'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {AddClient, Client, Filtertype, SearchFilter} from '../core/_models'
import {
  addClient,
  bulkUpdate,
  deleteClientByid,
  deleteManyClientsByid,
  editClient,
  getClients,
  getClientsForExport,
  getTrendingClients,
  getTrendingClientsOfAgent,
  getclientpipecount,
  linkAgentToClient,
} from '../core/_requests'
import {useAuth} from '../../../modules/auth/core/Auth'
import {Dropdown2} from '../../../../_metronic/partials/content/dropdown/Dropdown2'
import {Link} from 'react-router-dom'
import Agentsearch from './Agentsearch'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'
import Pipelinestatus from './Pipelinestatus'
import qs from 'qs'
import Filter from './Filter'
import AgentSearchAssignMany from './AgentSearchAssignMany'
import Flatpickr from 'react-flatpickr'
import {FilterContext} from '../../../App'
import {changeBulkPipeline} from '../../pipeline/core/_requests'
import AgentSearchTransferMany from './AgentSearchTransferMany'
import Swal from 'sweetalert2'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import debounce from 'lodash/debounce'
import {setClientsData, setTotalClientsState} from '../../../redux/client/clientSlice'
import * as XLSX from 'xlsx'
import {Spinner} from 'react-bootstrap'

const Clientlist: React.FC = () => {
  const [excelFile, setExcelFile] = useState(null)
  const {currentUser} = useAuth()
  const isInitialRender = useRef(true)
  const dispatch = useDispatch()
  const [permissions, setPermissions] = useState<any>({})
  const [trending, setTrending] = useState(false)
  const clientDataList = useSelector((state: RootState) => state.client.client.clients)
  const totalClientsData = useSelector((state: RootState) => state.client.client.totalClients)
  const [exportLoading, setExportLoading] = useState(false)

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchNo, setSearchNo] = useState<string>('')
  const fileInputRef = useRef(null)
  const [page, setPage] = useState(1)
  // const [count, setcount] = useState({})
  const [clientSelect, setclientSelect] = useState([])
  const [totalClients, setTotalClients] = useState<number>(
    totalClientsData > 0 ? totalClientsData : 0
  )
  const [dateState, setDateState] = useState<any>({
    startDate: null,
    endDate: null,
  })

  const [loading, setloading] = useState(false)

  const PF: any = 'https://unifeed.s3.ap-south-1.amazonaws.com/'

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    setExcelFile(file)
  }

  const [isLoading, setisLoading] = useState<boolean>(true)
  const [deleteid, setdeleteid] = useState<string>('')
  const [clientId, setclientId] = useState<string>('')
  const [clients, setClients] = useState<Client[]>(clientDataList.length > 0 ? clientDataList : [])
  const [loadingClients, setLoadingClients] = useState(false)
  const [clientCount, setClientCount] = useState<number>(0)
  const [editclient, seteditclient] = useState<AddClient>({
    name: '',
    phone: '',
    email: '',
    profession: '',
    country: '',
    source: '',
    category: '',
  })
  const [formData, setFormData] = useState<AddClient>({
    name: '',
    phone: '',
    email: '',
    profession: '',
    country: '',
    source: '',
    category: 'internal',
    pipeline: currentUser?.pipeline,
    alternatePhone: '',
  })
  const {filter} = useContext(FilterContext)

  let searchFilter = {
    search: '',
    type: '',
    number: '',
    filter: filter,
    page,
  }

  const setSearchFilter = (updateValues: {
    search?: string
    type?: string
    number?: string
    page?: number
    filter?: Partial<Filtertype>
  }): void => {
    searchFilter = {
      ...searchFilter,
      ...(updateValues.search && {search: updateValues.search}),
      ...(updateValues.type && {type: updateValues.type}),
      ...(updateValues.page && {page: updateValues.page}),
      ...(updateValues.number && {number: updateValues.number}),
      ...(updateValues.filter && {
        filter: {
          ...searchFilter.filter,
          ...updateValues.filter,
        },
      }),
    }
  }

  const handlebulkpipelinestatus = async (status) => {
    const data = {
      clientIds: clientSelect.map((item) => ({
        id: item,
        past: clients.find((client) => client._id === item)?.pipelinestatus,
      })),
      present: status,
      pipelineId: currentUser?.pipeline,
    }
    const response = await changeBulkPipeline(data)
    if (response) {
      getClientList().then((res) => {
        if (res) {
          dispatch(setClientsData(res.client))
          dispatch(setTotalClientsState(res.totalClient))
        }
      })
      setclientSelect([])
      Swal.fire({
        icon: 'success',
        title: 'Pipeline Status Updated Successfully',
        showConfirmButton: false,
        timer: 1500,
      })
      const closeModalButton = document.getElementById('closemodalbuttonbulkstatus')
      closeModalButton?.click()
    }
  }

  const [isMobileView, setIsMobileView] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767)
    }

    handleResize() // Initial check
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleTrendingClients = async () => {
    setLoadingClients(true)
    if (!trending) {
      if (permissions?.clients?.admin === false) {
        const res = await getTrendingClientsOfAgent(currentUser?._id)
        setClients(res)
        setClientCount(res.length)
      } else if (currentUser?.profileType === 'superadmin' || permissions?.clients?.admin) {
        const res = await getTrendingClients(currentUser?.company)
        setClients(res)
        setClientCount(res.length)
      }
    } else {
      // getClientList()
      setClients(clientDataList)
    }
    setTrending(!trending)
    setLoadingClients(false)
  }

  const stringifyObject = (obj: SearchFilter): string => {
    const queryString = qs.stringify(
      {
        search: obj.search,
        type: obj.type,
        number: obj.number,
        page: obj.page,
        filter_agent: obj.filter.agent,
        filter_status: obj.filter.status,
        filter_category: obj.filter.category,
        filter_country: obj.filter.country,
        filter_source: obj.filter.source,
        filter_assignedDate:
          obj.filter.assignedDate !== '' ? new Date(obj.filter.assignedDate).toDateString() : '',
        startDate: dateState.startDate,
        endDate: dateState.endDate,
      },
      {encodeValuesOnly: true, skipNulls: true, delimiter: '&'}
    )

    return queryString
  }

  const searchclient = async () => {
    let queryId = ''
    if (permissions?.clients?.admin === false) {
      queryId = currentUser?._id
      setSearchFilter({
        type: 'agent',
        search: searchTerm,
        number: searchNo,
        page: page,
      })
    } else if (currentUser?.profileType === 'superadmin' || permissions?.clients?.admin) {
      queryId = currentUser?.company
      setSearchFilter({
        type: 'company',
        search: searchTerm,
        number: searchNo,
        page: page,
      })
    }

    try {
      const queryString = stringifyObject(searchFilter)
      const res = await getClients(queryId, queryString)
      setClients(res?.client)
      setClientCount(res?.client.length)
      setTotalClients(res?.totalClient)
      setisLoading(false)
      setLoadingClients(false)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const debouncedSearch = debounce(() => {
    setLoadingClients(true)
    searchclient().then(() => {
      setLoadingClients(false)
    })
    setTrending(false)
  }, 500)

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    } else {
      debouncedSearch()
    }

    return () => {
      debouncedSearch.cancel()
    }
  }, [searchTerm, searchNo, permissions?.clients, page, dateState.endDate])

  // const handlePaginationChange = (activePage: number) => {
  //   if (activePage < 1) {
  //     return
  //   }
  //   setPage(activePage)
  //   setLoadingClients(true)
  //   searchclient()
  // }

  const getClientList = async () => {
    setLoadingClients(true)
    let queryId = ''

    if (permissions?.clients?.admin === false) {
      queryId = currentUser?._id
      setSearchFilter({
        type: 'agent',
      })
    } else if (currentUser?.profileType === 'superadmin' || permissions?.clients?.admin) {
      queryId = currentUser?.company
      setSearchFilter({
        type: 'company',
      })
    }

    try {
      if (queryId === '') return null
      const queryString = stringifyObject(searchFilter)
      const res = await getClients(queryId, queryString)
      setClients(res?.client)
      setClientCount(res?.client.length)
      setTotalClients(res?.totalClient)
      setisLoading(false)
      setLoadingClients(false)
      return res
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getclientpipecounts = async () => {
    try {
      const rescount = await getclientpipecount(currentUser?.company)
      // setcount(rescount)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  useEffect(() => {
    if (clientDataList.length === 0) {
      getClientList().then((res) => {
        if (res) {
          dispatch(setClientsData(res.client))
          dispatch(setTotalClientsState(res.totalClient))
        }
      })
      // getclientpipecounts()
      setTrending(false)
    } else {
      setisLoading(false)
    }
  }, [currentUser, permissions?.clients, page, dispatch])

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    e.preventDefault()
    const {name, value} = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }
  const handleChangeEdit = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    e.preventDefault()
    const {name, value} = e.target
    seteditclient({
      ...editclient,
      [name]: value,
    })
  }

  const handledelete = async () => {
    const res = await deleteClientByid(deleteid)
    if (res === 'deleted') {
      const updatedArray = clients.filter((item) => item._id !== deleteid)
      setClients(updatedArray)
      setClientCount(updatedArray.length)
      dispatch(setClientsData(updatedArray))
      dispatch(setTotalClientsState(totalClients - 1))
      const closeModalButton = document.getElementById('closeModalButtondelete')
      if (closeModalButton) {
        closeModalButton.click()
      }
    }
  }

  const handledeleteMany = async () => {
    const data = {
      clientIds: clientSelect,
    }
    const response = await deleteManyClientsByid(data)
    if (response === 'deleted') {
      window.location.reload()
      // getClientList()
      setclientSelect([])
      const closeModalButton = document.getElementById('closeModalButtondeleteMany')
      if (closeModalButton) {
        closeModalButton.click()
      }
    }
  }

  const handleExportClients = async () => {
    setExportLoading(true)
    const res = await getClientsForExport(clientSelect)
    console.log(res)
    const data = res.map((item, index) => {
      return {
        ...item,
        _id: index + 1,
        agent: item?.agent?.map((currElem) => currElem?.fullName).join(', '),
        notes: item?.notes?.map((note) => note?.text)?.join(', '),
      }
    })
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Clients')
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})

    const blob = new Blob([excelBuffer], {type: 'application/octet-stream'})
    const fileName = 'client-list.xlsx'

    // Create download link
    const downloadLink = document.createElement('a')
    downloadLink.href = URL.createObjectURL(blob)
    downloadLink.setAttribute('download', fileName)

    // Trigger download
    downloadLink.click()
    setExportLoading(false)
  }

  const handleSubmit = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault()

    try {
      await addClient(formData, currentUser?.company).then(async (res) => {
        if (permissions?.clients?.admin === false) {
          await linkAgentToClient({clientId: res._id, agentId: currentUser?._id}).then((resp) => {
            getClientList().then((res) => {
              if (res) {
                dispatch(setClientsData(res.client))
                dispatch(setTotalClientsState(res.totalClient))
              }
            })
          })
        } else {
          getClientList().then((res) => {
            if (res) {
              dispatch(setClientsData(res.client))
              dispatch(setTotalClientsState(res.totalClient))
            }
          })
        }
      })
      // console.log(response)

      // const updatedClient = [...clients, response]
      // setClients(updatedClient)
      setFormData({
        name: '',
        phone: '',
        email: '',
        profession: '',
        country: '',
        pipeline: currentUser?.pipeline, // Assuming user?.pipeline returns a string or undefined
        alternatePhone: '',
      })

      const closeModalButton = document.getElementById('closeModalButton')
      if (closeModalButton) {
        closeModalButton.click()
      }
    } catch (error) {
      // Handle error as needed
      console.error('Error adding client:', error)
    }
  }

  const handleSubmitedit = async (e: SyntheticEvent): Promise<void> => {
    e.preventDefault()

    try {
      await editClient(editclient).then((response) => {
        getClientList().then((res) => {
          console.log(res)
          if (res) {
            dispatch(setClientsData(res.client))
            dispatch(setTotalClientsState(res.totalClient))
          }
        })
      })

      seteditclient({
        name: '',
        phone: '',
        email: '',
        profession: '',
        country: '',
        // Assuming user?.pipeline returns a string or undefined
      })

      const closeModalButton = document.getElementById('closeModalButtonedit')
      if (closeModalButton) {
        closeModalButton.click()
      }
    } catch (error) {
      // Handle error as needed
      console.error('Error adding client:', error)
    }
  }

  const handleTrigger = () => {
    getClientList().then((res) => {
      if (res) {
        dispatch(setClientsData(res.client))
        dispatch(setTotalClientsState(res.totalClient))
      }
    })
  }

  const handleAutoDial = (phoneNumber: Client) => {
    if (!currentUser?.app?.telecom) {
      const url = `tel:${phoneNumber?.phone}`
      window.open(url, null, 'toolbar=no,menubar=no')
      return
    }
    const url = `/telecom/dialer/${phoneNumber._id}`
    const tabName = 'myTab'

    // Check if the tab is already open
    const existingTab = window.open('', tabName)

    if (existingTab) {
      // Tab is already open, reuse it
      existingTab.location.href = url
    } else {
      // Open a new tab and store the URL in localStorage

      // Open the new tab
      const newTab = window.open(url, tabName)
    }
  }

  const maskPhoneNumber = (phone) => {
    if (!phone) return ''
    const visiblePart = phone.slice(0, phone.length - 4)
    return `${visiblePart}XXXX`
  }

  const handleUpload = async () => {
    setloading(true)
    if (!excelFile) {
      console.error('No file selected')
      return
    }
    const formData = new FormData()
    formData.append('excelFile', excelFile)

    try {
      await bulkUpdate(formData, currentUser?.company).then((response) => {
        Swal.fire({
          icon: 'success',
          title: 'Bulk Upload Successful!',
          text: `Total Success: ${response.successCount}\nTotal Failures: ${response.failedCount}`,
        }).then(() => {
          if (response.errors && response.errors.length > 0) {
            // Format errors for display
            const errorMessages = response.errors
              .map((error) => {
                return `Row ${error.row}: ${error.errors.join(', ')}`
              })
              .join('\n')

            const clipboardButton = document.createElement('button')
            clipboardButton.id = 'copy-to-clipboard'
            clipboardButton.className = 'swal2-confirm swal2-styled'
            clipboardButton.innerText = 'Copy to Clipboard'

            // Show error message
            Swal.fire({
              icon: 'error',
              title: 'Bulk Upload Errors',
              html: `<pre>${errorMessages}</pre>`,
              confirmButtonText: 'OK',
              showCancelButton: false,
              showCloseButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              showConfirmButton: false,
              footer: clipboardButton.outerHTML, // Using outerHTML to get the button HTML as a string
            })

            // Event listener for copying error response to clipboard
            const copyToClipboardButton = document.getElementById('copy-to-clipboard')
            if (copyToClipboardButton) {
              copyToClipboardButton.addEventListener('click', () => {
                navigator.clipboard.writeText(errorMessages)
                Swal.fire({
                  icon: 'success',
                  title: 'Copied to Clipboard!',
                  showConfirmButton: false,
                  timer: 1500,
                })
              })
            }
          }
        })

        getClientList().then((res) => {
          if (res) {
            dispatch(setClientsData(res.client))
            dispatch(setTotalClientsState(res.totalClient))
          }
        })
        setloading(false)
        setExcelFile(null)
      })
    } catch (error) {
      console.error('Error:', error)
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while processing the bulk upload.',
      })
    }
  }

  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  const handlereset = async () => {
    // setSearchFilter(initialSearchFilter)
    // getClientList()
    setClients(clientDataList)
    setClientCount(clientDataList.length)
    setTotalClients(totalClientsData)
    setPage(1)
  }

  const loadingClientState = useSelector((state: RootState) => state.client.client.loading)

  if (loadingClientState) {
    return (
      <div className='d-flex justify-content-center'>
        <div className='loader'></div>
      </div>
    )
  }

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <div className={`card h-md-50 mb-5 mb-xl-10 overflow-scroll`}>
          <div className='card-header border-0 pt-5'>
            {isMobileView ? (
              <>
                <div className='d-flex card-title align-items-start'>
                  <div className='d-flex align-items-center position-relative me-5'>
                    <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                    <input
                      type='text'
                      data-kt-user-table-filter='search'
                      className='form-control form-control-solid w-250px ps-14'
                      placeholder='Search client'
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>

                  <Filter
                    searchFilter={searchFilter}
                    setSearchFilter={setSearchFilter}
                    handlereset={handlereset}
                    getClientList={getClientList}
                  />
                </div>
                <div className='d-flex align-items-center position-relative me-5'>
                  <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                  <input
                    type='text'
                    data-kt-user-table-filter='search'
                    className='form-control form-control-solid w-250px ps-14'
                    placeholder='Search No.'
                    value={searchNo}
                    onChange={(e) => setSearchNo(e.target.value)}
                  />
                </div>

                <div className='d-flex align-items-left position-relative me-5'>
                  <Flatpickr
                    value={dateState.date}
                    onChange={([startDate, endDate]) => {
                      setDateState({startDate, endDate})
                    }}
                    options={{
                      mode: 'range',
                    }}
                    className='form-control form-control-solid w-250px mx-2 my-4'
                    placeholder='Pick date'
                  />
                </div>
              </>
            ) : (
              <div className=' d-flex flex-row  card-title align-items-start'>
                <div className='d-flex align-items-center position-relative me-5'>
                  <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                  <input
                    type='text'
                    data-kt-user-table-filter='search'
                    className='form-control form-control-solid w-175px ps-14'
                    placeholder='Search client'
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className='d-flex align-items-center position-relative me-5'>
                  <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                  <input
                    type='text'
                    data-kt-user-table-filter='search'
                    className='form-control form-control-solid w-175px ps-14'
                    placeholder='Search No.'
                    value={searchNo}
                    onChange={(e) => setSearchNo(e.target.value)}
                  />
                </div>
                <Flatpickr
                  value={dateState.date}
                  onChange={([startDate, endDate]) => {
                    setDateState({startDate, endDate})
                  }}
                  options={{
                    mode: 'range',
                    // showMonths: 2,
                    // static: true,
                  }}
                  className='form-control form-control-solid w-175px mx-2'
                  placeholder='Pick date'
                />
                <Filter
                  searchFilter={searchFilter}
                  setSearchFilter={setSearchFilter}
                  handlereset={handlereset}
                  getClientList={getClientList}
                />
                <button
                  type='button'
                  className={`btn btn-light-info ${trending ? 'active ' : ' '}`}
                  onClick={handleTrendingClients}
                >
                  Trending Clients
                </button>
              </div>
            )}
            {clientSelect.length > 0 ? (
              <div className='d-flex flex-row align-content-end gap-3'>
                <p className='fs-5 fw-semibold my-auto'>Selected Clients: {clientSelect.length}</p>
                {(permissions?.clients?.delete || currentUser?.profileType === 'superadmin') && (
                  <div className='card-toolbar'>
                    <div
                      className='btn btn-sm btn-light-danger'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_20'
                    >
                      Delete Clients
                    </div>
                  </div>
                )}
                <div className='card-toolbar'>
                  <div
                    className='btn btn-sm btn-light-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_30'
                  >
                    Assign Agents
                  </div>
                </div>
                {clientSelect.length > 0 && (
                  <div className='card-toolbar'>
                    <div
                      className='btn btn-sm btn-light-info'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_40'
                    >
                      Transfer Agent
                    </div>
                  </div>
                )}
                {clientSelect.length > 1 && (
                  <div className='card-toolbar'>
                    <div
                      className='btn btn-sm btn-light-primary'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_change_status_bulk'
                    >
                      Change Status
                    </div>
                  </div>
                )}
                {(currentUser?.hierarchy === 1 || currentUser?.profileType === 'superadmin') &&
                  clientSelect.length > 0 && (
                    <div className='card-toolbar'>
                      <div className='btn btn-sm btn-light-warning' onClick={handleExportClients}>
                        {exportLoading ? (
                          <>
                            <Spinner animation='border' role='status' />
                          </>
                        ) : (
                          'Export Clients'
                        )}
                      </div>
                    </div>
                  )}
              </div>
            ) : (
              <div className='d-flex flex-row  align-content-end'>
                <div
                  className='card-toolbar me-5'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_1'
                >
                  <a className='btn btn-sm btn-light-primary'>
                    <KTIcon iconName='plus' className='fs-2' />
                    Add Client
                  </a>
                </div>

                {!excelFile ? (
                  <div className='card-toolbar'>
                    <a className='btn btn-sm btn-light-danger' onClick={handleButtonClick}>
                      <KTIcon iconName='plus' className='fs-2' />
                      Import Client
                    </a>
                  </div>
                ) : loading ? (
                  <div className='card-toolbar'>
                    <a className='btn btn-sm btn-light-warning'>
                      <KTIcon iconName='plus' className='fs-2' />
                      Loading...
                    </a>
                  </div>
                ) : (
                  <div className='card-toolbar'>
                    <a className='btn btn-sm btn-light-success' onClick={handleUpload}>
                      <KTIcon iconName='plus' className='fs-2' />
                      Upload Client
                    </a>
                  </div>
                )}

                <input
                  type='file'
                  accept='.xlsx, .xls'
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  style={{display: 'none'}}
                />
              </div>
            )}
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive-xxl'>
              {/* begin::Table */}
              <table className='table align-middle gs-0 gy-4 table-row-bordered'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    {(currentUser?.profileType === 'superadmin' ||
                      permissions?.clients?.update ||
                      permissions?.agents?.assign) && (
                      <th className='ps-4 min-w-25px rounded-start'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value='1'
                            data-kt-check='true'
                            data-kt-check-target='.widget-13-check'
                            onChange={(e) => {
                              if (e.target.checked) {
                                setclientSelect(clients.map((item) => item._id))
                              } else {
                                setclientSelect([])
                              }
                            }}
                          />
                        </div>
                      </th>
                    )}
                    {isMobileView ? (
                      <>
                        <th
                          className={`min-w-25px ${
                            currentUser?.profileType === 'superadmin' ||
                            permissions?.clients?.update ||
                            permissions?.agents?.assign
                              ? ''
                              : 'ps-4 rounded-start'
                          }`}
                        >
                          No.
                        </th>
                        <th className='min-w-150px'>Name</th>
                        <th className='min-w-150px'>Agent</th>
                        <th className='min-w-100px'>Status</th>
                      </>
                    ) : (
                      <>
                        <th
                          className={`min-w-25px ${
                            currentUser?.profileType === 'superadmin' ||
                            permissions?.clients?.update ||
                            permissions?.agents?.assign
                              ? ''
                              : 'ps-4 rounded-start'
                          }`}
                        >
                          No.
                        </th>
                        <th className='min-w-150px'>Name</th>
                        <th className='min-w-150px'>Email</th>
                        <th className='min-w-150px'>Phone</th>
                        {/* <th className='min-w-100px'>Source</th> */}
                        <th className='min-w-100px'>Country</th>
                        <th className='min-w-100px'>Category</th>
                        <th className='min-w-150px'>Agent</th>
                        <th className='min-w-100px'>Status</th>

                        <th className='min-w-150px  rounded-end'></th>
                      </>
                    )}
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {loadingClients ? (
                    <>
                      <tr>
                        <td colSpan={isMobileView ? 4 : 9} className='text-center'>
                          <div className='d-flex justify-content-center'>
                            <div className='loader'></div>
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : (
                    clients.map((product: Client | undefined, idx) => (
                      <tr key={product?._id?.toString()}>
                        {(currentUser?.profileType === 'superadmin' ||
                          permissions?.clients?.update ||
                          permissions?.agents?.assign) && (
                          <td className='ps-4'>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input
                                className='form-check-input widget-13-check'
                                type='checkbox'
                                checked={clientSelect.includes(product?._id.toString())}
                                value={product?._id.toString()}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setclientSelect([...clientSelect, product?._id.toString()])
                                  } else {
                                    setclientSelect(
                                      clientSelect.filter(
                                        (item) => item !== product?._id.toString()
                                      )
                                    )
                                  }
                                }}
                              />
                            </div>
                          </td>
                        )}
                        {isMobileView ? (
                          <>
                            <td>
                              <span
                                className={`text-dark fw-bold text-hover-primary d-block mb-1 fs-6 ${
                                  currentUser?.profileType === 'superadmin' ||
                                  permissions?.clients?.update
                                    ? ''
                                    : 'ps-5'
                                }`}
                              >
                                {trending
                                  ? clientCount - idx
                                  : totalClients - idx - (page - 1) * 100}
                                {/* ((clientCount* page) * page) - idx} */}
                              </span>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='symbol symbol-50px'>
                                  {/* <img
                        src={toAbsoluteUrl('/media/stock/600x400/img-26.jpg')}
                        className=''
                        alt=''
                      /> */}
                                </div>
                                <Link
                                  to={`/clients/clientDetails/${product._id}`}
                                  className=' client_field cursor-pointer '
                                >
                                  <div className='d-flex justify-content-start flex-column'>
                                    <div className='text-dark fw-bold text-hover-primary mb-1'>
                                      {product?.name}
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {product?.agent && product.agent[0]?.fullName ? (
                                    product?.agent.length > 1 ? (
                                      <div
                                        className='symbol-group symbol-hover'
                                        data-kt-menu-trigger='click'
                                        data-kt-menu-placement='bottom-end'
                                        data-kt-menu-flip='top-end'
                                      >
                                        {product.agent.map((item, index) => (
                                          <div
                                            key={index}
                                            className='symbol symbol-circle symbol-30px '
                                          >
                                            {item.profilePic !== '' ? (
                                              <img
                                                src={PF + item.profilePic}
                                                alt=''
                                                className='object-fit-cover'
                                              />
                                            ) : (
                                              <div className='symbol-label fs-6 fw-semibold bg-danger text-inverse-danger'>
                                                {item.fullName.charAt(0).toUpperCase()}
                                              </div>
                                            )}
                                          </div>
                                        ))}

                                        <div
                                          className='menu menu-sub menu-sub-dropdown w-200px w-md-200px p-5'
                                          data-kt-menu='true'
                                          style={{zIndex: 1000000}}
                                        >
                                          {product.agent.map((item, index) => (
                                            <div className='p-3' key={index}>
                                              <div className='symbol symbol-30px me-2 symbol-square'>
                                                {item?.profilePic ? (
                                                  <img
                                                    src={PF + item.profilePic}
                                                    className='object-fit-cover'
                                                    alt=''
                                                  />
                                                ) : (
                                                  <div className='symbol-label fs-6 fw-semibold bg-danger text-inverse-danger'>
                                                    {item.fullName.charAt(0).toUpperCase()}
                                                  </div>
                                                )}
                                              </div>
                                              <a
                                                href='#'
                                                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                                              >
                                                {item.fullName}
                                              </a>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    ) : (
                                      <div>
                                        <div className='symbol symbol-30px me-2 symbol-square'>
                                          {product?.agent && product.agent[0]?.profilePic ? (
                                            <img
                                              src={PF + product.agent[0].profilePic}
                                              className='object-fit-cover'
                                              alt=''
                                            />
                                          ) : (
                                            <div className='symbol-label fs-6 fw-semibold bg-danger text-inverse-danger'>
                                              {product.agent[0].fullName.charAt(0).toUpperCase()}
                                            </div>
                                          )}
                                        </div>
                                        <a
                                          href='#'
                                          className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                                        >
                                          {product?.agent[0]?.fullName}
                                        </a>
                                      </div>
                                    )
                                  ) : (
                                    <a
                                      href='#'
                                      className='text-danger fw-bold text-hover-primary mb-1 fs-6'
                                    >
                                      Not Assigned
                                    </a>
                                  )}
                                </div>
                              </div>
                              <Dropdown2 />
                            </td>
                            <td>
                              <Pipelinestatus
                                pipelinestatus={product?.pipelinestatus}
                                clientId={product?._id}
                                handleTrigger={handleTrigger}
                              />
                            </td>

                            <td className=''>
                              {(currentUser?.profileType === 'superadmin' ||
                                permissions?.agents?.assign) && (
                                <a
                                  href='#'
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_modal_4'
                                  onClick={() => setclientId(product._id)}
                                >
                                  <KTIcon iconName='switch' className='fs-3' />
                                </a>
                              )}

                              {(currentUser?.profileType === 'superadmin' ||
                                permissions?.clients?.update ||
                                (permissions?.clients?.admin === false &&
                                  (product.category === 'Internal' ||
                                    product.category === 'internal'))) && (
                                <a
                                  href='#'
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_modal_3'
                                  onClick={() => seteditclient(product)}
                                >
                                  <KTIcon iconName='pencil' className='fs-3' />
                                </a>
                              )}
                              {(currentUser?.profileType === 'superadmin' ||
                                permissions?.clients?.delete ||
                                (permissions?.clients?.admin &&
                                  (product.category === 'Internal' ||
                                    product.category === 'internal'))) && (
                                <a
                                  href='#'
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_modal_2'
                                  onClick={() => setdeleteid(product._id)}
                                >
                                  <KTIcon iconName='trash' className='fs-3' />
                                </a>
                              )}
                            </td>
                          </>
                        ) : (
                          <>
                            <td>
                              <span
                                className={`text-dark fw-bold text-hover-primary d-block mb-1 fs-6 ${
                                  currentUser?.profileType === 'superadmin' ||
                                  permissions?.clients?.update
                                    ? ''
                                    : 'ps-5'
                                }`}
                              >
                                {/* {idx + 1} */}
                                {trending
                                  ? clientCount - idx
                                  : // clientCount * page - idx
                                    totalClients - idx - (page - 1) * 100}
                                {/* {(clientCount* page) - idx} */}
                              </span>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='symbol symbol-50px'>
                                  {/* <img
                        src={toAbsoluteUrl('/media/stock/600x400/img-26.jpg')}
                        className=''
                        alt=''
                      /> */}
                                </div>
                                <Link
                                  to={`/clients/clientDetails/${product._id}`}
                                  className=' client_field cursor-pointer '
                                >
                                  <div className='d-flex justify-content-start flex-column'>
                                    <div className='text-dark fw-bold text-hover-primary mb-1'>
                                      {product?.name}
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </td>

                            <td>
                              <a
                                href={`mailto:${product?.email}`}
                                className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                              >
                                {product?.email}
                              </a>
                            </td>
                            <td>
                              <a
                                className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                                href='#'
                                onClick={() => handleAutoDial(product)}
                              >
                                {maskPhoneNumber(product?.phone)}
                              </a>
                            </td>
                            <td>
                              <a className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-truncate'>
                                {product?.country}
                              </a>
                            </td>
                            {/* <td>
                        <span className={`text-dark fw-bold text-hover-primary d-block mb-1 fs-6`}>
                        {product?.source}
                        </span>
                      </td> */}
                            <td>
                              <span
                                className={`badge fs-6 fw-semibold badge-light-dark capitalize`}
                              >
                                {product?.category === 'old'
                                  ? 'Gray'
                                  : product?.category === 'new'
                                  ? 'Blue'
                                  : product?.category}
                              </span>
                            </td>
                            <td>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {product?.agent && product.agent[0]?.fullName ? (
                                    product?.agent.length > 1 ? (
                                      <div
                                        className='symbol-group symbol-hover'
                                        data-kt-menu-trigger='click'
                                        data-kt-menu-placement='bottom-end'
                                        data-kt-menu-flip='top-end'
                                      >
                                        {product.agent.map((item, index) => (
                                          <div
                                            key={index}
                                            className='symbol symbol-circle symbol-30px '
                                          >
                                            {item.profilePic !== '' ? (
                                              <img
                                                src={PF + item.profilePic}
                                                alt=''
                                                className='object-fit-cover'
                                              />
                                            ) : (
                                              <div className='symbol-label fs-6 fw-semibold bg-danger text-inverse-danger'>
                                                {item.fullName.charAt(0).toUpperCase()}
                                              </div>
                                            )}
                                          </div>
                                        ))}

                                        <div
                                          className='menu menu-sub menu-sub-dropdown w-200px w-md-200px p-5'
                                          data-kt-menu='true'
                                          style={{zIndex: 1000000}}
                                        >
                                          {product.agent.map((item, index) => (
                                            <div className='p-3' key={index}>
                                              <div className='symbol symbol-30px me-2 symbol-square'>
                                                {item?.profilePic ? (
                                                  <img
                                                    src={PF + item.profilePic}
                                                    className='object-fit-cover'
                                                    alt=''
                                                  />
                                                ) : (
                                                  <div className='symbol-label fs-6 fw-semibold bg-danger text-inverse-danger'>
                                                    {item.fullName.charAt(0).toUpperCase()}
                                                  </div>
                                                )}
                                              </div>
                                              <a
                                                href='#'
                                                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                                              >
                                                {item.fullName}
                                              </a>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    ) : (
                                      <div>
                                        <div className='symbol symbol-30px me-2 symbol-square'>
                                          {product?.agent && product.agent[0]?.profilePic ? (
                                            <img
                                              src={PF + product.agent[0].profilePic}
                                              className='object-fit-cover'
                                              alt=''
                                            />
                                          ) : (
                                            <div className='symbol-label fs-6 fw-semibold bg-danger text-inverse-danger'>
                                              {product.agent[0].fullName.charAt(0).toUpperCase()}
                                            </div>
                                          )}
                                        </div>
                                        <a
                                          href='#'
                                          className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                                        >
                                          {product?.agent[0]?.fullName}
                                        </a>
                                      </div>
                                    )
                                  ) : (
                                    <a
                                      href='#'
                                      className='text-danger fw-bold text-hover-primary mb-1 fs-6'
                                    >
                                      Not Assigned
                                    </a>
                                  )}
                                </div>
                              </div>
                              <Dropdown2 />
                            </td>
                            <td>
                              <Pipelinestatus
                                pipelinestatus={product?.pipelinestatus}
                                clientId={product?._id}
                                handleTrigger={handleTrigger}
                              />
                            </td>

                            <td className=''>
                              {(currentUser?.profileType === 'superadmin' ||
                                permissions?.agents?.assign) && (
                                <a
                                  href='#'
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_modal_4'
                                  onClick={() => setclientId(product._id)}
                                >
                                  <KTIcon iconName='switch' className='fs-3' />
                                </a>
                              )}

                              {(currentUser?.profileType === 'superadmin' ||
                                permissions?.clients?.update ||
                                (permissions?.clients?.admin === false &&
                                  (product.category === 'Internal' ||
                                    product.category === 'internal'))) && (
                                <a
                                  href='#'
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_modal_3'
                                  onClick={() => seteditclient(product)}
                                >
                                  <KTIcon iconName='pencil' className='fs-3' />
                                </a>
                              )}
                              {(currentUser?.profileType === 'superadmin' ||
                                permissions?.clients?.delete ||
                                (permissions?.clients?.admin === false &&
                                  (product.category === 'Internal' ||
                                    product.category === 'internal'))) && (
                                <a
                                  href='#'
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_modal_2'
                                  onClick={() => setdeleteid(product._id)}
                                >
                                  <KTIcon iconName='trash' className='fs-3' />
                                </a>
                              )}
                            </td>
                          </>
                        )}
                      </tr>
                    ))
                  )}
                </tbody>
                {/* end::Table body */}
              </table>
              <div className='w-100 d-flex justify-end gap-4 p-2'>
                <button
                  className={`btn btn-sm btn-primary ${page === 1 ? 'disabled' : ''}`}
                  onClick={() => setPage(page - 1)}
                >
                  Previous
                </button>
                <div className='d-flex gap-3 justify-center align-middle'>
                  {page > 1 && <h3 className={`btn btn-sm btn-secondary disabled`}>{page - 1}</h3>}
                  {page > 0 && <h3 className={`btn btn-sm btn-danger disabled`}>{page}</h3>}
                  {page > 1 && <h3 className={`btn btn-sm btn-secondary disabled`}>{page + 1}</h3>}
                </div>
                <button className={`btn btn-sm btn-primary`} onClick={() => setPage(page + 1)}>
                  Next
                </button>
              </div>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}

          {/* Modal for add client */}
        </div>
      )}

      <Agentsearch clientId={clientId} handleTrigger={handleTrigger} />
      <AgentSearchAssignMany clientSelect={clientSelect} handleTrigger={handleTrigger} />
      <AgentSearchTransferMany clientSelect={clientSelect} handleTrigger={handleTrigger} />

      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add Client</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <form>
                <div className='row rowwidthclient'>
                  <div className='form-group mb-3 col-md-12'>
                    <label>Full Name</label>
                    <input
                      type='text'
                      name='name'
                      value={formData.name}
                      onChange={handleChange}
                      className='form-control'
                    />
                  </div>
                  <div className='form-group mb-3 col-md-12'>
                    <label>Phone No</label>
                    <input
                      type='text'
                      name='phone'
                      value={formData.phone}
                      onChange={handleChange}
                      className='form-control'
                    />
                  </div>
                  <div className='form-group mb-3 col-md-12'>
                    <label>Alternate Phone No</label>
                    <input
                      type='text'
                      name='alternatePhone'
                      placeholder='Optional'
                      value={formData.alternatePhone}
                      onChange={handleChange}
                      className='form-control'
                    />
                  </div>
                  <div className='form-group mb-3 col-md-12'>
                    <label>Email</label>
                    <input
                      type='text'
                      name='email'
                      value={formData.email}
                      onChange={handleChange}
                      className='form-control'
                    />
                  </div>
                  <div className='form-group mb-3 col-md-6'>
                    <label>Profession</label>
                    <input
                      type='text'
                      name='profession'
                      value={formData.profession}
                      onChange={handleChange}
                      className='form-control'
                    />
                  </div>
                  <div className='form-group mb-3 col-md-6'>
                    <label>Country</label>
                    <input
                      type='text'
                      name='country'
                      value={formData.country}
                      onChange={handleChange}
                      className='form-control'
                    />
                  </div>

                  <div className='form-group mb-3 col-md-6'>
                    <label>Source</label>
                    <select
                      name='source'
                      value={formData.source}
                      onChange={handleChange}
                      className='form-control'
                    >
                      <option value=''>Other</option>
                      <option value='primary'>Primary</option>
                      <option value='secondary'>Secondary</option>
                    </select>
                  </div>

                  <div className='form-group mb-3 col-md-6'>
                    <label>Category</label>
                    {permissions?.clients?.admin === false ? (
                      <select
                        name='category'
                        value={formData.category}
                        onChange={handleChange}
                        className='form-control'
                      >
                        <option value=''>Choose...</option>
                        <option value={'external'}>External</option>
                        <option value={'events'}>Events</option>
                        <option value={'old'}>Gray</option>
                        <option value={'new'}>Blue</option>
                      </select>
                    ) : (
                      <select
                        name='category'
                        value={formData.category}
                        onChange={handleChange}
                        className='form-control'
                      >
                        <option value='internal' disabled>
                          Internal
                        </option>
                      </select>
                    )}
                  </div>
                </div>
              </form>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton'
              >
                Close
              </button>
              <button type='button' className='btn btn-primary' onClick={handleSubmit}>
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_modal_3'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Edit Client</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <form>
                <div className='row rowwidthclient'>
                  <div className='form-group mb-3 col-md-12'>
                    <label>Full Name</label>
                    <input
                      type='text'
                      name='name'
                      value={editclient.name}
                      onChange={handleChangeEdit}
                      className='form-control'
                    />
                  </div>
                  <div className='form-group mb-3 col-md-12'>
                    <label>Phone No</label>
                    <input
                      type='text'
                      name='phone'
                      value={editclient.phone}
                      onChange={handleChangeEdit}
                      className='form-control'
                    />
                  </div>
                  <div className='form-group mb-3 col-md-12'>
                    <label>Alternate Phone No</label>
                    <input
                      type='text'
                      name='alternatePhone'
                      placeholder='Optional'
                      value={editclient.alternatePhone}
                      onChange={handleChangeEdit}
                      className='form-control'
                    />
                  </div>
                  <div className='form-group mb-3 col-md-12'>
                    <label>Email</label>
                    <input
                      type='text'
                      name='email'
                      value={editclient.email}
                      onChange={handleChangeEdit}
                      className='form-control'
                    />
                  </div>
                  <div className='form-group mb-3 col-md-6'>
                    <label>Profession</label>
                    <input
                      type='text'
                      name='profession'
                      value={editclient.profession}
                      onChange={handleChangeEdit}
                      className='form-control'
                    />
                  </div>
                  <div className='form-group mb-3 col-md-6'>
                    <label>Country</label>
                    <input
                      type='text'
                      name='country'
                      value={editclient.country}
                      onChange={handleChangeEdit}
                      className='form-control'
                    />
                  </div>

                  <div className='form-group mb-3 col-md-6'>
                    <label>Source</label>
                    <select
                      name='source'
                      value={formData.source}
                      onChange={handleChange}
                      className='form-control'
                    >
                      <option value=''>Other</option>
                      <option value='primary'>Primary</option>
                      <option value='secondary'>Secondary</option>
                    </select>
                  </div>

                  <div className='form-group mb-3 col-md-6'>
                    <label>Category</label>
                    <select
                      name='category'
                      value={editclient.category}
                      onChange={handleChangeEdit}
                      className='form-control'
                    >
                      <option value=''>Choose...</option>
                      <option value={'internal'}>Internal</option>
                      <option value={'external'}>External</option>
                      <option value={'events'}>Events</option>
                      <option value={'old'}>Gray</option>
                      <option value={'new'}>Blue</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButtonedit'
              >
                Close
              </button>
              <button type='button' className='btn btn-primary' onClick={handleSubmitedit}>
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Delete</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <h3> Are you sure you want to delete</h3>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButtondelete'
              >
                No
              </button>
              <button type='button' className='btn btn-primary' onClick={handledelete}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_20'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Delete</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <h3> Are you sure you want to delete</h3>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButtondeleteMany'
              >
                No
              </button>
              <button type='button' className='btn btn-primary' onClick={handledeleteMany}>
                Delete
              </button>
            </div>
          </div>
        </div>

        {/* kt_modal_change_status_bulk */}
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_change_status_bulk'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Change Bulk Status</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <div className='d-flex flex-column gap-3 p-5'>
                <div
                  className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${'badge-light-dark'} `}
                  onClick={() => handlebulkpipelinestatus('new')}
                >
                  new
                </div>
                <div
                  className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${'badge-light-dark'} `}
                  onClick={() => handlebulkpipelinestatus('prospect')}
                >
                  prospect
                </div>
                <div
                  className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${'badge-light-dark'} `}
                  onClick={() => handlebulkpipelinestatus('viewing')}
                >
                  viewing
                </div>
                <div
                  className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${'badge-light-dark'} `}
                  onClick={() => handlebulkpipelinestatus('close')}
                >
                  close
                </div>
                <div
                  className={`badge fs-7 p-3 mb-3 fw-semibold cursor-pointer ${' badge-light-dark'} `}
                  onClick={() => handlebulkpipelinestatus('reject')}
                >
                  reject
                </div>
                <div
                  className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${'badge-light-dark'} `}
                  onClick={() => handlebulkpipelinestatus('noanswer')}
                >
                  noanswer
                </div>
                <div
                  className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${'badge-light-dark'} `}
                  onClick={() => handlebulkpipelinestatus('callback')}
                >
                  callback
                </div>
                <div
                  className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${'badge-light-dark'} `}
                  onClick={() => handlebulkpipelinestatus('pending')}
                >
                  pending
                </div>
                <div
                  className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${'badge-light-dark'} `}
                  onClick={() => handlebulkpipelinestatus('junk')}
                >
                  junk
                </div>
              </div>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closemodalbuttonbulkstatus'
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {Clientlist}
