import React, {useEffect, useState} from 'react'
import {getProfitAndLoss} from '../../core/_requests'
import {useAuth} from '../../../../modules/auth'

const ProfitAndLoss = () => {
  const {currentUser} = useAuth()
  const [data, setData] = useState([])
  const [pnl, setPnl] = useState({
    gross: 0,
    operating: 0,
    net: 0,
    income: 0,
    expense: 0,
    goods: 0,
  })

  const fetchData = async () => {
    const res = await getProfitAndLoss(currentUser?.company)
    console.log(res)
    setData(res)
    res?.map((item) => {
      if (item['type'] === 'income') {
        setPnl((prev) => ({...prev, gross: item['total'], income: item['total']}))
      }
      if (item['type'] === 'costofgoodssold') {
        setPnl((prev) => ({
          ...prev,
          gross: prev.gross - item['total'],
          goods: item['total'],
          net: prev.gross - item['total'],
        }))
      }
      if (item['type'] === 'expense') {
        setPnl((prev) => ({
          ...prev,
          operating: item['total'],
          expense: item['total'],
          net: prev.gross - item['total'],
        }))
      }
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header d-flex align-items-center'>
          <h2 className='fs-1'>Profit and Loss</h2>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-150px rounded-start'>Account</th>
                  <th className='min-w-150px'>Account Code</th>
                  <th className='min-w-150px'>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='ps-4 text-dark fw-bolder mb-1 fs-6'>Operating Income</td>
                </tr>
                {data?.map((item) => {
                  if (item['type'] === 'income') {
                    return item['accountName']?.map((account: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td className='ps-8 text-primary fw-bold mb-1 fs-6'>{account.name}</td>
                          <td className='ps-4 text-primary fw-bold mb-1 fs-6'>
                            {account.accountCode}
                          </td>
                          <td className='ps-4 text-primary fw-bold mb-1 fs-6'>{account.total}</td>
                        </tr>
                      )
                    })
                  }
                  return null
                })}
                <tr>
                  <td className='ps-4 text-dark fw-bold mb-1 fs-6'>Total for Operating Income</td>
                  <td></td>
                  <td className='ps-4 text-primary fw-bold mb-1 fs-6'>
                    {/* {data?.map((item) => {
                      if (item['type'] === 'income' || item['type'] === 'otherincome') {
                        return item['total']
                      }
                      return null
                    })} */}
                    {pnl.income}
                  </td>
                </tr>
                <tr>
                  <td className='ps-4 text-dark fw-bolder mb-1 fs-6'>Cost of Goods Sold</td>
                </tr>
                {data?.map((item) => {
                  if (item['type'] === 'costofgoodssold') {
                    return item['accountName']?.map((account: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td className='ps-8 text-primary fw-bold mb-1 fs-6'>{account.name}</td>
                          <td className='ps-4 text-primary fw-bold mb-1 fs-6'>
                            {account.accountCode}
                          </td>
                          <td className='ps-4 text-primary fw-bold mb-1 fs-6'>{account.total}</td>
                        </tr>
                      )
                    })
                  }
                  return null
                })}
                <tr>
                  <td className='ps-4 text-dark fw-bold mb-1 fs-6'>Total for Cost of Goods Sold</td>
                  <td></td>
                  <td className='ps-4 text-primary fw-bold mb-1 fs-6'>
                    {/* {data?.map((item) => {
                      if (item['type'] === 'costofgoodssold') {
                        return item['total']
                      }
                      return null
                    })} */}
                    {pnl.goods}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>Gross Profit</td>
                  <td className='ps-4 text-primary fw-bold mb-1 fs-6'>
                    {/* total is income total minus goods total */}
                    {pnl.gross}
                  </td>
                </tr>
                <tr>
                  <td className='ps-4 text-dark fw-bolder mb-1 fs-6'>Operating Expense</td>
                </tr>
                {data?.map((item) => {
                  if (item['type'] === 'expense' || item['type'] === 'otherexpense') {
                    return item['accountName']?.map((account: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td className='ps-8 text-primary fw-bold mb-1 fs-6'>{account.name}</td>
                          <td className='ps-4 text-primary fw-bold mb-1 fs-6'>
                            {account.accountCode}
                          </td>
                          <td className='ps-4 text-primary fw-bold mb-1 fs-6'>{account.total}</td>
                        </tr>
                      )
                    })
                  }
                  return null
                })}
                <tr>
                  <td className='ps-4 text-dark fw-bold mb-1 fs-6'>Total for Operating Expense</td>
                  <td></td>
                  <td className='ps-4 text-primary fw-bold mb-1 fs-6'>
                    {/* {data?.map((item) => {
                      if (item['type'] === 'expense') {
                        return item['total']
                      }
                      return null
                    })} */}
                    {pnl.expense}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>Operating Profit</td>
                  <td className='ps-4 text-primary fw-bold mb-1 fs-6'>{pnl.gross - pnl.expense}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Net Profit/Loss</td>
                  <td className='ps-4 text-primary fw-bold mb-1 fs-6'>{pnl.gross - pnl.expense}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfitAndLoss
