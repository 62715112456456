import React, { useEffect, useState } from 'react'
import { getall } from '../core/_requests';
import { KTIcon } from '../../../../_metronic/helpers';
import { Link, useParams } from 'react-router-dom';
import { useAuth } from '../../../modules/auth';

function Dealhistory() {
    const [data, setdata] = useState<any>([])
    const dealid = useParams().id
    const {currentUser} = useAuth()
    const [permissions, setPermissions] = useState<any>({})

    useEffect(() => {
        if (currentUser?.permissions) {
          setPermissions(currentUser?.permissions)
        }
    }, [currentUser])
    
    const formatCreatedAt = (createdAt: string | undefined) => {
        if (!createdAt) return 'N/A';
        const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        });
        return formattedDate;
      };

      const getdata = async() => {
      await getall( currentUser?.company,dealid).then((res) =>{
            setdata(res)
            console.log(res)
        }
         
        )
      }

      useEffect(() => {
       getdata()
      }, [])
          
  return (
    <div className="card card-flush py-4 flex-row-fluid">
       
        <div className="card-header">
            <div className="card-title">
                <h2>Deal History</h2>
            </div>
            {!(currentUser?.profileType === "superadmin" || permissions?.sales?.admin) && <div className='d-flex flex-row'>
            <div className='card-toolbar me-5'>
                <Link to={`/sales/invoicetodeal/add/${dealid}`} className='btn btn-sm btn-light-primary'>
            <KTIcon iconName='plus' className='fs-2' />
            Add Invoice
          </Link>
        </div>
        <div className='card-toolbar'>
                <Link to={`/sales/quotetodeal/add/${dealid}`} className='btn btn-sm btn-light-info'>
            <KTIcon iconName='plus' className='fs-2' />
            Add Quote
          </Link>
        </div>
            </div>}
        </div>
       
       
        <div className="card-body pt-0">
            <div className="table-responsive">
              
                <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                    <thead>
                        <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                            <th className="min-w-100px">Date Added</th>
                            <th className="min-w-175px">Property</th>
                            <th className="min-w-50px">Bill Id</th>
                            <th className="min-w-50px">Bill Type</th>
                            <th className="min-w-50px">Propose Price</th>
                            <th className="min-w-100px">Action</th>
                        </tr>
                    </thead>
                   
                    <tbody className="fw-semibold text-gray-600">
                      
                    {
                        data.map((item,index)=> (
                            <tr>
                            <td>{formatCreatedAt(item.createdAt)}</td>
                            <td>{item.inventory?.name}</td>
                            <td>{item.id}</td>
                            <td>
                               
                                <div className={`${item.type==="quote"?"badge badge-light-info":"badge badge-light-success"}`}>{item.type}</div>
                               
                            </td>
                            
                            <td>{item.total}</td>
                            <td>
                            <Link to={`/sales/${item.type}/details/${item.id}`} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3' >
                            <i className="fa-solid fa-folder-open"></i>
                            </Link>
                            <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm' >
                            <i className="fas fa-print fs-3"></i>
                            </a>
                           
                            </td>
                        </tr>
                        ))
                    }
                    </tbody>
                </table>
              
            </div>
        </div>
       
    </div>
  )
}

export default Dealhistory