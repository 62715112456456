import React, {useEffect, useState} from 'react'
import {getAccountById, updateAccount} from '../core/_requests'
import {useNavigate, useParams} from 'react-router-dom'

const EditAccount = () => {
  const params = useParams()
  const id = params.id
  const [inactive, setInactive] = useState(false)
  const [formData, setFormData] = useState({
    accountName: '',
    subAccount: false,
    parentAccount: '',
    accountCode: '',
    accountNumber: '',
    currency: 'AED',
    description: '',
    watchList: false,
  })
  const [subAccount] = useState(false)
  const [parentAccountToggle, setParentAccountToggle] = useState(false)
  const [accountNumberToggle] = useState(false)
  const navigate = useNavigate()

  const handleChange = (e) => {
    const {name, value} = e.target
    console.log(value)
    const updatedFormData = {...formData}
    updatedFormData[name] = value
    setFormData(updatedFormData)
  }

  const handleChecked = (e) => {
    const {name, checked} = e.target
    const updatedFormData = {...formData}
    updatedFormData[name] = checked
    setFormData(updatedFormData)
    if (name === 'subAccount' && checked === true) {
      setParentAccountToggle(true)
    } else {
      setParentAccountToggle(false)
      setFormData((prev) => {
        const updatedData = {...prev}
        updatedData.parentAccount = ''
        return updatedData
      })
    }
  }
  //   console.log(formData.accountType)

  const handleEdit = async (e) => {
    e.preventDefault()
    const data = {
      ...formData,
      status: inactive ? false : true,
    }
    const res = await updateAccount(id, data)
    if (res) {
      navigate('/account/chartofaccounts')
    }
  }

  const fetchAccount = async () => {
    const res = await getAccountById(id)
    if (res) {
      if (res.fixed) {
        navigate('/account/chartofaccounts')
      }
      setFormData({
        accountName: res.accountName,
        subAccount: res.subAccount,
        parentAccount: res.parentAccount,
        accountCode: res.accountCode,
        accountNumber: res.accountNumber,
        currency: res.currency,
        description: res.description,
        watchList: res.watchList,
      })
      setInactive(!res.status)
    }
  }

  useEffect(() => {
    fetchAccount()
  }, [])

  return (
    <div className='card p-4'>
      <div className='card-header pt-4'>
        <h3>Edit Account</h3>
      </div>
      <div className='card-body'>
        <form>
          <div className='d-flex flex-col gap-2'>
            {/* toggle switch for mark as inactive */}
            <div>
              <input
                type='checkbox'
                name='status'
                id='status'
                checked={inactive}
                onChange={(e) => setInactive(e.target.checked)}
                className='form-check-input w-20px h-20px me-4'
              />
              <label htmlFor='status' className='form-check-label'>
                Mark as Inactive
              </label>
            </div>

            <div className=''>
              <p>
                <span className='required'>Account Name</span>
              </p>
              <input
                type='text'
                name='accountName'
                value={formData?.accountName}
                className='form-control form-control-solid w-50'
                onChange={handleChange}
              />
            </div>
            {subAccount && (
              <>
                <div>
                  <input
                    type='checkbox'
                    name='subAccount'
                    className='form-check-input w-20px h-20px me-4'
                    onChange={handleChecked}
                  />
                  <span>Make this a sub-account</span>
                </div>
                {parentAccountToggle && (
                  <div className=''>
                    <p>
                      <span className='required'>Parent Account</span>
                    </p>
                    <input
                      type='text'
                      name='parentAccount'
                      value={formData.parentAccount}
                      className='form-control form-control-solid w-50'
                      onChange={handleChange}
                    />
                  </div>
                )}
              </>
            )}
            <div className=''>
              <p>Account Code</p>
              <input
                type='text'
                name='accountCode'
                value={formData.accountCode}
                onChange={handleChange}
                className='form-control form-control-solid w-50'
              />
            </div>
            {accountNumberToggle && (
              <>
                <div>
                  <p>Account Number</p>
                  <input
                    type='text'
                    name='accountNumber'
                    value={formData.accountNumber}
                    onChange={handleChange}
                    className='form-control form-control-solid w-50'
                  />
                </div>
                {/* <div>
                  <p>Currency</p>
                  <select name="" id=""></select>
                </div> */}
              </>
            )}
            <div className=''>
              <p>Description</p>
              <input
                type='text'
                name='description'
                value={formData.description}
                onChange={handleChange}
                className='form-control form-control-solid w-50'
              />
            </div>
            {!accountNumberToggle && (
              <div className=' my-2'>
                <input
                  type='checkbox'
                  name='watchList'
                  onChange={handleChecked}
                  className='form-check-input w-20px h-20px me-4'
                />
                <span>Add to watchlist on my Dashoard</span>
              </div>
            )}
          </div>
          <button type='button' className='btn btn-warning my-3' onClick={handleEdit}>
            Edit
          </button>
        </form>
      </div>
    </div>
  )
}

export default EditAccount
