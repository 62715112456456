import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { changeOrderStatus, getDealsbyids } from '../core/_requests'

function Dealsdetailsheader() {
    const Id = useParams().id
    const [deal, setdeal] = useState<any>()

    const getdealsbyid = async() => {
        const res = await getDealsbyids(Id)
        setdeal(res)
        console.log(res)
    }
    useEffect(() => {
        getdealsbyid()
    }, [])

    const formatCreatedAt = (createdAt: string | undefined) => {
        if (!createdAt) return 'N/A';
        const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        });
        return formattedDate;
      };
    
    const handleStatusChange = async (status: string) => {
        if (status === deal?.[0]?.status) return
        await changeOrderStatus(deal?.[0]?.status, status, Id).then((res) =>
            getdealsbyid()
        )
      }
    
  return (
    <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
 
    <div className="card card-flush py-4 flex-row-fluid">

        <div className="card-header">
            <div className="card-title">
                <h2>Deal Details </h2>
            </div>
        </div>
     
        <div className="card-body pt-0">
            <div className="table-responsive">
            
                <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                    <tbody className="fw-semibold text-gray-600">
                        <tr>
                            <td className="text-muted">
                                <div className="d-flex align-items-center">
                                <i className="ki-duotone ki-calendar fs-2 me-2">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>Order Id</div>
                            </td>
                            <td className="fw-bold text-end">#{deal?.[0].id}</td>
                        </tr>
                        <tr>
                            <td className="text-muted">
                                <div className="d-flex align-items-center">
                                <i className="ki-duotone ki-calendar fs-2 me-2">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>Date Added</div>
                            </td>
                            <td className="fw-bold text-end">{formatCreatedAt(deal?.[0].createdAt)}</td>
                        </tr>
                       {deal?.[0]?.project?.name && <tr>
                            <td className="text-muted">
                                <div className="d-flex align-items-center">
                                <i className="ki-duotone ki-home fs-2 me-2">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>Project</div>
                            </td>
                            <td className="fw-bold text-end">{deal?.[0]?.project?.name}</td>
                        </tr>}
                        <tr>
                            <td className="text-muted">
                                <div className="d-flex align-items-center">
                                <i className="ki-duotone ki-wallet fs-2 me-2">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                    <span className="path4"></span>
                                </i>Status</div>
                            </td>
                            {/* <td className="fw-bold text-end">{deal?.[0].status}
                           </td> */}
                           <td
                      className={`cursor-pointer badge fw-bold d-flex justify-end text-end ${
                        deal?.[0]?.status === 'open' ? 'badge-light-primary' : 'badge-light-success'
                      }`}
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                    >
                      {deal?.[0]?.status}
                    </td>
                    <div
                      className='menu menu-sub menu-sub-dropdown w-150px w-md-150px p-5'
                      data-kt-menu='true'
                      style={{zIndex: 1000000}}
                    >
                      <div
                        className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${
                          deal?.[0]?.status === 'open' ? 'badge-primary' : 'badge-light-dark'
                        } `}
                        onClick={() => handleStatusChange('open')}
                      >
                        open
                      </div>
                      <div
                        className={`badge  fs-7 p-3 mb-3 fw-semibold cursor-pointer ${
                          deal?.[0]?.status === 'close' ? 'badge-success' : 'badge-light-dark'
                        } `}
                        onClick={() => handleStatusChange('close')}
                      >
                        close
                      </div>
                    </div>
                        </tr>
                     
                    </tbody>
                </table>
              
            </div>
        </div>
       
    </div>
  
    <div className="card card-flush py-4 flex-row-fluid">
       
        <div className="card-header">
            <div className="card-title">
                <h2>Customer Details</h2>
            </div>
        </div>
        
        <div className="card-body pt-0">
            <div className="table-responsive">
               
                <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                    <tbody className="fw-semibold text-gray-600">
                        <tr>
                            <td className="text-muted">
                                <div className="d-flex align-items-center">
                                <i className="ki-duotone ki-profile-circle fs-2 me-2">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                </i>Customer</div>
                            </td>
                            <td className="fw-bold text-end">
                                <div className="d-flex align-items-center justify-content-end">
                                
                                    <div className="symbol symbol-circle symbol-25px overflow-hidden me-3">
                                        <a href="../../demo1/dist/apps/ecommerce/customers/details.html">
                                           
                                        </a>
                                    </div>
                                  
                                    <a href="../../demo1/dist/apps/ecommerce/customers/details.html" className="text-gray-600 text-hover-primary">{deal?.[0]?.client?.name}</a>
                                  
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-muted">
                                <div className="d-flex align-items-center">
                                <i className="ki-duotone ki-sms fs-2 me-2">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>Email</div>
                            </td>
                            <td className="fw-bold text-end">
                                <a href="../../demo1/dist/apps/user-management/users/view.html" className="text-gray-600 text-hover-primary">{deal?.[0].client?.email}</a>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-muted">
                                <div className="d-flex align-items-center">
                                <i className="ki-duotone ki-phone fs-2 me-2">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>Phone</div>
                            </td>
                            <td className="fw-bold text-end">{deal?.[0].client?.phone}</td>
                        </tr>
                    </tbody>
                </table>
            
            </div>
        </div>
       
    </div>
   
    <div className="card card-flush py-4 flex-row-fluid">
     
        <div className="card-header">
            <div className="card-title">
                <h2>Agent Details</h2>
            </div>
        </div>
        
        <div className="card-body pt-0">
            <div className="table-responsive">
               
                <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                    <tbody className="fw-semibold text-gray-600">
                        <tr>
                            <td className="text-muted">
                                <div className="d-flex align-items-center">
                                <i className="ki-duotone ki-devices fs-2 me-2">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                    <span className="path4"></span>
                                    <span className="path5"></span>
                                </i>Name
                                <span className="ms-1" data-bs-toggle="tooltip" title="View the invoice generated by this order.">
                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span></div>
                            </td>
                            <td className="fw-bold text-end">
                                <a href="../../demo1/dist/apps/invoices/view/invoice-3.html" className="text-gray-600 text-hover-primary">{deal?.[0].agent.fullName}</a>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-muted">
                                <div className="d-flex align-items-center">
                                <i className="ki-duotone ki-truck fs-2 me-2">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                    <span className="path4"></span>
                                    <span className="path5"></span>
                                </i>Email
                                <span className="ms-1" data-bs-toggle="tooltip" title="View the shipping manifest generated by this order.">
                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span></div>
                            </td>
                            <td className="fw-bold text-end">
                                <a href="#" className="text-gray-600 text-hover-primary">{deal?.[0].agent.email}</a>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-muted">
                                <div className="d-flex align-items-center">
                                <i className="ki-duotone ki-discount fs-2 me-2">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>Phone
                                <span className="ms-1" data-bs-toggle="tooltip" title="Reward value earned by customer when purchasing this order">
                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span></div>
                            </td>
                            <td className="fw-bold text-end">{deal?.[0].agent.phone}</td>
                        </tr>
                    </tbody>
                </table>
            
            </div>
        </div>
       
    </div>
    
</div>
  )
}

export default Dealsdetailsheader