/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Agent } from '../core/_models'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import { getAgent, getclientstatusdata } from '../core/_requests'
import { Link } from 'react-router-dom';
import { useAuth } from '../../../modules/auth';
import profile from './profile.png'
import {useSelector, useDispatch} from 'react-redux'
import { RootState } from '../../../redux/store';
import { setAgentsData } from '../../../redux/agent/agentSlice';




const Agentlist: React.FC = () => {
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const agentsData = useSelector((state: RootState) => state.agent.agent.agents)
  const PF:any = "https://unifeed.s3.ap-south-1.amazonaws.com/";
  const [agents, setagents] = useState<Agent[]>([]); 
  const [clients, setclients] = useState({
    "new" : 0,
    "prospect" : 0,
    "viewing" : 0,
    "close" : 0,
    "reject" : 0,
    "noanswer" : 0,
    "callback" : 0,
    "pending" : 0,
    "junk" : 0,
  }); 
  const [agentid, setagentid] = useState<string>(''); 
  const [searchTerm, setSearchTerm] = useState<string>('');

  const getClientList = async () => {
    try {
      const res = await getAgent(currentUser?.company);
      setagents(res);
      // setisLoading(false)
      // console.log(res)
      dispatch(setAgentsData(res))
      // setagentsearch(res)
   
    } catch (error) {
      console.error('Error fetching client list:', error);
    }
  };

  const getClientInfo = async () => {
    try {
      const res = await getclientstatusdata(agentid);
      console.log(res)
      setclients(res);
    } catch (error) {
      console.error('Error fetching client list:', error);
    }
  };
  
  useEffect(() => {
    if (agentsData.length === 0)
      getClientList();
    else 
      setagents(agentsData)
  }, [agentsData]);

  useEffect(() => {
    if (agentid === '' || agentid === undefined)
      return;
    setLoading(true)
    getClientInfo().then(() => setLoading(false))
  }, [agentid]);

  useEffect(() => {
    setagents(
      agentsData.filter((client) =>
        client.fullName && client.fullName.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm]);
  

  const loadingData = useSelector((state: RootState) => state.agent.agent.loading)

  if (loadingData){
    return (
      <div className='d-flex justify-content-center'>
        <div className="loader"></div>
      </div>
    );
  }
   

  return (
    <>
        <>
           <span className='card-label fw-bold fs-3 mb-1'>Agent List</span>
    <div className={'card h-md-50 mb-5 mb-xl-10'}>
   
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        
        <div className='card-title align-items-start flex-column'>
        <div className=' d-flex flex-row  card-title align-items-start'>
        <div className='d-flex align-items-center position-relative me-5'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder='Search user'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
         </div>
      
        </div>

        </div>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
        
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
        
                <th className='min-w-150px'>Name</th>
                <th className='min-w-100px'>Employee Id</th>
                <th className='min-w-150px'>Info</th>
                <th className='min-w-30px text-center'>Total Clients</th>
                <th className='min-w-30px text-center'>No. of Meetings</th>
                <th className='min-w-30px text-center'>No. of Deals</th>
                {/* <th className='min-w-150px'>Progress</th> */}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {
            agents.map((product: any) => (
              <tr key={product?._id?.toString()}>
              
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
             
                      {
                   ( product?.profilePic === "") ?(
                      <img src={`${profile}`} alt='Metornic' />
                   ):(
                    <img src={PF + product?.profilePic} alt='' className='object-fit-cover'/>
                   )
                  }
                    </div>
                    <Link to={`/agents/agentDetails/${product._id}`} className=' client_field cursor-pointer '>
                    <div className='d-flex justify-content-start flex-column'>
                      <div className='text-dark fw-bold text-hover-primary fs-6'>
                        {product?.fullName}
                      </div>
                     
                    </div>
                    </Link>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    {product?.employeeid}
                  </a>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    {product?.email}
                  </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    {product?.phone}
                  </span>
                </td>

                <td  data-bs-toggle = "modal"
                data-bs-target = "#kt_modal_4" onClick={()=>{
                  setagentid(product._id)
                }}
                className='text-center'
                >
                <div className="symbol px-2 symbol-30px cursor-pointer border me-2 border-dark bg-light-dark" title="Total Clients">
                  <div className="symbol-label fs-5 fw-semibold text-dark ">{product?.totalClient}</div>
                  </div>
                </td>

                <td className='text-center'>
                <div className="symbol px-2 symbol-30px cursor-pointer border me-2 border-danger bg-light-danger" title="No. of meetings done">
                  <div className="symbol-label fs-5 fw-semibold text-danger ">{product?.totalMeetingsDone}</div>
                  </div>
                </td>

                <td className='text-center'>
                <div className="symbol px-2 symbol-30px cursor-pointer border me-2 border-success bg-light-success" title="No. of meetings done">
                  <div className="symbol-label fs-5 fw-semibold text-success ">{product?.totalDealsClosed}</div>
                  </div>
                </td>

                {/* <td className='text-end'  data-bs-toggle="modal" data-bs-target="#kt_modal_4" onClick={()=>setagentid(product._id)}>
                <div className='d-flex'>
                <div className="symbol px-2 symbol-30px cursor-pointer border me-2 border-dark bg-light-dark" title="Total Clients">
                  <div className="symbol-label fs-5 fw-semibold text-dark ">{product?.statusCounts?.totalClient}</div>
              </div>
                <div className="symbol symbol-30px cursor-pointer border me-2 border-primary bg-light-primary px-2"
                data-toggle="tooltip" title="New">
                  <div className="symbol-label fs-5 fw-semibold  text-primary ">{product?.statusCounts?.new}</div>
              </div>
                <div className="symbol symbol-30px cursor-pointer border me-2 border-warning bg-light-warning px-2"
                data-toggle="tooltip" title="Prospect">
                  <div className="symbol-label fs-5 fw-semibold  text-warning ">{product?.statusCounts?.prospect}</div>
              </div>
                <div className="symbol symbol-30px cursor-pointer border me-2 bg-light-info px-2 border-info"
                data-toggle="tooltip" title="Viewing">
                  <div className="symbol-label fs-5 fw-semibold  text-info ">{product?.statusCounts?.viewing}</div>
              </div>
                <div className="symbol symbol-30px cursor-pointer border me-2 border-success bg-light-success px-2"
                data-toggle="tooltip" title="Close">
                  <div className="symbol-label fs-5 fw-semibold  text-success ">{product?.statusCounts?.close}</div>
              </div>
                <div className="symbol symbol-30px cursor-pointer border me-2 border-danger bg-light-danger  px-2"
                data-toggle="tooltip" title="Reject">
                  <div className="symbol-label fs-5 fw-semibold text-danger ">{product?.statusCounts?.reject}</div>
              </div>
                <div className="symbol symbol-30px cursor-pointer bg-light-dark px-2 border me-2 border-dark"
                data-toggle="tooltip" title="No Answer">
                  <div className="symbol-label fs-5 fw-semibold  text-dark ">{product?.statusCounts?.noanswer}</div>
              </div>
                <div className="symbol symbol-30px cursor-pointer bg-light-dark px-2 border me-2 border-dark"
                data-toggle="tooltip" title="Callback">
                  <div className="symbol-label fs-5 fw-semibold text-dark ">{product?.statusCounts?.callback}</div>
              </div>
                <div className="symbol symbol-30px cursor-pointer bg-light-dark px-2 border me-2 border-dark"
                data-toggle="tooltip" title="Pending">
                  <div className="symbol-label fs-5 fw-semibold text-dark ">{product?.statusCounts?.pending}</div>
              </div>
                <div className="symbol symbol-30px cursor-pointer bg-light-dark px-2 border me-2 border-dark"
                data-toggle="tooltip" title="Junk">
                  <div className="symbol-label fs-5 fw-semibold text-dark ">{product?.statusCounts?.junk}</div>
              </div>

                  </div>
                </td> */}

                {/* <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>50%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-primary'
                        role='progressbar'
                        style={{width: '50%'}}
                      ></div>
                    </div>
                  </div>
                </td> */}
                
              </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
    </>
  


<div className="modal fade" tabIndex={-1} id="kt_modal_4">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Clients Status</h5>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon svg-icon-2x"
          />
        </div>
      </div>

      <div className="modal-body">

    <div className='card card-xl-stretch mb-xl-8' >
      {/* begin::Body */}
      <div className='card-header align-items-center border-0 mt-4'>
        {
          loading ? (
            <div className='card-toolbar'>
            <span className='badge badge-light-primary'>Loading...</span>
          </div>
          ):clients.new === 0 && clients.prospect === 0 && clients.viewing === 0 && clients.close === 0 && clients.reject === 0 && clients.noanswer === 0 && clients.callback === 0 && clients.pending === 0 && clients.junk === 0 ? (
            <div className='card-toolbar'>
            <span className='badge badge-light-primary py-4 fs-2'>No Clients Found</span>
          </div>
          ):(
            <div className='d-flex flex-column gap-8 w-100'>
            <span className='badge badge-light-primary fs-2 w-100 py-4 px-2'>New : 
            <span className='ms-auto me-10'>
              {clients.new}</span>
              </span>
            <span className='badge badge-light-warning fs-2 w-100 py-4 px-2'>Prospect :
            <span className='ms-auto me-10'>
              {clients.prospect}</span>
              </span>
            <span className='badge badge-light-info fs-2 w-100 py-4 px-2'>Viewing :
            <span className='ms-auto me-10'>
              {clients.viewing}</span>
              </span>
            <span className='badge badge-light-success fs-2 w-100 py-4 px-2'>Close :
            <span className='ms-auto me-10'>
              {clients.close}</span>
              </span>
            <span className='badge badge-light-danger fs-2 w-100 py-4 px-2'>Reject :
            <span className='ms-auto me-10'>
              {clients.reject}</span>
              </span>
            <span className='badge badge-light-dark fs-2 w-100 py-4 px-2'>No Answer :
            <span className='ms-auto me-10'>
              {clients.noanswer}</span>
              </span>
            <span className='badge badge-light-dark fs-2 w-100 py-4 px-2'>Callback :
            <span className='ms-auto me-10'>
              {clients.callback}</span>
              </span>
            <span className='badge badge-light-dark fs-2 w-100 py-4 px-2'>Pending :
            <span className='ms-auto me-10'>
              {clients.pending}</span>
              </span>
            <span className='badge badge-light-dark fs-2 w-100 py-4 px-2'>Junk :
            <span className='ms-auto me-10'>
              {clients.junk}</span>
              </span>
          </div>
          )
        
        }
        </div>
      </div>
      
    </div>
</div>
</div>
  </div>
  
    
      

</>
    
    )
  }
export {Agentlist}
