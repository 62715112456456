import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Dashboard from '../sales/pages/Dashboard'
import Dealsdetails from './pages/Dealsdetails'
import Invoice from './components/Invoice'
import Createinvoice from './components/Createinvoice'
import Quote from './components/Quote'
import Createquote from './components/CreateQuote'
import Receipt from './components/Receipt'
import QuotesList from './components/QuotesList'
import InvoiceList from './components/InvoiceList'
import CreateQuotetodeal from './components/CreateQuotetodeal'
import Createinvoicetodeal from './components/Createinvoicetodeal'
import SalesStatus from './pages/SalesStatus'
import EditQuote from './components/EditQuote'
import EditInvoice from './components/EditInvoice'


const chatBreadCrumbs: Array<PageLink> = [
  {
    title: 'Projects',
    path: '/',
    isSeparator: false,
    isActive: false,
  }
]

const Salespage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='deals'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Dashboard</PageTitle>
            <Dashboard/>
            </>
          }
        />
        <Route
          path='dealdetails/:id'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Dashboard</PageTitle>
            <Dealsdetails/>
            </>
          }
        />
        <Route
          path='invoice/details/:id'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Dashboard</PageTitle>
            <Invoice/>
            </>
          }
        />
       
        <Route
          path='quote/details/:id'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Dashboard</PageTitle>
            <Quote/>
            </>
          }
        />
        <Route
          path='receipt/details/:id'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Dashboard</PageTitle>
            <Receipt/>
            </>
          }
        />
        <Route
          path='invoicetodeal/add/:id'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Dashboard</PageTitle>
            <Createinvoicetodeal/>
            </>
          }
        />
        <Route
          path='invoice/add'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Dashboard</PageTitle>
            <Createinvoice/>
            </>
          }
        />
        <Route
          path='invoice/edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Dashboard</PageTitle>
            <EditInvoice/>
            </>
          }
        />
        <Route
          path='quote/add'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Dashboard</PageTitle>
            <Createquote/>
            </>
          }
        />
        <Route
          path='quote/edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Dashboard</PageTitle>
            <EditQuote/>
            </>
          }
        />
        <Route
          path='quotetodeal/add/:id'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Dashboard</PageTitle>
            <CreateQuotetodeal/>
            </>
          }
        />
        <Route
          path='quotes'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Dashboard</PageTitle>
            <QuotesList/>
            </>
          }
        />
        <Route
          path='invoices'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Dashboard</PageTitle>
            <InvoiceList/>
            </>
          }
        />
        <Route
          path='status'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Status</PageTitle>
            <SalesStatus/>
            </>
          }
        />
        <Route index element={<Navigate to='/sales/deals' />} />
      </Route>
    </Routes>
  )
}

export default Salespage
