import axios, {AxiosResponse} from 'axios'
import { Client } from '../../client/core/_models';


const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_USERS_URL = `${API_URL}/client/trending`
const GET_CLIENT_URL = `${API_URL}/client/agenttrending/`
const GET_CLIENT_COUNT = `${API_URL}/client/total/`
const GET_CLIENT_COUNTAGENT = `${API_URL}/client/totalagent/`
const GET_SALE_COUNT = `${API_URL}/sales/totalsales/`
const GET_REVENUE_COUNT = `${API_URL}/sales/totalrevenue/`
const GET_SALE_COUNTAGENT = `${API_URL}/sales/totalagentsales/`
const GET_REVENUE_COUNTAGENT = `${API_URL}/sales/totalagentrevenue/`
const GET_EXPENSE_COUNT = `${API_URL}/account/totalcompany/`
const GET_EXPENSE_COUNTAGENT = `${API_URL}/account/totalagent/`

export const getClient = async(companyid):Promise<Array<Client>> => {
    try {
    const response : AxiosResponse<Client[]> = await axios.get<Array<Client>>(`${GET_USERS_URL}/${companyid}`)
    return response.data
} catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
}


export const getClientagentId = async(id:string):Promise<Array<Client>> => {
    try {
    const response : AxiosResponse<Client[]> = await axios.get<Array<Client>>(`${GET_CLIENT_URL}${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  }

export const getclientcount = async(companyid:string) => {
    try {
    const response : AxiosResponse<any> = await axios.get(`${GET_CLIENT_COUNT}${companyid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  }
export const getclientcountagent = async(agentid:string) => {
    try {
    const response : AxiosResponse<any> = await axios.get(`${GET_CLIENT_COUNTAGENT}${agentid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  }

export const getexpensecount = async(companyid:string) => {
    try {
    const response : AxiosResponse<any> = await axios.get(`${GET_EXPENSE_COUNT}${companyid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  }

export const getexpensecountagent = async(agentid:string) => {
    try {
    const response : AxiosResponse<any> = await axios.get(`${GET_EXPENSE_COUNTAGENT}${agentid}`)

    return response.data
  } catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  }

export const gettotalsales = async(companyid:string) => {
    try {
    const response : AxiosResponse<any> = await axios.get(`${GET_SALE_COUNT}${companyid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  }

export const gettotalsalesagent = async(agentid:string) => {
    try {
    const response : AxiosResponse<any> = await axios.get(`${GET_SALE_COUNTAGENT}${agentid}`)

    return response.data
  } catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  }

  export const gettotalrevenue = async(companyid:string) => {
    try {
    const response : AxiosResponse<any> = await axios.get(`${GET_REVENUE_COUNT}${companyid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  }

export const gettotalrevenueagent = async(agentid:string) => {
    try {
    const response : AxiosResponse<any> = await axios.get(`${GET_REVENUE_COUNTAGENT}${agentid}`)

    return response.data
  } catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  }

  