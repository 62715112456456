import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  quotes: [],
  loading: true,
}

export const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    setQuotes: (state, action) => {
      state.quotes = action.payload
      state.loading = false
    },
  },
})

export const {setQuotes} = quoteSlice.actions
export default quoteSlice.reducer
