import React, {ChangeEvent, useEffect, useRef, useState} from 'react'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {Card} from 'react-bootstrap'
import {DragDropContext, Droppable, Draggable, DropResult} from 'react-beautiful-dnd'
import {useAuth} from '../../../modules/auth'
import {useParams} from 'react-router-dom'
import {
  addTask,
  changeTaskStatus,
  deleteTaskById,
  editTask,
  getProjectById,
  getTaskById,
} from '../core/_requests'
import DatePicker from 'react-datepicker'
import AgentListForTask from './AgentListForTask'
import Swal from 'sweetalert2'

interface TaskItem {
  id: string
  title: string
  items: any[] // Adjust the type according to your data structure
}

const ProjectDetails: React.FC = () => {
  const {currentUser} = useAuth()
  const params = useParams()
  const projectid = params.projectid
  const [data, setData] = useState<TaskItem[]>([])
  const [task, setTask] = useState<any>(true)
  const [agentids, setagentids] = useState<string[]>([])
  const [assiopen, setassiopen] = useState<boolean>(false)
  const [projectName, setProjectName] = useState<string>('')
  const [showModal, setShowModal] = useState(false)
  const [currentTaskData, setCurrentTaskData] = useState<any>({})
  const editTodo = useRef(null)
  const [editTaskId, setEditTaskId] = useState<string>('')
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    due: new Date(),
  })
  const [permissions, setPermissions] = useState<any>({})

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  useEffect(() => {
    if (task) {
      const initialData: TaskItem[] = [
        {
          id: 'new',
          title: 'Not Started',
          items: task.new || [],
        },
        {
          id: 'inprogress',
          title: 'In Progress',
          items: task.inprogress || [],
        },
        {
          id: 'done',
          title: 'Done',
          items: task.done || [],
        },
      ]
      setData(initialData)
    }
  }, [task])

  const fetchTasks = async () => {
    const res = await getProjectById(projectid)
    setProjectName(res.name)
    const tasknew = res.tasks.filter((task: any) => task.status === 'new')
    const taskinprogress = res.tasks.filter((task: any) => task.status === 'inprogress')
    const taskdone = res.tasks.filter((task: any) => task.status === 'done')
    const tasks = {
      new: tasknew,
      inprogress: taskinprogress,
      done: taskdone,
    }
    setTask(tasks)
  }

  useEffect(() => {
    fetchTasks()
  }, [])

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      due: date,
    })
  }

  const handleDatePickerClick = (e) => {
    // Prevent the click event from propagating to parent elements
    e.stopPropagation()
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    e.preventDefault()
    const {name, value} = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = async () => {
    const data = {
      ...formData,
      assignee: currentUser._id,
      agents: agentids,
    }
    const res = await addTask(data, projectid)
    if (res) {
      setFormData({
        title: '',
        description: '',
        due: new Date(),
      })
      setagentids([])
      setassiopen(false)
      fetchTasks()
      const closeModalButton2 = document.getElementById('closeModalButton2')
      closeModalButton2?.click()
    }
  }

  const handleEdit = async () => {
    const data = {
      ...formData,
      assignee: currentUser._id,
      agents: agentids,
    }
    const res = await editTask(data, editTaskId)
    if (res) {
      setFormData({
        title: '',
        description: '',
        due: new Date(),
      })
      setagentids([])
      setassiopen(false)
      setEditTaskId('')
      fetchTasks()
      const closeModalButton3 = document.getElementById('closeModalButton3')
      closeModalButton3?.click()
    }
  }

  const handleDeleteEvent = () => {
    handleCloseModal()
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
    }).then(async (res) => {
      if (res.value) {
        const resp = await deleteTaskById(currentTaskData?._id)
        if (resp) {
          fetchTasks()
          Swal.fire('Deleted!', 'Your Event has been deleted.', 'success')
        }
      }
    })
  }

  const handleEditEvent = () => {
    handleCloseModal()
    setEditTaskId(currentTaskData?._id)
    setFormData({
      title: currentTaskData?.title,
      description: currentTaskData?.description,
      due: new Date(currentTaskData?.due),
    })
    setagentids(currentTaskData?.assignee.map((agent) => agent._id))
    Swal.fire({
      title: 'Edit Event ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Edit it!',
      cancelButtonText: 'No, cancel!',
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
    }).then((res) => {
      if (res.value) {
        editTodo.current.click()
      }
    })
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return

    const {source, destination, draggableId} = result

    if (source.droppableId === destination.droppableId) {
      // Reorder items within the same card
      const cardIndex = data.findIndex((card) => card.id === source.droppableId)
      const items = [...data[cardIndex].items]
      const [removedItem] = items.splice(source.index, 1)
      items.splice(destination.index, 0, removedItem)

      const updatedData = [...data]
      updatedData[cardIndex].items = items
      setData(updatedData)
    } else {
      // Move item to a different card
      const sourceCardIndex = data.findIndex((card) => card.id === source.droppableId)
      const destCardIndex = data.findIndex((card) => card.id === destination.droppableId)
      const sourceItems = [...data[sourceCardIndex].items]
      const destItems = [...data[destCardIndex].items]
      const [movedItem] = sourceItems.splice(source.index, 1)
      destItems.splice(destination.index, 0, movedItem)

      const updatedData = [...data]
      updatedData[sourceCardIndex].items = sourceItems
      updatedData[destCardIndex].items = destItems
      setData(updatedData)
      console.log(movedItem._id)
      changeTaskStatus(data[destCardIndex].id, movedItem._id)
    }
  }

  const formatDueTime = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  const handleShowModal = async (key) => {
    setShowModal(true)
    const res = await getTaskById(key)
    // const currentTask = data
    //   ?.map((card) => card.items)
    //   .flat()
    //   .find((item) => item._id === key)
    // console.log(res)
    setCurrentTaskData(res)
  }
  const handleCloseModal = () => {
    setShowModal(false)
    setCurrentTaskData({})
  }

  const handleWindowClick = (e) => {
    const nearestTask = e.target.closest('#currentTask')
    if (nearestTask) {
      // console.log(nearestTask)
      const elem = nearestTask.dataset.currenttask
      // console.log(elem)
      handleShowModal(elem)
    }
  }

  return (
    <div>
      <button
        type='button'
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_1234'
        ref={editTodo}
      ></button>
      <div className={`card h-md-50 mb-5 mb-xl-10`}>
        <div className='card-header border-0 py-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1 capitalize'>{projectName}</span>
          </h3>

          {(currentUser?.profileType === 'superadmin' || permissions?.sprint?.create) && (
            <div className='card-toolbar' data-bs-toggle='modal' data-bs-target='#kt_modal_2'>
              <a href='#' className='btn btn-sm btn-light-primary'>
                <KTIcon iconName='plus' className='fs-2' />
                Add New Task
              </a>
            </div>
          )}
        </div>

        <div className='modal fade' tabIndex={-1} id='kt_modal_2'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Add Task</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon svg-icon-2x'
                  />
                </div>
              </div>

              <div className='modal-body'>
                <form>
                  {!assiopen ? (
                    <div>
                      <div className='row rowwidthclient'>
                        <div className='form-group mb-3 col-md-12'>
                          <label>Title</label>
                          <input
                            type='text'
                            name='title'
                            value={formData.title}
                            onChange={handleChange}
                            className='form-control'
                            placeholder='Enter title'
                          />
                        </div>
                        <div className='form-group mb-3 col-md-12'>
                          <label>Description</label>
                          <input
                            type='text'
                            name='description'
                            value={formData.description}
                            onChange={handleChange}
                            className='form-control'
                            placeholder='Enter description'
                          />
                        </div>
                        <div className='form-group mb-10 d-flex flex-column'>
                          <label>Due</label>
                          <DatePicker
                            selected={formData.due}
                            onChange={handleDateChange}
                            dateFormat='MMMM d, yyyy'
                            className='form-control'
                            onClick={handleDatePickerClick}
                          />
                        </div>
                      </div>

                      <button
                        type='button'
                        className='btn btn-sm btn-light-primary mt-5'
                        onClick={() => setassiopen(true)}
                      >
                        <KTIcon iconName='plus' className='fs-2' />
                        Assign Agent
                      </button>
                    </div>
                  ) : (
                    <div className='modal-body'>
                      <button
                        type='button'
                        className='btn btn-sm btn-light-primary mb-5'
                        onClick={() => setassiopen(false)}
                      >
                        <KTIcon iconName='arrow-left' className='fs-2' />
                      </button>
                      <AgentListForTask agentids={agentids} setagentids={setagentids} />
                    </div>
                  )}
                </form>
              </div>

              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  id='closeModalButton2'
                >
                  Close
                </button>
                <button type='button' className='btn btn-primary' onClick={handleSubmit}>
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='m-4' onClick={handleWindowClick}>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10 w-100'>
            {data.map((card) => (
              <div className='col-xl-4 mb-4' key={card.id}>
                <Card
                  className={`bg-light  w-100 ${
                    card.title === 'Not Started'
                      ? 'bg-light-primary  border border-primary'
                      : card.title === 'In Progress'
                      ? 'bg-light-warning border border-warning'
                      : card.title === 'Done'
                      ? 'bg-light-success border border-success'
                      : 'bg-light-danger border border-danger'
                  }`}
                  style={{width: '19rem'}}
                >
                  {/* <Card.Header> */}
                  <div
                    className={`p-2  m-2  ${
                      card.title === 'Not Started'
                        ? 'bg-light-primary '
                        : card.title === 'In Prrogress'
                        ? 'bg-light-warning'
                        : card.title === 'Done'
                        ? 'bg-light-success'
                        : 'bg-light-danger'
                    }`}
                  >
                    <div
                      className={`capitalize font-semibold flex justify-center items-center  text-xl ${
                        card.title === 'Not Started'
                          ? 'text-primary'
                          : card.title === 'In Progress'
                          ? 'text-warning'
                          : card.title === 'Done'
                          ? 'text-success'
                          : 'text-danger'
                      } `}
                    >
                      {card.title}
                    </div>
                  </div>
                  {/* </Card.Header> */}
                  <Droppable droppableId={card.id} type='ITEM'>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {card.items.map((item, index) => (
                          <Draggable key={item?._id} draggableId={item?._id} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  className=' m-3 my-5'
                                  id='currentTask'
                                  data-currenttask={item?._id}
                                >
                                  <div className=' text-md font-light bg-white rounded-lg p-3 leading-none px-3'>
                                    <h4 className='text-black capitalize'>{item?.title}</h4>
                                    {item?.description && (
                                      <p className='text-black'>{item?.description}</p>
                                    )}
                                    <p className=' text-black'>Due: {formatDueTime(item?.due)}</p>
                                    {item?.assignee?.length > 0 && (
                                      <p className='text-black truncate'>
                                        <span className='text-black'>
                                          Assigned To:{' '}
                                          {item?.assignee?.map((agent, index) => (
                                            <span
                                              key={agent._id}
                                              className='text-black fw-semibold'
                                            >
                                              {agent?.fullName}
                                              {index < item.assignee.length - 1 && ', '}
                                            </span>
                                          ))}
                                        </span>
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Card>
              </div>
            ))}
          </div>
        </DragDropContext>
      </div>
      {showModal && (
        <div className='modal fade show' tabIndex={-1} role='dialog' style={{display: 'block'}}>
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title capitalize'>
                  {/* {calendarEventModal?._def?.extendedProps?.client !== null ? 'Meeting' : 'Task'} */}
                </h5>
                <button type='button' className='close' onClick={handleCloseModal}>
                  <span>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <div className='table-responsive'>
                  <table className='table'>
                    <tbody>
                      <tr>
                        <td>Title</td>
                        <td>
                          <strong className='capitalize'>{currentTaskData?.title}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Description</td>
                        <td>
                          <strong>{currentTaskData?.description}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Due date</td>
                        <td>
                          <strong>{formatDueTime(currentTaskData?.due)}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Created date</td>
                        <td>
                          <strong>{formatDueTime(currentTaskData?.createdAt)}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Assignee</td>
                        <td>
                          <strong>
                            {currentTaskData?.assignee?.map((agent) => agent.fullName).join(', ')}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>
                          <strong
                            className={`badge uppercase ${
                              currentTaskData?.status === 'inprogress'
                                ? 'badge-light-warning'
                                : currentTaskData?.status === 'new'
                                ? 'badge-light-primary'
                                : 'badge-light-success'
                            }`}
                          >
                            {currentTaskData?.status === 'new'
                              ? 'NOT STARTED'
                              : currentTaskData?.status === 'inprogress'
                              ? 'IN PROGRESS'
                              : 'DONE'}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-secondary' onClick={handleCloseModal}>
                  Close
                </button>
                <button type='button' className='btn btn-danger' onClick={handleDeleteEvent}>
                  Remove Event
                </button>
                <button type='button' className='btn btn-warning' onClick={handleEditEvent}>
                  Edit Event
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='modal fade' tabIndex={-1} id='kt_modal_1234'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Edit Task</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              <form>
                {!assiopen ? (
                  <div>
                    <div className='row rowwidthclient'>
                      <div className='form-group mb-3 col-md-12'>
                        <label>Title</label>
                        <input
                          type='text'
                          name='title'
                          value={formData.title}
                          onChange={handleChange}
                          className='form-control'
                          placeholder='Enter title'
                        />
                      </div>
                      <div className='form-group mb-3 col-md-12'>
                        <label>Description</label>
                        <input
                          type='text'
                          name='description'
                          value={formData.description}
                          onChange={handleChange}
                          className='form-control'
                          placeholder='Enter description'
                        />
                      </div>
                      <div className='form-group mb-10 d-flex flex-column'>
                        <label>Due</label>
                        <DatePicker
                          selected={formData.due}
                          onChange={handleDateChange}
                          dateFormat='MMMM d, yyyy'
                          className='form-control'
                          onClick={handleDatePickerClick}
                        />
                      </div>
                    </div>

                    <button
                      type='button'
                      className='btn btn-sm btn-light-primary mt-5'
                      onClick={() => setassiopen(true)}
                    >
                      <KTIcon iconName='plus' className='fs-2' />
                      Assign Agent
                    </button>
                  </div>
                ) : (
                  <div className='modal-body'>
                    <button
                      type='button'
                      className='btn btn-sm btn-light-primary mb-5'
                      onClick={() => setassiopen(false)}
                    >
                      <KTIcon iconName='arrow-left' className='fs-2' />
                    </button>
                    <AgentListForTask agentids={agentids} setagentids={setagentids} />
                  </div>
                )}
              </form>
            </div>

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton3'
              >
                Close
              </button>
              <button type='button' className='btn btn-primary' onClick={handleEdit}>
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectDetails
