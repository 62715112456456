/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'

import {useAuth} from '../../../modules/auth/core/Auth'
import { Dropdown2 } from '../../../../_metronic/partials/content/dropdown/Dropdown2'
import { Link } from 'react-router-dom'
import { Client } from '../../client/core/_models'
import { getClient, getClientagentId } from '../core/_requests'


const Clientlist: React.FC = () => {
  const {currentUser} = useAuth()

  const PF:any = "https://unifeed.s3.ap-south-1.amazonaws.com/";


  const [isLoading, setisLoading] = useState<boolean>(true);
const [clients, setClients] = useState<Client[]>([]); 
const [permissions, setPermissions] = useState<any>({})

useEffect(() => {
  if (currentUser?.permissions) {
    setPermissions(currentUser?.permissions)
  }
}, [currentUser])

const maskPhoneNumber = (phone) => {
  if (!phone) return '';
  const visiblePart = phone.slice(0, phone.length - 6);
  return `${visiblePart}XXXXXX`;
};



useEffect(() => {
      const getClientList = async () => {
        try {
      
      
          if(permissions?.dashboard?.admin === false ){
            const res = await getClientagentId(currentUser?._id);
            setClients(res);
            setisLoading(false)
          }else if(currentUser?.profileType==="superadmin" || permissions?.dashboard?.admin){
            const res = await getClient(currentUser?.company);
            setClients(res);
            setisLoading(false)
          }
        
         
        } catch (error) {
          console.error('Error fetching client list:', error);
        }
      };

      getClientList();
    }, [currentUser, permissions?.dashboard]);


const handleAutoDial = (phoneNumber:string) => {
  if(!currentUser?.app?.telecom){
    const url = `tel:${phoneNumber}`;
    window.open(url, null, 'toolbar=no,menubar=no');
    return
  }
  const url = `https://portal.maqsam.com/phone/dialer#autodial=${phoneNumber}`;
  window.open(url, null, 'toolbar=no,menubar=no');
};

 

  return (
    <>
    {
      isLoading ? (
        <></>
      ):(
        <div className={`card h-md-50 mb-5 mb-xl-10 overflow-scroll`}>

         

  
<div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Trending Clients</span>
          <span className='text-muted fw-semibold fs-7'></span>
        </h3>
        </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4 table-row-bordered' >
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-200px rounded-start'>Name</th>
                <th className='min-w-200px'>Email</th>
                <th className='min-w-200px'>Phone</th>
                <th className='min-w-150px'>Category</th>
                <th className='min-w-200px'>Agent</th>
                <th className='min-w-150px  rounded-end'>Status</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {clients.map((product:Client | undefined) => (
              <tr key={product?._id?.toString()}>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-5'>
                      {/* <img
                        src={toAbsoluteUrl('/media/stock/600x400/img-26.jpg')}
                        className=''
                        alt=''
                      /> */}
                    </div>
                    <Link to={`/clients/clientDetails/${product._id}`} className=' client_field cursor-pointer '>
                    <div className='d-flex justify-content-start flex-column'>
                      <div  className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {product?.name}
                      </div>
                   
                    </div>
                    </Link>
                  </div>
                </td>
                <td>
                  <a href={`mailto:${product?.email}`} className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                  {product?.email}
                  </a>
                
                </td>
                <td>
                  <a  className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6' href="#" onClick={()=>handleAutoDial(product?.phone)}>
                  {maskPhoneNumber(product?.phone)}
                  </a>

                </td>
                <td>
                  <span className={`badge  fs-7 fw-semibold badge-light-primary capitalize`}> {product?.category === 'old' ? "Gray" : product?.category === 'new' ? "Blue" : product?.category}</span>
                </td>
                <td>
                <div className='d-flex align-items-center'>
                 
                    <div className='d-flex justify-content-start flex-column'>
                        {product?.agent && product.agent[0]?.fullName ? (
                          product?.agent.length > 1 ? (
                            <div className="symbol-group symbol-hover"  data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'>
                             { product.agent.map((item,index) => (
                              <div className="symbol symbol-circle symbol-30px " key={index}>
                                {
                                  item.profilePic !== "" ? (
                                    <img src={PF+item.profilePic} alt="" className='object-fit-cover'/>
                                  ):(
                                    <div className="symbol-label fs-5 fw-semibold bg-danger text-inverse-danger">{item.fullName.charAt(0).toUpperCase()}</div>
                                  )
                                }
                              
                          </div>
                          
                              ))}
                            
                            <div  className='menu menu-sub menu-sub-dropdown w-200px w-md-200px p-5' data-kt-menu='true' style={{ zIndex: 1000000}}>
                            { product.agent.map((item,index) => (
                                                    <div className='p-3' key={index}>
                                                    <div className='symbol symbol-30px me-2 symbol-square'>
                                                    {item?.profilePic ? (
                                                        <img
                                                          src={PF + item.profilePic}
                                                          className='object-fit-cover'
                                                          alt=''
                                                        />
                                                      ):(
                                                        <div className="symbol-label fs-5 fw-semibold bg-danger text-inverse-danger">{item.fullName.charAt(0).toUpperCase()}</div>
                                                      )}
                                                    </div>
                                                    <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                                      {item.fullName}
                                                    </a>
                                                    </div>
                            ))}
                               </div>
                          </div>
                          ) : (
                            <div>
                            <div className='symbol symbol-30px me-2 symbol-square'>
                            {product?.agent && product.agent[0]?.profilePic ? (
                                <img
                                  src={PF + product.agent[0].profilePic}
                                  className='object-fit-cover'
                                  alt=''
                                />
                              ):(
                                <div className="symbol-label fs-5 fw-semibold bg-danger text-inverse-danger">{product.agent[0].fullName.charAt(0).toUpperCase()}</div>
                              )}
                            </div>
                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              {product?.agent[0]?.fullName}
                            </a>
                            </div>
                          )
                        ) : (
                          <a href='#' className='text-danger fw-bold text-hover-primary mb-1 fs-6'>
                            Not Assigned
                          </a>
                        )}
                      </div>
                  </div>
                 <Dropdown2/>
                 
                </td>
                <td>
                  <span className={`badge fs-6 capitalize fw-semibold ${product?.pipelinestatus==='new' ? "badge-light-primary" :product?.pipelinestatus==='prospect'? "badge-light-warning":product?.pipelinestatus==='viewing'? "badge-light-info":product?.pipelinestatus==='close'? "badge-light-success":
                  product?.pipelinestatus==='reject'? "badge-light-danger":
                  "badge-light-dark" }`}> {product?.pipelinestatus}</span>
                </td>
               
              </tr>
            ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {/* Modal for add client */}
 

    </div>
      )
    }





    </>
    
  )
}

export {Clientlist}
