import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL
const ACCOUNTS_URL = `${API_URL}/maccount`
const EXPENSE_URL = `${ACCOUNTS_URL}/expenses`
const JOURNAL_URL = `${ACCOUNTS_URL}/journals`
const ORDER_ID = `${API_URL}/lastInsertedId`
const TRANSACTIONS_URL = `${API_URL}/transactions`
const PAYROLL_URL = `${API_URL}/payroll`
const REPORTS_URL = `${API_URL}/accounts/reports`
const POST_DOCS_URL = `${API_URL}/uploaddocs/accounts`

export const postAccount = async (data) => {
  try {
    const response = await axios.post(`${ACCOUNTS_URL}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const postCOA = async (data) => {
  try {
    const response = await axios.post(`${ACCOUNTS_URL}/addcategory`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getAccounts = async (accountsType: string, orgid: string) => {
  try {
    const response = await axios.get(`${ACCOUNTS_URL}/getaccounts/${orgid}/${accountsType}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getAllAccounts = async (orgid: string) => {
  try {
    const response = await axios.get(`${ACCOUNTS_URL}/getaccounts/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getAllGroupedAccounts = async (orgid: string) => {
  try {
    const response = await axios.get(`${ACCOUNTS_URL}/accountgroupedwithtypes/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getAccountsForExpense = async (orgid: string) => {
  try {
    const response = await axios.get(`${ACCOUNTS_URL}/getaccounts/accountsforexpense/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}
export const getAccountsForExpensePaidThrough = async (orgid: string) => {
  try {
    const response = await axios.get(
      `${ACCOUNTS_URL}/getaccounts/accountsforexpensepaidthrough/${orgid}`
    )
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const postExpense = async (data) => {
  try {
    const response = await axios.post(`${EXPENSE_URL}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getExpenses = async (orgid: string) => {
  try {
    const response = await axios.get(`${EXPENSE_URL}/getexpenses/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const postJournal = async (data) => {
  try {
    const response = await axios.post(`${JOURNAL_URL}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getJournals = async (orgid: string) => {
  try {
    const response = await axios.get(`${JOURNAL_URL}/getjournals/${orgid}`)
    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function getJournalLastId(orgid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ORDER_ID}/${orgid}/Journal`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function deleteAccount(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.delete(`${ACCOUNTS_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getAccountById(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ACCOUNTS_URL}/getaccountbyid/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function updateAccount(id: string, data: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(
      `${ACCOUNTS_URL}/editaccountbyid/${id}`,
      data
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function updateActivationStatus(id: string, data: any): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(
      `${ACCOUNTS_URL}/changeactivation/${id}`,
      data
    )
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

export async function getTransactionsByAccountId(id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${TRANSACTIONS_URL}/${id}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add property error:', error)
    throw error
  }
}

//post function for payroll
export const postPayroll = async (data) => {
  try {
    const response = await axios.post(`${PAYROLL_URL}`, data)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

//get function for payroll
export const getPayroll = async () => {
  try {
    const response = await axios.get(`${PAYROLL_URL}/getpayroll`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

// Get function for payroll by month grouping
export const getPayrollWithMonth = async (orgid: string) => {
  try {
    const response = await axios.get(`${PAYROLL_URL}/getpayrollbymonth/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

// Get function for payroll by months and year
export const getPayrollsByMonth = async (month: string, orgid: string) => {
  try {
    const response = await axios.get(`${PAYROLL_URL}/getpayrollbymonth/${orgid}/${month}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getProfitAndLoss = async (orgid: string) => {
  try {
    const response = await axios.get(`${REPORTS_URL}/profitandloss/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getBalanceSheet = async (orgid: string) => {
  try {
    const response = await axios.get(`${REPORTS_URL}/balancesheet/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getSalesByCustomer = async (orgid: string) => {
  try {
    const response = await axios.get(`${REPORTS_URL}/salesbycustomer/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getParentAccounts = async (orgid: string) => {
  try {
    const response = await axios.get(`${ACCOUNTS_URL}/parentaccounts/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getCategoryOfAccounts = async (orgid: string) => {
  try {
    const response = await axios.get(`${ACCOUNTS_URL}/getcategoryofaccounts/${orgid}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export const getAccountsByCategory = async (id: string) => {
  try {
    const response = await axios.get(`${ACCOUNTS_URL}/category/${id}`)
    console.log(response.data)

    return response.data
  } catch (error) {
    console.error('Axios error:', error)
    throw error
  }
}

export async function getDocs(docid: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.get(`${ACCOUNTS_URL}/files/${docid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add Client error:', error)
    throw error
  }
}

export const deletedocs = async (id: string, docid: string) => {
  try {
    const response = await axios.delete(`${ACCOUNTS_URL}/files/${id}/${docid}`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}

export async function uploaddocs(formData: any, id: string): Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.post(`${POST_DOCS_URL}/${id}`, formData)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error('Add client error:', error)
    throw error
  }
}
