/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'

import { Agentlist } from './components/Agentlist'
import { Agentstat } from './components/Agentstat'


const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Agent/',
    path: '',
    isSeparator: false,
    isActive: false,
  }
]

const Clientmain: React.FC = () => { 

 return( <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      <Agentstat/>
      {/* <Topagents/> */}
        <Agentlist/>

       </div>
  </>
)}

const AgentWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>Agent List</PageTitle>
      <Clientmain />
    </>
  )
}

export {AgentWrapper}
