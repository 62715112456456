import {combineReducers} from '@reduxjs/toolkit'

import agentPerformanceSlice from './agentPerformanceSlice'
import clientPerformanceSlice from './clientPerformanceSlice'

const rootReducer = combineReducers({
  agent: agentPerformanceSlice,
  client: clientPerformanceSlice,
})

export default rootReducer
