import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'

import Property from './components/Property'
import Secondary from './components/Secondary'
import MapWrapper from '../map/MapWrapper'
import { DeveloperList } from './components/DeveloperList'
import DeveloperDetails from './components/DeveloperDetails'
import { PropertyListOfDeveloper } from './components/PropertyListOfDeveloper'
import { Vertical } from './wizards/components/Vertical'
import PropertyDetails from './components/PropertyDetails'

const chatBreadCrumbs: Array<PageLink> = [
  {
    title: 'Projects',
    path: '/',
    isSeparator: false,
    isActive: false,
  }
]

const ProjectPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='Primary'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Primary</PageTitle>
              <Property />
            </>
          }
        />
        <Route
          path='developerDetails/:id'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Details</PageTitle>
              <DeveloperDetails />
            </>
          }
        />
        <Route
          path='propertydetail/:id'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Details</PageTitle>
              <PropertyDetails />
            </>
          }
        />
        <Route
          path='propertlylistofdeveloper/:id'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Details</PageTitle>
              <PropertyListOfDeveloper />
            </>
          }
        />
        <Route
          path='secondary'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Secondary</PageTitle>
              <Secondary />
            </>
          }
        />
        <Route
          path='developer'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Developer</PageTitle>
              <DeveloperList />
            </>
          }
        />
        <Route
          path='maps'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Maps</PageTitle>
              <MapWrapper />
            </>
          }
        />
        <Route
          path='addproperty/:id'
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Maps</PageTitle>
              <Vertical />
            </>
          }
        />
        <Route index element={<Navigate to='/property/Primary' />} />
      </Route>
    </Routes>
  )
}

export default ProjectPage
