import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  invoices: [],
  loading: true,
}

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    setInvoices: (state, action) => {
      state.invoices = action.payload
      state.loading = false
    },
  },
})

export const {setInvoices} = invoiceSlice.actions
export default invoiceSlice.reducer
