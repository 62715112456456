import {createSlice} from '@reduxjs/toolkit'

interface PipelineItem {
  id: string
  title: string
  items: any[] // Adjust the type according to your data structure
}

const initialState = {
  pipelineData: [] as PipelineItem[],
  loading: true,
}

const pipelineSlice = createSlice({
  name: 'pipeline',
  initialState,
  reducers: {
    setPipelineData(state, action) {
      state.pipelineData = action.payload
      state.loading = false
    },
  },
})

export const {setPipelineData} = pipelineSlice.actions
export default pipelineSlice.reducer
