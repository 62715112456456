import React, {useEffect, useState} from 'react'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'

import {Link, useParams} from 'react-router-dom'
import {useAuth} from '../../../modules/auth/core/Auth'
import {addevents, getmeeting} from '../core/_requests'
import profile from './profile.png'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import AgentListTask from './AgentListTask'

function MeetList() {
  const [Leadlist, setLeadlist] = useState<any>([])
  const id = useParams().id
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [title, settitle] = useState('')
  const [description, setDescription] = useState('')
  const [reminder, setReminder] = useState('5')
  const [selectedOption, setSelectedOption] = useState('')
  const [assiopen, setassiopen] = useState(false)
  const [agentids, setagentids] = useState<string[]>([])
  const [color, setcolor] = useState('')

  const {currentUser} = useAuth()
  const PF: any = 'https://unifeed.s3.ap-south-1.amazonaws.com/'

  useEffect(() => {
    if (selectedOption === 'Important') {
      setcolor('#dc3545')
    } else if (selectedOption === 'Medium') {
      setcolor('#ffc107')
    } else {
      setcolor('#28a745')
    }
  }, [selectedOption])

  const getmeetinglist = async () => {
    const res = await getmeeting(id)
    setLeadlist(res)
    console.log(res)
  }
  useEffect(() => {
    getmeetinglist()
  }, [])

  const handleDatePickerClick = (e) => {
    // Prevent the click event from propagating to parent elements
    e.stopPropagation()
  }

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  const handletodosubmit = async () => {
    const todo = {
      title,
      start: selectedDate,
      end: selectedDate,
      type: 'meeting',
      client: id,
      priority: selectedOption,
      color: color,
      agentids,
      description,
      reminder,
    }

    await addevents(todo, currentUser?._id).then((res) => {
      settitle('')
      setSelectedOption('')
      setSelectedDate(new Date())
      setagentids([])
      setcolor('')
      setDescription('')
      setReminder('')
      getmeetinglist()

      const closeModalButton = document.getElementById('closeModalButton')
      if (closeModalButton) {
        closeModalButton.click()
      }
    })

    // Close the modal
  }

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    })
    return formattedDate
  }

  const formatCreatedAttime = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A'

    const formattedTime = new Date(createdAt).toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })

    return formattedTime
  }

  return (
    <div>
      <div className={`card h-md-50 mb-5 mb-xl-10`}>
        <div className='card-header border-0 pt-5 justify-between'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Meetings</span>
          </h3>
          <div className='card-toolbar me-5' data-bs-toggle='modal' data-bs-target='#kt_modal_124'>
            <a className='btn btn-sm btn-light-primary'>
              <KTIcon iconName='plus' className='fs-2' />
              Add Meeting
            </a>
          </div>
          {/* <div className='card-toolbar' data-bs-toggle="modal" data-bs-target="#kt_modal_1">
        <a href='#' className='btn btn-sm btn-light-primary'>
          <KTIcon iconName='plus' className='fs-2' />
          Add Deals
        </a>
      </div> */}
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4 table-row-bordered'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-50px rounded-start'>ID</th>
                  <td className='min-w-200px'>Title</td>
                  <th className='min-w-200px'>Agent</th>
                  <th className='min-w-200px'>Date</th>
                  <th className='min-w-200px'>Time</th>
                  <th className='min-w-200px'>Priority</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {Leadlist.map((lead: any, index) => (
                  <tr key={lead?._id?.toString()}>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block ps-4 mb-1 fs-6'
                      >
                        {index + 1}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {lead?.title}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-5'>
                              {lead?.agent[0]?.profilePic === '' ? (
                                <img src={`${profile}`} alt='profile' />
                              ) : (
                                <img src={PF + lead?.agent[0]?.profilePic} alt='' className="object-fit-cover"/>
                              )}
                            </div>
                            <Link
                              to={`/agents/agentDetails/${lead?.agent[0]?._id}`}
                              className=' client_field cursor-pointer '
                            >
                              <div className='d-flex justify-content-start flex-column'>
                                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                  {lead?.agent[0]?.fullName}
                                </a>
                              </div>
                            </Link>
                          </div>
                        </td>
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {formatCreatedAt(lead?.start)}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {formatCreatedAttime(lead?.start)}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        <span
                          className={`badge fs-7 fw-semibold ${
                            lead?.priority === 'Important'
                              ? 'badge-light-danger'
                              : lead?.priority === 'Medium'
                              ? 'badge-light-warning'
                              : 'badge-light-success'
                          }`}
                        >
                          {lead?.priority}
                        </span>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}

        {/* Modal for add client */}
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_modal_124'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <div className='d-flex overflow-auto h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                  <li className='nav-item'>
                    <div className={`nav-link text-active-primary me-6`}>Meeting</div>
                  </li>
                </ul>
              </div>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            {!assiopen ? (
              <div className='modal-body'>
                <div className='mb-10'>
                  <label className='form-label'>Title</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter title'
                    value={title}
                    onChange={(e) => settitle(e.target.value)}
                  />
                </div>

                <div className='mb-10 d-flex flex-column'>
                  <label className='form-label'>Date</label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat='MMMM d, yyyy'
                    className='form-control'
                    onClick={handleDatePickerClick}
                  />
                </div>

                <div className='mb-10 d-flex flex-column'>
                  <label className='form-label'>Time</label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    dateFormat='h:mm aa'
                    className='form-control'
                    onClick={handleDatePickerClick}
                  />
                </div>

                <div className='mb-10 d-flex flex-column'>
                  <label className='form-label'>Description</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter Description (Optional)'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>

                <div className='mb-10 d-flex flex-column'>
                  <label className='form-label'>Reminder</label>
                  <select
                    className='form-select'
                    value={reminder}
                    onChange={(e) => setReminder(e.target.value)}
                  >
                    <option value='5'>5 minutes before</option>
                    <option value='15'>15 minutes before</option>
                    <option value='30'>30 minutes before</option>
                    <option value='60'>1 hour before</option>
                    <option value='10'>1 day before</option>
                  </select>
                </div>

                <div className='container mt-3'>
                  <label htmlFor='exampleSelect' className='form-label'>
                    Select Priority
                  </label>
                  <select
                    className='form-select'
                    id='exampleSelect'
                    value={selectedOption}
                    onChange={handleSelectChange}
                  >
                    <option value=''>Select Priority</option>
                    <option value='Important'>Important</option>
                    <option value='Medium'>Medium</option>
                    <option value='Flexible'>Flexible</option>
                    {/* Add more options as needed */}
                  </select>
                </div>

                <button
                  type='button'
                  className='btn btn-sm btn-light-primary mt-5'
                  onClick={() => setassiopen(true)}
                >
                  <KTIcon iconName='plus' className='fs-2' />
                  Assign Agent
                </button>
              </div>
            ) : (
              <div className='modal-body'>
                <button
                  type='button'
                  className='btn btn-sm btn-light-primary mb-5'
                  onClick={() => setassiopen(false)}
                >
                  <KTIcon iconName='arrow-left' className='fs-2' />
                </button>
                <AgentListTask agentids={agentids} setagentids={setagentids} />
              </div>
            )}

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton'
              >
                Close
              </button>
              <button type='button' className='btn btn-primary' onClick={handletodosubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MeetList
