import React, {useEffect, useRef, useState} from 'react'

import {useAuth} from '../../modules/auth'
import {KTIcon, KTSVG} from '../../../_metronic/helpers'
import Select from 'react-select'
import {addevents, gettodo} from './core/_requests'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import AgentList from './AgentList'
import {getClientbyagent, getClientbycompany} from '../sales/core/_requests'
import {Link} from 'react-router-dom'
import ClientSearch from '../sales/components/ClientSearch'
function TodoList() {
  const {currentUser} = useAuth()
  const [todolist, settodolist] = useState([])

  const [selectedDate, setSelectedDate] = useState(new Date())
  const [title, settitle] = useState('')
  const [selectedOption, setSelectedOption] = useState('')
  const [assiopen, setassiopen] = useState(false)
  const [color, setcolor] = useState('')
  const [agentids, setagentids] = useState<string[]>([])
  const [property, setProperty] = useState('todo')
  const [selectedBillFrom, setselectedBillFrom] = useState(null)
  // const [clientsList, setClientsList] = useState<any>([])
  const [permissions, setPermissions] = useState<any>({})
  const [clientName, setClientName] = useState(null)
  const closeClientSelectRef = useRef<HTMLButtonElement>(null)
  const closeModal = useRef<HTMLButtonElement>(null)
  const [selectClient, setSelectClient] = useState(false)

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  // const getClientList = async () => {
  //   try {
  //     const res = await getClientbycompany(currentUser?.company)
  //     setClientsList(
  //       res.map((r) => ({
  //         value: r._id,
  //         label: r.name, // Corrected the typo here from "labe" to "label"
  //       }))
  //     )
  //   } catch (error) {
  //     console.error('Error fetching client list:', error)
  //   }
  // }

  // const getClientListbyagent = async () => {
  //   try {
  //     const res = await getClientbyagent(currentUser?._id)
  //     setClientsList(
  //       res.map((r) => ({
  //         value: r._id,
  //         label: r.name, // Corrected the typo here from "labe" to "label"
  //       }))
  //     )
  //   } catch (error) {
  //     console.error('Error fetching client list:', error)
  //   }
  // }

  // useEffect(() => {
  //   if (permissions?.calendar?.admin === false) {
  //     getClientListbyagent()
  //   }
  //   if (currentUser?.profileType === 'superadmin' || permissions?.calendar?.admin) {
  //     getClientList()
  //   }
  // }, [permissions])

  useEffect(() => {
    if (selectedOption === 'Important') {
      setcolor('#dc3545')
    } else if (selectedOption === 'Medium') {
      setcolor('#ffc107')
    } else {
      setcolor('#28a745')
    }
  }, [selectedOption])

  const getevent = async () => {
    try {
      const res = await gettodo(currentUser?._id)
      settodolist(res) // Assuming that res is an array
    } catch (error) {
      console.error('Error fetching todo list:', error)
    }
  }

  useEffect(() => {
    // getClientList()
    getevent()
  }, [])

  const handleBillFromChange = (selectedOption) => {
    setselectedBillFrom(selectedOption)
  }

  const handletodosubmit = async () => {
    const todo = {
      title,
      start: selectedDate,
      end: selectedDate,
      type: 'todo',
      priority: selectedOption,
      color: color,
      agentids,
    }

    await addevents(todo, currentUser?._id).then((res) => {
      const updatevents = [...todolist, res]
      settitle('')
      setSelectedOption('')
      setcolor('')
      setSelectedDate(new Date())
      setagentids([])
      settodolist(updatevents)
      getevent()
      setProperty('')
      setselectedBillFrom(null)
      setSelectClient(false)
      setClientName(null)
      closeModal?.current?.click()
      // const closeModalButton = document.getElementById('closeModalButton')
      // if (closeModalButton) {
      //   closeModalButton.click()
      // }
    })
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }

  const handleDatePickerClick = (e) => {
    // Prevent the click event from propagating to parent elements
    e.stopPropagation()
  }

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value)
  }

  return (
    <div className='card w-100 h-100 overflow-scroll'>
      <div className='card-header flex justify-between border-0   '>
        <h3 className='card-title fw-bold text-dark'>Todo</h3>
        <div className='card-toolbar gap-3'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_153'
          >
            <KTIcon iconName='plus' className='fs-2' />
          </button>

          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        <div id='external-events'>
          {todolist.map((event) => (
            <div title={event.title} key={event?._id}>
              {event.type === 'meeting' ? (
                <Link
                  to={`/clients/clientDetails/${event.client?._id}`}
                  className='d-flex align-items-center mb-8'
                >
                  <span
                    className={`bullet bullet-vertical h-40px me-5`}
                    style={{backgroundColor: event.color}}
                  ></span>
                  {/* end::Bullet */}
                  {/* begin::Checkbox */}

                  {/* end::Checkbox */}
                  {/* begin::Description */}
                  <div className='flex-grow-1'>
                    <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
                      {event.title}
                    </a>
                    <span className='text-muted fw-semibold d-block'>{event.type}</span>
                  </div>
                  {/* end::Description */}
                  <span
                    className={`badge  fs-8 fw-bold ${
                      event.priority === 'Important'
                        ? 'badge-light-danger'
                        : event.priority === 'Medium'
                        ? 'badge-light-warning'
                        : 'badge-light-success'
                    }`}
                  >
                    {event.priority}
                  </span>
                </Link>
              ) : (
                <div className='d-flex align-items-center mb-8'>
                  <span
                    className={`bullet bullet-vertical h-40px me-5`}
                    style={{backgroundColor: event.color}}
                  ></span>

                  <div className='flex-grow-1'>
                    <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
                      {event.title}
                    </a>
                    <span className='text-muted fw-semibold d-block'>{event.type}</span>
                  </div>
                  {/* end::Description */}
                  <span
                    className={`badge  fs-8 fw-bold ${
                      event.priority === 'Important'
                        ? 'badge-light-danger'
                        : event.priority === 'Medium'
                        ? 'badge-light-warning'
                        : 'badge-light-success'
                    }`}
                  >
                    {event.priority}
                  </span>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_modal_153'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <div className='d-flex h-55px'>
                <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                  <li className='nav-item'>
                    <div
                      className={`nav-link text-active-primary me-6 cursor-pointer  
                  ${property === 'todo' ? ' active' : ''}`}
                      onClick={() => setProperty('todo')}
                    >
                      Task
                    </div>
                  </li>
                  <li className='nav-item'>
                    <div
                      className={`nav-link text-active-primary me-6 cursor-pointer  
                  ${property === 'meeting' ? ' active' : ''}`}
                      onClick={() => setProperty('meeting')}
                    >
                      Meeting
                    </div>
                  </li>
                </ul>
              </div>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            {!assiopen && !selectClient ? (
              <div className='modal-body'>
                <div className='mb-10'>
                  <label className='form-label'>Title</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Enter title'
                    value={title}
                    onChange={(e) => settitle(e.target.value)}
                  />
                </div>

                <div className='mb-10 d-flex flex-column'>
                  <label className='form-label'>Date</label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat='MMMM d, yyyy'
                    className='form-control'
                    onClick={handleDatePickerClick}
                  />
                </div>

                <div className='mb-10 d-flex flex-column'>
                  <label className='form-label'>Time</label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    dateFormat='h:mm aa'
                    className='form-control'
                    onClick={handleDatePickerClick}
                  />
                </div>

                {property === 'meeting' && (
                  <div className='form-group mt-10 mb-10 col-md-12'>
                    <label className='form-label block'>Select Client</label>
                    {/* <Select
                      name='client'
                      aria-label='Select Client'
                      placeholder='Select client'
                      options={clientsList}
                      isSearchable={true}
                      onChange={handleBillFromChange}
                      value={selectedBillFrom}
                    /> */}

                    {clientName ? (
                      <div>
                        <input
                          type='text'
                          value={clientName?.name}
                          readOnly
                          className='form-control cursor-pointer'
                          onClick={() => {
                            setSelectClient(true)
                          }}
                        />
                      </div>
                    ) : (
                      <button
                        className='btn btn-sm btn-light-success w-50'
                        type='button'
                        onClick={() => {
                          setSelectClient(true)
                        }}
                      >
                        <KTIcon iconName='plus' className='fs-2' />
                        Select Client
                      </button>
                    )}
                  </div>
                )}

                <div className='container mt-3'>
                  <label htmlFor='exampleSelect' className='form-label'>
                    Select Priority
                  </label>
                  <select
                    className='form-select'
                    id='exampleSelect'
                    value={selectedOption}
                    onChange={handleSelectChange}
                  >
                    <option value=''>Select Priority</option>
                    <option value='Important'>Important</option>
                    <option value='Medium'>Medium</option>
                    <option value='Flexible'>Flexible</option>
                    {/* Add more options as needed */}
                  </select>
                </div>

                <button
                  type='button'
                  className='btn btn-sm btn-light-primary mt-5'
                  onClick={() => setassiopen(true)}
                >
                  <KTIcon iconName='plus' className='fs-2' />
                  Assign Agent
                </button>
              </div>
            ) : (
              <div className='modal-body'>
                {selectClient ? (
                  <>
                    <button
                      type='button'
                      className='btn btn-sm btn-light-primary mb-5'
                      onClick={() => setSelectClient(false)}
                      ref={closeClientSelectRef}
                    >
                      <KTIcon iconName='arrow-left' className='fs-2' />
                    </button>
                    <ClientSearch
                      clientId={selectedBillFrom?.value}
                      setClient={(e) => {
                        setselectedBillFrom({
                          label: e,
                          value: e,
                        })
                      }}
                      setClientName={setClientName}
                      closeRef={closeClientSelectRef}
                    />
                  </>
                ) : (
                  <>
                    <button
                      type='button'
                      className='btn btn-sm btn-light-primary mb-5'
                      onClick={() => setassiopen(false)}
                    >
                      <KTIcon iconName='arrow-left' className='fs-2' />
                    </button>
                    <AgentList agentids={agentids} setagentids={setagentids} />
                  </>
                )}
              </div>
            )}

            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                id='closeModalButton'
                ref={closeModal}
              >
                Close
              </button>
              <button type='button' className='btn btn-primary' onClick={handletodosubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TodoList
