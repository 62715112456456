import {combineReducers} from '@reduxjs/toolkit'
import clientSlice from './clientSlice'
import clientStatsSlice from './clientStatsSlice'

const rootReducer = combineReducers({
  client: clientSlice,
  clientStat: clientStatsSlice,
})

export default rootReducer
