import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    agents: [],
    loading: true,
};

const agentSlice = createSlice({
    name: "agent",
    initialState,
    reducers: {
        setAgentsData(state, action) {
            state.agents = action.payload;
            state.loading = false;
        },
        setLoading(state) {
            state.loading = true;
        },
    },
});

export const { setAgentsData, setLoading } = agentSlice.actions;
export default agentSlice.reducer;