import React from 'react'
import {Route, Routes} from 'react-router-dom'
import AgentPerformance from './Components/reports/AgentPerformance'

const ReportsPage = () => {
  return (
    <Routes>
      <Route path='/' element={<AgentPerformance />} />
    </Routes>
  )
}

export default ReportsPage
