import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  sprints: [],
  loading: true,
}

const sprintSlice = createSlice({
  name: 'sprint',
  initialState,
  reducers: {
    setSprints(state, action) {
      state.sprints = action.payload
      state.loading = false
    },
    addSprint(state, action) {
      state.sprints.push(action.payload)
    },
  },
})

export const {setSprints, addSprint} = sprintSlice.actions
export default sprintSlice.reducer
