import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  clients: [],
  totalClients: 0,
  error: null,
  loading: true,
}

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClientsData(state, action) {
      state.clients = action.payload
      state.error = null
      state.loading = false
    },
    setTotalClientsState(state, action) {
      state.totalClients = action.payload
    },
    setLoading(state) {
      state.loading = true
      state.error = null
    },
    setError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {setClientsData, setTotalClientsState, setLoading, setError} = clientSlice.actions
export default clientSlice.reducer
