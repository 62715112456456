import React, {useCallback, useEffect, useState} from 'react'
import {Status} from '../components/Status'
import {Pending} from '../components/Pending'
import {
  getNonPendingInvoice,
  getNonPendingQuotes,
  getPendingInvoice,
  getPendingQuotes,
} from '../core/_requests'
import {useAuth} from '../../../modules/auth'
import {PendingQuotes} from '../components/PendingQuotes'
import {StatusQuotes} from '../components/StatusQuotes'
import {useSelector} from 'react-redux'
import {RootState} from '../../../redux/store'

function SalesStatus() {
  const invoiceList = useSelector((state: RootState) => state.sales.invoice.invoices)
  const quoteList = useSelector((state: RootState) => state.sales.quote.quotes)
  const [loading, setLoading] = useState(invoiceList.length === 0 || quoteList.length === 0)
  const [invoicepending, setInvoicepending] = useState([])
  const [invoicenotpending, setInvoicenotpendin] = useState([])
  const [quotePending, setQuotePending] = useState([])
  const [quoteNotPending, setQuoteNotPending] = useState([])
  const {currentUser} = useAuth()

  const fetchAccountpending = useCallback(async () => {
    const allres = await getPendingInvoice(currentUser?.company)
    setInvoicepending(allres)
  }, [currentUser])

  const fetchQuotespending = useCallback(async () => {
    const allres = await getPendingQuotes(currentUser?.company)
    setQuotePending(allres)
  }, [currentUser])

  const fetchAccountnotpendin = useCallback(async () => {
    const allres = await getNonPendingInvoice(currentUser?.company)
    setInvoicenotpendin(allres)
    setLoading(false)
  }, [currentUser])

  const fetchQuotenotpendin = useCallback(async () => {
    const allres = await getNonPendingQuotes(currentUser?.company)
    setQuoteNotPending(allres)
    setLoading(false)
  }, [currentUser])

  const change = () => {
    fetchAccountpending()
    fetchAccountnotpendin()
  }

  const changeQuote = () => {
    fetchQuotespending()
    fetchQuotenotpendin()
  }

  useEffect(() => {
    if (invoiceList.length === 0) {
      fetchAccountpending()
      fetchAccountnotpendin()
    } else {
      setInvoicepending(invoiceList.filter((invoice) => invoice.status === 'pending'))
      setInvoicenotpendin(invoiceList.filter((invoice) => invoice.status !== 'pending'))
    }
    if (quoteList.length === 0) {
      fetchQuotespending()
      fetchQuotenotpendin()
    } else {
      setQuotePending(quoteList.filter((quote) => quote.status === 'pending'))
      setQuoteNotPending(quoteList.filter((quote) => quote.status !== 'pending'))
    }
  }, [
    currentUser,
    fetchAccountpending,
    fetchAccountnotpendin,
    fetchQuotenotpendin,
    fetchQuotespending,
    invoiceList,
    quoteList,
  ])

  if (loading) {
    return (
      <div className='d-flex justify-content-center'>
        <div className='loader'></div>
      </div>
    )
  }

  return (
    <div>
      <Pending fetchAccount={fetchAccountpending} invoice={invoicepending} change={change} />
      <PendingQuotes
        fetchAccount={fetchQuotespending}
        invoice={quotePending}
        change={changeQuote}
      />
      <Status fetchAccount={fetchAccountnotpendin} invoice={invoicenotpending} change={change} />
      <StatusQuotes
        fetchAccount={fetchQuotenotpendin}
        invoice={quoteNotPending}
        change={changeQuote}
      />
    </div>
  )
}

export default SalesStatus
