import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  tasks: [],
  loading: true,
}

const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    setTasks(state, action) {
      state.tasks = action.payload
      state.loading = false
    },
  },
})

export const {setTasks} = taskSlice.actions
export default taskSlice.reducer
