import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  revenueCount: 'Loading',
  salesCount: 'Loading',
  expenseCount: 'Loading',
}

const dashboardStatsSlice = createSlice({
  name: 'dashboardStats',
  initialState,
  reducers: {
    setRevenueCount(state, action) {
      state.revenueCount = action.payload
    },
    setSalesCount(state, action) {
      state.salesCount = action.payload
    },
    setExpenseCount(state, action) {
      state.expenseCount = action.payload
    },
  },
})

export const {setRevenueCount, setSalesCount, setExpenseCount} = dashboardStatsSlice.actions
export default dashboardStatsSlice.reducer
