import React, {FC, useRef, useState} from 'react'
import {useFormikContext } from 'formik';
import profile from '../camera.png'


const Step5: FC = () => {
  const { setFieldValue } = useFormikContext();
  const [images, setImages] = useState<File[]>([]);
  const [imagesname, setImagesname] = useState([]);
  const [profiles, setProfiles] = useState<any>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const fileInputRefprofile = useRef<HTMLInputElement | null>(null);
   
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedImages = Array.from(e.target.files);
      setImages((prevImages) => [...prevImages, ...selectedImages]);
      // const timestamp = Date.now();
      const newImagesname = [...imagesname]
      for (let i = 0; i < selectedImages.length; i++) {
        // newImagesname.push(timestamp+selectedImages[i]?.name)
        newImagesname.push(selectedImages[i]?.name)
      }
      // Assuming you want to store only the first file name
      // setImagesname((prevNames) => [...prevNames, timestamp+selectedImages[0].name]);
      setImagesname(newImagesname)
      setFieldValue('imagesname', newImagesname);

      // Update Formik values
      setFieldValue('images', [...images, ...selectedImages]);
     
    }
  };
  
  const handleImageChangeprofile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const timestamp = Date.now();
      setProfiles(e.target.files[0]);
      setFieldValue('profileImage', e.target.files[0]);
      setFieldValue('profileImagename', timestamp+e.target.files[0].name);
    }
  };
  
  const handleRemoveImage = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
    const newImagesname = [...imagesname];
    newImagesname.splice(index, 1);
    setFieldValue('images', newImages);
    setFieldValue('imagesname', newImagesname);
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleClickprofile = () => {
    if (fileInputRefprofile.current) {
      fileInputRefprofile.current.click();
    }
  };
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Images</h2>

    
      </div>

      <div className='d-flex flex-column mb-7 fv-row'>
      <label className='required fs-6 fw-bold form-label mb-2'>Main Image</label>
      

        <div className='d-flex flex-column'>
        <button
          type='button'
          className='btn btn-lg btn-primary me-3 w-150px mb-5'
          onClick={handleClickprofile}
        >
          Select Images
        </button>

        {/* Hidden file input */}
        <input
        type='file'
        accept='image/*'
        onChange={handleImageChangeprofile}
        ref={fileInputRefprofile}
        style={{ display: 'none' }}
      />
        {
          !profiles ? (
            
         <div
         className='image-input image-input-outline'
         data-kt-image-input='true'
        
       >
  
         <div
           className='image-input-wrapper w-125px h-125px'
           style={{backgroundImage: `url('${profile}')`}}
         ></div>

    
       </div>
          ):(
            
         <div
         className='image-input-wrapper w-125px h-125px'
         data-kt-image-input='true'
        
       >
         {/* begin::Preview existing avatar */}
         <img
                src={URL.createObjectURL(profiles)}
                alt={`Preview `}
                className='image-input-wrapper w-125px h-125px'
              />

         {/* end::Remove */}
       </div>
          )
        }
        </div> 
      </div>

      <div className='d-flex flex-column mb-7 fv-row'>
        <label className='required fs-6 fw-bold form-label mb-2'>Media Images</label>
        <div>
      

        {/* Custom-styled button to trigger file input */}
        <button
          type='button'
          className='btn btn-lg btn-primary me-3 mb-5'
          onClick={handleClick}
        >
          Select Images
        </button>

        {/* Hidden file input */}
        <input
        type='file'
        accept='image/*'
        multiple
        onChange={handleImageChange}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />

        {/* Display the selected images with remove option */}
        <div>
          {images.map((image, index) => (
       
            <div
            key={index}
              className='image-input image-input-outline'
              data-kt-image-input='true'
           
            >
              {/* begin::Preview existing avatar */}
              <img
                src={URL.createObjectURL(image)}
                alt={`Preview ${index + 1}`}
                className='image-input-wrapper w-125px h-125px'
              />

              <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='remove'
              data-bs-toggle='tooltip'
              title='Remove avatar'
              onClick={() => handleRemoveImage(index)}
            >
             <i className='bi bi-x fs-2'></i>
            </span>
              
            </div>
              
          ))}

          
             
     

        </div>

        {/* Upload button */}

        </div>
  
         

      </div>

      

      
    </div>
  )
}

export {Step5}
