import {combineReducers} from '@reduxjs/toolkit'

import agentReducer from './agentSlice'
import agentStatsReducer from './agentStatsSlice'

const rootReducer = combineReducers({
  agent: agentReducer,
  agentStats: agentStatsReducer,
})

export default rootReducer
