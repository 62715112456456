/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'



type Props = {
  className: string
  logs:any
}



const Logs: React.FC<Props> = ({className,logs}) => {
  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A';
  
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  
    return formattedDate;
  };
  console.log(logs)
  return (
    <div className={`card h-600px overflow-scroll ${className}`}>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>Logs</span>
        </h3>
        <div className='card-toolbar'></div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5'>
        {/* begin::Timeline */}
        <div className='timeline-label'>
          {/* begin::Item */}

          {logs?.map((log, index) => (
            <div className='timeline-item' key={log}>
              <div className='timeline-label fw-bold text-gray-800 fs-6'> {log?.pipeline}</div>
              {/* end::Label */}
              {/* begin::Badge */}
              <div className='timeline-badge'>
                <i
                  className={`fa fa-genderless fs-1 ${
                    log?.pipeline === 'new'
                      ? 'text-primary'
                      : log?.pipeline === 'prospect'
                      ? 'text-warning'
                      : log?.pipeline === 'viewing'
                      ? 'text-info'
                      : log?.pipeline === 'close'
                      ? 'text-success'
                      : log?.pipeline === 'reject'
                      ? 'text-danger'
                      : 'text-dark'
                  }`}
                ></i>
              </div>

              {/* end::Badge */}
              {/* begin::Text */}
              <div className='timeline-content fw-mormal text-muted ps-3'>
                {formatCreatedAt(log?.date)}
              </div>
              {/* end::Text */}
            </div>
          ))}
          {/* end::Item */}
        </div>
        {/* end::Timeline */}
      </div>
      {/* end: Card Body */}
    </div>
  )
}

export {Logs}
