import React, { useContext, useEffect, useState } from 'react'
import { KTIcon } from '../../../../_metronic/helpers'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'

import { useAuth } from '../../../modules/auth';
import { getAgent } from '../../agent/core/_requests';
import { FilterContext } from '../../../App';
import Flatpickr from 'react-flatpickr';
import {useSelector, useDispatch} from 'react-redux'
import { RootState } from '../../../redux/store';
import { setAgentsData } from '../../../redux/agent/agentSlice';

type Props = {
  searchFilter:any
  setSearchFilter:any
  getClientList:any
    handlereset:any
}



const Filter:React.FC<Props> = ({handlereset, setSearchFilter, searchFilter, getClientList}) => {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})
  const dispatch = useDispatch()
  const agentsListData = useSelector((state:RootState) => state.agent.agent.agents)

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const [agentsList, setAgentsList] = useState<any>([]); 
  const {filter, setFilter} = useContext(FilterContext)
  const [status, setstatus] = useState(filter?.status)
  const [category, setcategory] = useState(filter?.category)
  const [country, setcountry] = useState(filter?.country)
  const [agent, setagent] = useState(filter?.agent)
  const [source, setsource] = useState(filter?.source)
  const [assignedDate, setassignedDate] = useState(filter?.assignedDate)

  useEffect(() => {
    setSearchFilter({
      filter: {
        status,
        category,
        source,
        agent,
        country,
        assignedDate
      },
    });
    setFilter({
      status,
      category,
      source,
      agent,
      country,
      assignedDate
    })
  }, [status,category,agent, source, country, assignedDate]);

  // useEffect(()=>{
  //   setSearchFilter({
  //     filter: {
  //       status : filter.status,
  //       category : filter.category,
  //       source : filter.source,
  //       agent : filter.agent,
  //       country : filter.country
  //     },
  //   });
  // }, [])
  

  const getAgenttList = async () => {
    try {
      const res = await getAgent(currentUser?.company);
      setAgentsList(
        res.map((r) => ({
          value: r._id,
          label: r.fullName, // Corrected the typo here from "labe" to "label"
        }))
      );

      dispatch(setAgentsData(res))
  
      // console.log(res)
   
    } catch (error) {
      console.error('Error fetching client list:', error);
    }
  };

  useEffect(() => {
    MenuComponent.reinitialization()
    if (agentsListData.length === 0){
      getAgenttList()
    } else {
      setAgentsList(
        agentsListData.map((r) => ({
          value: r._id,
          label: r.fullName,
        }))
      );
    }
  }, [])

  const handleagentChange = (selectedOption) => {
    setagent(selectedOption?.value);
  };

  const handleResetFilter = () => {
    setstatus("")
    setcategory("")
    setcountry("")
    setagent("")
    setsource("")
    setassignedDate("")
    setFilter({
      status: '',
      category: '',
      source: '',
      agent: '',
      country: '',
      assignedDate: ''
    })
    handlereset()
  }


  return (
    <>
    {/* begin::Filter Button */}
    <div
      className='btn btn-light-primary me-3'
      data-kt-menu-trigger='click'
      data-kt-menu-placement='bottom-end'
      data-kt-menu-flip='top-end'
    >
      <KTIcon iconName='filter' className='fs-2' />
      Filter
      
    </div>
    <div
        className='menu menu-sub menu-sub-dropdown w-400px w-md-400px p-5'
        data-kt-menu='true'
        style={{zIndex: 1000000}}
      >
        {/* begin::Header */}
        <div className='px-7 py-2'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
            <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Status:</label>
            <select
              className='form-select form-select-solid fw-bolder  w-75'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='status'
              data-hide-search='true'
              onChange={(e) => (setstatus(e.target.value))}
              value={status}
            >
              <option value=''>Choose</option>
              <option value='new'>New</option>
              <option value='prospect'>Prospect</option>
              <option value='viewing'>Viewing</option>
              <option value='close'>Close</option>
              <option value='reject'>Reject</option>
              <option value='noanswer'>No Answer</option>
              <option value='callback'>Call Back</option>
              <option value='pending'>Pending</option>
              <option value='junk'>Junk</option>
            </select>
          </div>
          <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
            <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Category:</label>
            <select
              className='form-select form-select-solid fw-bolder  w-75'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='category'
              data-hide-search='fasle'
              onChange={(e) => (setcategory(e.target.value))}
              value={category}
            >
              <option value=''>Choose</option>
              <option value='internal'>Internal</option>
              <option value='external'>External</option>
              <option value='events'>Events</option>
              <option value='old'>Gray</option>
              <option value='new'>Blue</option>
            </select>
          </div>
          <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
            <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Source:</label>
            <select
              className='form-select form-select-solid fw-bolder  w-75'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='source'
              data-hide-search='fasle'
              onChange={(e) => (setsource(e.target.value))}
              value={source}
            >
              <option value=''>Other</option>
              <option value='primary'>Primary</option>
              <option value='secondary'>Secondary</option>
            </select>
          </div>
          {(currentUser?.profileType === 'superadmin' ||
            permissions?.agents?.read) && (
            <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
              <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Agent:</label>
         
          <select
            className='form-select form-select-solid fw-bolder  w-75'
            data-kt-select2='true'
            data-placeholder='Select agent'
            data-allow-clear='true'
            data-kt-user-table-filter='agent'
            data-hide-search='false'
            onChange={(e) => setagent(e.target.value)}
            value={agent}
          >
            <option value=''>Choose</option>
            <option value='unassigned'>Unassigned</option>
            {agentsList.map((agent) => (
              <option key={agent.value} value={agent.value}>
                {agent.label}
              </option>
            ))}
          </select>
          
            </div>
          )}
          <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
            <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Country:</label>
            <input
              type='text'
              className='form-control form-control-solid fw-bolder w-75'
              placeholder='Country'
              data-kt-user-table-filter='country'
              onChange={(e) => setcountry(e.target.value)}
              value={country}
            />
          </div>
          <div className='mb-5 d-flex flex-row align-content-center justify-content-center'>
            <label className='form-label fs-6 fw-bold mt-2 w-100px me-5'>Assigned Date:</label>
            <Flatpickr 
              className='form-control form-control-solid fw-bolder w-75'
              data-kt-user-table-filter='assignedDate'
              value={assignedDate}
              onChange={(date) => setassignedDate(date[0])}
              placeholder='Select date'
            />
          </div>
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              // disabled={isLoading}
              // onClick={filterData}
              className='btn btn-light btn-light-danger fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
              onClick={handleResetFilter}
            >
              Reset
            </button>
            <button
              type='button'
              // disabled={isLoading}
              // onClick={filterData}
              className='btn btn-light btn-light-primary fw-bold me-2 px-6'
     
              onClick={getClientList}
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
  </>
  )
}

export default Filter