import axios, {AxiosResponse} from 'axios'
import {Client} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_USERS_URL = `${API_URL}/pipeline`
const GET_AGENT_PIPELINE = `${API_URL}/pipeline/agents`
const CLIENTPIPECOUNT_URL = `${API_URL}/agent/pipelinetotallenght/`
const AGENTCLIENTPIPECOUNT_URL = `${API_URL}/agent/pipelineagentcounts/`


// export const getClient = (query: string) => {
//     console.log(query)
//      return axios
//       .get(`${GET_USERS_URL}?${query}`)
//       .then((res: AxiosResponse<ClientQueryResponse>) => res.data)
//   }

export const getPipeline = (pipeline:string) => {
   
     return axios
      .get(`${GET_USERS_URL}/${pipeline}`)
      .then((res: AxiosResponse<any>) => res.data)
  }
export const getPipelineagent = (pipeline:string,id:string) => {
   
     return axios
      .get(`${GET_AGENT_PIPELINE}/${pipeline}/${id}`)
      .then((res: AxiosResponse<any>) => res.data)
  }

export const changePipeline = (past:string, present:string, pipelineId:string, clientId:string) => {
   
     return axios
      .put(`${GET_USERS_URL}/change`,{past, present, pipelineId, clientId})
      .then((res: AxiosResponse<Client>) => res.data)
  }

export const changeBulkPipeline = (data:any) => {
   
     return axios
      .put(`${GET_USERS_URL}/bulk/change`,data)
      .then((res: AxiosResponse<Client>) => res.data)
  }

  export async function getclientpipecount(companyid: string): Promise<any>  {
    try {
      const response: AxiosResponse<any> = await axios.get(`${CLIENTPIPECOUNT_URL}${companyid}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("search agent error:", error);
      throw error;
    }
  }
  export async function getclientpipecountagent(companyid: string,id:string): Promise<any>  {
    try {
      const response: AxiosResponse<any> = await axios.get(`${AGENTCLIENTPIPECOUNT_URL}${companyid}/${id}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("search agent error:", error);
      throw error;
    }
  }
  
