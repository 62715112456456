import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  meetings: [],
  loading: true,
}

const meetingSlice = createSlice({
  name: 'meeting',
  initialState,
  reducers: {
    setMeetings(state, action) {
      state.meetings = action.payload
      state.loading = false
    },
  },
})

export const {setMeetings} = meetingSlice.actions
export default meetingSlice.reducer
