/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react'

import {KTIcon} from '../../../../_metronic/helpers'
import {  getPropertybyAgentId } from '../core/_requests'
import { Link, useParams } from 'react-router-dom';

import { Property } from '../../project/core/_models';
import Propertysearch from './PropertySearch';
import { useAuth } from '../../../modules/auth';





const PropertyListbyId: React.FC = () => {
  const PF:any = "https://unifeed.s3.ap-south-1.amazonaws.com/";
  const id  = useParams().id
  const [agents, setagents] = useState<Property[]>([]);
  const [isLoading, setisLoading] = useState<boolean>(true);
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const getClientList = useCallback(async () => {
    try {
      const res = await getPropertybyAgentId(id);
      setagents(res);
      setisLoading(false)
   
    } catch (error) {
      console.error('Error fetching client list:', error);
    }
  }, [id])


  
  useEffect(() => {
    getClientList();
  }, [getClientList]);


  
  const handleTrigger = () => {
    getClientList()
  }


  return (
    <>
    {
      isLoading ? (
        <></>
      ):(
    <div className={'card h-md-50 mb-5 mb-xl-10 min-h-500px overflow-scroll'}>
      {/* begin::Header */}
           

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'></span>
         
        </h3>
        {
          (currentUser?.profileType === "superadmin" || permissions?.property?.assign) &&(
        <div className='d-flex flex-row'>
        <div className='card-toolbar me-5'    data-bs-toggle="modal" data-bs-target="#kt_modal_12" >
          <a  className='btn btn-sm btn-light-primary'>
            <KTIcon iconName='plus' className='fs-2' />
            Assign Property
          </a>
        </div>


      </div>
          )}
        
 
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
        
                <th className='min-w-150px'>Name</th>
                <th className='min-w-150px'>Address</th>
                <th className='min-w-150px'>Status</th>
                <th className='min-w-150px'>Project Type</th>
            
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {
            agents.map((product:Property | undefined) => (
              <tr key={product?._id?.toString()}>
              
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>
                      <img src={PF + product?.productPic} alt='' />
                    </div>
                    <Link to={`/agents/agentDetails/${product._id}`} className=' client_field cursor-pointer '>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        {product?.name}
                      </a>
                     
                    </div>
                    </Link>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    {product?.address}
                  </a>
                </td>
                <td>
                <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    {product?.status}
                  </a>
                </td>
                <td>
                <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    {product?.projectType}
                  </a>
                </td>
                

                

                {/* <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>50%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-primary'
                        role='progressbar'
                        style={{width: '50%'}}
                      ></div>
                    </div>
                  </div>
                </td>
                 */}
              </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}



  
    
  <Propertysearch   handleTrigger = {handleTrigger}/>

</>
    
    )
  }
export {PropertyListbyId}
