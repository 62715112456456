import axios, {AxiosResponse} from 'axios'
import {AddClient, Client, ClientIds, responseWithClientTotal} from './_models'
import { Property } from '../../project/core/_models'
import { Agent } from '../../agent/core/_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_USERS_URL = `${API_URL}/client/get`
const GET_USERS_FOR_EXPORT_URL = `${API_URL}/client/getclientsforexport`
const EVENTS_URL = `${API_URL}/calander/events`
const DETAILS_CLIENT_URL = `${API_URL}/client/getdetails/`
const POST_USERS_URL = `${API_URL}/client/`
const DELETE_USERS_URL = `${API_URL}/client/delete`
const DELETE_MANY_USERS_URL = `${API_URL}/client/deletemany`
const EDIT_USERS_URL = `${API_URL}/client/edit`
const SEARCH_USERS_URL = `${API_URL}/agent/search`
const SEARCH_USER_URL = `${API_URL}/agent/searchuser`
const SEARCH_PROPERTY_URL = `${API_URL}/inventory/search`
const LINK_USERS_URL = `${API_URL}/client/agentadd`
const LINK_MANY_USERS_URL = `${API_URL}/client/many/agentadd`
const TRANSFER_MANY_USERS_URL = `${API_URL}/client/many/agenttransfer`
const UNLINK_MANY_USERS_URL = `${API_URL}/client/many/agentremove`
const LINK_PROPERTY_URL = `${API_URL}/client/inventoryadd`
const BULKUPDATE_URL = `${API_URL}/client/bulkupload`
const CLIENTPIPECOUNT_URL = `${API_URL}/agent/pipelinetotallenght/`
const AGENTCLIENTPIPECOUNT_URL = `${API_URL}/agent/pipelineagentcounts/`
const CLIENT_PROPERT_URL = `${API_URL}/inventory/client/`
const GET_AGENT_URL = `${API_URL}/agent/client/`
const GET_CLIENTINFO_URL = `${API_URL}/agent/getclientinfo`
const LINK_AGENT_URL = `${API_URL}/agent/clientadd`
const PIPELINE_CHANGE_URL = `${API_URL}/pipeline/change`
const ADD_NOTES_URL = `${API_URL}/client/notes`
const GET_CLIENT_URL = `${API_URL}/client/agent/`
const POST_DOCS_URL = `${API_URL}/uploaddocs/`
const GET_DOCS_URL = `${API_URL}/client/documents/`
const GET_EVENT_URL = `${API_URL}/event/eventbyclientid/`
const DEL_DOCS_URL = `${API_URL}/client/documents/`
const GET_CLIENT_COUNT = `${API_URL}/client/total/`
const GET_CLIENT_COUNTAGENT = `${API_URL}/client/totalagent/`
const GET_TRENDINGUSERS_URL = `${API_URL}/client/trendinglist`
const GET_TRENDINGUSERS_OF_AGENT_URL = `${API_URL}/client/agenttrendinglist`
const GET_EXPORT_CLIENT = `${API_URL}/client/get/exportclient`


export const deletedocs = async(id:string, docid:string) => {
  try {
  const response  = await axios.delete(`${DEL_DOCS_URL}${id}/${docid}`)
  return response.data
} catch (error) {
  // Handle errors here
  console.error("Add client error:", error);
  throw error;
}
  
}


export const getclientcount = async(companyid:string) => {
  try {
  const response : AxiosResponse<any> = await axios.get(`${GET_CLIENT_COUNT}${companyid}`)
  return response.data
} catch (error) {
  // Handle errors here
  console.error("Add client error:", error);
  throw error;
}
  
}
export const getclientcountagent = async(agentid:string) => {
  try {
  const response : AxiosResponse<any> = await axios.get(`${GET_CLIENT_COUNTAGENT}${agentid}`)
  return response.data
} catch (error) {
  // Handle errors here
  console.error("Add client error:", error);
  throw error;
}
  
}

export const getClientsForExport = async(clientSelect) => {
  try {
  const response : AxiosResponse<any> = await axios.post(`${GET_EXPORT_CLIENT}`, {clientSelect})
  return response.data
} catch (error) {
  // Handle errors here
  console.error("Add client error:", error);
  throw error;
}
  
}


export const getClientagentId = async(id:string):Promise<Array<Client>> => {
  try {
  const response : AxiosResponse<Client[]> = await axios.get<Array<Client>>(`${GET_CLIENT_URL}${id}`)
  return response.data
} catch (error) {
  // Handle errors here
  console.error("Add client error:", error);
  throw error;
}
  
}


export async function changePipeline(past,present, pipelineId, clientId) {
  // console.log(past,present, pipelineId, clientId)
  const body = {past,present, pipelineId, clientId}
    try {
      const response = await axios.put(`${PIPELINE_CHANGE_URL}`,body);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add Company error:", error);
      throw error;
    }
  }

export async function addnotes(data, clientId) {
  // console.log(past,present, pipelineId, clientId)

    try {
      const response = await axios.put(`${ADD_NOTES_URL}/${clientId}`,data);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add Company error:", error);
      throw error;
    }
  }

export const getAgentbyClientId = async(id:string):Promise<Array<Agent>> => {
  try {
  const response : AxiosResponse<Agent[]> = await axios.get<Array<Agent>>(`${GET_AGENT_URL}${id}`)
  return response.data
} catch (error) {
  // Handle errors here
  console.error("Add client error:", error);
  throw error;
}
  
}


export const getclientdata = async(agentId :string):Promise<Array<Agent>> => {
  try {
  const response : AxiosResponse<Agent[]> = await axios.get<Array<Agent>>(`${GET_CLIENTINFO_URL}/${agentId}`)
  return response.data
} catch (error) {
  // Handle errors here
  console.error("Add client error:", error);
  throw error;
}
  
}


export async function linkClientToAgent(ids: any)  : Promise<any> {
  try {
    const response: AxiosResponse<any> = await axios.put(`${LINK_AGENT_URL}`,ids);
    return response.data;
  } catch (error) {
    // Handle errors here
    console.error("Add property error:", error);
    throw error;
  }
}

export async function getPropertybyClientId(id:string): Promise<Array<Property>>  {
  try {
    const response: AxiosResponse<Property[]> = await axios.get(`${CLIENT_PROPERT_URL}${id}`);
    return response.data;
  } catch (error) {
    // Handle errors here
    console.error("Add property error:", error);
    throw error;
  }
}

export const getClient = async(companyid):Promise<Array<Client>> => {
    try {
    const response : AxiosResponse<Client[]> = await axios.get<Array<Client>>(`${GET_USERS_URL}/${companyid}`)
    return response.data
} catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  }



  export const getClients = async(id:string, query:string):Promise<
    responseWithClientTotal
  > => {
    try {
    const response : AxiosResponse<responseWithClientTotal> = await axios.get<responseWithClientTotal>(`${GET_USERS_URL}/${id}?${query}`)
    return response.data
} catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  }

  export async function addevents(formData,agentId) {
    try {
      const response = await axios.post(`${EVENTS_URL}/${agentId}`, formData);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add agent error:", error);
      throw error;
    }
  }

  export async function getClientById(clientId: string) {
    try {
      const response = await axios.get(`${DETAILS_CLIENT_URL}${clientId}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add Client error:", error);
      throw error;
    }
  }
  export async function getDocs(clientId: string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axios.get(`${GET_DOCS_URL}${clientId}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add Client error:", error);
      throw error;
    }
  }

  export async function addClient(formData: AddClient, companyid:string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axios.post(`${POST_USERS_URL}${companyid}`, formData);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add client error:", error);
      throw error;
    }
  }

  export async function uploaddocs(formData: any, id:string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axios.post(`${POST_DOCS_URL}${id}`, formData);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add client error:", error);
      throw error;
    }
  }

  export async function bulkUpdate(formData: any,companyid:string): Promise<any> {
    try {
      const response: AxiosResponse<string> = await axios.post(`${BULKUPDATE_URL}/${companyid}`, formData);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add client error:", error);
      throw error;
    }
  }

  export async function deleteClientByid(id: string): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axios.delete(`${DELETE_USERS_URL}/${id}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add client error:", error);
      throw error;
    }
  }
  
  export async function deleteManyClientsByid(clientIds: ClientIds): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axios.put(`${DELETE_MANY_USERS_URL}`, clientIds);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add client error:", error);
      throw error;
    }
  }

  export async function editClient(editData: AddClient): Promise<any> {
    try {
      const response: AxiosResponse<any> = await axios.put(`${EDIT_USERS_URL}`,editData);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add client error:", error);
      throw error;
    }
  }


  export async function searchAgent(searchTerm: string,companyid:string): Promise<any>  {
    try {
      const response: AxiosResponse<any> = await axios.get(`${SEARCH_USERS_URL}/${companyid}?search=${searchTerm}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("search agent error:", error);
      throw error;
    }
  }
  export async function searchUser(searchTerm: string,companyid:string): Promise<any>  {
    try {
      const response: AxiosResponse<any> = await axios.get(`${SEARCH_USER_URL}/${companyid}?search=${searchTerm}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("search agent error:", error);
      throw error;
    }
  }

  export async function searchProperty(searchTerm: string,companyid:string): Promise<any>  {
    try {
      const response: AxiosResponse<any> = await axios.get(`${SEARCH_PROPERTY_URL}/${companyid}?search=${searchTerm}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("search agent error:", error);
      throw error;
    }
  }

  export async function searchPropertyForAgent(searchTerm: string,companyid:string, agentid: string): Promise<any>  {
    try {
      const response: AxiosResponse<any> = await axios.get(`${SEARCH_PROPERTY_URL}/${companyid}/${agentid}?search=${searchTerm}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("search agent error:", error);
      throw error;
    }
  }


  export async function getclientpipecount(companyid: string): Promise<any>  {
    try {
      const response: AxiosResponse<any> = await axios.get(`${CLIENTPIPECOUNT_URL}${companyid}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("search agent error:", error);
      throw error;
    }
  }
  export async function getclientpipecountagent(companyid: string,id:string): Promise<any>  {
    try {
      const response: AxiosResponse<any> = await axios.get(`${AGENTCLIENTPIPECOUNT_URL}${companyid}/${id}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("search agent error:", error);
      throw error;
    }
  }
  export async function getmeeting(clientid: string): Promise<any>  {
    try {
      const response: AxiosResponse<any> = await axios.get(`${GET_EVENT_URL}${clientid}`);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("search agent error:", error);
      throw error;
    }
  }


  export async function linkAgentToClient(ids: any)  : Promise<any> {
    try {
      const response: AxiosResponse<any> = await axios.put(`${LINK_USERS_URL}`,ids);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }
  
  export async function linkAgentToManyClient(data: any)  : Promise<any> {
    try {
      const response: AxiosResponse<any> = await axios.put(`${LINK_MANY_USERS_URL}`,data);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }
  
  export async function transferAgentToManyClient(data: any)  : Promise<any> {
    try {
      const response: AxiosResponse<any> = await axios.put(`${TRANSFER_MANY_USERS_URL}`,data);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }
  
  export async function unlinkAgentToManyClient(data: any)  : Promise<any> {
    try {
      const response: AxiosResponse<any> = await axios.put(`${UNLINK_MANY_USERS_URL}`,data);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }

  export async function linkPropertyToClient(ids: any)  : Promise<any> {
    try {
      const response: AxiosResponse<any> = await axios.put(`${LINK_PROPERTY_URL}`,ids);
      return response.data;
    } catch (error) {
      // Handle errors here
      console.error("Add property error:", error);
      throw error;
    }
  }
 
  export const getTrendingClients = async(companyid: string):Promise<Array<Client>> => {
    try {
    const response : AxiosResponse<Client[]> = await axios.get<Array<Client>>(`${GET_TRENDINGUSERS_URL}/${companyid}`)
    return response.data
} catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
}
  
export const getTrendingClientsOfAgent = async(id: string):Promise<Array<Client>> => {
    try {
    const response : AxiosResponse<Client[]> = await axios.get<Array<Client>>(`${GET_TRENDINGUSERS_OF_AGENT_URL}/${id}`)
    return response.data
} catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
}

  export const getClientsForExportPipeline = async(id:string, status:string, agent: string) => {
    try {
    const response : AxiosResponse<any> = await axios.get(`${GET_USERS_FOR_EXPORT_URL}/${id}?filter_status=${status}&filter_agent=${agent}&assignedDate=''`)
    return response.data
  } catch (error) {
    // Handle errors here
    console.error("Add client error:", error);
    throw error;
  }
    
  }