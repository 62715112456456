import React, {ChangeEvent, useEffect, useState} from 'react'
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_blue.css'
import 'flatpickr/dist/flatpickr.min.css'
import {
  editQuote,
  getAgent,
  getClientbyagent,
  getClientbycompany,
  getProperty,
  getQuotebyids,
  getQuoteid,
} from '../core/_requests'

import {useAuth} from '../../../modules/auth/core/Auth'
import {KTIcon} from '../../../../_metronic/helpers'
import {useNavigate, useParams} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../redux/store'
import {setAgentsData} from '../../../redux/agent/agentSlice'
import {setClientsData} from '../../../redux/client/clientSlice'

function EditQuote() {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch()
  const agentListData = useSelector((state: RootState) => state.agent.agent.agents)
  const clientListData = useSelector((state: RootState) => state.client.client.clients)
  const [agentsList, setAgentsList] = useState<any>([])
  const [clientsList, setClientsList] = useState<any>([])
  const [propertyList, setPropertyList] = useState<any>([])
  const [selectedBillFrom, setselectedBillFrom] = useState(null)
  const [selectedBillTo, setselectedBillTo] = useState(null)
  const [selectedItem, setselectedItem] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [permissions, setPermissions] = useState<any>({})
  const [dealId, setDealId] = useState('')
  const [quoteId, setQuoteId] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const [quote, setQuote] = useState({
    date: new Date(),
    agent: '',
    client: '',
    id: '',
    property: '',
    subtotal: 0,
    tax: 0,
    total: 0,
    notes: '',
  })
  const [items, setItems] = useState([
    {
      name: '',
      description: '',
      amount: '',
    },
  ])

  const currencyOptions = [
    {
      value: 'AED',
      label: 'AED - United Arab Emirates dirham',
      flag: 'flags/united-arab-emirates.svg',
    },
    {value: 'USD', label: 'USD - USA dollar', flag: 'flags/united-states.svg'},
    {value: 'GBP', label: 'GBP - British pound', flag: 'flags/united-kingdom.svg'},
    {value: 'AUD', label: 'AUD - Australian dollar', flag: 'flags/australia.svg'},
    {value: 'JPY', label: 'JPY - Japanese yen', flag: 'flags/japan.svg'},
    {value: 'SEK', label: 'SEK - Swedish krona', flag: 'flags/sweden.svg'},
    {value: 'CAD', label: 'CAD - Canadian dollar', flag: 'flags/canada.svg'},
    {value: 'CHF', label: 'CHF - Swiss franc', flag: 'flags/switzerland.svg'},
    // Add other options similarly
  ]
  const defaultCurrency = currencyOptions.find((option) => option.value === 'AED')

  const getClientList = async () => {
    try {
      const res = await getClientbycompany(currentUser?.company)
      setClientsList(
        res.map((r) => ({
          value: r._id,
          label: r.name, // Corrected the typo here from "labe" to "label"
        }))
      )
      dispatch(setClientsData(res))

      console.log(res)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getClientListbyagent = async () => {
    try {
      const res = await getClientbyagent(currentUser?._id)
      setClientsList(
        res.map((r) => ({
          value: r._id,
          label: r.name, // Corrected the typo here from "labe" to "label"
        }))
      )

      dispatch(setClientsData(res))
      console.log(res)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getAgenttList = async () => {
    try {
      const res = await getAgent(currentUser?.company)
      setAgentsList(
        res.map((r) => ({
          value: r._id,
          label: r.fullName, // Corrected the typo here from "labe" to "label"
        }))
      )
      dispatch(setAgentsData(res))
      console.log(res)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getPropertyList = async () => {
    try {
      const res = await getProperty(currentUser?.company)
      setPropertyList(
        res.map((r) => ({
          value: r._id,
          label: r.name, // Corrected the typo here from "labe" to "label"
        }))
      )

      console.log(res)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const getQuoteLength = async () => {
    try {
      const res = await getQuoteid()
      setQuote((prevInvoice) => ({
        ...prevInvoice,
        id: res,
        // You can add other properties if needed
      }))

      console.log(res)
    } catch (error) {
      console.error('Error fetching client list:', error)
    }
  }

  const fetchQuoteData = async () => {
    const res = await getQuotebyids(params.id)
    console.log(res)
    const resp = res[0]
    setQuote((prevInvoice) => ({
      ...prevInvoice,
      date: new Date(resp?.date),
      client: resp.client._id,
      id: resp?.id,
      property: resp?.inventory?._id,
      subtotal: resp?.subtotal,
      tax: resp?.tax,
      total: resp?.total,
      notes: resp?.notes,
    }))
    setItems(
      resp.items.map((item) => ({
        name: item?.name,
        description: item?.description,
        amount: item?.amount,
      }))
    )
    setselectedBillTo({
      value: resp?.client?._id,
      label: resp?.client?.name,
    })
    setselectedItem({
      value: resp?.inventory?._id,
      label: resp?.inventory?.name,
    })
    setQuoteId(resp?._id)
    setDealId(resp?.deal)
  }

  useEffect(() => {
    if (clientListData.length === 0) {
      if (permissions?.sales?.admin === false) {
        getClientListbyagent()
        setQuote((prev) => ({
          ...prev,
          agent: currentUser?._id,
        }))
      } else if (currentUser?.profileType === 'superadmin' || permissions?.sales?.admin) {
        getClientList()
      }
    } else {
      setClientsList(
        clientListData.map((r) => ({
          value: r._id,
          label: r.name,
        }))
      )
    }
  }, [permissions])

  useEffect(() => {
    fetchQuoteData()
    getPropertyList()
    getQuoteLength()
    if (agentListData.length === 0) getAgenttList()
    else {
      setAgentsList(
        agentListData.map((r) => ({
          value: r._id,
          label: r.fullName,
        }))
      )
    }
  }, [])

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    e.preventDefault()
    const {name, value} = e.target
    setQuote((prevInvoice) => ({
      ...prevInvoice,
      [name]: value,
      // You can add other properties if needed
    }))
  }

  const handleChangerows = (index, e) => {
    const {name, value} = e.target
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      updatedItems[index] = {
        ...updatedItems[index],
        [name]: value,
      }
      return updatedItems
    })
  }

  useEffect(() => {
    setQuote((prevInvoice) => ({
      ...prevInvoice,
      subtotal: items.reduce((accumulator, currentItem) => {
        // Assuming that the 'amount' property is a number
        return accumulator + parseFloat(currentItem.amount)
      }, 0),
      // You can add other properties if needed
    }))
  }, [items])

  useEffect(() => {
    setQuote((prevInvoice) => {
      const subtotal = prevInvoice.subtotal || 0
      const tax = prevInvoice.tax || 0

      const total = (subtotal * (1 + tax / 100)).toFixed(2)

      return {
        ...prevInvoice,
        total: parseFloat(total),
        // You can add other properties if needed
      }
    })
  }, [items, quote.tax])

  const handleBillToChange = (selectedOption) => {
    setselectedBillTo(selectedOption)

    setQuote((prevInvoice) => ({
      ...prevInvoice,
      client: selectedOption.value,
    }))
  }

  const handleBillFromChange = (selectedOption) => {
    setselectedBillFrom(selectedOption)
    setQuote((prevInvoice) => ({
      ...prevInvoice,
      agent: selectedOption.value,
    }))
  }

  const handleItemChange = (selectedOption) => {
    setselectedItem(selectedOption)
    setQuote((prevInvoice) => ({
      ...prevInvoice,
      property: selectedOption.value,
    }))
  }

  const handleDateChange = (selectedDate) => {
    setQuote((prevInvoice) => ({
      ...prevInvoice,
      date: selectedDate[0], // Flatpickr returns an array of selected dates
    }))
  }

  useEffect(() => {
    console.log(quote)
  }, [quote])

  const handleSubmit = async () => {
    if (quote.subtotal === 0) {
      alert('Please add items to quote')
      return
    }
    if (!quote.client) {
      alert('Please select a client')
      return
    }
    setLoading(true)
    const data = {
      ...quote,
      items,
      deal: dealId,
    }
    try {
      await editQuote(data, quoteId).then((res) => {
        setSubmitted(true)
        console.log(res)
        navigate(`/sales/quote/details/${res.id}`)
      })
    } catch (error) {}
  }

  const additem = () => {
    setItems([
      ...items,
      {
        name: '',
        description: '',
        amount: '',
      },
    ])
  }

  const removeitem = (index) => {
    if (items.length === 1) {
      setItems([
        {
          name: '',
          description: '',
          amount: '',
        },
      ])
      return
    }
    const temp = [...items]
    temp.splice(index, 1)
    setItems(temp)
  }

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid'>
      {/* begin::Content container */}
      <div id='kt_app_content_container' className='app-container container-xxl'>
        {/* begin::Layout */}
        <div className='d-flex flex-column flex-lg-row'>
          {/* begin::Content */}
          <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
            {/* begin::Card */}
            <div className='card'>
              {/* begin::Card body */}
              <div className='card-body p-12'>
                {/* begin::Form */}
                <form action='' id='kt_quote_form'>
                  {/* begin::Wrapper */}
                  <div className='d-flex flex-column align-items-start flex-xxl-row'>
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center flex-equal fw-row me-4 order-2'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify quote date'
                    >
                      {/* begin::Date */}
                      <div className='fs-6 fw-bold text-gray-700 text-nowrap'>Date:</div>
                      {/* Date */}
                      {/* begin::Input */}
                      <div className='position-relative d-flex align-items-center w-150px'>
                        {/* begin::Datepicker */}
                        <Flatpickr
                          value={quote.date}
                          onChange={handleDateChange}
                          className='form-control form-control-solid'
                          placeholder='Pick date'
                        />
                        {/* Datepicker */}
                        {/* begin::Icon */}
                        <i className='ki-duotone ki-down fs-4 position-absolute ms-4 end-0'></i>
                        {/* Icon */}
                      </div>
                      {/* Input */}
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div
                      className='d-flex flex-center flex-equal fw-row text-nowrap order-1 order-xxl-2 me-4'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Enter quote number'
                    >
                      <span className='fs-2x fw-bold text-gray-800'>Quotation #</span>
                      <input
                        type='text'
                        className='form-control form-control-flush fw-bold text-muted fs-3 w-125px'
                        value={quote.id}
                        placeholder='...'
                        disabled
                      />
                    </div>
                    {/* Input group */}
                    {/* begin::Input group */}
                    <div
                      className='d-flex align-items-center justify-content-end flex-equal order-3 fw-row'
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      title='Specify quote due date'
                    ></div>
                    {/* Input group */}
                  </div>
                  {/* Top */}
                  {/* begin::Separator */}
                  <div className='separator separator-dashed my-10'></div>
                  {/* Separator */}
                  {/* begin::Wrapper */}
                  <div className='mb-0'>
                    {/* begin::Row */}
                    <div className='row gx-10 mb-5'>
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Bill From
                        </label>
                        {/* begin::Input group */}
                        {permissions?.sales?.admin ? (
                          <Select
                            name='agent'
                            aria-label='Select a Agent'
                            placeholder='Select agent'
                            options={agentsList}
                            defaultValue={{value: currentUser?._id, label: currentUser?.fullName}}
                            isSearchable={true}
                            className='form-control form-control-solid'
                            onChange={handleBillFromChange}
                            value={selectedBillFrom}
                          />
                        ) : (
                          <input
                            type='text'
                            className='form-control form-control-solid'
                            disabled
                            value={currentUser?.fullName}
                            placeholder={currentUser?.fullName}
                          />
                        )}

                        {/* Input group */}
                      </div>
                      {/* Col */}
                      {/* begin::Col */}
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Bill To
                        </label>
                        {/* begin::Input group */}
                        <Select
                          name='client'
                          aria-label='Select a Client'
                          placeholder='Select a Client'
                          options={clientsList}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={handleBillToChange}
                          value={selectedBillTo}
                        />
                      </div>
                      <div className='col-lg-12'>
                        <label className='form-label fs-6 fw-bold text-gray-700 mb-3'>
                          Property
                        </label>
                        {/* begin::Input group */}
                        <Select
                          name='property'
                          aria-label='Select a Property'
                          placeholder='Select a Property'
                          options={propertyList}
                          isSearchable={true}
                          className='form-control form-control-solid'
                          onChange={handleItemChange}
                          value={selectedItem}
                        />
                      </div>
                      {/* Col */}
                    </div>
                    {/* Row */}
                    {/* begin::Table wrapper */}
                    <div className='table-responsive mb-10'>
                      {/* begin::Table */}
                      <table
                        className='table g-5 gs-0 mb-0 fw-bold text-gray-700'
                        data-kt-element='items'
                      >
                        {/* begin::Table head */}
                        <thead>
                          <tr className='border-bottom fs-7 fw-bold text-gray-700 text-uppercase'>
                            <th className='min-w-100px w-250px'>Item</th>
                            <th className='min-w-150px w-250px'>Description</th>
                            <th className='min-w-50px w-150px'>Amount</th>
                            <th className='min-w-70px w-150px'>Action</th>
                          </tr>
                        </thead>
                        {/* Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          {items.map((item, index) => (
                            <tr
                              className='border-bottom border-bottom-dashed'
                              data-kt-element='item'
                              key={index}
                            >
                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  name='name'
                                  placeholder='Description'
                                  value={item.name}
                                  onChange={(e) => handleChangerows(index, e)}
                                />
                              </td>
                              <td className='pe-7'>
                                {/* begin::Input group */}

                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  name='description'
                                  placeholder='Description'
                                  value={item.description}
                                  onChange={(e) => handleChangerows(index, e)}
                                />
                              </td>

                              <td>
                                <input
                                  type='number'
                                  className='form-control form-control-solid text-end'
                                  name='amount'
                                  placeholder='0.00'
                                  value={item.amount}
                                  onChange={(e) => handleChangerows(index, e)}
                                />
                              </td>
                              <td>
                                <div
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
                                  onClick={additem}
                                >
                                  <KTIcon iconName='plus-square' className='fs-3' />
                                </div>
                                <div
                                  className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm '
                                  onClick={() => removeitem(index)}
                                >
                                  <KTIcon iconName='trash' className='fs-3' />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        {/* Table body */}
                        {/* begin::Table foot */}
                        <tfoot>
                          <tr className='border-top border-top-dashed align-top fs-6 fw-bold text-gray-700'>
                            <th className='text-primary'></th>
                            <th className='text-primary'></th>
                            <th className='border-bottom border-bottom-dashed ps-0'>
                              <div className='d-flex flex-column align-items-start'>
                                <div className='fs-5'>Subtotal</div>
                                <button
                                  className='btn btn-link '
                                  data-bs-toggle='tooltip'
                                  data-bs-trigger='hover'
                                  title='Tax in percentage'
                                >
                                  Add tax %
                                </button>
                              </div>
                            </th>
                            <th className='border-bottom border-bottom-dashed'>
                              <span data-kt-element='sub-total' className=' '>
                                {quote.subtotal}
                              </span>
                              <input
                                type='number'
                                className='form-control form-control-solid '
                                name='tax'
                                placeholder='0.00'
                                value={quote.tax}
                                onChange={handleChange}
                              />
                            </th>
                          </tr>
                          <tr className='align-top fw-bold text-gray-700'>
                            <th className='min-w-100px w-100px'></th>
                            <th className='min-w-150px w-150px'></th>
                            <th className='fs-4 ps-0 min-w-50px w-50px'>Total</th>
                            <th className='text-end fs-4 text-nowrap min-w-70px w-70px'>
                              AED
                              <span data-kt-element='grand-total'> {quote.total}</span>
                            </th>
                          </tr>
                        </tfoot>
                        {/* Table foot */}
                      </table>
                    </div>
                    {/* Table */}
                    {/* begin::Item template */}

                    <table className='table d-none' data-kt-element='empty-template'>
                      <tr data-kt-element='empty'>
                        <th colSpan={5} className='text-muted text-center py-10'>
                          No items
                        </th>
                      </tr>
                    </table>
                    {/* Item template */}
                    {/* begin::Notes */}
                    <div className='mb-0'>
                      <label className='form-label fs-6 fw-bold text-gray-700'>Notes</label>
                      <input
                        type='textarea'
                        name='notes'
                        className='form-control form-control-solid'
                        placeholder='Thanks for your business'
                        value={quote.notes}
                        onChange={handleChange}
                      />
                    </div>
                    {/* Notes */}
                  </div>
                  {/* Wrapper */}
                </form>
                {/* Form */}
              </div>
              {/* Card body */}
            </div>
            {/* Card */}
          </div>
          {/* Content */}
          {/* begin::Sidebar */}
          <div className='flex-lg-auto min-w-lg-300px'>
            {/* begin::Card */}
            <div
              className='card'
              data-kt-sticky='true'
              data-kt-sticky-name='quote'
              data-kt-sticky-offset="{default: false, lg: '200px'}"
              data-kt-sticky-width="{lg: '250px', lg: '300px'}"
              data-kt-sticky-left='auto'
              data-kt-sticky-top='150px'
              data-kt-sticky-animation='false'
              data-kt-sticky-zindex='95'
            >
              {/* begin::Card body */}
              <div className='card-body p-10'>
                {/* begin::Input group */}
                <div className='mb-10'>
                  <label className='form-label fw-bold fs-6 text-gray-700'>Currency</label>
                  <Select
                    name='currency'
                    aria-label='Select a Currency'
                    placeholder='Select currency'
                    options={currencyOptions}
                    defaultValue={defaultCurrency}
                    isSearchable={true}
                    className=''
                  />
                </div>
                {/* <!--::Input group-->
                        <!--begin::Separator--> */}

                {/* <!--::Separator-->
                        <!--begin::Input group--> */}
                <div className='mb-8'>
                  {/* <!--begin::Option--> */}
                  <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-5'>
                    <span className='form-check-label ms-0 fw-bold fs-6 text-gray-700'>
                      Payment method
                    </span>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      defaultChecked={true}
                      value=''
                    />
                  </label>

                  {/* <!--::Option-->
                            <!--begin::Option--> */}
                  <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack mb-5'>
                    <span className='form-check-label ms-0 fw-bold fs-6 text-gray-700'>
                      Late fees
                    </span>
                    <input className='form-check-input' type='checkbox' value='' />
                  </label>
                  {/* <!--::Option-->
                            <!--begin::Option--> */}
                  <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                    <span className='form-check-label ms-0 fw-bold fs-6 text-gray-700'>Notes</span>
                    <input className='form-check-input' type='checkbox' value='' />
                  </label>
                  {/* <!--::Option--> */}
                </div>
                {/* <!--::Input group-->
                        <!--begin::Separator--> */}
                <div className='separator separator-dashed mb-8'></div>
                {/* <!--::Separator-->
                        <!--begin::Actions--> */}
                <div className='mb-0'>
                  {/* <!--begin::Row--> */}

                  {/* <!--::Row--> */}
                  {!submitted ? (
                    <button
                      className='btn btn-primary w-100'
                      id='kt_quote_submit_button'
                      onClick={handleSubmit}
                      disabled={loading}
                    >
                      <i className='ki-duotone ki-triangle fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      Edit Quotation
                    </button>
                  ) : (
                    <button className='btn btn-danger w-100' id='kt_quote_submit_button'>
                      <i className='ki-duotone ki-triangle fs-3'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      Print Quotation
                    </button>
                  )}
                </div>
                {/* <!--::Actions--> */}
              </div>
              {/* <!--::Card body--> */}
            </div>
            {/* <!--::Card--> */}
          </div>
          {/* <!--::Sidebar--> */}
        </div>
        {/* <!--::Layout--> */}
      </div>
      {/* <!--::Content container--> */}
    </div>
  )
}

export default EditQuote
