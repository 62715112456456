/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef, useState } from 'react'

import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {  deletedocs, getDocs, uploaddocs } from '../core/_requests'
import { Link, useParams } from 'react-router-dom';





const Documents: React.FC = () => {
  const PF:any = "https://unifeed.s3.ap-south-1.amazonaws.com/";
  const id  = useParams().id
  const [docs, setdocs] = useState<any>([]); 
  const [name, setname] = useState("")
  const [filename, setfilename] = useState("")
  const [excelFile, setExcelFile] = useState(null);
  const fileInputRef = useRef(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setfilename(event.target.files[0].name)
    setExcelFile(file);
  };

  const [deleteid, setdeleteid] = useState();


  const getdocss = useCallback(async () => {
    try {
      const res = await getDocs(id);
      setdocs(res.documents);

    } catch (error) {
      console.error('Error fetching client list:', error);
    }
  }, [id])
  
  useEffect(() => {
    getdocss()
  }, [id, getdocss]);



  
  
  const handleUpload = async () => {

    if (!excelFile) {
      console.error('No file selected');
      return;
    }

    const timestamp = Date.now();

      const formData = new FormData();
      formData.append('file', excelFile);
      formData.append('name', name);
      formData.append('filename', timestamp+filename);



    try {
      const response = await uploaddocs(formData,id)

      if (response ==="success") {
        console.log('File uploaded successfully');
        // Optionally, update UI to indicate successful upload
        // window.location.reload();
        getdocss()
        setExcelFile(null);
        setname('')
        setfilename('')
        const closeModalButton = document.getElementById('closeModalButton');
        closeModalButton?.click();
      } else {
        alert("failed to upload please try again")
        // Optionally, update UI to indicate failure
      }
    } catch (error) {
      console.error('Error:', error);
      // Opti

    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };


  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A';
  
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    
    });
  
    return formattedDate;
  };

  const handleDownload = (product) => {
    // You can construct the file URL here (replace this with your logic)
    const fileUrl = `${PF}${product.filename}`;

    // Create a temporary anchor element
    const downloadLink = document.createElement('a');
    downloadLink.href = fileUrl;

    // Set the download attribute with the desired filename
    downloadLink.download = product.filename;

    // Append the anchor element to the body
    document.body.appendChild(downloadLink);

    // Trigger a click event to start the download
    downloadLink.click();

    // Remove the anchor element from the body
    document.body.removeChild(downloadLink);
  };

  const handledelete = async() => {
    try{
      const res =await deletedocs(id,deleteid).then((res)=>{
        getdocss()
      })
  
      const closeModalButton = document.getElementById('closeModalButtondeldelete');
  
      if (closeModalButton) {
        closeModalButton.click();
      }
    }catch(error){
      console.log(error)
    }
   

  }



  return (
    <>
    
    <div className={'card h-md-50 mb-5 mb-xl-10 min-h-500px overflow-scroll'}>
      {/* begin::Header */}
           

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'></span>
         
        </h3>
        <div className='d-flex flex-row'>
        <div className='card-toolbar me-5'    data-bs-toggle="modal" data-bs-target="#kt_modal_11" >
          <a  className='btn btn-sm btn-light-primary'>
            <KTIcon iconName='plus' className='fs-2' />
            Add Documents
          </a>
        </div>


      </div>
        
 
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
        
                <th className='min-w-150px'>Name</th>
                <th className='min-w-150px'>Date</th>
                <th className='min-w-150px'>Action</th>
               
            
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {
            docs.map((product:any | undefined) => (
              <tr key={product?._id?.toString()}>
              
                <td>
                  <div className='d-flex align-items-center'>
        
                    <Link to={`/agents/agentDetails/${product._id}`} className=' client_field cursor-pointer '>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        {product?.name}
                      </a>
                     
                    </div>
                    </Link>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                 { formatCreatedAt(product?.date)}
                  </a>
                </td>
                <td>
                <div className='d-flex flex-row'>
                  <div className='card-toolbar me-5'  >
                  {/* <a href={PF+product.filename} className="btn btn-sm btn-light-danger me-5" download>
                      Download
                  </a> */}
                    <a href={PF+product.filename} className="btn btn-sm btn-light-warning" download target="_blank">
                    Open 
                </a>


         
        </div>
        <div className='card-toolbar me-5'  >
             
             <div className="btn btn-sm btn-light-danger" onClick={() =>setdeleteid(product?._id)}  data-bs-toggle="modal" data-bs-target="#kt_modal_12" >
             Delete 
         </div>
         </div>
     


      </div>
           
                </td>
              
                

                

                {/* <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>50%</span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-primary'
                        role='progressbar'
                        style={{width: '50%'}}
                      ></div>
                    </div>
                  </div>
                </td>
                 */}
              </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>





    <div className="modal fade" tabIndex={-1} id="kt_modal_12">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Delete Document</h5>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
        
        </div>
      </div>

      <div className="row modal-body">
          Delete This Document
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          data-bs-dismiss="modal"
          id="closeModalButtondeldelete" 
        >
          Close
        </button>
        <button type="button" className="btn btn-danger" onClick={() =>handledelete()}   >
          Delete
        </button>
      </div>
    </div>
  </div>
  </div>
  
    



  <div className="modal fade" tabIndex={-1} id="kt_modal_11">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Add Documents</h5>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon svg-icon-2x"
          />
        </div>
      </div>

      <div className="modal-body">
      <form >
      <div className="row rowwidthclient">
        <div className="form-group mb-3 col-md-12">
          <label>File Name</label>
          <input
            type="text"
            name="name"
            value={name}
            onChange={(e)=>setname(e.target.value)}
            className="form-control"
          />
        </div>
    
       
          <div className='card-toolbar'>
        <a className='btn btn-sm btn-light-danger' onClick={handleButtonClick}>
          <KTIcon iconName='plus' className='fs-2' />
          Upload File
        </a>
      </div>
           
          
      
      <input
  type='file'
  accept='.pdf, .jpg, .jpeg, .png, .gif, .doc, .docx, .xlsx, .xls'
  onChange={handleFileChange}
  ref={fileInputRef}
  style={{ display: 'none' }}
/>
      {excelFile && <p>Selected file: {excelFile.name}</p>}
        
       
      </div>
      
    </form>
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          data-bs-dismiss="modal"
          id="closeModalButton" 
       
        >
          Close
        </button>
        <button type="button" className="btn btn-primary"    onClick={handleUpload}>
          Save changes
        </button>
      </div>
    </div>
  </div>
</div>

</>
    
    )
  }
export {Documents}
