import React, { useEffect, useState } from 'react'


import { gettodo } from "./core/_requests";
import { Link, useParams } from "react-router-dom";
function TodoList() {
// const {currentUser} = useAuth()
const [todolist, settodolist] = useState([]);

  // const [selectedDate, setSelectedDate] = useState(new Date());
  const id = useParams().id
  // const [title, settitle] = useState('');
  //   const [selectedOption, setSelectedOption] = useState('');
  //   const [color, setcolor] = useState('');
  //   const [agentids, setagentids] = useState<string[]>([]);
  //   const [permissions, setPermissions] = useState<any>({})

    // useEffect(() => {
    //   if (currentUser?.permissions) {
    //     setPermissions(currentUser?.permissions)
    //   }
    // }, [currentUser]) 

    // useEffect(() => {
    //   if(selectedOption==="Important"){
    //    setcolor("#dc3545")
    //   }else if(selectedOption==="Medium"){
    //    setcolor("#ffc107")
    //   }else{
    //    setcolor("#28a745")
    //   }
    //  }, [selectedOption])


    const getevent = async () => {
        try {
          const res = await gettodo(id);
          settodolist(res);  // Assuming that res is an array
          console.log(res)
        } catch (error) {
          console.error("Error fetching todo list:", error);
        }
      }

      useEffect(() => {
      getevent()
      }, [])
    

      // const handletodosubmit = async () => {
      //   const todo = {
      //     title,
      //     start: selectedDate,
      //     end: selectedDate,
      //     type: "todo",
      //     priority: selectedOption,
      //     color: color,
      //     agentids,
      //   };
      
      //   await addevents(todo, currentUser?._id).then((res) => {
      //     const updatevents = [...todolist, res];
      //     console.log(res);
      //     settitle('');
      //     setSelectedOption('');
      //     setcolor('');
      //     setSelectedDate(new Date());
      //     setagentids([]);
      //     settodolist(updatevents);
      //     getevent();
      //     setProperty("");
      //     setselectedBillFrom(null);
      
      //     const closeModalButton = document.getElementById('closeModalButton');
      //     if (closeModalButton) {
      //       closeModalButton.click();
      //     }
      //   });
      // };
        

  return (
    <div className='card w-100 h-100 overflow-scroll'>
       <div className='card-header flex justify-between border-0   '>
    <h3 className='card-title fw-bold text-dark'>Todo</h3>
    <div className='card-toolbar gap-3'>
      {/* begin::Menu */}
   
      

      {/* end::Menu */}
    </div>
  </div>
  {/* end::Header */}
  {/* begin::Body */}
  <div className='card-body pt-2'>
            <div id="external-events">
              {todolist.map((event) => (
                <div  title={event.title} key={event.id}>
                  {
                    event.type === "meeting" ? (
                      <Link to={`/clients/clientDetails/${event.client?._id}`} className='d-flex align-items-center mb-8'>
                <span className={`bullet bullet-vertical h-40px me-5` } style={{backgroundColor:event.color}}></span>
                {/* end::Bullet */}
                {/* begin::Checkbox */}
          
                {/* end::Checkbox */}
                {/* begin::Description */}
                <div className='flex-grow-1'>
                  <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
                  {event.title}
                  </a>
                  <span className='text-muted fw-semibold d-block'>{event.type}</span>
                </div>
                {/* end::Description */}
                <span className={`badge  fs-8 fw-bold ${event.priority==="Important"?"badge-light-danger":event.priority==="Medium"?"badge-light-warning":"badge-light-success"}`}>{event.priority}</span>
              </Link>
                    ):(
                      
                       
                       <div className='d-flex align-items-center mb-8'>  
                    <span className={`bullet bullet-vertical h-40px me-5` } style={{backgroundColor:event.color}}></span>
             
          
                    <div className='flex-grow-1'>
                      <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
                      {event.title}
                      </a>
                      <span className='text-muted fw-semibold d-block'>{event.type}</span>
                    </div>
                    {/* end::Description */}
                    <span className={`badge  fs-8 fw-bold ${event.priority==="Important"?"badge-light-danger":event.priority==="Medium"?"badge-light-warning":"badge-light-success"}`}>{event.priority}</span>
                
                        </div>
                      
                    )
                  }

                  
              </div>
              ))}
            </div>
        </div>
               

    
               </div>
  )
}

export default TodoList