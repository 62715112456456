import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'

const Step3: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Business Infonrmation</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Property Price</label>

        <Field name='propertyPrice' className='form-control form-control-lg form-control-solid' />
        <div className='text-danger mt-2'>
          <ErrorMessage name='propertyPrice' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center form-label'>
          <span className='required'>Number of Units</span>
        </label>

        <Field
          name='propertyUnits'
          className='form-control form-control-lg form-control-solid'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='propertyUnits' />
        </div>

       
      </div>

      
      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-3'>
          Aminities
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Provide your team size to help us setup your billing'
          ></i>
        </label>

    
        <div className='form-text mt-10'>
        Appliances
        </div>

        <div className='row mb-2' data-kt-buttons='true'>
          <div className='col'>
            <Field
              type='checkbox'
              className='btn-check'
              name='propertyAnimities'
              value='Refrigerator'
              id='kt_account_team_size_select_1'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_1'
            >
              <span className='fw-bolder fs-3'>Refrigerator</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='checkbox'
              className='btn-check'
              name='propertyAnimities'
              value='Stove'
              id='kt_account_team_size_select_2'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_2'
            >
              <span className='fw-bolder fs-3'>Stove</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='checkbox'
              className='btn-check'
              name='propertyAnimities'
              value='Microwave'
              id='kt_account_team_size_select_3'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_3'
            >
              <span className='fw-bolder fs-3'>Microwave</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='checkbox'
              className='btn-check'
              name='propertyAnimities'
              value='Dishwasher'
              id='kt_account_team_size_select_4'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_4'
            >
              <span className='fw-bolder fs-3'>Dishwasher</span>
            </label>
          </div>
        </div>
        
        <div className='row mb-2' data-kt-buttons='true'>
          <div className='col'>
            <Field
              type='checkbox'
              className='btn-check'
              name='propertyAnimities'
              value='Washer'
              id='kt_account_team_size_select_5'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_5'
            >
              <span className='fw-bolder fs-3'>Washer</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='checkbox'
              className='btn-check'
              name='propertyAnimities'
              value='Dryer'
              id='kt_account_team_size_select_6'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_6'
            >
              <span className='fw-bolder fs-3'>Dryer</span>
            </label>
          </div>

    



        <div className='form-text mt-10'>
        Utilities
        </div>
        <div className='row mb-2' data-kt-buttons='true'>
          <div className='col'>
            <Field
              type='checkbox'
              className='btn-check'
              name='propertyAnimities'
              value='Haeter'
              id='kt_account_team_size_select_7'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_7'
            >
              <span className='fw-bolder fs-3'>Heater</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='checkbox'
              className='btn-check'
              name='propertyAnimities'
              value='A/C'
              id='kt_account_team_size_select_8'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_8'
            >
              <span className='fw-bolder fs-3'>A/C</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='checkbox'
              className='btn-check'
              name='propertyAnimities'
              value='Wifi'
              id='kt_account_team_size_select_9'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_9'
            >
              <span className='fw-bolder fs-3'>Wifi</span>
            </label>
          </div>

        </div>




        <div className='form-text mt-10'>
        Security and Safety
        </div>
        <div className='row mb-2' data-kt-buttons='true'>
          <div className='col'>
            <Field
              type='checkbox'
              className='btn-check'
              name='propertyAnimities'
              value='Secure Entry System'
              id='kt_account_team_size_select_10'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_10'
            >
              <span className='fw-bolder fs-3'>Secure Entry System</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='checkbox'
              className='btn-check'
              name='propertyAnimities'
              value='Surveillance Cameras'
              id='kt_account_team_size_select_11'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_11'
            >
              <span className='fw-bolder fs-3'>Surveillance Cameras</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='checkbox'
              className='btn-check'
              name='propertyAnimities'
              value='Smoke Detectors'
              id='kt_account_team_size_select_12'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_12'
            >
              <span className='fw-bolder fs-3'>Smoke Detectors</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='checkbox'
              className='btn-check'
              name='propertyAnimities'
              value='Fire Sprinklers System'
              id='kt_account_team_size_select_13'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_13'
            >
              <span className='fw-bolder fs-3'>Fire Sprinklers System</span>
            </label>
          </div>

        </div>

        <div className='form-text mt-10'>
        Facilities
        </div>
        <div className='row mb-2' data-kt-buttons='true'>
          <div className='col'>
            <Field
              type='checkbox'
              className='btn-check'
              name='propertyAnimities'
              value='Lounge'
              id='kt_account_team_size_select_14'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_14'
            >
              <span className='fw-bolder fs-3'>Lounge</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='checkbox'
              className='btn-check'
              name='propertyAnimities'
              value='Gym'
              id='kt_account_team_size_select_15'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_15'
            >
              <span className='fw-bolder fs-3'>Gym</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='checkbox'
              className='btn-check'
              name='propertyAnimities'
              value='Swimming Pool'
              id='kt_account_team_size_select_16'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_16'
            >
              <span className='fw-bolder fs-3'>Swimming Pool</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='checkbox'
              className='btn-check'
              name='propertyAnimities'
              value='sauna'
              id='kt_account_team_size_select_17'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_17'
            >
              <span className='fw-bolder fs-3'>sauna</span>
            </label>
          </div>

        </div>


        
        <div className='form-text mt-10'>
        Other
        </div>
        <div className='row mb-2' data-kt-buttons='true'>
          <div className='col'>
            <Field
              type='checkbox'
              className='btn-check'
              name='propertyAnimities'
              value='Parking'
              id='kt_account_team_size_select_18'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_18'
            >
              <span className='fw-bolder fs-3'>Parking</span>
            </label>
          </div>

          <div className='col'>
            <Field
              type='checkbox'
              className='btn-check'
              name='propertyAnimities'
              value='Furniture'
              id='kt_account_team_size_select_19'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4'
              htmlFor='kt_account_team_size_select_19'
            >
              <span className='fw-bolder fs-3'>Furniture</span>
            </label>
          </div>

        </div>

        

        </div>


















        
      </div>

      
    </div>
  )
}

export {Step3}
