import React, { useEffect, useState } from 'react'

import { Link, useParams } from 'react-router-dom'
import {  getDealsofagent } from '../../sales/core/_requests';

function Deallist() {
const [Leadlist, setLeadlist] = useState<any>([])
const id = useParams().id

useEffect(() => {
  const getdealslist = async() => {
   
   
      const res = await getDealsofagent(id)
      setLeadlist(res)
    
    
  }
  
  getdealslist()
}, [id])


//   const handleSubmit = async() => {
//    const data:any = {
//         agent: currentUser?._id,
//         client:selectedBillFrom.value
//     }
//     try{
//          await postlead(data,currentUser?.company).then((res)=> {
//           getdealslist()
//            console.log(res)

//               const closeModalButton = document.getElementById('closeModalButton');
//         if (closeModalButton) {
//           closeModalButton.click();
//         }
    
           
//          })
//     }catch(error){

//     }
// }



  return (
    <div className={`card h-md-50 mb-5 mb-xl-10`}>

         

    <div className='card-header border-0 pt-5'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bold fs-3 mb-1'>Deals</span>
       
      </h3>

      {/* <div className='card-toolbar' data-bs-toggle="modal" data-bs-target="#kt_modal_1">
        <a href='#' className='btn btn-sm btn-light-primary'>
          <KTIcon iconName='plus' className='fs-2' />
          Add Deals
        </a>
      </div> */}

    </div>
    {/* end::Header */}
    {/* begin::Body */}
    <div className='card-body py-3'>
      {/* begin::Table container */}
      <div className='table-responsive'>
        {/* begin::Table */}
        <table className='table align-middle gs-0 gy-4 table-row-bordered' >
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold text-muted bg-light'>
              <th className='ps-4 min-w-200px rounded-start'>ID</th>
              <th className='min-w-200px'>Agent</th>
              <th className='min-w-200px'>Client</th>
              <th className='min-w-100px'>Status</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
          {Leadlist.map((lead:any) => (
            <tr key={lead?._id?.toString()}>
                
                <td>
                <Link to={`/sales/dealdetails/${lead._id}`}>
                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                {lead?.id}
                </div>
                </Link>
              </td>
             <td>
             <Link to={`/sales/dealdetails/${lead._id}`}>
                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                {lead?.agent?.fullName}
                </div>
                </Link>
              </td>
              <td>
              <Link to={`/sales/dealdetails/${lead._id}`}>
                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                {lead?.client?.name}
                </div>
                </Link>
              </td>
              <td>
              <Link to={`/sales/dealdetails/${lead._id}`}>
                <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6' >
                <span className={`badge  fs-7 fw-semibold ${lead?.status==='open' ? "badge-light-primary" :"badge-light-primary"}`}> {lead?.status}</span>
                </div>
                </Link>
              </td>
             
              
            </tr>
          ))}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
      {/* end::Table container */}
    </div>
    {/* begin::Body */}

    {/* Modal for add client */}


        
    {/* <div className="modal fade" tabIndex={-1} id="kt_modal_1">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Add Deals</h5>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon svg-icon-2x"
          />
        </div>
      </div>

      <div className="modal-body">
      <form >
      <div className="row rowwidthclient">
        <div className="form-group mb-3 col-md-12">
          <label>Select Client</label>
          <Select
            name="client"
            aria-label="Select Client"
            placeholder="Select client"
            options={clientsList}
            isSearchable={true}
            className="form-control form-control-solid"
            onChange={handleBillFromChange}
            value={selectedBillFrom}

        />
          
      </div>
      </div>
      
    </form>
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          data-bs-dismiss="modal"
          id="closeModalButton" 
       
        >
          Close
        </button>
        <button type="button" className="btn btn-primary"    onClick={handleSubmit}>
          Save changes
        </button>
      </div>
    </div>
  </div>
</div> */}

  </div>
  )
}

export default Deallist