import React, {useEffect, useRef, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import {debounce} from 'lodash'
import {getClients} from '../core/_requests'

const ClientSearch = ({setClient, clientId, setClientName, closeRef}) => {
  const {currentUser} = useAuth()
  const modalRef = useRef<HTMLDivElement | null>(null)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [clientList, setClientList] = useState<any>([])
  const [permissions, setPermissions] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (currentUser) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  const handleSearch = async () => {
    setLoading(true)
    let type: string, queryId: string
    if (permissions?.clients?.admin === false) {
      type = 'agent'
      queryId = currentUser?._id
    } else if (currentUser?.profileType === 'superadmin' || permissions?.clients?.admin) {
      type = 'company'
      queryId = currentUser?.company
    }

    try {
      if (searchTerm !== '') {
        await getClients(queryId, searchTerm, type).then((res) => {
          setClientList(res?.client)
          setLoading(false)
        })
      } else {
        setClientList([])
        setLoading(false)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const debouncedSearch = debounce(handleSearch, 500)
  useEffect(() => {
    debouncedSearch()

    return () => debouncedSearch.cancel()
  }, [searchTerm])

  const handleClickList = (e: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
      setClientList([])
      setSearchTerm('')
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickList)
    return () => {
      document.removeEventListener('mousedown', handleClickList)
    }
  }, [])

  return (
    <div>
      <h6>Select Client</h6>
      <input
        type='text'
        className='form-control'
        placeholder='Name'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <div ref={modalRef} className='serach'>
        <div className='search-list'>
          <div className='line'></div>

          {loading ? (
            <div className='d-flex justify-content-center my-5'>
              <div className='loader'></div>
            </div>
          ) : (
            <div className='card card-xl-stretch mb-xl-8 max-h-screen overflow-y-scroll'>
              {clientList?.map((user, index) => (
                <div className='card-body pt-3' key={user?._id}>
                  {/* begin::Item */}
                  <div className='d-flex align-items-sm-center mb-7'>
                    <div className='d-flex flex-row-fluid flex-wrap align-items-center gap-1   '>
                      <div className='flex-grow-1 me-2'>
                        <a href='#' className='text-gray-800 fw-bold text-hover-primary fs-6'>
                          {user?.name}
                        </a>
                        <span className='text-muted fw-semibold d-block pt-1'>{user?.phone}</span>
                      </div>

                      <div
                        className='card-toolbar'
                        onClick={() => {
                          setClient(user?._id)
                          setClientName(user)
                          closeRef?.current?.click()
                        }}
                      >
                        <button
                          type='button'
                          className={`btn-light-success btn btn-sm ${
                            user?._id === clientId ? 'active' : ''
                          }`}
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ClientSearch
