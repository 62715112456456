import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, StreetViewPanorama, } from '@react-google-maps/api';
import './map.css';
import { getProperty } from './core/_requests';
import { Property } from './core/_models';
import { useAuth } from '../../modules/auth';

// Import the CSS file with marker styles

interface MapProps {}

const MapWrapper: React.FC<MapProps> = () => {
  const [data, setData] = useState<Property[]>([]);
  const [selectedMarker, setSelectedMarker] = useState<Property | null>(null);
  const { currentUser } = useAuth();

  const PF: any = "https://unifeed.s3.ap-south-1.amazonaws.com/";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getProperty(currentUser?.company);
        setData(res);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [currentUser]);

  const mapContainerStyle = {
    height: '600px',
    width: '100%',
  };

  const center = { lat: 25.276987, lng: 55.296249 }; // Coordinates for Dubai
  const zoom = 12; // Adjust the zoom level as needed

  const handleMarkerClick = (property: Property) => {
    setSelectedMarker(property);
  };

  return (
    <div className="map-wrapper">
        <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={zoom}>
          {data.map((property) => (
            <Marker
              key={property._id}
              position={{ lat: parseFloat(property.lat.toString()), lng: parseFloat(property.lng.toString()) }}
              title={property.name || ''}
              onClick={() => handleMarkerClick(property)}
              icon={{
                url: PF + property.productPic, // Placeholder image URL
                scaledSize: new google.maps.Size(50, 50), // scaled size
                origin: new google.maps.Point(0,0), // origin
                anchor: new google.maps.Point(0, 0)
              }}
            />
          ))}


{selectedMarker && (
  <StreetViewPanorama
    options={{
      position: { lat: parseFloat(selectedMarker.lat.toString()), lng: parseFloat(selectedMarker.lng.toString()) },
      visible: true,
    }}
    onLoad={(streetViewPanorama) => {
      // Check if Street View data is available for the selected position
      const streetViewService = new window.google.maps.StreetViewService();
      streetViewService.getPanorama({ location: { lat: parseFloat(selectedMarker.lat.toString()), lng: parseFloat(selectedMarker.lng.toString()) } }, (data, status) => {
        if (status === window.google.maps.StreetViewStatus.OK) {
          // Street View is available for the selected position
          streetViewPanorama.setPosition({ lat: parseFloat(selectedMarker.lat.toString()), lng: parseFloat(selectedMarker.lng.toString()) });
        } else {
          // Street View is not available, show surrounding Street View
          const streetViewNearby = new window.google.maps.StreetViewService();
          streetViewNearby.getPanorama({ location: { lat: parseFloat(selectedMarker.lat.toString()), lng: parseFloat(selectedMarker.lng.toString()) }, radius: 50 }, (dataNearby, statusNearby) => {
            if (statusNearby === window.google.maps.StreetViewStatus.OK) {
              streetViewPanorama.setPosition(dataNearby.location.latLng);
            } else {
              // No Street View available for the selected location and surrounding area
              console.error('No Street View available for the selected location and surrounding area.');
              // You can display a message or take other actions as needed
            }
          });
        }
      });
    }}
  />
)}
        </GoogleMap>
    </div>
  );
};

export default MapWrapper;
