/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {Dropdown1} from '../../../../_metronic/partials'
import { getClientById, changePipeline } from '../core/_requests'
import { Client } from '../core/_models'
import { AgentlistbyId } from './AgentListbyId'
import { useAuth } from '../../../modules/auth'
import {Notes} from './Notes'
import { Logs } from './Logs'
import { PropertyListbyId } from './PropertyListbyId'
import { Documents } from './Documnets'
import Deallist from './Deallist'
import MeetList from './MeetList'




const ClientDetail: React.FC = () => {
  const {currentUser} = useAuth()
  const [profileData, setprofileData] = useState<Client>({});
  const [property, setProperty] = useState("overview")
  const clientId = useParams().id
  const [companyName, setCompanyName] = useState('')
  const [pipelinestatus, setpipelinestatus] = useState("")

  useEffect(() => {
		const Clientdetail = async() => {
      try{
      const res = await getClientById(clientId);
      setprofileData(res)
      setpipelinestatus(res.pipelinestatus)
      setCompanyName(res?.company?.name)
			}catch (error) {
       
        console.error('Error fetching client details:', error);
      }
    };
			Clientdetail()
	}, [clientId])

  const handlepipelinestatus = (status) => {
      if(status === pipelinestatus) return
    changePipeline(pipelinestatus, status ,currentUser?.pipeline,clientId).then(
      res => setpipelinestatus(status)
    )
  }

  
  const handleAutoDial = (phoneNumber: Client) => {
    if(!currentUser?.app?.telecom){
      const url = `tel:${phoneNumber?.phone}`;
      window.open(url, null, 'toolbar=no,menubar=no');
      return
    }
    const url = `/telecom/dialer/${phoneNumber._id}`;
    const tabName = "myTab";
  
    // Check if the tab is already open
    const existingTab = window.open("", tabName);
  
    if (existingTab) {
      // Tab is already open, reuse it
      existingTab.location.href = url;
    } else {
      // Open a new tab and store the URL in localStorage
     
  
      // Open the new tab
      window.open(url, tabName);
  
      
    }
  };


  return (
   <div> 
        <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
       

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-1 fw-bolder me-1 capitalize'>
                    {profileData.name}
                  </a>
                  {/* <a href='#'>
                    <KTIcon iconName='verify' className='fs-2 text-primary' />
                  </a> */}
                  
                  <button
                      type="button"
                      className={`badge  badge-light-dark me-3 capitalize`}
                    >
                    {profileData.category === "old" ? "Gray" : profileData.category === "new" ? "Blue" : profileData.category }
                    </button>
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                    {profileData.phone}
                  </a>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='geolocation' className='fs-4 me-1' />
                    {profileData.country}
                  </a>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    <KTIcon iconName='sms' className='fs-4 me-1' />
                    {profileData.email}
                  </a>
                </div>
              </div>

              <div className='d-flex my-4'>
                <a href={`https://wa.me/${profileData?.phone}?text=${encodeURIComponent(`Hii, this is ${currentUser?.fullName} from ${companyName}`)}`} className='btn btn-sm btn-light me-2' id='kt_user_follow_button' target='_blank' rel='noreferrer'>
                  <KTIcon iconName='check' className='fs-3 d-none' />

                  <span className='indicator-label'>Whatsapp</span>
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </a>
                <a
                 onClick={()=>handleAutoDial(profileData)}
                  className='btn btn-sm btn-primary me-3'
            
                >
                  Call
                </a>
                <div className='me-0'>
                  <button
                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <i className='bi bi-three-dots fs-3'></i>
                  </button>
                  <Dropdown1 />
                </div>
              </div>
            </div>

            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
              <div className='d-flex flex-wrap'>
        
                    <button
                      type="button"
                      className={`btn btn-sm me-3 mb-3 ${
                        pipelinestatus === 'new' ? 'btn-light-primary active' : 'btn-light-dark'
                      }`}
                      data-mdb-ripple-color="dark"
                      style={{ zIndex: 1 }}
                      onClick = {()=>handlepipelinestatus("new")}
                    >
                      New
                    </button>
                    <button
                      type="button"
                      className={`btn btn-sm me-3 mb-3  ${
                        pipelinestatus === 'prospect' ? 'btn-light-warning active' : 'btn-light-dark'
                      }`}
                      data-mdb-ripple-color="dark"
                      style={{ zIndex: 1 }}
                      onClick = {()=>handlepipelinestatus("prospect")}
                    >
                      Prospect
                    </button>
                    <button
                      type="button"
                      className={`btn btn-sm me-3 mb-3  ${
                        pipelinestatus === 'viewing' ? 'btn-light-info active' : 'btn-light-dark'
                      }`}
                      data-mdb-ripple-color="dark"
                      style={{ zIndex: 1 }}
                      onClick = {()=>handlepipelinestatus("viewing")}
                    >
                      Viewing
                    </button>
                    <button
                      type="button"
                      className={`btn btn-sm me-3 mb-3  ${
                        pipelinestatus === 'close' ? 'btn-light-success active' : 'btn-light-dark'
                      }`}
                      data-mdb-ripple-color="dark"
                      style={{ zIndex: 1 }}
                      onClick = {()=>handlepipelinestatus("close")}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className={`btn btn-sm me-3 mb-3  ${
                        pipelinestatus === 'reject' ? 'btn-light-danger active' : 'btn-light-dark'
                      }`}
                      data-mdb-ripple-color="dark"
                      style={{ zIndex: 1 }}
                      onClick = {()=>handlepipelinestatus("reject")}
                    >
                      Reject
                    </button>
                    <button
                      type="button"
                      className={`btn btn-sm btn-light-dark me-3 mb-3  ${
                        pipelinestatus === 'noanswer' ? 'active' : ''
                      }`}
                      data-mdb-ripple-color="dark"
                      style={{ zIndex: 1 }}
                      onClick = {()=>handlepipelinestatus("noanswer")}
                    >
                      No Answer
                    </button>
                    <button
                      type="button"
                      className={`btn btn-sm btn-light-dark me-3 mb-3  ${
                        pipelinestatus === 'callback' ? 'active' : ''
                      }`}
                      data-mdb-ripple-color="dark"
                      style={{ zIndex: 1 }}
                      onClick = {()=>handlepipelinestatus("callback")}
                    >
                      Call Back
                    </button>
                    <button
                      type="button"
                      className={`btn btn-sm btn-light-dark me-3 mb-3  ${
                        pipelinestatus === 'pending' ? 'active' : ''
                      }`}
                      data-mdb-ripple-color="dark"
                      style={{ zIndex: 1 }}
                      onClick = {()=>handlepipelinestatus("pending")}
                    >
                      Pending
                    </button>
                    <button
                      type="button"
                      className={`btn btn-sm btn-light-dark me-3 mb-3  ${
                        pipelinestatus === 'junk' ? 'active' : ''
                      }`}
                      data-mdb-ripple-color="dark"
                      style={{ zIndex: 1 }}
                      onClick = {()=>handlepipelinestatus("junk")}
                    >
                      Junk
                    </button>
              </div>

              </div>
            </div>
          </div>
        </div>

        <div className='d-flex h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap cursor-pointer'>
          
            <li className='nav-item'>
              <div
                className={
                  `nav-link text-active-primary me-6  
                  ${property === "overview" ? " active" :""}`} onClick={()=>setProperty("overview")
                }>
                
              
                Overview
                </div>
            </li>
            <li className='nav-item'>
              <div
                className={
                  `nav-link text-active-primary me-6  
                  ${property === "properties" ? " active" :""}`} onClick={()=>setProperty("properties")
                }>
                
              
                Properties
                </div>
            </li>
            <li className='nav-item'>
              <div
                className={
                  `nav-link text-active-primary me-6  
                  ${property === "documents" ? " active" :""}`} onClick={()=>setProperty("documents")
                }>
                
              
                Documents
                </div>
            </li>
            
            <li className='nav-item'>
              <div
                className={
                  `nav-link text-active-primary me-6  
                  ${property === "agent" ? " active" :""}`} onClick={()=>setProperty("agent")
                }>
                Agents
                </div>
            </li>

            <li className='nav-item'>
              <div
                className={
                  `nav-link text-active-primary me-6  
                  ${property === "deals" ? " active" :""}`} onClick={()=>setProperty("deals")
                }>
                Deals
                </div>
            </li>

            <li className='nav-item'>
              <div
                className={
                  `nav-link text-active-primary me-6  
                  ${property === "meetings" ? " active" :""}`} onClick={()=>setProperty("meetings")
                }>
                Meetings
                </div>
            </li>
            
           
           
           
          </ul>
        </div>
      </div>
    </div>
    {
        property==="overview" && (
            <div className=' '>
              <div className='card mb-5  '>
                <div className='card-body pt-9 pb-0 mb-xl-10'>
                  <div>
                      <h1 className=' text-2xl font-semibolds p-7 '>Contact Info</h1>
                  </div>
                  
                  <div className=' d-flex flex-wrap flex-stack  '>
                  <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap justify-content-between ps-10 pe-10'>
                        <div className='mb-5 w-150px'>
                          <h2 className=' text-slate-500 text-base '>Full Name</h2>
                          <p className=''>{profileData.name}</p>
                        </div>
                        <div className='mb-5 w-150px'>
                          <h2 className=' text-slate-500 text-base '>Email Id</h2>
                          <p>{profileData.email}</p>
                        </div>
                        <div className='mb-5 w-150px'>
                          <h2 className=' text-slate-500 text-base '>Location</h2>
                          <p>{profileData.country}</p>
                        </div>
                        
                        <div className='mb-5 w-150px'>
                          <h2 className=' text-slate-500 text-base '>Phone number</h2>
                          <p>{profileData.phone}</p>
                        </div>
                        <div className='mb-5 w-200px'>
                          <h2 className=' text-slate-500 text-base '>Alternate phone number</h2>
                          <p>{profileData.alternatePhone}</p>
                        </div>
                        <div className='mb-5 w-150px'>
                          <h2 className=' text-slate-500 text-base '>Source</h2>
                          <p>{profileData.source}</p>
                        </div>
                        
                    </div>
                    </div>
                    </div>
                  </div>
                  </div>
               
                <div className='row g-5 g-xl-8 mt-5'>
                <div className='col-xl-8'>
                  <Notes className='card-xl-stretch mb-xl-8' noteslist = {profileData?.notes} />
              
                </div>
                <div className='col-xl-4'>
                  <Logs className='card-xl-stretch mb-xl-8'  logs = {profileData?.logs}/>
                </div>
               
      </div>
             </div>
        )}
    {
        property==="properties" && (
          <PropertyListbyId/>
            
        )}
    {
        property==="documents" && (
            <Documents/>
        )}
    {
        property==="agent" && (
            <AgentlistbyId/>
        )}
    {
        property==="deals" && (
            <Deallist/>
        )}
    {
        property==="meetings" && (
            <MeetList/>
        )}





</div>
  )
}

export {ClientDetail}
