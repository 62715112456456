import {createSlice} from '@reduxjs/toolkit'
import {Developer} from '../../pages/project/core/_models'

const initialState = {
  developers: [] as Developer[],
}

const developerSlice = createSlice({
  name: 'developer',
  initialState,
  reducers: {
    setDevelopers: (state, action) => {
      state.developers = action.payload
    },
  },
})

export const {setDevelopers} = developerSlice.actions
export default developerSlice.reducer
