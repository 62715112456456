/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import { Infobox } from './components/Infobox'
import { Salesboxs } from './components/Salesboxs'
import { PropertyTable } from './components/PropertyTable'
import { Clientlist } from './components/Clientlist'
import { getexpensecount, gettotalsales, getexpensecountagent, gettotalsalesagent, gettotalrevenue, gettotalrevenueagent } from './core/_requests'
import { useAuth } from '../../modules/auth'
import {useSelector, useDispatch} from 'react-redux'
import { RootState } from '../../redux/store'
import { setExpenseCount, setRevenueCount, setSalesCount } from '../../redux/dashboard/dashboardStatsSlice'







const DashboardMain: FC = () => {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  // const [clientcount, setclientcount] = useState()

  // const [revenuecount, setrevenuecount] = useState()
  // const [expensecount, setexpensecount] = useState()
  // const [salescount, setsalescount] = useState()
  const dispatch = useDispatch()
  const revenuecount = useSelector((state: RootState) => state.dashboard.revenueCount)
  const expensecount = useSelector((state: RootState) => state.dashboard.expenseCount)
  const salescount = useSelector((state: RootState) => state.dashboard.salesCount)

  
  useEffect(() => {
      const countfunc = async() => {
        // const res = await getclientcount(currentUser?.company)
        const resrevenue = await gettotalrevenue(currentUser?.company)
        const resexpense = await getexpensecount(currentUser?.company)
        const ressales = await gettotalsales(currentUser?.company)
        // setclientcount(res.totalClients)
    
        // setrevenuecount(resrevenue.totalRevenue)
        // setexpensecount(resexpense)
        // setsalescount(ressales.totalSales)
        dispatch(setRevenueCount(resrevenue.totalRevenue))
        dispatch(setExpenseCount(resexpense))
        dispatch(setSalesCount(ressales.totalSales))   
      }
    
      const countagentfunc = async() => {
        // const res = await getclientcountagent(currentUser?._id)
        const resrevenue = await gettotalrevenueagent(currentUser?._id)
        const resexpense = await getexpensecountagent(currentUser?._id)
        const ressales = await gettotalsalesagent(currentUser?._id)
        // setclientcount(res.totalClients)
        // console.log(first)
    
        // setexpensecount(resexpense)
        // setsalescount(ressales.totalSales)
        // setrevenuecount(resrevenue.totalRevenue)
        dispatch(setRevenueCount(resrevenue.totalRevenue))
        dispatch(setExpenseCount(resexpense))
        dispatch(setSalesCount(ressales.totalSales))  
      }
     
    // if(currentUser?.profileType === "agent" ){
      if(revenuecount === 'Loading' && salescount === 'Loading' && expensecount === 'Loading' ){
      if(permissions?.dashboard?.admin === false ){
      countagentfunc()
    } else if(currentUser?.profileType === "superadmin" || permissions?.dashboard?.admin){
      
    // {
      countfunc()
      
    }
    }
 
  }, [permissions?.dashboard, currentUser, dispatch, expensecount, revenuecount, salescount])
  
  



  return( <>
       {/* begin::Row */}
       <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
       {/* <div className="row">
    <div className="col-4">
      <Infobox p='Number of Clients' h3='200' />
    </div>
    <div className="col-4">
      <Infobox p='Number of Agents' h3='14' />
    </div>
    <div className="col-4">
      <Infobox p='Number of developers' h3='35' />
    </div>
  </div>  */}
  {/* begin::Row */}
   {/* begin::Row */}

   <div className='col-xl-4'>
          <Infobox
            className='card-xl-stretch mb-xl-8'
            svgIcon='cheque'
            color='danger'
            iconColor='light'
            title={expensecount}
            titleColor='light'
            description='Total Expenses'
            descriptionColor='light'
          />
        </div>

   
        <div className='col-xl-4'>
          <Infobox
            className='card-xl-stretch mb-xl-8'
            svgIcon='graph-up'
            color='warning'
            iconColor='light'
            title={revenuecount}
            titleColor='light'
            description='Revenue Forecast'
            descriptionColor='light'
          />
        </div>


        <div className='col-xl-4'>
          <Infobox
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='dollar'
            color='success'
            iconColor='light'
            title={salescount}
            titleColor='light'
            description='Total Sales'
            descriptionColor='light'
          />
        </div>
        </div>
      {/* end::Row */}

  <div className='row mt-4 h-100'>
  <div className='col-xl-4 mb-4  overflow-hidden'>
     <Salesboxs        chartColor='primary'
            chartHeight='200px'
            strokeColor='#0078d0'/>
  </div>
  <div className='col-xl-8 mb-4 '>
    <PropertyTable/>
  </div>
</div>
         
        
 
      
            <Clientlist/>
         
  

      

        
   </>
 )}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardMain />
    </>
  )
}

export {DashboardWrapper}
