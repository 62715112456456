import React, {FC, useEffect, useState} from 'react'
import {
  StatisticsWidget5,
} from '../../../../_metronic/partials/widgets'
import { useAuth } from '../../../modules/auth'
import { getclientcount, getagentcount } from '../core/_requests'
import {useSelector, useDispatch} from 'react-redux'
import { RootState } from '../../../redux/store'
import { setClientCount } from '../../../redux/agent/agentStatsSlice'


const Agentstat: FC = () => {

  const {currentUser} = useAuth()
  const dispatch = useDispatch()
  const clientCounts = useSelector((state: RootState) => state.agent.agentStats.clientCount)

  const [permissions, setPermissions] = useState<any>({})

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])


  
  useEffect(() => {
    const countfunc = async() => {
      if(currentUser?.profileType === "agent"){
       
        const res = await getclientcount(currentUser?._id)
           dispatch(setClientCount(res.clientLength))
      }else if(currentUser?.profileType === "superadmin" || permissions?.agents?.read){
        const res = await getagentcount(currentUser?.company)
     
        // setclientcount(res.totalAgents)
        dispatch(setClientCount(res.totalAgents))
   
  
      }
      
   
  
    }

    if (clientCounts === 'Loading')
      countfunc()
  }, [clientCounts, dispatch, currentUser, permissions])
  
  
  return (
    <>
    <div className='card-title align-items-start d-flex flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Statitics</span>

        
          
        </div>
      <div className='row g-5 g-xl-8 mt-0'>
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='briefcase'
            color='dark'
            iconColor='white'
            title={clientCounts}
            titleColor='white'
            description={`${(currentUser?.profileType === "superadmin" || currentUser?.profileType === 'admin') ? "Total Agents": currentUser?.profileType === "agent" ?"Total Clients":""}`}
            descriptionColor='white'
          />
        </div>

        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8'
            svgIcon='dollar'
            color='success'
            iconColor='light'
            title='AED 0'
            titleColor='white'
            description='Monthly Sales'
            descriptionColor='white'
          />
        </div>

        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='cheque'
            color='danger'
            iconColor='white'
            title='AED 0'
            titleColor='white'
            description='Monthly Expense'
            descriptionColor='white'
          />
        </div>
      </div>


     
    </>
  )
}

export {Agentstat}
