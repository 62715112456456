import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
/* import {PageLink, PageTitle} from '../../../_metronic/layout/core' */




import { ClientWrapper } from '../ClientWrapper'
import { ClientDetail } from './ClientDetail'



const ClientPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/dashboard'
          element={
            <>
              {/* <PageTitle breadcrumbs={chatBreadCrumbs}>Primary</PageTitle> */}
              <ClientWrapper />

            </>
          }
        />
        <Route
          path='clientDetails/:id'
          element={
            <>
             {/*  <PageTitle breadcrumbs={chatBreadCrumbs}>Details</PageTitle> */}
              <ClientDetail />
            </>
          }
        />
       
        <Route index element={<Navigate to='/clients/dashboard' />} />
      </Route>
    </Routes>
  )
}

export default ClientPage