import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getInvoicebyids } from '../core/_requests'
import './print.css'; 



function Invoice() {
  const id = useParams().id
const [data, setdata] = useState([])
  const getinvoicedetails = async () => {
    await getInvoicebyids(id).then((res) => {
      console.log(res)
      setdata(res)

    })
  }

  useEffect(() => {
    getinvoicedetails()
  }, [])

  const formatCreatedAt = (createdAt: string | undefined) => {
    if (!createdAt) return 'N/A';
    const formattedDate = new Date(createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
    return formattedDate;
  };

  const handlePrint = () => {
    window.print(); // This will trigger the browser's print functionality
  };
  

//   const handleDownloadPDF = () => {
//     const content = document.getElementById('pdf-content');
// console.log("dfv")
//     if (content) {
//       // Apply styles to hide specific elements
//       const hiddenElements = content.querySelectorAll('.hide-on-pdf');
//       hiddenElements.forEach((element) => {
//         // Cast the Node to HTMLElement to access the style property
//         const htmlElement = element as HTMLElement;
//         htmlElement.style.display = 'none';
//       });

//       html2pdf(content);
//     }

    
//   }

  return (
    <div id="pdf-content" className="app-content flex-column-fluid print-content">
  {/* begin::Content container */}
  <div id="kt_app_content_container" className="app-container container-xxl">
    {/* begin::Invoice 2 main */}
    <div className="card">
      {/* begin::Body */}
      <div className="card-body p-lg-20">
        {/* begin::Layout */}
        <div className="d-flex flex-column flex-xl-row">
          {/* begin::Content */}
          <div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">
            {/* begin::Invoice 2 content */}
            <div className="mt-n1">
              {/* begin::Top */}
              <div className="d-flex flex-stack pb-10">
                {/* begin::Logo */}
                              {/* end::Logo */}
                {/* begin::Action */}
             
                <button   className=" hide-on-pdf btn btn-danger print-button" id="kt_invoice_submit_button" onClick={handlePrint}>
                print Invoice</button>
                {/* <button   className=" hide-on-pdf btn btn-primary print-button" id="kt_invoice_submit_button" onClick={handleDownloadPDF}>
                Download Invoice</button> */}
                {/* end::Action */}
              </div>
              {/* end::Top */}
              {/* begin::Wrapper */}
              <div className="m-0">
                {/* begin::Label */}
                <div className="fw-bold fs-3 text-gray-800 mb-8">Invoice #{data[0]?.id}</div>
                {/* end::Label */}
                {/* begin::Row */}
                <div className="row g-5 mb-11">
                  {/* end::Col */}
                  <div className="col-sm-6">
                    {/* end::Label */}
                    <div className="fw-semibold fs-7 text-gray-600 mb-1">Issue Date:</div>
                    {/* end::Label */}
                    {/* end::Col */}
                    <div className="fw-bold fs-6 text-gray-800">{formatCreatedAt(data[0]?.createdAt)}</div>
                    {/* end::Col */}
                  </div>
                  {/* end::Col */}
                  {/* end::Col */}
                  {/* <div className="col-sm-6">
                
                    <div className="fw-semibold fs-7 text-gray-600 mb-1">Due Date:</div>
                   
                    <div className="fw-bold fs-6 text-gray-800 d-flex align-items-center flex-wrap">
                      <span className="pe-2">02 May 2021</span>
                      <span className="fs-7 text-danger d-flex align-items-center">
                        <span className="bullet bullet-dot bg-danger me-2"></span>Due in 7 days
                      </span>
                    </div>
               
                  </div> */}
                  {/* end::Col */}
                </div>
                {/* end::Row */}
                {/* begin::Row */}
                <div className="row g-5 mb-12 d-flex flex-row" >
                  {/* end::Col */}
                  <div className="col-4">
                    {/* end::Label */}
                    <div className="fw-semibold fs-7 text-gray-600 mb-1">Issue For:</div>
                    {/* end::Label */}
                    {/* end::Text */}
                    <div className="fw-bold fs-6 text-gray-800">{data[0]?.client?.name}</div>
                    {/* end::Text */}
                    {/* end::Description */}
                    <div className="fw-semibold fs-7 text-gray-600">{data[0]?.client?.email}
                      <br />{data[0]?.client?.phone}</div>
                    {/* end::Description */}
                  </div>
                  {/* end::Col */}
                  {/* end::Col */}
                  <div className="col-4">
                    {/* end::Label */}
                    <div className="fw-semibold fs-7 text-gray-600 mb-1">Issued By:</div>
                    {/* end::Label */}
                    {/* end::Text */}
                    <div className="fw-bold fs-6 text-gray-800">{data[0]?.agent?.fullName}</div>
                    {/* end::Text */}
                    {/* end::Description */}
                    <div className="fw-semibold fs-7 text-gray-600">{data[0]?.agent?.email}
                      <br />{data[0]?.agent?.phone}</div>
                    {/* end::Description */}
                  </div>
                  <div className="col-4">
                    {/* end::Label */}
                    <div className="fw-semibold fs-7 text-gray-600 mb-1">Property:</div>
                    {/* end::Label */}
                    {/* end::Text */}
                    <div className="fw-bold fs-6 text-gray-800">{data[0]?.inventory?.name}</div>
                    {/* end::Text */}
                    {/* end::Description */}
                    <div className="fw-semibold fs-7 text-gray-600">{data[0]?.inventory?.address}
                      <br />{data[0]?.inventory?.projectType}</div>
                    {/* end::Description */}
                  </div>
                  {/* end::Col */}
                </div>
                {/* end::Row */}
                {/* begin::Content */}
                <div className="flex-grow-1">
                  {/* begin::Table */}
                  <div className="table-responsive border-bottom mb-9">
                    <table className="table mb-3">
                      <thead>
                        <tr className="border-bottom fs-6 fw-bold text-muted">
                          <th className="min-w-175px pb-2">Property</th>
                          <th className="min-w-80px text-end pb-2">Description</th>
                          <th className="min-w-100px text-end pb-2">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                      {
                        data[0]?.items?.map((item)=>(
                          <tr className="fw-bold text-gray-700 fs-5">
                          <td className="d-flex align-items-center pt-6">
                            <i className="fa fa-genderless text-danger fs-2 me-2"></i>{item.name}
                          </td>
                          <td className="pt-6">{item.description}</td>
                          <td className="pt-6 text-dark fw-bolder">{item.amount}</td>
                        </tr>
                        ))
                       }
                      </tbody>
                    </table>
                  </div>
                  {/* end::Table */}
                  {/* begin::Container */}
                  <div className="d-flex justify-content-end">
                    {/* begin::Section */}
                    <div className="mw-300px">
                      {/* begin::Item */}
                      <div className="d-flex flex-stack mb-3">
                        {/* begin::Accountname */}
                        <div className="fw-semibold pe-10 text-gray-600 fs-7">Subtotal:</div>
                        {/* end::Accountname */}
                        {/* begin::Label */}
                        <div className="text-end fw-bold fs-6 text-gray-800">{data[0]?.subtotal}</div>
                        {/* end::Label */}
                      </div>
                      {/* end::Item */}
                      {/* begin::Item */}
                      <div className="d-flex flex-stack mb-3">
                        {/* begin::Accountname */}
                        <div className="fw-semibold pe-10 text-gray-600 fs-7">VAT 0%</div>
                        {/* end::Accountname */}
                        {/* begin::Label */}
                        <div className="text-end fw-bold fs-6 text-gray-800">{data[0]?.tax}</div>
                        {/* end::Label */}
                      </div>
         
                      <div className="d-flex flex-stack">
                        {/* begin::Code */}
                        <div className="fw-semibold pe-10 text-gray-600 fs-7">Total</div>
                        {/* end::Code */}
                        {/* begin::Label */}
                        <div className="text-end fw-bold fs-6 text-gray-800">{data[0]?.total}</div>
                        {/* end::Label */}
                      </div>
                      {/* end::Item */}
                    </div>
                    {/* end::Section */}
                  </div>
                  {/* end::Container */}
                </div>
                {/* end::Content */}
              </div>
              {/* end::Wrapper */}
            </div>
            {/* end::Invoice 2 content */}
          </div>
          {/* end::Content */}
          {/* begin::Sidebar */}
          <div className="m-0">
            {/* begin::Invoice 2 sidebar */}
            {/* d-print-none */}
            <div className=" border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-350px p-9 bg-lighten">
              {/* begin::Labels */}
              <div className="mb-8">
                <span className="badge badge-light-info me-2">{data[0]?.status}</span>
                {
                  data[0]?.paid ?(
                    <span className="badge badge-light-success">Paid By: {data[0]?.paymentMethod}</span>
                  ):(
                    <span className="badge badge-light-warning">Pending Payment</span>
                   ) 
                }
               
              </div>
              {/* end::Labels */}
              {/* begin::Title */}
              <h6 className="mb-8 fw-bolder text-gray-600 text-hover-primary">PAYMENT DETAILS</h6>
              {/* end::Title */}
              {/* begin::Item */}
              <div className="mb-6">
                <div className="fw-semibold text-gray-600 fs-7">Paypal:</div>
                <div className="fw-bold text-gray-800 fs-6">codelabpay@codelab.co</div>
              </div>
              {/* end::Item */}
              {/* begin::Item */}
              <div className="mb-6">
                <div className="fw-semibold text-gray-600 fs-7">Account:</div>
                <div className="fw-bold text-gray-800 fs-6">Nl24IBAN34553477847370033
                  <br />AMB NLANBZTC</div>
              </div>
              {/* end::Item */}
              {/* begin::Item */}
     
              {/* end::Item */}
              {/* begin::Title */}
             
              {/* end::Item */}
            </div>
            {/* end::Invoice 2 sidebar */}
          </div>
          {/* end::Sidebar */}
        </div>
        {/* end::Layout */}
      </div>
      {/* end::Body */}
    </div>
    {/* end::Invoice 2 main */}
  </div>
  {/* end::Content container */}
</div>

  )
}

export default Invoice