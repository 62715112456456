import {createSlice} from '@reduxjs/toolkit'
import {Property} from '../../pages/project/core/_models'

const initialState = {
  propertyList: [] as Property[],
}

const secondarySlice = createSlice({
  name: 'secondary',
  initialState,
  reducers: {
    setPropertyList: (state, action) => {
      state.propertyList = action.payload
    },
  },
})

export const {setPropertyList} = secondarySlice.actions
export default secondarySlice.reducer
