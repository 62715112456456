import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
// import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

// import MarketingWrapper from '../pages/marketing/MarketingWrapper'
import MapWrapper from '../pages/map/MapWrapper'
import ProjectPage from '../pages/project/ProjectPage'
import PipelineWrapper from '../pages/pipeline/PipelineWrapper'
import CalenderWrapper from '../pages/calender/CalenderWrapper'
import Salespage from '../pages/sales/Salespage'

import ClientPage from '../pages/client/components/ClientPage'
import AgentPage from '../pages/agent/components/AgentPage'
import {useAuth} from '../modules/auth'
import ProjectWrapper from '../pages/task-management/ProjectWrapper'
import MeetingsPage from '../pages/meetings/MeetingsPage'
import TasksPage from '../pages/task-calendar/TasksPage'
import ReportsPage from '../pages/hrm/ReportsPage'
import MAccountWrapper from '../pages/maccount/AccountWrapper'
import ReportWrapper from '../pages/maccount/ReportWrapper'
import ClientPerformance from '../pages/hrm/Components/reports/ClientPerformance'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const UserManagementPage = lazy(() => import('../pages/user-management/UserManagementPage'))
  const Deparment = lazy(() => import('../pages/department/Deparment'))
  const ModulesPage = lazy(() => import('../pages/modules/ModulesPage'))
  const RoleManagementPage = lazy(() => import('../pages/role-management/RoleManagementPage'))
  const EventPage = lazy(() => import('../pages/event/EventPage'))
  const EventDetails = lazy(() => import('../pages/event/EventDetails'))
  const StoragePage = lazy(() => import('../pages/storage/StoragePage'))
  const AccountWrapper = lazy(() => import('../pages/account/AccountWrapper'))
  const Setting = lazy(() => import('../pages/setting/Setting'))
  const TelecomPage = lazy(() => import('../pages/telecom/TelecomPage'))

  const HrmPage = lazy(() => import('../pages/hrm/HrmPage'))
  const LeaveManagement = lazy(() => import('../pages/hrm/LeaveManagement'))
  const LeavePage = lazy(() => import('../pages/hrm/AttendancePage'))
  const DepartmentRoutes = lazy(() => import('../pages/hrm/DepartmentRoutes'))
  const HRMDocument = lazy(() => import('../pages/hrm/Pages/HRMDocument'))
  const FilesPage = lazy(() => import('../pages/hrm/Components/FilesPage'))
  const Payroll = lazy(() => import('../pages/hrm/Payroll'))
  const TimeTrackerPage = lazy(() => import('../pages/hrm/TimeTrackerPage'))
  const ReportsRoutes = lazy(() => import('../pages/hrm/ReportsRoutes'))
  const EmployeeReportsRoutes = lazy(() => import('../pages/hrm/employeeReportsRoutes'))

  const {currentUser} = useAuth()
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route
          path='auth/*'
          element={
            currentUser?.profileType === 'marketing' || currentUser?.profileType === 'agent' ? (
              <Navigate to='/property' />
            ) : (
              <Navigate to='/dashboards' />
            )
          }
        />
        {/* Pages */}
        {currentUser?.app?.crm ? (
          <Route path='dashboards' element={<DashboardWrapper />} />
        ) : (
          <Route
            path='dashboards/*'
            element={
              <>
                {currentUser?.profileType === 'superadmin' && (
                  <Navigate to='/modules/module-list' />
                )}
              </>
            }
          />
        )}
        {currentUser?.app?.crm && (
          <>
            <Route path='clients/*' element={<ClientPage />} />
            <Route path='agents/*' element={<AgentPage />} />
            <Route path='property/*' element={<ProjectPage />} />
            <Route path='sales/*' element={<Salespage />} />
            <Route path='maps' element={<MapWrapper />} />
            <Route path='pipeline' element={<PipelineWrapper />} />
            <Route path='/agent-report/*' element={<ReportsPage />} />
            <Route path='/client-report' element={<ClientPerformance />} />
          </>
        )}

        <Route
          path='storage/*'
          element={
            <SuspensedView>
              <StoragePage />
            </SuspensedView>
          }
        />
        <Route
          path='events/*'
          element={
            <SuspensedView>
              <EventPage />
            </SuspensedView>
          }
        />
        <Route
          path='events/eventdetail/*'
          element={
            <SuspensedView>
              <EventDetails />
            </SuspensedView>
          }
        />
        {/* <Route path='marketing/*' element={<MarketingWrapper />} /> */}
        <Route
          path='telecom/*'
          element={
            <SuspensedView>
              <TelecomPage />
            </SuspensedView>
          }
        />
        <Route
          path='expenses/*'
          element={
            <SuspensedView>
              <AccountWrapper />
            </SuspensedView>
          }
        />

        {currentUser?.app?.hrm && (
          <>
            <Route
              path='employee/*'
              element={
                <SuspensedView>
                  <HrmPage />
                </SuspensedView>
              }
            />
            <Route
              path='leaveManagement/*'
              element={
                <SuspensedView>
                  <LeaveManagement />
                </SuspensedView>
              }
            />
            <Route
              path='attendance/*'
              element={
                <SuspensedView>
                  <LeavePage />
                </SuspensedView>
              }
            />
            <Route
              path='empDepartment/*'
              element={
                <SuspensedView>
                  <DepartmentRoutes />
                </SuspensedView>
              }
            />
            <Route
              path='hrm/documents/'
              element={
                <SuspensedView>
                  <HRMDocument />
                </SuspensedView>
              }
            />
            <Route
              path='hrm/documents/:id'
              element={
                <SuspensedView>
                  <FilesPage />
                </SuspensedView>
              }
            />
            <Route
              path='payroll/*'
              element={
                <SuspensedView>
                  <Payroll />
                </SuspensedView>
              }
            />
            <Route
              path='time-tracker/*'
              element={
                <SuspensedView>
                  <TimeTrackerPage />
                </SuspensedView>
              }
            />
            <Route
              path='/reports/*'
              element={
                <SuspensedView>
                  <ReportsRoutes />
                </SuspensedView>
              }
            />
            <Route
              path='/submissionReports/*'
              element={
                <SuspensedView>
                  <EmployeeReportsRoutes />
                </SuspensedView>
              }
            />
          </>
        )}

        {currentUser?.app?.accounts && (
          <>
            <Route path='/account/*' element={<MAccountWrapper />} />
            <Route path='/account/reports/*' element={<ReportWrapper />} />
          </>
        )}

        <Route path='calendar' element={<CalenderWrapper />} />
        <Route path='sprint/*' element={<ProjectWrapper />} />
        <Route path='meetings/*' element={<MeetingsPage />} />
        <Route path='tasks/*' element={<TasksPage />} />
        {/* <Route path='builder' element={<BuilderPageWrapper />} /> */}
        <Route
          path='setting'
          element={
            <SuspensedView>
              <Setting />
            </SuspensedView>
          }
        />

        <Route
          path='modules/*'
          element={
            <SuspensedView>
              <ModulesPage />
            </SuspensedView>
          }
        />
        <Route
          path='department/*'
          element={
            <SuspensedView>
              <Deparment />
            </SuspensedView>
          }
        />

        <Route
          path='role-management/*'
          element={
            <SuspensedView>
              <RoleManagementPage />
            </SuspensedView>
          }
        />

        <Route
          path='superuser-management/*'
          element={
            <SuspensedView>
              <UserManagementPage />
            </SuspensedView>
          }
        />

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
