/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import './mixed.css'

import {KTIcon} from '../../../../_metronic/helpers'
import { Property } from '../core/_models'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../modules/auth'

type Props = {
  className: String
  item: Property
  handleid:any
}

const PF:any = "https://unifeed.s3.ap-south-1.amazonaws.com/";

const PropertyWidget: React.FC<Props> = ({className, handleid,item}) => {
  const {currentUser} = useAuth()
  const [permissions, setPermissions] = useState<any>({})

  useEffect(() => {
    if (currentUser?.permissions) {
      setPermissions(currentUser?.permissions)
    }
  }, [currentUser])

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Header */}
        <div className={`px-9 pt-7 card-rounded h-275px w-100  object-fit-cover`} style={{
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.381)), url('${PF + item?.productPic}')`,
    backgroundSize: 'cover',  // Add this line to make the image cover the entire div
  }}
>
          
          {/* begin::Heading */}
          <div className='d-flex flex-stack'>
            <h3 className='m-0 text-white fw-bold fs-3 capitalize' >{item?.name}</h3>
            <div className='ms-1'>
         

            {(currentUser?.profileType === 'superadmin' || permissions?.property?.update) && <button
              type='button'
              className='btn btn-sm btn-icon btn-color-white btn-active-light-white'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              >
              <KTIcon iconName='category' className='fs-2' />
              </button>}
              {/* begin::Menu 2 */}
              <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-200px'
              data-kt-menu='true' style={{ zIndex: 1000000}}
              >
              {/* begin::Menu item */}
              <div className='menu-item px-3'>
                <div className='menu-content fs-6 text-dark fw-bold px-3 py-4'>Quick Actions</div>
              </div>
       
              <div className='menu-item px-3' onClick={()=>handleid(item?._id)}  data-bs-toggle="modal" data-bs-target="#kt_modal_3">
                <a href='#' className='menu-link px-3'>
                  Edit
                </a>
              </div>
              <div className='separator mt-3 opacity-75'></div>
              <div className='menu-item px-3' onClick={()=>handleid(item?._id)}  data-bs-toggle="modal" data-bs-target="#kt_modal_4">
                <a href='#' className='menu-link px-3'>
                  Delete
                </a>
              </div>
              <div className='separator mt-3 opacity-75'></div>
              </div>
         
          </div>
          </div>
          {/* end::Heading */}
          {/* begin::Balance */}
          <div className='d-flex text-center flex-column text-white pt-8 '>
            <span className='fw-semibold fs-5 ' >Price</span>
            <span className='fw-bold fs-2x pt-1'>{item?.startingprice}</span>
          </div>
          {/* end::Balance */}
        </div>
        {/* end::Header */}
        {/* begin::Items */}
        <Link to={`/property/propertydetail/${item._id}`}>
        <div
          className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body'
          style={{marginTop: '-100px'}}
        >
          {/* begin::Item */}
          <div className='d-flex align-items-center mb-6'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='compass' className='fs-1' />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <div className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  Project Type
                </div>
                
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1'>{item?.projectType}</div>
     
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className='d-flex align-items-center mb-6'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='category' className='fs-1' />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <div className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                 Area
                </div>
              
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1'>{item?.area}</div>
              
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className='d-flex align-items-center mb-6'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='phone' className='fs-1' />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <div className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  Status
                </div>
               
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1'>{item?.status}</div>
             
              </div>
              {/* end::Label */}
            </div>
            {/* end::Description */}
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className='d-flex align-items-center'>
            {/* begin::Symbol */}
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='document' className='fs-1' />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Description */}
            <div className='d-flex align-items-center flex-wrap w-100'>
              {/* begin::Title */}
              <div className='mb-1 pe-3 flex-grow-1'>
                <div className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  Handover
                </div>
  
              </div>
              {/* end::Title */}
              {/* begin::Label */}
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1'>{item?.handOver}</div>
              </div>
              {/* end::Label */}
            </div>
            
            {/* end::Description */}
          </div>
          {/* end::Item */}
        </div>
        {/* end::Items */}
        </Link>
      </div>
      {/* end::Body */}


      
    </div>
  )
}

export {PropertyWidget}
